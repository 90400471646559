import React, { Component } from 'react';
import { Segment, Table, Container, Button, Icon, Form } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { log } from '../../util/api';

class EventTable extends Component {
	state = {
		mainlabel: 'closed',
		category: 'state',
		group_key: 'title',
		group_value: '',
		loading: true,
	};
	componentDidMount() {
		this.getUserList();
		this.getGroupList();
	}
	componentDidUpdate(oldProps, oldState) {
		if (this.state.group_key !== oldState.group_key) {
			this.getGroupList();
		}
	}
	async getUserList() {
		let { start_date, end_date, product } = this.props;
		let pid = product.settings.live.token;
		this.setState({ buttonLoading: true });
		fetch('https://live.dahi.ai/analytics/dialogtab/userList', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					users: data.data,
				});
			});
	}
	async getGroupList() {
		let { mainlabel, group_key } = this.state;
		let category = mainlabel === 'closed' ? 'state' : 'product';
		let { product } = this.props;
		let pid = product.settings.live.token;
		await log('/live/generateDropdown', { where: { pid, label: mainlabel, category }, group: `extra->'${group_key}'` }).then((data) => {
			let result = data.data.result;
			this.setState({
				groupList: _.map(result, (it) => ({
					text: it,
					value: it,
				})),
				loading: false,
			});
		});
	}
	async getEventsList() {
		let { mainlabel, group_key, group_value } = this.state;
		let category = mainlabel === 'closed' ? 'state' : 'product';
		let { product, start_date, end_date } = this.props;
		let pid = product.settings.live.token;
		await log('/live/filterUser', {
			start_date: moment(start_date).valueOf(),
			end_date: moment(end_date).valueOf(),
			pid,
			label: mainlabel,
			category,
			group_key: `extra->>'${group_key}'`,
			group_value: `'${group_value}'`,
		}).then((data) => {
			let result = data.data.message;
			this.setState({
				result,
			});
		});
	}
	handleChange = (e, { name, value }) => {
		this.setState({ [name]: value });
		if (name === 'mainlabel') {
			this.setState({ group_key: '', result: [] });
		}
	};
	render() {
		let { mainlabel, group_key, group_value, loading, groupList, result, users } = this.state;
		let { t } = this.props;
		let mainList = [
			{
				text: t('closed'),
				value: 'closed',
			},
			{
				text: t('outofstock'),
				value: 'out-of-stock',
			},
		];
		let closedList = [
			{
				text: t('title'),
				value: 'title',
			},
		];
		let outofstockList = [
			{
				text: t('productId'),
				value: 'product_id',
			},
			{
				text: t('productCode'),
				value: 'product_code',
			},
			{
				text: t('productName'),
				value: 'product_name',
			},
		];
		let sublist = [],
			selectedHeader = [];
		let closedHeader = [
			{
				title: t('phone'),
				value: 'extra.user_phone',
			},
			{
				title: t('title'),
				value: 'extra.title',
			},
			{
				title: t('date'),
				value: 'created_at',
			},
		];
		let outofstockHeader = [
			{
				title: t('phone'),
				value: 'extra.user_phone',
			},
			{
				title: t('productName'),
				value: 'extra.product_name',
			},
			{
				title: t('productCode'),
				value: 'extra.product_code',
			},
			{
				title: t('productId'),
				value: 'extra.product_id',
			},
			{
				title: t('size'),
				value: 'extra.product_size',
			},
			{
				title: t('sizeCode'),
				value: 'extra.product_size_code',
			},
			{
				title: t('sizeId'),
				value: 'extra.product_size_id',
			},
			{
				title: t('date'),
				value: 'created_at',
			},
		];
		switch (mainlabel) {
			case 'closed':
				sublist = closedList;
				selectedHeader = closedHeader;
				break;
			case 'out-of-stock':
				selectedHeader = outofstockHeader;
				sublist = outofstockList;
				break;

			default:
				break;
		}
		if (loading) return <Page.Loader></Page.Loader>;
		return (
			<Container>
				<Segment>
					<Form onSubmit={() => this.getEventsList()}>
						<Form.Group>
							<Form.Dropdown
								selection
								label={t('mainlabel')}
								options={mainList}
								name="mainlabel"
								value={mainlabel}
								onChange={this.handleChange}
							/>
							<Form.Dropdown
								required
								selection
								label={t('category')}
								options={sublist}
								name="group_key"
								placeholder={t('selectCategory')}
								value={group_key}
								onChange={this.handleChange}
							/>
							<Form.Dropdown
								required
								disabled={!group_key}
								selection
								search
								label={t('group')}
								options={groupList}
								name="group_value"
								value={group_value}
								onChange={this.handleChange}
								placeholder={t('selectGroup')}
							/>
							<Button type="submit" style={{ marginTop: 'auto', marginLeft: '10px' }} positive>
								{t('get')}
							</Button>
						</Form.Group>
					</Form>
					<br />
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>#</Table.HeaderCell>
								{_.map(selectedHeader, ({ title }) => (
									<Table.HeaderCell>{title}</Table.HeaderCell>
								))}
								<Table.HeaderCell></Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{_.map(result, (it, i) => (
								<Table.Row>
									<Table.Cell>{i + 1}</Table.Cell>
									{_.map(selectedHeader, (o) => {
										let m = o.value;
										if (/created_at/.test(m)) return <Table.Cell>{moment(_.get(it, m)).format('DD-MM-YYYY HH:mm')}</Table.Cell>;
										else if (/phone/.test(m) && !_.get(it, m)) {
											let user = _.find(users, { conversation_id: it.cid });
											return <Table.Cell>{user && user.phone}</Table.Cell>;
										} else return <Table.Cell>{_.get(it, m)}</Table.Cell>;
									})}
									<Table.Cell>
										{
											<Button icon as={Link} target="_blank" to={'/#' + it.cid} color="blue">
												<Icon name="conversation"></Icon>
											</Button>
										}
									</Table.Cell>
								</Table.Row>
							))}
						</Table.Body>
					</Table>
				</Segment>
			</Container>
		);
	}
}

const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product.live });

export default withRouter(connect(stateToProps)(withTranslation()(EventTable)));
