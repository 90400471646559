import React, { Component } from 'react';
import Page from '../../../components/Page';
import AccountProgress from '../../../components/AccountProgress';
import { Link } from 'react-router-dom';
import { Button, Breadcrumb, Icon, Segment, Modal, Header, List, Responsive, Popup, Message, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { toWapi, get } from '../../../util/api';
import MyMenu from '../menu';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
// eslint-disable-next-line
import styled from 'styled-components/macro';

class Phones extends Component {
	phoneToDelete = null;

	state = {
		currentPhones: 0,
		totalPhones: 0,
		loading: true,
		imageLoad: true,
		phones: [],
		refreshBtn: false,
		selectedPhone: null,
		screen_ss: null,
		addBtnClickable: true,
		deleteBtnClicked: false,
		hoveredIndex: null,
		goToSubs: false,
		phoneLoading: false,
	};

	componentDidMount() {
		let { product } = this.props;
		let pid = product.id;

		this.mount = true;
		if (product.settings === null || !product.settings.subscription_package || !product.settings.subscription_package) {
			return this.setState({ loading: false });
		}

		toWapi(`/api/${product.settings.wapi_pid || pid}/listPhones`, product.settings.wapi_token)
			.then((data) => {
				if (!this.mount) return;
				if (data.status !== 200) {
					return;
				}
				data = data = _.filter(data.data, function (it) {
					return it.type === 'instagram';
				});
				/* 
				for (let i = 0; i < data.length; i++) {
					data[i].order = i;
				} */
				let currentPhones = data.length;
				let totalPhones = currentPhones;
				if (product.settings && product.settings.instagram_limit) {
					totalPhones = product.settings.instagram_limit;
				}
				this.setState({ loading: false, phones: data, currentPhones, totalPhones, addBtnClickable: currentPhones < totalPhones });
			})
			.catch((err) => {
				console.error(err);
				this.setState({ loading: false });
			});
	}
	componentWillUnmount() {
		clearTimeout(this._sr);
	}
	loginInstagram = () => {
		let selectedPhoneId = this.state.selectedPhoneId;
		let { product } = this.props;
		this.setState({ loginLoading: true });
		let account = _.find(this.state.phones, { id: selectedPhoneId });
		toWapi(`/api/${product.settings.wapi_pid || product.id}/${selectedPhoneId}/login`, product.settings.wapi_token, {
			username: this.state.email,
			password: this.state.password,
			code: this.state.code,
		})
			.then((data) => {
				data = data.data;
				if (data.success) {
					this.updateStatusWhenClicked();
					account.number = this.state.email;
					this.setState({ createAccountModal: false, loginLoading: false });
					return;
				}
				if (data.success === false) {
					this.setState({
						loginError: data.error,
						code_required: data.code_required,
						two_factor_code: data.two_factor_code,
						loginLoading: false,
					});
					return;
				}
				let phones = this.state.phones;
				this.setState({ phones, currentPhones: phones.length, addBtnClickable: this.state.totalPhones > phones.length });
			})
			.catch((err) => {
				console.error(err);
			});
	};
	addNewPhone = () => {
		let { product } = this.props;
		toWapi(`/api/${product.settings.wapi_pid || product.id}/addInstagram`, product.settings.wapi_token, {})
			.then((data) => {
				if (data.status !== 200) {
					return;
				}
				data = data.data;

				if (data.success === false) {
					return;
				}
				let phones = this.state.phones;
				let newPhone = {
					id: data.id,
					number: data.number,
					status: 'creating...',
				};
				phones.push(newPhone);
				this.setState({ phones, currentPhones: phones.length, addBtnClickable: this.state.totalPhones > phones.length, selectedPhone: newPhone });
				this.updateStatusWhenClicked();
			})
			.catch((err) => {
				console.error(err);
			});
	};
	openCreateModal = (id, status) => {
		this.setState({
			//createAccountModal: true,
			twofactorwarning: true,
			selectedPhoneId: id,
			selectedPhone: {
				id,
				status,
			},
		});
	};
	logoutPhone = (id, number, status) => {
		//let selectedPhone = this.state.selectedPhone;
		this.setState({
			logoutLoading: true,
			selectedPhone: {
				id,
				number,
				status,
			},
		});
		let { product } = this.props;
		toWapi(`/api/${product.settings.wapi_pid || product.id}/${id}/logout`, product.settings.wapi_token).then((data) => {
			//console.log(data);
			if (data.data.success) {
				this.setState({ logoutLoading: false });
				this.updateStatusWhenClicked();
			} else {
				this.setState({ logoutLoading: false });
				console.error(data.data);
			}
		});
	};

	redeployPhone = (id, number, status) => {
		this.setState({
			selectedPhone: {
				id,
				number,
				status,
			},
			redeoployLoading: true,
		});
		let { product } = this.props;
		toWapi(`/api/${product.settings.wapi_pid || product.id}/${id}/redeploy`, product.settings.wapi_token).then((data) => {
			if (data.data.success) {
				this.updateStatusWhenClicked();
				this.setState({ redeoployLoading: false });
			} else {
				console.error(data.data);
			}
		});
	};
	factoryResetPhone = (id, number, status) => {
		this.setState({
			selectedPhone: {
				id,
				number,
				status,
			},
			factoryResetLoading: true,
			factoryButtonLoading: true,
		});
		let { product } = this.props;
		toWapi(`/api/${product.settings.wapi_pid || product.id}/${id}/factoryreset`, product.settings.wapi_token).then((data) => {
			this.setState({ clickMessage: data.data.message, factoryResetLoading: false, factoryresetmodal: false, factoryButtonLoading: false });
		});
	};

	checkEnabled = (id, number, status) => {
		this.setState({
			logoutLoading: true,
			selectedPhone: {
				id,
				number,
				status,
			},
		});
		let { product } = this.props;
		toWapi(`/api/${product.settings.wapi_pid || product.id}/${id}/enable`, product.settings.wapi_token).then((data) => {
			if (data.data.success) {
				this.updateStatusWhenClicked();
				this.setState({ logoutLoading: false });
			} else {
				this.setState({
					disabledModal: true,
					disabledModalMessage: data.data.message,
				});
			}
		});
	};

	deletePhone = (id, number, status) => {
		this.phoneToDelete = { id, number, status };
		this.setState({ selectedPhone: null, deleteBtnClicked: true });
	};

	closeDeleteButton = () => {
		this.setState({
			deleteBtnClicked: false,
			goToSubs: false,
			disabledModal: false,
			createAccountModal: false,
			factoryresetmodal: false,
			twofactorwarning: false,
		});
	};

	handleDelete = () => {
		let phones = this.state.phones;
		let { product } = this.props;
		let newPhones = [];
		for (let i = 0; i < phones.length; i++) {
			let phone = phones[i];
			if (phone.id !== this.phoneToDelete.id) {
				newPhones.push(phone);
			}
		}
		this.setState({ deleteBtnClicked: false, phones, currentPhones: phones.length, addBtnClickable: this.state.totalPhones > phones.length });
		toWapi(`/api/${product.settings.wapi_pid || product.id}/${this.phoneToDelete.id}/delete`, product.settings.wapi_token, {})
			.then((data) => {
				//	console.log(data);
				window.location.reload();
			})
			.catch((err) => {
				console.error(err);
			});
	};
	openAddModal = () => {
		this.setState({ goToSubs: true });
	};
	selectedAccountWhenClicked = (id, number, status) => {
		this.setState({
			selectedPhone: {
				id,
				number,
				status,
			},
		});
		setTimeout(() => {
			this.updateStatusWhenClicked();
		}, 0);
	};
	updateStatusWhenClicked = () => {
		let selectedAccount = this.state.selectedPhone;
		let { product } = this.props;
		let updatedStatus = 'loading';
		let account = _.find(this.state.phones, { id: selectedAccount.id });
		account.status = updatedStatus;
		this.setState({ phones: this.state.phones });
		toWapi(`/api/${product.settings.wapi_pid || product.id}/${selectedAccount.id}/status`, product.settings.wapi_token)
			.then((data) => {
				if (!this.mount) return;
				//console.log(data);
				data = data.data;
				if (data.success) {
					updatedStatus = 'active';
					if (data.status.connRetry) updatedStatus = 'no internet';
					else if (!data.status.loggedIn) updatedStatus = 'not_logged_in';
					else if (data.status.loading) updatedStatus = 'loading';
					else if (data.status.message) updatedStatus = 'account-error';
					else if (data.status.loggedIn) updatedStatus = 'active';
					else updatedStatus = 'other';
					account.status = updatedStatus;
					this.setState({
						phones: this.state.phones,
						statusError: data && data.status && data.status.message && data.status.message.replace(/.*;/, ''),
					});
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};
	handleFormChange = (e, { name, value }) => this.setState({ [name]: value });
	TableRow = (phonedata, index) => {
		let number = phonedata.number;
		let id = phonedata.id;
		let status = phonedata.status;
		let icon, textColor;
		switch (status) {
			case 'active':
				icon = 'check';
				textColor = 'green';
				break;
			case 'not_logged_in':
				icon = 'sign-in';
				textColor = 'orange';
				break;
			case 'loading':
				icon = 'clock';
				textColor = 'orange';
				break;
			case 'conn-retry':
				status = 'no internet';
				icon = 'wifi';
				textColor = 'red';
				break;
			case 'TIMEOUT':
			case 'PAIRING':
				icon = 'close';
				textColor = 'orange';
				break;
			case 'idle':
				icon = 'clock';
				textColor = 'grey';
				break;
			case 'disabled':
				icon = 'dont';
				textColor = 'grey';
				break;
			default:
				//status = 'no calls';
				icon = 'question circle';
				textColor = 'blue';
				break;
		}
		return (
			<List.Item
				key={index}
				active={this.state.selectedPhone && this.state.selectedPhone.id === id}
				style={{ padding: 0, paddingLeft: '0.5em', paddingRight: '0.5em' }}
			>
				<div
					css={`
						display: flex;
						align-items: center;

						.phone-line {
							display: flex;
							align-items: center;
							flex: 1;
							color: black;
							height: 30px;
							padding: 1.5em 0.5em;
						}
						.phone-id {
							width: 80px;
						}
						.phone-number {
							width: 200px;
						}
						.phone-status {
							margin-right: 20px;
							margin-left: auto;
							white-space: nowrap;
						}
					`}
				>
					<div
						className="phone-line"
						onClick={() => {
							status === 'loading'
								? console.log('loading')
								: status === 'disabled'
								? this.checkEnabled(id, number, status)
								: status === 'active' || (status === 'idle' && number) || status === 'account-error'
								? this.selectedAccountWhenClicked(id, number, status)
								: this.openCreateModal(id, status);
						}}
					>
						<div className="phone-id">
							<strong>id: {id}</strong>
						</div>
						<Responsive as="div" className="phone-number" minWidth={Responsive.onlyMobile.maxWidth - 1}>
							<Icon name="instagram"></Icon> {number || 'New Account'}
						</Responsive>
						<div className="phone-status" style={{ color: textColor }}>
							<Icon name={icon} loading={status === 'loading'}></Icon>
							{status}
						</div>
					</div>
					{status !== 'disabled' ? (
						<div style={{ textAlign: 'center', color: 'white', whiteSpace: 'nowrap' }}>
							<Popup
								content="Logout"
								position="bottom right"
								trigger={
									<Button
										icon
										size="tiny"
										loading={this.state.selectedPhone && this.state.selectedPhone.id === id && this.state.logoutLoading}
										onClick={() => this.logoutPhone(id, number, status)}
										disabled={
											status === 'loading' ||
											status === 'not_logged_in' ||
											status === 'idle' ||
											status === 'creating...' ||
											status === null
										}
									>
										<Icon name="sign-out"></Icon>
									</Button>
								}
							/>
							<Popup
								content="Disable"
								position="bottom right"
								trigger={
									<Button
										icon
										color="red"
										size="tiny"
										onClick={(e) => {
											this.deletePhone(id, number, status);
											e.preventDefault();
											e.stopPropagation();
										}}
									>
										<Icon name="dont"></Icon>
									</Button>
								}
							/>
							<Popup
								content="Redeploy"
								trigger={
									<Button
										icon
										size="tiny"
										loading={this.state.selectedPhone && this.state.selectedPhone.id === id && this.state.redeoployLoading}
										onClick={() => this.redeployPhone(id)}
									>
										<Icon name="redo"></Icon>
									</Button>
								}
							/>
							<Popup
								content="Factory Reset"
								position="bottom right"
								trigger={
									<Button
										icon
										size="tiny"
										loading={this.state.selectedPhone && this.state.selectedPhone.id === id && this.state.factoryResetLoading}
										onClick={() => this.setState({ factoryresetmodal: true, factory_id: id })}
									>
										<Icon name="setting"></Icon>
									</Button>
								}
							/>
						</div>
					) : null}
				</div>
			</List.Item>
		);
	};

	async refreshSubscription() {
		await get('/live/payment/refreshSubscription').then(this.setState({ phoneLoading: false }));
	}
	render() {
		const { t } = this.props;
		let { email, password, code } = this.state;
		if (this.state.loading)
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
								{t('integrations')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
					flex
				>
					<MyMenu activeItem="instagram">
						<Page.Loader />
					</MyMenu>
				</Page>
			);
		else if (!this.props.product.settings || !this.props.product.settings.subscription_package || !this.props.product.settings.instagram_limit) {
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
								{t('integrations')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />
							<Breadcrumb.Section>Instagram API</Breadcrumb.Section>
						</Breadcrumb>
					}
					extra={
						<div className="ml-auto">
							<AccountProgress></AccountProgress>
						</div>
					}
					flex
				>
					<MyMenu activeItem="instagram">
						<Page.Wrap>
							<Segment>
								<div style={{ textAlign: 'center' }}>
									{/* <div>
										<Icon size="big" name="dont"></Icon>
									</div>
									<h2>No Instagram Limit</h2>
									<p>Please buy a subscription package for instagram.</p>
									<Button as={Link} to="/settings/subscription" primary>
										{trial === null ? 'Start Free Trial' : 'Go to Subscription'}
									</Button> */}
									<div>
										<Icon size="big" name="instagram"></Icon>
									</div>
									<h2>{t('instaBetaOpen')}</h2>
									<p>{t('instaBetaJoin', { mail: 'info@dialogtab.com' })}</p>
								</div>
							</Segment>
						</Page.Wrap>
					</MyMenu>
				</Page>
			);
		} else {
			let orderList = _.orderBy(this.state.phones, ['id'], ['asc']);
			//console.log(status);
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
								{t('integrations')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />
							<Breadcrumb.Section>Instagram API</Breadcrumb.Section>
						</Breadcrumb>
					}
					extra={
						<div className="ml-auto">
							<AccountProgress></AccountProgress>
						</div>
					}
					flex
				>
					<MyMenu activeItem="instagram">
						<Page.Wrap>
							<Message info hidden={!this.state.clickMessage}>
								{this.state.clickMessage}
							</Message>
							<Message info hidden={!this.state.statusError}>
								{this.state.statusError}
							</Message>
							<Segment attached="top" color="orange" textAlign="center">
								{t('registeredAccounts')}
							</Segment>
							<Segment attached textAlign="center" style={{ padding: 5 }}>
								<Button onClick={this.state.addBtnClickable ? this.addNewPhone : this.openAddModal} fluid positive>
									<span style={{ marginRight: 5 }}>{t('addNewAccounts')}</span> ({this.state.currentPhones}/{this.state.totalPhones})
								</Button>
							</Segment>
							{this.state.phones && (
								<Segment
									attached="bottom"
									piled
									style={{
										padding: 5,
										maxHeight: '300px',
										overflowY: orderList.length > 6 ? 'scroll' : 'hidden',
										overflowX: 'hidden',
										marginBottom: '5px',
									}}
								>
									{this.state.phones.length ? (
										<List selection verticalAlign="middle">
											{_.map(orderList, (data, index) => this.TableRow(data, index))}
										</List>
									) : (
										<p style={{ textAlign: 'center' }}>{t('thereIsNoAccount')}</p>
									)}
								</Segment>
							)}

							<Message info hidden={!orderList.length}>
								<p>
									{t('disabled')}: {t('disabledDesc')}
								</p>
								<p>
									{t('redeploy')}: {t('redeployDesc', { query: 'Instagram' })}
								</p>
								<p>
									{t('factoryReset')}: {t('factoryResetDesc')}
								</p>
								<p>
									{t('logout')}: {t('logoutDesc', { query: 'Instagram' })}
								</p>
							</Message>
							{/* 
							<Message hidden={!!_.find(orderList, { status: 'active' })} warning style={{ textAlign: 'center' }}>
								We don't scan your old messages. We start to scan unread messages and messages that come after you scan QR code.
							</Message>
							<Message error style={{ textAlign: 'center' }}>
								<p>
									If you want to take the history of all old chats on WhatsApp. <Link to="/contact">Please contact us.</Link>
								</p>
							</Message> */}
						</Page.Wrap>
						<Modal open={this.state.deleteBtnClicked} onClose={this.closeDeleteButton} style={{ width: 500 }}>
							<Header icon="dont" content="Disable the Selected Acoount" />
							<Modal.Content>
								<p>{t('sureDisableAccount')}</p>
							</Modal.Content>
							<Modal.Actions>
								<Button color="red" onClick={this.closeDeleteButton}>
									<Icon name="remove" /> {t('no')}
								</Button>
								<Button color="green" onClick={this.handleDelete}>
									<Icon name="checkmark" /> {t('yes')}
								</Button>
							</Modal.Actions>
						</Modal>
						{/*go to subs modal*/}
						{/* <Modal open={this.state.goToSubs} onClose={this.closeDeleteButton} style={{ width: 500 }}>
						<Header icon="instagram" content="Instagram Limit" />
						<Modal.Content>
							<p>Your Instagram limit is not enough for add account.</p>
							<p>Do you want to update Instagram subscription.</p>
						</Modal.Content>
						<Modal.Actions>
							<Button color="red" onClick={this.closeDeleteButton}>
								<Icon name="remove" /> No
							</Button>
							<Button color="green" as={Link} to="/settings/subscription">
								<Icon name="checkmark" /> Yes
							</Button>
						</Modal.Actions>
					</Modal> */}
						<Modal open={this.state.goToSubs} onClose={this.closeDeleteButton} style={{ width: 500 }}>
							<Header icon="instagram" content="Instagram Limit" />
							<Modal.Content>
								<h4>{t('closedBetaContact')}</h4>
							</Modal.Content>
						</Modal>
						{/*towfactor modal*/}
						<Modal open={this.state.twofactorwarning} onClose={this.closeDeleteButton} style={{ width: 500 }}>
							<Header icon="key" content={t('twoFactorAuthentication')} />
							<Modal.Content>
								<h4>{t('twoFactorWarning')}</h4>
							</Modal.Content>
							<Modal.Actions>
								<Button color="red" onClick={this.closeDeleteButton}>
									<Icon name="remove" /> {t('close')}
								</Button>
								<Button color="green" onClick={() => this.setState({ twofactorwarning: false, createAccountModal: true })}>
									<Icon name="checkmark" /> {t('login')}
								</Button>
							</Modal.Actions>
						</Modal>
						{/*disabled modal*/}
						<Modal open={this.state.disabledModal} onClose={this.closeDeleteButton} style={{ width: 500 }}>
							<Header icon="dont" content={t('enabledError')} F />
							<Modal.Content>
								<p>{this.state.disabledModalMessage}</p>
							</Modal.Content>
							<Modal.Actions>
								<Button color="green" as={Link} to="/settings/subscription">
									{t('gotoSubs')}
								</Button>
							</Modal.Actions>
						</Modal>
						{/*factoryreset modal*/}
						<Modal open={this.state.factoryresetmodal} onClose={this.closeDeleteButton} style={{ width: 500 }}>
							<Header icon="dont" content={t('factoryReset')} />
							<Modal.Content>
								<p>{t('areYouSureFactoryReset')}</p>
							</Modal.Content>
							<Modal.Actions>
								<Button color="red" onClick={this.closeDeleteButton}>
									<Icon name="remove" /> {t('no')}
								</Button>
								<Button color="green" onClick={() => this.factoryResetPhone(this.state.factory_id)} loading={this.state.factoryButtonLoading}>
									<Icon name="checkmark" /> {t('yes')}
								</Button>
							</Modal.Actions>
						</Modal>
						<Modal open={this.state.createAccountModal} onClose={this.closeDeleteButton} style={{ width: 500 }}>
							<Header icon="instagram" content="Login Instagram" />
							<Message error hidden={!this.state.loginError} style={{ marginLeft: '20px', marginRight: '20px' }}>
								{this.state.loginError}
							</Message>
							<Modal.Content>
								<Form onSubmit={this.loginInstagram}>
									<Form.Input
										label={t('username')}
										placeholder={t('companyPagePlaceholder', { query: 'username' })}
										required
										name="email"
										value={email}
										onChange={this.handleFormChange}
									></Form.Input>
									<Form.Input
										label={t('password')}
										required
										name="password"
										type="password"
										placeholder={t('companyPagePlaceholder', { query: 'password' })}
										value={password}
										onChange={this.handleFormChange}
									></Form.Input>
									{this.state.code_required ? (
										<Form.Input
											hidden
											label={t('code')}
											required
											name="code"
											placeholder={t('companyPagePlaceholder', { query: 'code' })}
											value={code}
											onChange={this.handleFormChange}
										></Form.Input>
									) : null}
									{/* {this.state.two_factor_code ? (
									<Form.Input
										hidden
										label="Two Factor Code"
										required
										name="code"
										placeholder="Enter Code"
										value={code}
										onChange={this.handleFormChange}
									></Form.Input>
								) : null} */}

									<Form.Group>
										<Form.Button style={{ marginLeft: 'auto' }} positive type="submit" loading={this.state.loginLoading}>
											<Icon name="checkmark" /> {t('login')}
										</Form.Button>
										<Form.Button color="red" onClick={this.closeDeleteButton}>
											<Icon name="x" /> {t('close')}
										</Form.Button>
									</Form.Group>
								</Form>
							</Modal.Content>
						</Modal>
					</MyMenu>
				</Page>
			);
		}
	}
}

const stateToProps = (state) => ({
	access: state.access,
	location: state.router.location,
	product: state.product.live,
	user: state.access.user.loggedUserObj,
});
export default connect(stateToProps)(withTranslation()(Phones));
