import React, { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { Icon, Breadcrumb, Form, Table, Button, Segment, Modal, Header, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import useFetch from 'use-http';
import MyMenu from './menu';
import AccountProgress from '../../components/AccountProgress';
import { useTranslation } from 'react-i18next';

const fetch_config = { responseType: 'json', cachePolicy: 'no-cache' };

export default function ProductSettings() {
	const { t } = useTranslation();
	const [reload, setReload] = useState(0);
	const { lloading, response: lresponse } = useFetch('/api/dahi/message/getLiveChat', fetch_config, [reload]);
	const { loading, response } = useFetch('/api/live/agent/list', fetch_config, [reload]);
	const [state, setState] = useState({
		showAdd: false,
		showUpdate: false,
		agentMail: '',
		agentPassword: '',
		agentConfirmPassword: '',
		agentType: 'agent',
		agentName: '',
		passworderror: false,
		error: null,
		openModal: false,
		addButton: t('add'),
		showPassword: true,
	});
	const [selectedLimit, setSelectedLimit] = useState();
	const [global_conv_limit, setglobal_conv_limit] = useState();
	if (loading || lloading)
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/settings`}>
							<Icon name="setting" />
							{t('settings')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
					</Breadcrumb>
				}
				flex
			>
				<MyMenu activeItem="agentlimits">
					<Page.Loader></Page.Loader>
				</MyMenu>
			</Page>
		);
	const data = response && response.data && response.data.agents;
	const dataagents = lresponse && lresponse.data && lresponse.data.result && lresponse.data.result.agents;
	let default_conv_limit =
		(lresponse && lresponse.data && lresponse.data.result && lresponse.data.result.staticOptions && lresponse.data.result.staticOptions.conv_take_limit) ||
		5;
	let agentsData = _.filter(data, function (it) {
		return it.role !== 'viewer';
	});
	//const product = response && response.data && response.data.product;
	const user = response && response.data && response.data.user;
	function reset() {
		setState({
			current: '',
			agentMail: '',
			agentPassword: '',
			agentConfirmPassword: '',
			agentType: 'agent',
			agentName: '',
			addButton: t('add'),
			passworderror: false,
			error: null,
		});
	}

	function closeDeleteButton() {
		setState({ openModal: false, goToSubs: false });
	}
	function handleUpdateAgent() {
		let { selected_agent } = state;
		let body = JSON.stringify({
			uid: state.uid,
			role: selected_agent.type,
			email: selected_agent.developer,
			name: selected_agent.name,
			user_id: selected_agent.wid,
			conv_take_limit: selectedLimit,
		});
		fetch('/api/live/agent/update', {
			method: 'POST',
			body,
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					reset();
					setReload(reload + 1);
				} else {
					setState((state) => ({ ...state, error: data.pg ? 'Error' : '' + data.message }));
				}
			});
	}
	function handleGlobalVariable() {
		let body = JSON.stringify({
			conv_take_limit: global_conv_limit,
		});
		console.log('ss', body);
		fetch('/api/dahi/message/updateConvLimit', {
			method: 'POST',
			body,
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					reset();
					setReload(reload + 1);
				} else {
					setState((state) => ({ ...state, error: data.pg ? 'Error' : '' + data.message }));
				}
			});
	}
	return (
		<Page
			header={
				<Breadcrumb size="big">
					<Breadcrumb.Section as={Link} to={`/settings`}>
						<Icon name="setting" />
						{t('settings')}
					</Breadcrumb.Section>
					<Breadcrumb.Divider icon="right chevron" />
					<Breadcrumb.Section>{t('agentsLimits')}</Breadcrumb.Section>
				</Breadcrumb>
			}
			extra={
				<div className="ml-auto">
					<AccountProgress></AccountProgress>
				</div>
			}
			flex
		>
			<MyMenu activeItem="agentlimits">
				<Page.Wrap>
					<Segment>
						<Form>
							<Form.Field inline>
								<label>{t('globalConvLimit')}</label>
								<Input
									inline
									name="global_conv_limit"
									type="number"
									value={global_conv_limit || default_conv_limit}
									onChange={(e, { value }) => setglobal_conv_limit(parseInt(value))}
									width={4}
								/>
							</Form.Field>
							<Button color="green" onClick={() => handleGlobalVariable()}>
								{t('save')}
							</Button>
						</Form>
					</Segment>
					<Segment>
						<h3>{t('agents')}</h3>
						<Table>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell width="7">{t('email')}</Table.HeaderCell>
									<Table.HeaderCell width="3">{t('type')}</Table.HeaderCell>
									<Table.HeaderCell width="3">{t('limit')}</Table.HeaderCell>
									<Table.HeaderCell width="1"></Table.HeaderCell>
									<Table.HeaderCell width="1"></Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								<Table.Row key={'owner'}>
									<Table.Cell>{user.email}</Table.Cell>
									<Table.Cell>{t('owner')}</Table.Cell>
									<Table.Cell>{user.name || t('enterName')}</Table.Cell>
									<Table.Cell style={{ textAlign: 'right' }}></Table.Cell>
								</Table.Row>
								{_.map(agentsData, (data, index) => {
									let selected_agent = _.find(dataagents, (it) => it.id === data.extra.user_id);
									return (
										<Table.Row key={index}>
											<Table.Cell>{data.email}</Table.Cell>
											<Table.Cell>{_.upperFirst(data.role) || t('owner')}</Table.Cell>
											<Table.Cell>{(selected_agent && selected_agent.conv_take_limit) || default_conv_limit}</Table.Cell>
											<Table.Cell></Table.Cell>
											{data.type !== 'admin' && (
												<Table.Cell style={{ textAlign: 'right' }}>
													<Button
														primary
														size="tiny"
														onClick={() => {
															setState({
																mail: data.email,
																agentType: data.role,
																uid: data.id,
																name: data.name,
																password: data.password,
																user_id: data.extra.user_id,
																openModal: true,
																selected_agent,
															});
															setSelectedLimit(selected_agent.conv_take_limit || default_conv_limit);
														}}
													>
														<i className="edit icon" style={{ color: 'white', opacity: 1, margin: 0, textAlign: 'center' }}></i>
													</Button>
												</Table.Cell>
											)}
										</Table.Row>
									);
								})}
							</Table.Body>
						</Table>
					</Segment>
				</Page.Wrap>
			</MyMenu>
			{/*config modal*/}
			<Modal open={state.openModal} onClose={() => closeDeleteButton()} style={{ width: 500 }}>
				<Header icon="user" content={state.mail} />
				<Modal.Content>
					<Form>
						<Form.Input
							label="Limit"
							type="number"
							name="limit"
							value={selectedLimit}
							onChange={(e, { value }) => setSelectedLimit(parseInt(value))}
						></Form.Input>
					</Form>
				</Modal.Content>
				<Modal.Actions>
					<Button color="red" onClick={() => closeDeleteButton()}>
						<Icon name="remove" /> {t('close')}
					</Button>
					<Button color="green" onClick={() => handleUpdateAgent()}>
						<Icon name="checkmark" /> {t('save')}
					</Button>
				</Modal.Actions>
			</Modal>
		</Page>
	);
}
