export const colors = [
	'#000000',
	'#df7126',
	'#6abe30',
	'#3f3f74',
	'#cbdbfc',
	'#595652',
	'#8f974a',
	'#222034',
	'#d9a066',
	'#37946e',
	'#306082',
	'#76428a',
	'#8a6f30',
	'#45283c',
	'#eec39a',
	'#4b692f',
	'#5b6ee1',
	'#9badb7',
	'#ac3232',
	'#663931',
	'#fbf236',
	'#524b24',
	'#639bff',
	'#847e87',
	'#d95763',
	'#8f563b',
	'#99e550',
	'#323c39',
	'#5fcde4',
	'#696a6a',
	'#d77bba',
];

export const colors2 = [
	'#0645ff',
	'#09d20f',
	'#ffbf00',
	'#813f00',
	'#ffd7b2',
	'#7a7a7a',
	'#de74ff',
	'#fd6a6a',
	'#580000',
	'#001d72',
	'#004d02',
	'#816102',
	'#130f0b',
	'#420056',
	'#ff4040',
	'#5681ff',
	'#76ff7a',
	'#ffd558',
	'#ff8615',
	'#ffbf83',
	'#8a00b4',
	'#bababa',
	'#4b4b4b',
];
