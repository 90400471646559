import React, { PureComponent } from 'react';
import { select_product } from '../actions/product.actions';
import Page from '../components/Page';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { List, Segment, Header, Button } from 'semantic-ui-react';
import { MyDivider } from '../components/FormikInput';
import { post } from '../util/api';
import { withTranslation } from 'react-i18next';
// eslint-disable-next-line
import styled, { StyleSheetManager, createGlobalStyle } from 'styled-components/macro';

class SelectList extends PureComponent {
	render() {
		const { t } = this.props;
		return (
			<Page disabled={true}>
				<Page.Wrap>
					<div
						css={`
							display: flex;
							justify-content: center;
							flex-direction: column;
							width: 100%;
							height: 100%;
						`}
					>
						<MyDivider icon="send" name="Your Agent Accounts" />
						<Segment>
							{this.props.list && this.props.list.length ? (
								<List selection divided size="big">
									{_.map(this.props.list, (it, i) => (
										<List.Item key={i} onClick={() => this.props.dispatch(select_product(it))}>
											<List.Icon name="angle double right" />
											<List.Content
												css={`
													color: #333;
												`}
											>
												{t('selectChatOf', { email: it.email })}
												{it.company ? (
													<span
														css={`
															opacity: 0.7;
														`}
													>
														- {it.company}
													</span>
												) : (
													''
												)}
											</List.Content>
										</List.Item>
									))}
								</List>
							) : (
								<Header>{t('youDontHaveAccount', { title: window.CONFIG.title })}</Header>
							)}
						</Segment>
					</div>
				</Page.Wrap>
			</Page>
		);
	}
}

class Chat extends PureComponent {
	state = {
		loading: true,
	};
	chat = React.createRef();
	async getList(pid) {
		try {
			let list = await post('/live/agent/list', {
				pid,
			});
			this.setState({ agents: list.data.agents, loading: false });
		} catch (error) {
			this.setState({ loading: false });
		}
	}
	componentDidMount() {
		if (this.props.product) this.getList(this.props.product.id);
	}
	render() {
		const { product = {}, user, t } = this.props;
		if (!this.props.product) return <SelectList {...this.props} />;
		if (this.state.loading) {
			return (
				<Page disabled={true}>
					<Page.Loader />
				</Page>
			);
		}
		let agentList = _.filter(this.state.agents, function (it) {
			return it.role !== 'viewer';
		});
		let agentLimit = product.settings.agent_limit - 1;
		let thisAgent = this.props.user.id;
		let isViewer = this.props.user && this.props.user.role && this.props.user.role === 'viewer';
		let isSubscribe = product && product.settings && product.settings.subscription_package !== null;
		let chatIsActive = _.findIndex(agentList, { id: thisAgent }) + 1 <= agentLimit;
		if (isViewer && isSubscribe) {
			chatIsActive = true;
		}
		let isAdmin = !(user && user.extra && user.extra.__isAgent);
		if (product && product.settings && product.settings.subscription_package === null) {
			return (
				<Page title="Chat">
					<div style={{ background: 'url(/blur.jpg)', height: '100%', display: 'flex', backgroundRepeat: 'no-repeat' }}>
						<Segment style={{ margin: 'auto', textAlign: 'center' }}>
							{isAdmin ? (
								<div>
									<h2>{t('noSubscription')}</h2>
									<p>{t('pleaseSubs')}</p>
									<Button as={Link} to="/settings/subscription" primary>
										{t('gotoSubs')}
									</Button>
								</div>
							) : (
								<div>
									<h2>{t('noSubscription')}</h2>
									<p>{t('yourAdminNoSub')}</p>
									<p>{t('contactYourAdmin')}</p>
								</div>
							)}
						</Segment>
					</div>
				</Page>
			);
		}
		if (chatIsActive) {
			return (
				<Page disabled={true} flex data={this.props}>
					<iframe
						title="chat"
						css={`
							flex: 1;
							border: none !important;
							width: 100%;
							height: 100%;
							//box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15), 0 0 0 1px rgba(34, 36, 38, 0.15);
							//border-radius: 5px;
							overflow: hidden;
						`}
						src={`/chat.html?__react__=${encodeURIComponent(
							JSON.stringify({
								hash: window.location.hash && window.location.hash.substr(1),
							})
						)}`}
						//initialContent={runCode(uid, isAdmin, wapi, wapi_token)}
					></iframe>
				</Page>
			);
		}
		return (
			<Page title="Chat">
				<div style={{ background: 'url(/blur.jpg)', height: '100%', display: 'flex' }}>
					<Segment style={{ margin: 'auto', textAlign: 'center' }}>
						<div>
							<h2>{t('noAgentLimit')}</h2>
							<p>{t('yourAdminDontLimit')}</p>
							<p>{t('contactYourAdmin')}</p>
						</div>
					</Segment>
				</div>
			</Page>
		);
	}
}

const stateToProps = (state) => ({ product: state.product.live, list: state.product.list, user: state.access.user.loggedUserObj });
export default connect(stateToProps)(withTranslation()(Chat));
