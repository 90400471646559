import React, { Component } from 'react';
import { Line, Bar, Radar } from 'react-chartjs-2';
import { Grid, Header, Icon, Segment, Table, Dropdown, Container, Radio, Checkbox } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import TypeList from '../filter3.json';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import { withTranslation } from 'react-i18next';
import 'moment/locale/tr';

const ChartWrap = (props) => (
	<Grid.Column width={props.width}>
		<Header as="h4" attached="top" style={{ display: 'flex' }}>
			<Icon name={props.icon || 'chart bar'} />
			<Header.Content>{props.title}</Header.Content>
			{props.content}
		</Header>
		<Segment attached>{props.children}</Segment>
	</Grid.Column>
);
const checkboxListArray = [
	{
		title: 'Order Canceled',
		type: 'orderCanceled',
		text: 'productCanceled',
		pairs: {
			agent: 'select5',
			kod: 'select2',
			topic: 'select3',
		},
		color: '#E10840',
		group: ['agent'],
		uncheck: true,
	},
	{
		title: 'Order Refunded',
		type: 'orderRefunded',
		text: 'productRefunded',
		pairs: {
			agent: 'select5',
			kod: 'select2',
			topic: 'select3',
		},
		color: '#C4FEE9',
		group: ['agent'],
		uncheck: true,
	},
];
class Analytic extends Component {
	state = {
		filterType: false,
		filterAgent: false,
		loading: true,
		start_date: this.props.start_date,
		end_date: this.props.end_date,
		today: moment(),
		group: ['agent'],
		value: _.filter(TypeList, function (it) {
			return !it.uncheck;
		}),
		pid: /* '1e00a47c670273a623c13c54dba44006' || */ /* '5f8dbdc560e4f7d3e00876b8ab5f2ecf' ||  */ this.props.product.live.settings.live.token,
		agent_role: this.props.access.user.loggedUserObj.role,
		agent_id: this.props.access.user.loggedUserObj.extra.user_id,
		selectedAgent: 'all',
		selectedDate: 'daily',
		column: null,
		botId: '',
		direction: null,
		dataloading: true,
		checkboxList: _.filter(checkboxListArray, function (it) {
			return !it.uncheck;
		}),
		selectedBarStyle: 'line',
		selectedPlatform: 'all',
	};
	_i = 0;
	handleSort = (clickedColumn) => () => {
		const { column, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				direction: 'desc',
			});

			return;
		}
		this.setState({
			direction: direction === 'asc' ? 'desc' : 'asc',
		});
	};
	TableHead = (column, direction, t) => (
		<Table.Header>
			<Table.HeaderCell width={4}>Agent</Table.HeaderCell>
			<Table.HeaderCell sorted={column === 'len' ? direction : null} onClick={this.handleSort('len')}>
				{t('orderCount')}
			</Table.HeaderCell>
			<Table.HeaderCell sorted={column === 'count' ? direction : null} onClick={this.handleSort('count')}>
				{t('productCount')}
			</Table.HeaderCell>
			<Table.HeaderCell sorted={column === 'lenCanceled' ? direction : null} onClick={this.handleSort('lenCanceled')}>
				{t('orderCanceled')}
			</Table.HeaderCell>

			{_.find(this.state.checkboxList, { type: 'orderCanceled' }) && (
				<Table.HeaderCell sorted={column === 'countCanceled' ? direction : null} onClick={this.handleSort('countCanceled')}>
					{t('productCanceled')}
				</Table.HeaderCell>
			)}
			<Table.HeaderCell sorted={column === 'lenRefunded' ? direction : null} onClick={this.handleSort('lenRefunded')}>
				{t('orderRefunded')}
			</Table.HeaderCell>

			{_.find(this.state.checkboxList, { type: 'orderRefunded' }) && (
				<Table.HeaderCell sorted={column === 'countRefunded' ? direction : null} onClick={this.handleSort('countRefunded')}>
					{t('productRefunded')}
				</Table.HeaderCell>
			)}
			<Table.HeaderCell sorted={column === 'sum' ? direction : null} onClick={this.handleSort('sum')}>
				{t('sum')}
			</Table.HeaderCell>
			<Table.HeaderCell sorted={column === 'sumCanceled' ? direction : null} onClick={this.handleSort('sumCanceled')}>
				{t('orderCanceledSum')}
			</Table.HeaderCell>
			<Table.HeaderCell sorted={column === 'sumRefunded' ? direction : null} onClick={this.handleSort('sumRefunded')}>
				{t('orderRefundedSum')}
			</Table.HeaderCell>
			<Table.HeaderCell sorted={column === 'sumRefunded' ? direction : null} onClick={this.handleSort('sumRefunded')}>
				{t('generalSum')}
			</Table.HeaderCell>
			<Table.HeaderCell sorted={column === 'avg' ? direction : null} onClick={this.handleSort('avg')}>
				{t('average')}
			</Table.HeaderCell>
			<Table.HeaderCell width={2} sorted={column === 'basketrate' ? direction : null} onClick={this.handleSort('basketrate')}>
				{t('basketproductrate')}
			</Table.HeaderCell>
		</Table.Header>
	);
	TableLine = (productAgentList2, selectedAgent, t, currency, othersdata) => (
		<Table.Body>
			{selectedAgent === 'all' ? (
				<>
					{_.map(_.orderBy(productAgentList2, [this.state.column], [this.state.direction]), (val, index) => (
						<Table.Row key={index}>
							<Table.Cell>{(val && val[this.props.displayName]) || 'Bot'}</Table.Cell>
							<Table.Cell>{val && val.len}</Table.Cell>
							<Table.Cell>{val && val.count}</Table.Cell>
							<Table.Cell>{(val && val.lenCanceled) || '0'}</Table.Cell>

							{_.find(this.state.checkboxList, { type: 'orderCanceled' }) && <Table.Cell>{val && val.countCanceled}</Table.Cell>}
							<Table.Cell>{(val && val.lenRefunded) || '0'}</Table.Cell>

							{_.find(this.state.checkboxList, { type: 'orderRefunded' }) && <Table.Cell>{val && val.countRefunded}</Table.Cell>}
							<Table.Cell>{val && val.sum && val.sum.toLocaleString('tr-TR', { style: 'currency', currency })}</Table.Cell>
							<Table.Cell>
								{(val && val.sumCanceled && val.sumCanceled.toLocaleString('tr-TR', { style: 'currency', currency })) || '0'}
							</Table.Cell>
							<Table.Cell>
								{(val && val.sumRefunded && val.sumRefunded.toLocaleString('tr-TR', { style: 'currency', currency })) || '0'}
							</Table.Cell>
							<Table.Cell>
								{((val.sum || 0) - (val.sumRefunded || 0) - (val.sumCanceled || 0)).toLocaleString('tr-TR', { style: 'currency', currency }) ||
									'0'}
							</Table.Cell>
							<Table.Cell>{val && val.avg && val.avg.toLocaleString('tr-TR', { style: 'currency', currency })}</Table.Cell>
							<Table.Cell>{val.basketrate}</Table.Cell>
						</Table.Row>
					))}
					{othersdata.count > 0 ? (
						<Table.Row key={'others'}>
							<Table.Cell>{t('oldAgents')}</Table.Cell>
							<Table.Cell>{othersdata && othersdata.len}</Table.Cell>
							<Table.Cell>{othersdata && othersdata.count}</Table.Cell>
							<Table.Cell>{(othersdata && othersdata.lenCanceled) || '0'}</Table.Cell>

							{_.find(this.state.checkboxList, { type: 'orderCanceled' }) && (
								<Table.Cell>{(othersdata && othersdata.countCanceled) || '0'}</Table.Cell>
							)}
							<Table.Cell>{(othersdata && othersdata.lenRefunded) || '0'}</Table.Cell>

							{_.find(this.state.checkboxList, { type: 'orderRefunded' }) && (
								<Table.Cell>{(othersdata && othersdata.countRefunded) || '0'}</Table.Cell>
							)}
							<Table.Cell>{othersdata && othersdata.sum && othersdata.sum.toLocaleString('tr-TR', { style: 'currency', currency })}</Table.Cell>
							<Table.Cell>
								{(othersdata && othersdata.sumCanceled && othersdata.sumCanceled.toLocaleString('tr-TR', { style: 'currency', currency })) ||
									'0'}
							</Table.Cell>
							<Table.Cell>
								{(othersdata && othersdata.sumRefunded && othersdata.sumRefunded.toLocaleString('tr-TR', { style: 'currency', currency })) ||
									'0'}
							</Table.Cell>
							<Table.Cell>
								{((othersdata.sum || 0) - (othersdata.sumRefunded || 0) - (othersdata.sumCanceled || 0)).toLocaleString('tr-TR', {
									style: 'currency',
									currency,
								}) || '0'}
							</Table.Cell>
							<Table.Cell>{othersdata && othersdata.avg && othersdata.avg.toLocaleString('tr-TR', { style: 'currency', currency })}</Table.Cell>
							<Table.Cell>{othersdata.basketrate}</Table.Cell>
						</Table.Row>
					) : (
						''
					)}
				</>
			) : (
				<Table.Row key="1">
					<Table.Cell>
						{(_.find(productAgentList2, { id: selectedAgent }) && _.find(productAgentList2, { id: selectedAgent })[this.props.displayName]) ||
							'Bot'}
					</Table.Cell>
					<Table.Cell>{(_.find(productAgentList2, { id: selectedAgent }) && _.find(productAgentList2, { id: selectedAgent }).len) || '0'}</Table.Cell>
					<Table.Cell>
						{(_.find(productAgentList2, { id: selectedAgent }) && _.find(productAgentList2, { id: selectedAgent }).count) || '0'}
					</Table.Cell>
					<Table.Cell>
						{(_.find(productAgentList2, { id: selectedAgent }) && _.find(productAgentList2, { id: selectedAgent }).lenCanceled) || '0'}
					</Table.Cell>
					{_.find(this.state.checkboxList, { type: 'orderCanceled' }) && (
						<Table.Cell>
							{(_.find(productAgentList2, { id: selectedAgent }) && _.find(productAgentList2, { id: selectedAgent }).countCanceled) || '0'}
						</Table.Cell>
					)}
					<Table.Cell>
						{(_.find(productAgentList2, { id: selectedAgent }) && _.find(productAgentList2, { id: selectedAgent }).lenRefunded) || '0'}
					</Table.Cell>
					{_.find(this.state.checkboxList, { type: 'orderRefunded' }) && (
						<Table.Cell>
							{(_.find(productAgentList2, { id: selectedAgent }) && _.find(productAgentList2, { id: selectedAgent }).countRefunded) || '0'}
						</Table.Cell>
					)}
					<Table.Cell>
						{(_.find(productAgentList2, { id: selectedAgent }) &&
							_.find(productAgentList2, { id: selectedAgent }).sum.toLocaleString('tr-TR', { style: 'currency', currency })) ||
							'0'}
					</Table.Cell>
					<Table.Cell>
						{(_.find(productAgentList2, { id: selectedAgent }) &&
							_.find(productAgentList2, { id: selectedAgent }).sumCanceled &&
							_.find(productAgentList2, { id: selectedAgent }).sumCanceled.toLocaleString('tr-TR', { style: 'currency', currency })) ||
							'0'}
					</Table.Cell>{' '}
					<Table.Cell>
						{(_.find(productAgentList2, { id: selectedAgent }) &&
							_.find(productAgentList2, { id: selectedAgent }).sumRefunded &&
							_.find(productAgentList2, { id: selectedAgent }).sumRefunded.toLocaleString('tr-TR', { style: 'currency', currency })) ||
							'0'}
					</Table.Cell>
					<Table.Cell>
						{(
							_.find(productAgentList2, { id: selectedAgent }) &&
							(_.find(productAgentList2, { id: selectedAgent }).sum || 0) -
								(_.find(productAgentList2, { id: selectedAgent }).sumCanceled || 0) -
								(_.find(productAgentList2, { id: selectedAgent }).sumRefunded || 0)
						).toLocaleString('tr-TR', { style: 'currency', currency }) || '0'}
					</Table.Cell>
					<Table.Cell>
						{(_.find(productAgentList2, { id: selectedAgent }) &&
							_.find(productAgentList2, { id: selectedAgent }).avg.toLocaleString('tr-TR', { style: 'currency', currency })) ||
							'0'}
					</Table.Cell>
					<Table.Cell>
						{(_.find(productAgentList2, { id: selectedAgent }) && _.find(productAgentList2, { id: selectedAgent }).basketrate) || '0'}
					</Table.Cell>
				</Table.Row>
			)}
			{selectedAgent === 'all' ? (
				<Table.Row key={'all'}>
					<Table.Cell>{t('all')}</Table.Cell>
					<Table.Cell>{_.sumBy(productAgentList2, 'len') + (othersdata.len || 0) || 0}</Table.Cell>
					<Table.Cell>{_.sumBy(productAgentList2, 'count') + (othersdata.count || 0) || 0}</Table.Cell>
					<Table.Cell>{_.sumBy(productAgentList2, 'lenCanceled') || 0}</Table.Cell>

					{_.find(this.state.checkboxList, { type: 'orderCanceled' }) && <Table.Cell>{_.sumBy(productAgentList2, 'countCanceled') || 0}</Table.Cell>}
					<Table.Cell>{_.sumBy(productAgentList2, 'lenRefunded') || 0}</Table.Cell>

					{_.find(this.state.checkboxList, { type: 'orderRefunded' }) && <Table.Cell>{_.sumBy(productAgentList2, 'countRefunded') || 0}</Table.Cell>}
					<Table.Cell>
						{(_.sumBy(productAgentList2, 'sum') &&
							(_.sumBy(productAgentList2, 'sum') + (othersdata.sum || 0)).toLocaleString('tr-TR', {
								style: 'currency',
								currency,
							})) ||
							0}
					</Table.Cell>
					<Table.Cell>
						{(_.sumBy(productAgentList2, 'sumCanceled') &&
							(_.sumBy(productAgentList2, 'sumCanceled') + (othersdata.sumCanceled || 0)).toLocaleString('tr-TR', {
								style: 'currency',
								currency,
							})) ||
							0}
					</Table.Cell>
					<Table.Cell>
						{(_.sumBy(productAgentList2, 'sumRefunded') &&
							(_.sumBy(productAgentList2, 'sumRefunded') + (othersdata.sumRefunded || 0)).toLocaleString('tr-TR', {
								style: 'currency',
								currency,
							})) ||
							0}
					</Table.Cell>
					<Table.Cell>
						{(_.sumBy(productAgentList2, 'sum') &&
							(
								_.sumBy(productAgentList2, 'sum') +
								(othersdata.sum || 0) -
								((_.sumBy(productAgentList2, 'sumRefunded') || 0) + (othersdata.sumRefunded || 0)) -
								((_.sumBy(productAgentList2, 'sumCanceled') || 0) + (othersdata.sumCanceled || 0))
							).toLocaleString('tr-TR', {
								style: 'currency',
								currency,
							})) ||
							0}
					</Table.Cell>
					<Table.Cell>
						{(_.sumBy(productAgentList2, 'sum') / _.sumBy(productAgentList2, 'len') &&
							(
								(_.sumBy(productAgentList2, 'sum') + (othersdata.sum || 0)) /
								(_.sumBy(productAgentList2, 'len') + (othersdata.len || 0))
							).toLocaleString('tr-TR', {
								style: 'currency',
								currency,
							})) ||
							0}
					</Table.Cell>
					<Table.Cell>
						{_.sumBy(productAgentList2, 'basketrate')
							? (
									(_.sumBy(productAgentList2, 'count') + (othersdata.count || 0)) /
									(_.sumBy(productAgentList2, 'len') + (othersdata.len || 0))
							  ).toFixed(2)
							: 0}
					</Table.Cell>
				</Table.Row>
			) : (
				''
			)}
		</Table.Body>
	);
	componentDidMount() {
		let typeQuery = [];
		let group = [];
		let select = [];
		let { start_date, end_date, agent_id, agent_role, selectedPlatform } = this.state;
		let valueArr = this.state.value.length ? this.state.value : TypeList;
		_.each(valueArr, (it) => {
			typeQuery = _.concat(typeQuery, it.type);
			if (it.group) {
				if (group.length) group = _.intersection(group, it.group);
				else group = it.group;
			}
			if (it.select) {
				if (select.length) select = _.intersection(select, it.select);
				else select = it.select;
			}
		});
		typeQuery = _.uniq(typeQuery);
		this.setState({ head: typeQuery });
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		let filter = {};
		if (agent_role === 'agent') {
			filter = { type: typeQuery, pid: this.state.pid, agent: agent_id };
			this.setState({ selectedAgent: agent_id });
		} else {
			filter = { type: typeQuery, pid: this.state.pid };
		}
		if (group.length) group = _.union(group, ['type']);
		if (select.length) select = _.union(select, ['type', 'day']);
		if (selectedPlatform !== 'all') _.set(filter, 'platform', selectedPlatform);
		else _.unset(filter, 'platform');
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select,
				group: group,
				pairs: { agent: 'select5', kod: 'select2', count: 'count', platform: 'platform' },
				options: {
					sum: 1,
					avg: 1,
				},
				from: `(select  COALESCE(lr.total, lr2.total) as total, lr.type, lr.pid, lr.select1, lr.select2, lr.select3, lr.select4, lr.select5, lr.select6, lr.select7, lr.count, lr.date, lr.day, lr.hour, lr.platform  from (select *  from live_refined lr where ((day >= '${moment(
					start_date
				).format('YYYY-MM-DD')}' and hour >= ${moment(start_date).format('H')}) and (day <= '${moment(end_date).format(
					'YYYY-MM-DD'
				)}' and hour <=  ${moment(end_date).format('H')} )) and pid = '${
					this.state.pid
				}') lr left join live_refined lr2 on lr2.select2=lr.select2 and lr2.type='orderCreated' and lr.type <> 'orderCreated') as lr`,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let agentList = _.map(data.result, function (it) {
					return { key: it.agent, text: it.agent, value: it.agent, id: it.agent };
				});
				agentList = _.uniqBy(agentList, 'key');
				this.setState({ gettingData: _.sortBy(data.result, ['agent', 'asc']), agentList: agentList });
			});
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select,
				group: ['type', 'day'],
				pairs: { agent: 'select5', kod: 'select2', platform: 'platform' },
				options: {
					sum: 1,
					avg: 1,
				},
				from: `(select  COALESCE(lr.total, lr2.total) as total, lr.type, lr.pid, lr.select1, lr.select2, lr.select3, lr.select4, lr.select5, lr.select6, lr.select7, lr.count, lr.date, lr.day, lr.hour, lr.platform  from (select *  from live_refined lr where ((day >= '${moment(
					start_date
				).format('YYYY-MM-DD')}' and hour >= ${moment(start_date).format('H')}) and (day <= '${moment(end_date).format(
					'YYYY-MM-DD'
				)}' and hour <=  ${moment(end_date).format('H')} )) and pid = '${
					this.state.pid
				}') lr left join live_refined lr2 on lr2.select2=lr.select2 and lr2.type='orderCreated' and lr.type <> 'orderCreated') as lr`,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				_.each(data.result, function (it) {
					return (it.dayNumber = moment(it.day).dayOfYear());
				});
				this.setState({ gettingDataDate: _.orderBy(data.result, ['day', 'asc']) });
			});
		fetch('/api/live/agent/list')
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ newList: data.agents });
			});
		fetch('/api/dahi/message/getLiveChat', {})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					productAgentList:
						data.result &&
						data.result.agents &&
						_.filter(data.result.agents, function (it) {
							return it.type !== 'viewer';
						}),
					botId: data.result && data.result.bot.id,
					loading: false,
				});
			});
	}
	handleFilterChange = (e, { text, value }) => this.setState({ selectedAgent: value, selectedAgentName: text });
	toggle = () => this.setState((prevState) => ({ selectedDate: prevState.selectedDate === 'daily' ? 'hourly' : 'daily' }));
	handleChange = (e, { name, value }) => this.setState({ [name]: value });
	handleFilterCheck(value) {
		let array = this.state.checkboxList || [];
		let exists = _.find(array, value);
		let valueArr = exists ? _.filter(array, (it) => it !== value) : _.concat([], array, value);
		this.setState({ checkboxList: valueArr });
	}
	handleGroupChange = (e, { text, value }) => this.setState({ group: value });
	filterSelectType = () => {
		this.setState({ filterType: !this.state.filterType });
	};
	componentDidUpdate(oldProps, oldState) {
		const check_list = ['start_date', 'end_date', 'selectedAgent', 'selectedDate', 'value', 'selectedPlatform'];
		for (let i = 0; i < check_list.length; i++) {
			const key = check_list[i];
			if (key === 'start_date' || key === 'end_date') {
				if (this.props[key] !== oldProps[key]) {
					this.getData()();
					break;
				}
			}
			if (this.state[key] !== oldState[key]) {
				this.getData()();
				break;
			}
		}
	}

	getData = (value) => async () => {
		let typeQuery = [];
		let pairs = {};
		let group = [];
		let select = [];
		let dayGroup = ['type', 'day'];
		let current = ++this._i;
		let { selectedAgent, selectedDate, selectedPlatform } = this.state;
		let { start_date, end_date } = this.props;
		_.each(this.state.value, (it) => {
			typeQuery = _.concat(typeQuery, it.type);
			if (it.group) {
				if (group.length) group = _.intersection(group, it.group);
				else group = it.group;
			}
			if (it.select) {
				if (select.length) select = _.intersection(select, it.select);
				else select = it.select;
			}
			if (it.pairs) pairs = _.assignIn(it.pairs);
		});
		typeQuery = _.uniq(typeQuery);
		if (group.length) group = _.union(group, ['type']);
		if (select.length) select = _.union(select, ['type', 'day']);
		let filter = {};
		if (selectedAgent === 'all') {
			filter = { type: typeQuery, pid: this.state.pid };
		} else {
			filter = { type: typeQuery, pid: this.state.pid, agent: selectedAgent };
		}
		if (!(selectedDate === 'daily')) {
			//filter.day = selectedDate;
			dayGroup = ['type', 'hour'];
		}
		if (selectedPlatform !== 'all') _.set(filter, 'platform', selectedPlatform);
		else _.unset(filter, 'platform');
		pairs.platform = 'platform';
		end_date = moment(end_date).format();
		end_date = moment(end_date).format();
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select,
				group: group,
				pairs: { agent: 'select5', kod: 'select2', platform: 'platform' },
				options: {
					sum: 1,
					avg: 1,
				},
				from: `(select  COALESCE(lr.total, lr2.total) as total, lr.type, lr.pid, lr.select1, lr.select2, lr.select3, lr.select4, lr.select5, lr.select6, lr.select7, lr.count, lr.date, lr.day, lr.hour, lr.platform  from (select *  from live_refined lr where ((day >= '${moment(
					start_date
				).format('YYYY-MM-DD')}' and hour >= ${moment(start_date).format('H')}) and (day <= '${moment(end_date).format(
					'YYYY-MM-DD'
				)}' and hour <=  ${moment(end_date).format('H')} )) and pid = '${
					this.state.pid
				}') lr left join live_refined lr2 on lr2.select2=lr.select2 and lr2.type='orderCreated' and lr.type <> 'orderCreated') as lr`,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (current !== this._i) return;
				this.setState({ head: typeQuery });
				let agentList = _.map(data.result, function (it) {
					return { key: it.agent, text: it.agent, value: it.agent };
				});
				agentList = _.uniqBy(agentList, 'key');

				this.setState({ gettingData: _.sortBy(data.result, ['agent', 'asc']), agentList: agentList });
			});
		if (typeQuery.length) {
			fetch('https://log.yapaytech.com/live/test/getInfo', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					start_date: start_date,
					end_date: end_date,
					include_hour: true,
					filter,
					select,
					group: dayGroup,
					pairs,
					options: {
						sum: 1,
						avg: 1,
					},
					from: `(select  COALESCE(lr.total, lr2.total) as total, lr.type, lr.pid, lr.select1, lr.select2, lr.select3, lr.select4, lr.select5, lr.select6, lr.select7, lr.count, lr.date, lr.day, lr.hour, lr.platform  from (select *  from live_refined lr where ((day >= '${moment(
						start_date
					).format('YYYY-MM-DD')}' and hour >= ${moment(start_date).format('H')}) and (day <= '${moment(end_date).format(
						'YYYY-MM-DD'
					)}' and hour <=  ${moment(end_date).format('H')} )) and pid = '${
						this.state.pid
					}') lr left join live_refined lr2 on lr2.select2=lr.select2 and lr2.type='orderCreated' and lr.type <> 'orderCreated') as lr`,
				}),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					//if (current !== this._i) return;

					if (!(selectedDate === 'daily')) {
						/* _.each(data.result, function (it) {
							return (it.hour = (it.hour + 2) % 24);
						}); */
					} else {
						_.each(data.result, function (it) {
							return (it.dayNumber = moment(it.day).dayOfYear());
						});
					}
					this.setState({ gettingDataDate: _.orderBy(data.result, ['day', 'asc']), loading: false });
				});
		} else {
			this.setState({ gettingDataDate: [], loading: false });
		}
	};

	dateChange = ({ selection }) => {
		let start_date = moment(selection.startDate).startOf('day');
		let end_date = moment(selection.endDate);
		if (end_date.format('YYYY-MM-DD') !== this.state.today.format('YYYY-MM-DD')) {
			end_date = end_date.endOf('day');
		} else {
			end_date = moment();
		}
		this.setState({ start_date, end_date });
	};
	exportExcel = (value, pid) => {
		fetch('https://log.yapaytech.com/analytic/download', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				filename: pid + '_sellingData',
				sheets: [
					{
						name: 'sheet - selling',
						x: ['developer', 'count', 'sum', 'avg'],
						datasets: value,
					},
				],
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let dataurl = data.url;
				var a = document.createElement('a');
				a.href = dataurl;
				a.setAttribute('download', dataurl);
				a.click();
			});
	};
	render() {
		if (this.state.loading)
			return (
				<Container>
					<Segment style={{ marginBottom: '20px' }}>
						<Page.Loader></Page.Loader>
					</Segment>
				</Container>
			);
		let { start_date, end_date, t, i18n, product, platformList } = this.props;
		let currency = (product && product.live && product.live.settings.currency) || 'try';
		let gettingData = this.state.gettingData;
		let newList = this.state.newList;
		_.map(gettingData, function (it) {
			it.len = parseFloat(it.len) || 0;
			it.basketrate = it.len ? it.count / it.len : 0;
		});

		let { agent_role, selectedBarStyle, selectedPlatform } = this.state;
		let gGettingData = _.groupBy(gettingData, 'agent');
		let agentList = this.state.agentList;
		let productAgentList2 = this.state.productAgentList;
		let head = ['len', 'count', 'sum', 'avg', 'lenCanceled', 'countCanceled', 'lenRefunded', 'countRefunded', 'basketrate'];
		let dataloading = this.state.dataloading;
		_.map(productAgentList2, function (it) {
			return _.map(head, function (d) {
				return (it[d] = 0);
			});
		});
		_.map(gGettingData, function (it) {
			return _.map(it, function (d, i) {
				var e = _.findIndex(agentList, function (o) {
					return o.key === d.agent;
				});
				if (d.type === 'orderCanceled') {
					return _.assign(agentList[e], {
						id: d.agent,
						lenCanceled: d.len || 0,
						countCanceled: d.count || 0,
						sumCanceled: d.sum || 0,
						avgCanceled: d.sum,
					});
				} else if (d.type === 'orderRefunded') {
					return _.assign(agentList[e], {
						id: d.agent,
						lenRefunded: d.len || 0,
						countRefunded: d.count || 0,
						sumRefunded: d.sum || 0,
						avgRefunded: d.sum,
					});
				} else {
					return _.assign(agentList[e], {
						id: d.agent,
						len: d.len || 0,
						count: d.count || 0,
						sum: d.sum || 0,
						avg: d.avg || 0,
						basketrate: parseFloat(d.basketrate.toFixed(2)),
					});
				}
			});
		});
		let gettingDataDate = this.state.gettingDataDate;
		function showingBar(data, barStyle) {
			switch (barStyle) {
				case 'line':
					return <Line data={data} options={barOptions} />;
				case 'bar':
					return <Bar data={data} options={barOptions} />;
				case 'stackbar':
					return <Bar data={data} options={barOptionsStack} />;
				case 'radar':
					return <Radar data={data} />;
				default:
					return <Line data={data} options={barOptions} />;
			}
		}
		const barOptions = {
			scales: {
				xAxes: [{ stacked: false }],
				yAxes: [
					{
						id: 'A',
						stacked: false,
						position: 'left',
						ticks: { min: 0 },
						gridLines: {
							display: false,
						},
						scaleLabel: {
							display: true,
							labelString: t('labelCount'),
						},
					},
					{
						id: 'B',
						stacked: false,
						position: 'right',
						ticks: { min: 0 },
						gridLines: {
							display: false,
						},
						scaleLabel: {
							display: true,
							labelString: t('labelPrice'),
						},
					},
				],
				//yAxes: [{ stacked: false, ticks: { min: 0 } }],
			},
			tooltips: {
				mode: 'index',
				intersect: false,
				filter: (a) => a.yLabel,
			},
			hover: {
				mode: 'nearest',
				intersect: true,
			},
			legend: {
				display: true,
				labels: { fill: true, reverse: true },
			},
		};
		const barOptionsStack = {
			scales: {
				xAxes: [{ stacked: true }],
				yAxes: [
					{
						id: 'A',
						stacked: true,
						position: 'left',
						ticks: { min: 0 },
						gridLines: {
							display: false,
						},
						scaleLabel: {
							display: true,
							labelString: t('labelCount'),
						},
					},
					{
						id: 'B',
						stacked: true,
						position: 'right',
						ticks: { min: 0 },
						gridLines: {
							display: false,
						},
						scaleLabel: {
							display: true,
							labelString: t('labelPrice'),
						},
					},
				],
				//yAxes: [{ stacked: false, ticks: { min: 0 } }],
			},
			tooltips: {
				mode: 'index',
				intersect: false,
				filter: (a) => a.yLabel,
			},
			hover: {
				mode: 'nearest',
				intersect: true,
			},
			legend: {
				display: true,
				labels: { fill: true, reverse: true },
			},
		};
		let data = chartData(gettingDataDate, start_date, end_date, this.state.selectedDate, t, i18n);
		let productAgentList = this.state.productAgentList;
		let ownerName =
			this.props && this.props.access && this.props.access.user && this.props.access.user.loggedUserObj && this.props.access.user.loggedUserObj.name;
		let dropAgentList = _.map(productAgentList, function (it) {
			return { text: it.developer, key: it.id, value: it.id };
		});
		if (this.state.botId) {
			dropAgentList = _.concat(
				{ text: t('allAgent'), key: 'all', value: 'all' },
				{ text: 'Bot', key: 'bot', value: this.state.botId, id: this.state.botId },
				dropAgentList
			);
		} else {
			dropAgentList = _.concat({ text: t('allAgent'), key: 'all', value: 'all' }, dropAgentList);
		}
		let othersdata = {
			avg: 0,
			basketrate: 0,
			count: 0,
			id: 'others',
			key: 'others',
			len: 0,
			sum: 0,
			text: 'others',
			value: 'others',
			sumCanceled: 0,
			sumRefunded: 0,
		};
		_.map(agentList, function (it) {
			let n = _.findIndex(productAgentList2, { id: it.key });
			if (n >= 0) {
				return _.assign(productAgentList2[n], it);
			} else if (/bot/.test(it.key)) {
				return (productAgentList2 = _.concat(productAgentList2, it));
			} else {
				othersdata = _.assign({
					count: othersdata.count + it.count,
					len: othersdata.len + it.len,
					sum: othersdata.sum + it.sum,
					sumCanceled: othersdata.sumCanceled + it.sumCanceled,
					sumRefunded: othersdata.sumRefunded + it.sumRefunded,
				});
			}
			dataloading = false;
		});
		othersdata = {
			avg: (othersdata.len && parseFloat((othersdata.sum / othersdata.len).toFixed(2))) || 0,
			basketrate: (othersdata.len && parseFloat((othersdata.count / othersdata.len).toFixed(2))) || 0,
			count: othersdata.count,
			id: 'others',
			key: 'others',
			len: othersdata.len,
			sum: othersdata.sum,
			sumCanceled: othersdata.sumCanceled,
			sumRefunded: othersdata.sumRefunded,
			text: 'others',
			value: 'others',
			developer: t('oldAgents'),
		};
		_.map(productAgentList2, function (it) {
			if (it && !it.developer) {
				return (it.developer = 'Bot');
			}
		});
		if (agentList) {
			dataloading = false;
		}
		if (this.state.loading || dataloading)
			return (
				<Container>
					<Segment style={{ marginBottom: '20px' }}>
						<Page.Loader></Page.Loader>
					</Segment>
				</Container>
			);
		_.map(productAgentList2, function (it, i) {
			let name = _.find(newList, { email: it.developer }) && _.find(newList, { email: it.developer }).name;
			if (i === 0) {
				return (it.name = ownerName || it.developer);
			}
			return (it.name = name ? name : it.developer);
		});
		let barStyleDropdown = [
			{
				text: t('Line'),
				value: 'line',
			},
			{
				text: t('Bar'),
				value: 'bar',
			},
		];
		return (
			<Segment style={{ marginBottom: '20px' }}>
				<Segment attached="bottom">
					{
						<div style={{ display: 'flex' }}>
							<Dropdown
								selection
								options={platformList}
								value={selectedPlatform}
								floating
								labeled
								button
								icon="filter"
								className="icon"
								name="selectedPlatform"
								onChange={(e, { name, value }) => this.setState({ selectedPlatform: value })}
							/>
							{agent_role !== 'agent' ? (
								<div style={{ display: 'flex' }}>
									<Dropdown
										options={dropAgentList}
										selection
										defaultValue={dropAgentList[0].value}
										icon="filter"
										floating
										labeled
										button
										className="icon"
										onChange={this.handleFilterChange}
									></Dropdown>
								</div>
							) : (
								''
							)}
						</div>
					}
					<div style={{ display: 'flex' }}>
						{_.map(checkboxListArray, (it, i) => (
							<Checkbox
								style={{ margin: '10px' }}
								label={t(it.text)}
								key={i}
								onChange={() => this.handleFilterCheck(it)}
								defaultChecked={!it.uncheck}
							></Checkbox>
						))}
					</div>
				</Segment>
				<Table sortable fixed width={16} style={{ padding: '0px' }}>
					{this.TableHead(this.state.column, this.state.direction, t)}
					{this.TableLine(productAgentList2, this.state.selectedAgent, t, currency, othersdata)}
				</Table>
				<Container>
					<ChartWrap
						width={8}
						title={t('graph')}
						agentList={this.state.agentList}
						content={
							<div style={{ marginLeft: 'auto' }}>
								<Dropdown
									options={barStyleDropdown}
									value={selectedBarStyle}
									name="selectedBarStyle"
									onChange={this.handleChange}
									style={{ marginRight: '30px' }}
								></Dropdown>
								<Radio style={{ marginLeft: 'auto' }} toggle label={t('24HourDist')} onChange={this.toggle}></Radio>
							</div>
						}
					>
						{showingBar(data, selectedBarStyle)}
					</ChartWrap>
				</Container>
			</Segment>
		);
	}
}
// date array
var getDateArray = function (start, end) {
	var arr = [],
		dt = new Date(start);

	while (dt <= end) {
		arr.push(new Date(dt));
		dt.setDate(dt.getDate() + 1);
	}

	return arr;
};
var getHourArray = function (start, end) {
	var arr = [],
		dt = 0;

	while (dt < 24) {
		arr.push(dt);
		dt++;
	}
	return arr;
};
function chartData(data, start_date, end_date, selectedDate, t, i18n) {
	moment.locale(i18n.language);
	/* let dataset = _.each(data, function(it) {
		return { label: it.type, count: it.count };
	}); */
	let startDate = new Date(start_date);
	let endDate = new Date(end_date);
	var dateArr = getDateArray(startDate, endDate);
	var hourArr = getHourArray();
	let gdata = data || [];
	let labels = [];
	let orderCreatedData = _.filter(gdata, { type: 'orderCreated' });
	let orderCanceledData = _.filter(gdata, { type: 'orderCanceled' });
	let orderRefundedData = _.filter(gdata, { type: 'orderRefunded' });
	if (selectedDate === 'daily') {
		_.each(dateArr, function (d, i) {
			if (_.findIndex(orderCreatedData, { dayNumber: moment(d).dayOfYear() }) === -1) {
				orderCreatedData.push({
					type: (orderCreatedData && orderCreatedData[0] && orderCreatedData[0].type) || '',
					day: moment(d).format(),
					dayNumber: moment(d).dayOfYear(),
					len: 0,
					count: 0,
					sum: 0,
					avg: 0,
				});
			}
		});
		orderCreatedData = _.sortBy(orderCreatedData, 'day');
		_.each(dateArr, function (d, i) {
			if (_.findIndex(orderCanceledData, { dayNumber: moment(d).dayOfYear() }) === -1) {
				orderCanceledData.push({
					type: (orderCanceledData && orderCanceledData[0] && orderCanceledData[0].type) || '',
					day: moment(d).format(),
					dayNumber: moment(d).dayOfYear(),
					len: 0,
					count: 0,
					sum: 0,
					avg: 0,
				});
			}
		});
		orderCanceledData = _.sortBy(orderCanceledData, 'day');
		_.each(dateArr, function (d, i) {
			if (_.findIndex(orderRefundedData, { dayNumber: moment(d).dayOfYear() }) === -1) {
				orderRefundedData.push({
					type: (orderRefundedData && orderRefundedData[0] && orderRefundedData[0].type) || '',
					day: moment(d).format(),
					dayNumber: moment(d).dayOfYear(),
					len: 0,
					count: 0,
					sum: 0,
					avg: 0,
				});
			}
		});
		orderRefundedData = _.sortBy(orderRefundedData, 'day');
	} else {
		_.each(hourArr, function (d, i) {
			if (_.findIndex(orderCreatedData, { hour: d }) === -1) {
				orderCreatedData.push({
					type: (orderCreatedData && orderCreatedData[0] && orderCreatedData[0].type) || '',
					hour: d,
					len: 0,
					count: 0,
					sum: 0,
					avg: 0,
				});
			}
		});
		orderCreatedData = _.sortBy(orderCreatedData, 'hour');
		_.each(hourArr, function (d, i) {
			if (_.findIndex(orderCanceledData, { hour: d }) === -1) {
				orderCanceledData.push({
					type: (orderCanceledData && orderCanceledData[0] && orderCanceledData[0].type) || '',
					hour: d,
					len: 0,
					count: 0,
					sum: 0,
					avg: 0,
				});
			}
		});
		orderCanceledData = _.sortBy(orderCanceledData, 'hour');
		_.each(hourArr, function (d, i) {
			if (_.findIndex(orderRefundedData, { hour: d }) === -1) {
				orderRefundedData.push({
					type: (orderRefundedData && orderRefundedData[0] && orderRefundedData[0].type) || '',
					hour: d,
					len: 0,
					count: 0,
					sum: 0,
					avg: 0,
				});
			}
		});
		orderRefundedData = _.sortBy(orderRefundedData, 'hour');
	}
	console.group('orderCanceledData', orderCanceledData);
	let nc = [
		{
			label: t('orderCount'),
			data: _.map(orderCreatedData, (item) => item.len),
			borderColor: '#40bb46',
			yAxisID: 'A',
			backgroundColor: '#40bb46',
			fill: false,
		},
		{
			label: t('productCount'),
			data: _.map(orderCreatedData, (item) => item.count),
			borderColor: '#E10840',
			yAxisID: 'A',
			backgroundColor: '#E10840',
			fill: false,
		},
		{
			label: t('sum'),
			data: _.map(orderCreatedData, (item) => item.sum || 0),
			borderColor: '#8B9AE7',
			yAxisID: 'B',
			backgroundColor: '#8B9AE7',
			hidden: false,
			fill: false,
		},

		{
			label: t('average'),
			data: _.map(orderCreatedData, (item) => (item.avg && item.avg.toFixed('2')) || 0),
			borderColor: '#8B565C',
			yAxisID: 'B',
			backgroundColor: '#8B565C',
			fill: false,
		},
	];
	if (orderCanceledData.length) {
		nc.push(
			{
				label: t('orderCanceled'),
				data: _.map(orderCanceledData, (item) => item.len),
				borderColor: '#A909AB',
				yAxisID: 'A',
				backgroundColor: '#A909AB',
				fill: false,
			},
			{
				label: t('productCanceled'),
				data: _.map(orderCanceledData, (item) => item.count),
				borderColor: '#D26C0F',
				yAxisID: 'A',
				backgroundColor: '#D26C0F',
				fill: false,
			},
			{
				label: t('orderCanceledSum'),
				data: _.map(orderCanceledData, (item) => item.sum || 0),
				borderColor: '#a630eb',
				yAxisID: 'B',
				backgroundColor: '#a630eb',
				hidden: false,
				fill: false,
			}
		);
	}
	if (orderRefundedData.length) {
		nc.push(
			{
				label: t('orderRefunded'),
				data: _.map(orderRefundedData, (item) => item.len),
				borderColor: '#E11E78',
				yAxisID: 'A',
				backgroundColor: '#E11E78',
				fill: false,
			},
			{
				label: t('productRefunded'),
				data: _.map(orderRefundedData, (item) => item.count),
				borderColor: '#F2EBAE',
				yAxisID: 'A',
				backgroundColor: '#F2EBAE',
				fill: false,
			},

			{
				label: t('orderRefundedSum'),
				data: _.map(orderRefundedData, (item) => item.sum || 0),
				borderColor: '#eb3030',
				yAxisID: 'B',
				backgroundColor: '#eb3030',
				hidden: false,
				fill: false,
			}
		);
	}
	if (selectedDate === 'daily') {
		labels = _.map(dateArr, (it) => moment(it).format('DD-MMM'));
	} else {
		labels = hourArr;
	}
	return {
		labels,
		datasets: nc,
	};
}
const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product });
export default connect(stateToProps)(withTranslation()(Analytic));