import React, { Component } from 'react';
import { post } from '../../util/api';
import { Segment, Table, Tab, Button, Header, Form } from 'semantic-ui-react';
import _ from 'lodash';

export default class MainTable extends Component {
	TableLineAnalytic = (data) =>
		_.map(data, (val, index) => (
			<Table.Row key={index}>
				<Table.Cell>
					<Button
						color="blue"
						onClick={() =>
							this.selectProductAnalytic({
								email: val.owner_email,
								pid: val.pid,
								ecommerce: val.ecommerce,
								sendProduct: val.sendProduct,
								showTemplate: val.showTemplate,
								generalTable: val.generalTable,
								conversationGroup: val.conversationGroup,
								ratePoint: val.ratePoint,
								userlist: val.userlist,
								mailing_report: val.mailing_report,
								stockinfo: val.stockinfo,
								timeSelect: val.timeSelect,
							})
						}
					>
						Edit
					</Button>
				</Table.Cell>
				<Table.Cell>{val && val.owner_id}</Table.Cell>
				<Table.Cell>{val && val.owner_email}</Table.Cell>
				<Table.Cell>{val && val.ecommerce ? 'true' : 'false'}</Table.Cell>
				<Table.Cell>{val && val.sendProduct ? 'true' : 'false'}</Table.Cell>
				<Table.Cell>{val && val.showTemplate ? 'true' : 'false'}</Table.Cell>
				<Table.Cell>{val && val.conversationGroup ? 'true' : 'false'}</Table.Cell>
				<Table.Cell>{val && val.ratePoint ? 'true' : 'false'}</Table.Cell>
				<Table.Cell>{val && val.userlist ? 'true' : 'false'}</Table.Cell>
				<Table.Cell>{val && val.mailing_report ? 'true' : 'false'}</Table.Cell>
				<Table.Cell>{val && val.generalTable ? 'true' : 'false'}</Table.Cell>
				<Table.Cell>{val && val.tstockinfo ? 'true' : 'false'}</Table.Cell>
				<Table.Cell>{val && val.timeSelect ? 'true' : 'false'}</Table.Cell>
			</Table.Row>
		));
	selectProductAnalytic(value) {
		this.setState({
			pid: value.pid,
			email: value.email,
			ecommerce: value.ecommerce,
			sendProduct: value.sendProduct,
			showTemplate: value.showTemplate,
			generalTable: value.generalTable,
			conversationGroup: value.conversationGroup,
			ratePoint: value.ratePoint,
			userlist: value.userlist,
			mailing_report: value.mailing_report,
			stockinfo: value.stockinfo,
			timeSelect: value.timeSelect,
		});
	}
	saveAnalytic = () => {
		this.setState({ btnloading: true });
		let { pid } = this.state;
		post('/live/admin/setAnalyticView', {
			pid: pid,
			analyticView: {
				ecommerce: this.state.ecommerce,
				sendProduct: this.state.sendProduct,
				showTemplate: this.state.showTemplate,
				conversationGroup: this.state.conversationGroup,
				ratePoint: this.state.ratePoint,
				userlist: this.state.userlist,
				generalTable: this.state.generalTable,
				mailing_report: this.state.mailing_report,
				stockinfo: this.state.stockinfo,
				timeSelect: this.state.timeSelect,
			},
		}).then((data) => {
			if (data.data.success) {
				this.setState({ btnloading: false });
				this.refresh();
			} else {
				this.setState({ btnloading: false, errMessage: data.data.error });
			}
		});
	};
	toggle = (e, { name, value }) => this.setState((prevState) => ({ [name]: !prevState[name] }));
	state = {
		loading: true,
		activePage: 1,
		limit: 50,
		email: '',
		buttonloading: true,
	};
	componentDidMount() {
		this.postData();
	}
	async postData() {
		await post('/live/admin/dashboard').then((data) => this.setState({ data: _.orderBy(data.data.result, 'owner_id', 'desc'), loading: false }));
	}
	handleSort = (clickedColumn, data) => () => {
		const { column, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				data: _.sortBy(data, [clickedColumn]),
				direction: 'ascending',
			});

			return;
		}

		this.setState({
			data: data.reverse(),
			direction: direction === 'ascending' ? 'descending' : 'ascending',
		});
	};
	refresh() {
		setTimeout(() => {
			window.location.reload(false);
		}, 1000);
	}
	render() {
		const { column, direction, loading, data } = this.state;
		if (loading) return <Segment attached="bottom">loading...</Segment>;
		let dg = _.map(data, function (it) {
			it = {
				agent_limit: it.agent_limit || 0,
				phone_limit: it.phone_limit || 0,
				instagram_limit: it.instagram_limit || 0,
				owner_id: it.owner_id,
				owner_name: it.owner_name,
				is_reseller: it.is_reseller,
				is_enterprise: it.is_enterprise,
				is_eticaret: it.is_eticaret,
				owner_email: it.owner_email,
				pid: it.pid,
				token: it.token,
				agents: it.agents,
				phones: it.phones,
				instagrams: it.instagrams,
				package: it.package,
				verified: it.verified,
				total_agent: it.total_agent,
				wapi_pid: it.wapi_pid,
				wapi_token: it.wapi_token,
				analyticview: it.analyticview,
				userlist: (it.analyticview && it.analyticview.userlist) || false,
				ecommerce: (it.analyticview && it.analyticview.ecommerce) || false,
				ratePoint: (it.analyticview && it.analyticview.ratePoint) || false,
				sendProduct: (it.analyticview && it.analyticview.sendProduct) || false,
				showTemplate: (it.analyticview && it.analyticview.showTemplate) || false,
				generalTable: (it.analyticview && it.analyticview.generalTable) || false,
				conversationGroup: (it.analyticview && it.analyticview.conversationGroup) || false,
				mailing_report: (it.analyticview && it.analyticview.mailing_report) || false,
				stockinfo: (it.analyticview && it.analyticview.stockinfo) || false,
				timeSelect: (it.analyticview && it.analyticview.timeSelect) || false,
				orderRefunded_count: 0,
				orderRefunded_len: 0,
				orderCanceled_count: 0,
				orderCanceled_len: 0,
				test_account: it.test_account || false,
			};
			return it;
		});
		return (
			<div style={{ display: 'flex', maxHeight: '95%' }}>
				<Tab.Pane attached={true} style={{ width: '75%', overflow: 'auto', padding: 0 }}>
					<Table celled sortable>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell width={1}></Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'owner_id' ? direction : null} onClick={this.handleSort('owner_id', dg)}>
									Id
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'owner_email' ? direction : null} onClick={this.handleSort('owner_email', dg)}>
									Email
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'ecommerce' ? direction : null} onClick={this.handleSort('ecommerce', dg)}>
									Ecommerce
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'sendProduct' ? direction : null} onClick={this.handleSort('sendProduct', dg)}>
									Send Product
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'showTemplate' ? direction : null} onClick={this.handleSort('showTemplate', dg)}>
									Template
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'conversationGroup' ? direction : null} onClick={this.handleSort('conversationGroup', dg)}>
									Conversation Group
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'ratePoint' ? direction : null} onClick={this.handleSort('ratePoint', dg)}>
									Rate Point
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'userlist' ? direction : null} onClick={this.handleSort('userlist', dg)}>
									User List
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'mailing_report' ? direction : null} onClick={this.handleSort('mailing_report', dg)}>
									Mail Report
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'generalTable' ? direction : null} onClick={this.handleSort('generalTable', dg)}>
									General Table
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'stockinfo' ? direction : null} onClick={this.handleSort('stockinfo', dg)}>
									Stock Info
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'timeSelect' ? direction : null} onClick={this.handleSort('timeSelect', dg)}>
									Time Select
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>{this.TableLineAnalytic(dg)}</Table.Body>
					</Table>
				</Tab.Pane>
				<Segment className="left-menu" style={{ marginTop: '0', width: '25%', maxHeight: '786px' }}>
					<Header>Edit</Header>
					<Form autoComplete="off" onSubmit={this.saveAnalytic}>
						<Form.Input label="Email" readOnly value={this.state.email} placeholder="Select Product" required></Form.Input>
						<Form.Input label="Pid" readOnly value={this.state.pid} placeholder="Select Product" required></Form.Input>
						<Form.Checkbox
							name="ecommerce"
							label="Ecommerce"
							disabled={!this.state.pid}
							onChange={this.toggle}
							checked={this.state.ecommerce}
							value={this.state.ecommerce}
						></Form.Checkbox>
						<Form.Checkbox
							name="sendProduct"
							label="Send Product"
							onChange={this.toggle}
							disabled={!this.state.pid}
							checked={this.state.sendProduct}
							value={this.state.sendProduct}
						></Form.Checkbox>
						<Form.Checkbox
							name="showTemplate"
							label="Template"
							onChange={this.toggle}
							disabled={!this.state.pid}
							checked={this.state.showTemplate}
							value={this.state.showTemplate}
						></Form.Checkbox>
						<Form.Checkbox
							name="conversationGroup"
							label="Conversation Group"
							onChange={this.toggle}
							disabled={!this.state.pid}
							checked={this.state.conversationGroup}
							value={this.state.conversationGroup}
						></Form.Checkbox>
						<Form.Checkbox
							name="ratePoint"
							label="Rate Point"
							onChange={this.toggle}
							disabled={!this.state.pid}
							checked={this.state.ratePoint}
							value={this.state.ratePoint}
						></Form.Checkbox>
						<Form.Checkbox
							name="userlist"
							label="User List"
							onChange={this.toggle}
							disabled={!this.state.pid}
							checked={this.state.userlist}
							value={this.state.userlist}
						></Form.Checkbox>
						<Form.Checkbox
							name="mailing_report"
							label="Mailing Report"
							onChange={this.toggle}
							disabled={!this.state.pid}
							checked={this.state.mailing_report}
							value={this.state.mailing_report}
						></Form.Checkbox>
						<Form.Checkbox
							name="generalTable"
							label="General Table"
							onChange={this.toggle}
							disabled={!this.state.pid}
							checked={this.state.generalTable}
							value={this.state.generalTable}
						></Form.Checkbox>
						<Form.Checkbox
							name="stockinfo"
							label="Stock Info"
							onChange={this.toggle}
							disabled={!this.state.pid}
							checked={this.state.stockinfo}
							value={this.state.stockinfo}
						></Form.Checkbox>
						<Form.Checkbox
							name="timeSelect"
							label="Time Select"
							onChange={this.toggle}
							disabled={!this.state.pid}
							checked={this.state.timeSelect}
							value={this.state.timeSelect}
						></Form.Checkbox>
						<div className="d-flex">
							<Form.Button
								positive
								content="Save"
								type="submit"
								style={{ width: '100px' }}
								disabled={!this.state.pid}
								loading={this.state.btnloading}
							/>
						</div>
					</Form>
				</Segment>
			</div>
		);
	}
}
