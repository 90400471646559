import React, { useState } from 'react';
import Page from '../../../components/Page';
import { Icon, Breadcrumb, Table, Tab, Button, Message } from 'semantic-ui-react';
import { Link, Prompt, Route, matchPath, NavLink } from 'react-router-dom';
import useFetch from 'use-http';
import { Formik, FastField } from 'formik';
import _ from 'lodash';
import MyMenu from '../menu';
import { TextInput, TableLine, BooleanInput } from '../inputs';
import AccountProgress from '../../../components/AccountProgress';
import { useTranslation } from 'react-i18next';
const fetch_config = { responseType: 'json', cachePolicy: 'no-cache' };

function SubConfigs() {
	const { t } = useTranslation();
	return (
		<FastField name="active">
			{({ field }) => (
				<Table definition>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell width={2} />
							<Table.HeaderCell>{t('value')}</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						<TableLine title={t('productSearch')} description={t('productSearchDesc')}>
							<BooleanInput name="_search" />
						</TableLine>
						<TableLine title={t('productDetails')} description={t('productDetailsDesc')}>
							<BooleanInput name="_detail" />
						</TableLine>
					</Table.Body>
				</Table>
			)}
		</FastField>
	);
}

function GeneralConfigs(extra) {
	const { t } = useTranslation();
	let { subscription_package } = extra;
	return (
		<Tab.Pane>
			<Table definition>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width={2} />
						<Table.HeaderCell>{t('value')}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{/* 	<TableLine title="Url">
						<TextInput name="public_url" placeholder={t('yourWebsiteUrl')} />
					</TableLine> */}

					<TableLine title={t('username')}>
						<TextInput name="username" placeholder={t('username')} readonly />
					</TableLine>
					<TableLine title={t('password')}>
						<TextInput name="password" placeholder={t('password')} readonly />
					</TableLine>
				</Table.Body>
			</Table>
			{subscription_package !== 'shopify_basic' ? <SubConfigs /> : <Message warning>{t('shopifyDownPackage')}</Message>}
		</Tab.Pane>
	);
}

function Configs(extra) {
	const { t } = useTranslation();
	let subscription_package = extra.product.settings.subscription_package;
	const panes = [
		{
			menuItem: {
				as: NavLink,
				content: t('general'),
				to: '/settings/integrations/shopify',
				exact: true,
				key: 'shopify',
			},
			render: () => (
				<Route path="/settings/integrations/shopify" exact component={() => <GeneralConfigs subscription_package={subscription_package} />} />
			),
		},
	];
	// Required to get a correct tab opened by default
	const defaultActiveIndex = panes.findIndex((pane) => {
		return !!matchPath(window.location.pathname, {
			path: pane.menuItem.to,
			exact: true,
		});
	});
	return (
		<Page.Wrap>
			<Tab menu={{ secondary: true, pointing: true }} defaultActiveIndex={defaultActiveIndex} panes={panes} />
		</Page.Wrap>
	);
}

export default function ClientSettings(props) {
	const { t } = useTranslation();
	let { product } = props;
	const { loading, response } = useFetch('/api/dahi/channel/get/shopify', fetch_config, []);
	const result = response && response.data;
	const [message, setMessage] = useState('');
	const [messageType, setMessageType] = useState('');
	const [showMessage, setShowMessage] = useState(true);
	const initialValues = React.useMemo(() => {
		if (!result) return;
		const { config } = (result && result.data) || {};
		//const agent = _.pick(_.assignIn(agentOptions, staticOptions), ['emoji']);
		return _.merge({ host: '', username: '', password: '' }, config);
	}, [result]);
	if (loading)
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/settings`}>
							<Icon name="setting" />
							{t('settings')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />

						<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
							{t('integrations')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
					</Breadcrumb>
				}
				flex
			>
				<MyMenu activeItem="shopify">
					<Page.Loader />
				</MyMenu>
			</Page>
		);

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values, actions) => {
				if (values.client) values.client.name = values.name;
				fetch('/api/dahi/channel/save', {
					method: 'POST',
					body: JSON.stringify({
						channel: 'shopify',
						data: values,
					}),
					headers: {
						'Content-Type': 'application/json',
					},
				})
					.then((response) => {
						return response.json();
					})
					.then((json) => {
						if (json.success) {
							setShowMessage(false);
							setMessageType('positive');
							setMessage('Your shopify account connected.');
							values.active = true;
							actions.resetForm(values);
						} else {
							setShowMessage(false);
							setMessageType('negative');
							setMessage('Error');
						}
						actions.setSubmitting(false);
					})
					.catch((e) => {
						console.error(e);
						setShowMessage(false);
						setMessageType('negative');
						setMessage('Error');
						actions.setSubmitting(false);
					});
			}}
		>
			{(props) => {
				return (
					<Page
						header={
							<Breadcrumb size="big">
								<Breadcrumb.Section as={Link} to={`/settings`}>
									<Icon name="setting" />
									{t('settings')}
								</Breadcrumb.Section>
								<Breadcrumb.Divider icon="right chevron" />

								<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
									{t('integrations')}
								</Breadcrumb.Section>
								<Breadcrumb.Divider icon="right chevron" />
								<Breadcrumb.Section>Shopify {t('settings')}</Breadcrumb.Section>
							</Breadcrumb>
						}
						extra={
							<div className="ml-auto d-flex">
								<AccountProgress></AccountProgress>
								<Button className="ml-auto" loading={props.isSubmitting} primary type="submit" onClick={props.submitForm}>
									{t('saveChanges')}
								</Button>
							</div>
						}
						flex
					>
						<MyMenu activeItem="shopify">
							<form onSubmit={props.handleSubmit} autoComplete="off">
								<Prompt
									when={props.dirty}
									message={(location) =>
										location.pathname.startsWith('/settings/integrations/website-chat') ? true : t('questionWithoutSaving')
									}
								/>

								<Configs product={product} />
								<div style={{ textAlign: 'center' }}>
									<Message compact hidden={showMessage} positive={messageType === 'positive'} negative={messageType === 'negative'}>
										{message}
									</Message>
								</div>
							</form>
						</MyMenu>
					</Page>
				);
			}}
		</Formik>
	);
}
