import React, { Component } from 'react';
import Page from '../../components/Page';
import { Icon, Breadcrumb, Segment, Progress, Grid, Header, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import MyMenu from './menu';
import _ from 'lodash';
import { get, toWapi } from '../../util/api';
import 'react-intl-tel-input/dist/main.css';
import { withTranslation } from 'react-i18next';

class ProgressPage extends Component {
	state = {
		phones: [],
		loading: true,
	};
	componentDidMount() {
		this._mount = true;
		this.load();
	}

	componentWillUnmount() {
		this._mount = false;
	}

	async load() {
		let product = this.props.product.live;
		let pid = product.id;
		let wapiPid = product && product.settings && product.settings.wapi_pid;
		let wapi_token = product && product.settings && product.settings.wapi_token;

		await get('/dahi/message/getLiveChat').then((data) => this.setState({ livechat: data.result }));
		if (wapi_token) {
			await toWapi(`/api/${wapiPid || pid}/listPhones`, product.settings.wapi_token).then((data) => {
				this.setState({ phones: data.data });
			});
		}
		await get('/live/agent/list').then((data) => {
			this.setState({ agents: data.agents, loading: false });
		});
	}

	render() {
		let { loading, agents, phones, livechat } = this.state;
		const { t } = this.props;
		const goalsStart = [
			{
				name: t('addPhone'),
			},
			/* {
				name: t('connectToWhatsappWeb'),
			}, */
			{
				name: t('addAgent'),
			},
			{
				name: t('verifyMail'),
			},
			{
				name: t('changeChatName'),
			},
			{
				name: t('subscriptionPackage'),
			},
			{
				name: t('completeAccount'),
			},
			{
				name: t('addCompanyInformation'),
			},
		];

		if (loading)
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
					flex
					data={this.props}
				>
					<MyMenu activeItem="progress">
						<Page.Wrap>
							<Segment>
								<h2 style={{ textAlign: 'center' }}>{t('your_goals')}</h2>
								<Progress percent={0} indicating progress />
								<div className="goals">
									<Grid columns={3} divided style={{ marginLeft: '30px' }}>
										{_.map(goalsStart, (it, i) => (
											<div
												key={i}
												style={{
													fontSize: '16px',
													display: 'flex',
													textAlign: 'left',
													width: '33%',
													padding: '15px',
													alignItems: 'center',
												}}
											>
												<Icon name={'spinner'} color={'black'} loading></Icon>
												<p style={{ color: 'black' }}>{it.name}</p>
											</div>
										))}
									</Grid>
								</div>
							</Segment>
						</Page.Wrap>
					</MyMenu>
				</Page>
			);
		let subscription =
			this.props.product && this.props.product.live && this.props.product.live.settings && this.props.product.live.settings.subscription_package;
		let isShopify = this.props.product && this.props.product.live && this.props.product.live.type && this.props.product.live.type === 'shopify';
		let goals = [
			{
				name: t('addPhone'),
				completed: phones && phones.length && phones.length > 0,
				url: '/settings/integrations/whatsapp-web-api',
			},
			/* {
				name: t('connectToWhatsappWeb'),
				completed: _.filter(phones, { status: 'active' }).length > 0,
				url: '/settings/integrations/whatsapp-web-api',
			}, */
			{
				name: t('addAgent'),
				completed: agents && agents.length && agents.length > 0,
				url: '/settings/agents',
			},
			{
				name: t('verifyMail'),
				completed: this.props.user.verified,
				url: '/account',
			},
			{
				name: t('changeChatName'),
				completed: livechat && livechat.name && livechat.name !== 'Live Chat',
				url: '/settings/integrations/website-chat',
			},
			{
				name: t('subscriptionPackage'),
				completed: subscription && subscription.length && subscription.length > 0,
				url: '/settings/subscription',
			},

			{
				name: t('completeAccount'),
				completed: !!(this.props.user.name && this.props.user.company && this.props.user.phone),
				url: '/account',
			},
			{
				name: t('addCompanyInformation'),
				completed: this.props.user.extra && !!this.props.user.extra.company,
				url: '/account/company',
			},
		];
		let percentage = ((_.filter(goals, { completed: true }).length * 100) / goals.length).toFixed(0);
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/settings`}>
							<Icon name="setting" />
							{t('settings')}
						</Breadcrumb.Section>
					</Breadcrumb>
				}
				flex
				data={this.props}
			>
				<MyMenu activeItem="progress">
					<Page.Wrap>
						{isShopify && (
							<Segment>
								<Header>{t('SetupInstructions')}</Header>
								<p>{t('letsStart')}</p>
								<div style={{ display: 'flex', marginBottom: '20px' }}>
									<h4 style={{ marginRight: '30px' }}>{t('step1')}</h4>
									<div>
										<p>{t('step1Shopify')}</p>
										<Button primary as={Link} to="/settings/integrations/shopify" size="mini">
											Shopify
										</Button>
									</div>
								</div>
								<div style={{ display: 'flex', marginBottom: '20px' }}>
									<h4 style={{ marginRight: '30px' }}>{t('step2')}</h4>
									<div>
										<p>{t('step2Shopify')}</p>
										<Button primary as={Link} to="/" size="mini">
											Chat
										</Button>
									</div>
								</div>
								<div style={{ display: 'flex', marginBottom: '20px' }}>
									<h4 style={{ marginRight: '30px' }}>{t('step3')}</h4>
									<div>
										<p>{t('step3Shopify')}</p>
										<Button primary as={Link} to="/settings/subscription" size="mini">
											{t('subscribe')}
										</Button>
									</div>
								</div>
							</Segment>
						)}
						<Segment>
							<h2 style={{ textAlign: 'center' }}>{t('your_goals')}</h2>
							<Progress percent={percentage} indicating progress />
							<div className="goals">
								<Grid columns={3} divided style={{ marginLeft: '30px' }}>
									{_.map(goals, (it, i) => (
										<div
											style={{
												fontSize: '18px',
												display: 'flex',
												textAlign: 'left',
												width: '33%',
												padding: '15px',
												alignItems: 'center',
											}}
										>
											<Icon name={it.completed ? 'check circle' : 'remove circle'} color={it.completed ? 'green' : 'red'}></Icon>
											<p style={{ color: it.completed ? 'green' : 'black' }}>
												{it.completed ? it.name : <Link to={it.url}>{it.name}</Link>}
											</p>
										</div>
									))}
								</Grid>
							</div>
						</Segment>
					</Page.Wrap>
				</MyMenu>
			</Page>
		);
	}
}

const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product, user: state.access.user.loggedUserObj });

export default connect(stateToProps)(withTranslation()(ProgressPage));
