import React from 'react';
import { Switch, Route } from 'react-router-dom';
import WhatsappHelp from './whatsappHelp';
import LebelHelp from './labelHelp';
import MainHelp from './mainHelp';
import EcommerceHelp from './ecommerce';

export default (props) => {
	return (
		<Switch>
			<Route path="/appstore/help" exact component={MainHelp} />
			<Route path="/appstore/help/whatsapp" component={WhatsappHelp} />
			<Route path="/appstore/help/label" component={LebelHelp} />
			<Route path="/appstore/help/ecommerce" component={EcommerceHelp} />
		</Switch>
	);
};
