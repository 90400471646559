import React, { Component } from 'react';
import Page from '../../../components/Page';
import { Icon, Breadcrumb, Button, Modal, Header, Segment, List, Form, Container, Grid, GridColumn, Table, Checkbox } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { post } from '../../../util/api';
import MyMenu from '../menu';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

class Phones extends Component {
	phoneToDelete = null;
	state = {
		loading: true,
		saveButton: 'add',
		newWaList: [],
	};
	async componentDidMount() {
		await this.getBusinessPhoneList();
		await this.getBusinessTemplates();
		await this.getList();
	}
	async getList() {
		let list = await post('/dahi/message/getLiveChat', {});
		let wa_templates = (list.data.result && list.data.result.staticOptions && list.data.result.staticOptions.wa_templates) || [];
		let waba_gen_id = (list.data.result && list.data.result.staticOptions && list.data.result.staticOptions.waba_gen_id) || '';
		this.setState({ waba_gen_id, wa_templates, loading: false });
	}
	async getBusinessPhoneList() {
		let { product } = this.props;
		let pid = product.id;
		await post('/broadcast/phoneList', { pid }).then((data) => {
			let phones = data.data.phones;
			let businessPhoneList = [];
			if (phones && phones.length) {
				businessPhoneList = _.map(phones, (it) => ({
					value: it,
					text: it,
				}));
			}
			this.setState({ businessPhoneList, selectedBusinessphone: businessPhoneList && businessPhoneList[0] && businessPhoneList[0].value });
		});
	}
	async getBusinessTemplates(refresh = false) {
		let { selectedBusinessphone } = this.state;
		let { product } = this.props;
		let pid = product.id;
		await post('/broadcast/templateList ', { pid, shop: selectedBusinessphone, refresh }).then((data) => {
			//console.log('da', data);
			let templates = _.filter(data.data.templates, { status: 'approved' });
			let template_config = data.data.config;
			let template_list = _.map(templates, (it) => ({
				text: it.name,
				value: it.name,
				includeVariable: _.findIndex(it.components, (o) => /{{\d+}}/.test(o.text)),
			}));
			template_list = _.filter(template_list, (it) => it.includeVariable < 0);
			this.setState({ templates, template_list, template_config, templateLoading: false });
		});
	}
	handleChange = (e, { name, value }) => {
		let { selected, tag_list } = this.state;
		tag_list[selected][name] = value;
		this.setState({ tag_list });
	};
	colorChance(value) {
		let { selected, tag_list } = this.state;
		tag_list[selected].color = value;
		this.setState({ tag_list });
	}
	addTagList() {
		let { tag_list } = this.state;
		if (!tag_list.length || tag_list[tag_list.length - 1]._id)
			tag_list.push({
				title: '',
				color: '#5d9cec',
			});
		this.setState({ tag_list, selected: tag_list.length - 1, saveButton: 'add' });
	}
	async saveTemplate() {
		let { newWaList } = this.state;
		this.setState({ saveButtonLoading: true });
		await post('/dahi/message/updateWaTemplates', { list: newWaList });
		await this.getList();
		this.setState({ saveButtonLoading: false });
	}
	handleSelectedList(obj) {
		let { newWaList } = this.state;
		if (_.find(newWaList, { name: obj.name, language: obj.language })) {
			_.pull(newWaList, obj);
		} else {
			newWaList.push(obj);
		}
		this.setState({ newWaList });
	}
	render() {
		const { t } = this.props;
		let { tag_list, selected, saveButton, saveButtonLoading, deleteButtonLoading, wa_templates, templates, templateLoading, newWaList, waba_gen_id } =
			this.state;
		if (this.state.loading) {
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
								{t('integrations')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
					flex
				>
					<MyMenu activeItem="wabatemplates">
						<Page.Loader />
					</MyMenu>
				</Page>
			);
		} else if (!waba_gen_id) {
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
								{t('integrations')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />
							<Breadcrumb.Section>WABA Template</Breadcrumb.Section>
						</Breadcrumb>
					}
					flex
				>
					<MyMenu activeItem="wabatemplates">
						<Page.Wrap>
							<Segment>{t('contactUsWabaTemplate')}</Segment>
						</Page.Wrap>
					</MyMenu>
				</Page>
			);
		} else {
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
								{t('integrations')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />
							<Breadcrumb.Section>WABA Template</Breadcrumb.Section>
						</Breadcrumb>
					}
					flex
				>
					<MyMenu activeItem="wabatemplates">
						<Page.Wrap>
							<Grid>
								<GridColumn width={6}>
									<Segment>
										<Header>Live Chat</Header>

										<Table>
											<Table.Body>
												{_.map(wa_templates, (it) => (
													<Table.Row>
														<Table.Cell>{it.name}</Table.Cell>
													</Table.Row>
												))}
											</Table.Body>
										</Table>
									</Segment>
								</GridColumn>
								<GridColumn width={10}>
									<Segment>
										<div style={{ display: 'flex' }}>
											<Header>System</Header>
											<Button
												basic
												color="green"
												type="button"
												loading={templateLoading}
												style={{ marginLeft: 'auto' }}
												onClick={() => {
													this.setState({ templateLoading: true });
													this.getBusinessTemplates(true);
												}}
											>
												Yenile
											</Button>
											<Button
												color="blue"
												type="button"
												loading={templateLoading || saveButtonLoading}
												onClick={() => {
													//this.setState({ templateLoading: true });
													this.saveTemplate(true);
												}}
											>
												Yeni Listeyi Kaydet
											</Button>
										</div>
										<Table>
											<Table.Body>
												{_.map(templates, (it) => (
													<Table.Row>
														<Table.Cell width={1}>
															<Checkbox onChange={() => this.handleSelectedList(it)}></Checkbox>
														</Table.Cell>
														<Table.Cell>
															{it.name} - {it.language}
														</Table.Cell>
													</Table.Row>
												))}
											</Table.Body>
										</Table>
									</Segment>
								</GridColumn>
							</Grid>
						</Page.Wrap>
					</MyMenu>
				</Page>
			);
		}
	}
}

const stateToProps = (state) => ({
	access: state.access,
	location: state.router.location,
	product: state.product.live,
	user: state.access.user.loggedUserObj,
});

export default connect(stateToProps)(withTranslation()(Phones));
