import React, { Component } from 'react';
import { Grid, Label, Divider, Segment, Container, Header, Icon, Table, Popup, Dropdown, Button } from 'semantic-ui-react';
import { Line, Bar, Radar } from 'react-chartjs-2';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import { withTranslation } from 'react-i18next';
import 'moment/locale/tr';

const barOptions = {
	scales: {
		xAxes: [{ stacked: false }],
		yAxes: [{ stacked: false, ticks: { min: 0 } }],
	},
	tooltips: {
		mode: 'index',
		intersect: false,
		filter: (a) => a.yLabel,
	},
	hover: {
		mode: 'nearest',
		intersect: true,
	},
	legend: {
		display: true,
		labels: { fill: false },
	},
};
const barOptionsStack = {
	scales: {
		xAxes: [{ stacked: true }],
		yAxes: [
			{
				stacked: true,
				ticks: { min: 0 },
				scaleLabel: {
					display: true,
				},
			},
		],
	},
	tooltips: {
		mode: 'index',
		intersect: false,
		filter: (a) => a.yLabel,
	},
	hover: {
		mode: 'nearest',
		intersect: true,
	},
	legend: {
		display: true,
		labels: { fill: false },
	},
};
const ChartWrap = (props) => (
	<Grid.Column width={props.width}>
		<Header as="h4" attached="top" style={{ display: 'flex' }}>
			<Icon name={props.icon || 'chart bar'} />
			<Header.Content style={{ width: '600px' }}>{props.title}</Header.Content>
			{props.content}
		</Header>
		<Segment attached>{props.children}</Segment>
	</Grid.Column>
);
const CardWrap = (props) => (
	<Grid.Column width={props.width}>
		<Segment textAlign="center" style={{ paddingTop: 60, minHeight: 230, paddingLeft: 8, paddingRight: 8 }} size="massive">
			<Label size="large" color={props.color} attached="top">
				{props.title}
			</Label>
			<div>
				{props.data}
				{props.unit ? <span style={{ fontSize: '10px' }}>{props.unit}</span> : ''}
			</div>
			{props.note ? <p style={{ fontSize: '10px', padding: '0', margin: '0', marginTop: '5px' }}>{props.note}</p> : ''}

			{props.note2 ? <p style={{ fontSize: '10px', padding: '0', margin: '0', marginTop: '5px' }}>{props.note2}</p> : ''}
			{props.note3 ? <p style={{ fontSize: '10px', padding: '0', margin: '0', marginTop: '5px' }}>{props.note3}</p> : ''}
			{props.note4 ? <p style={{ fontSize: '10px', padding: '0', margin: '0', marginTop: '5px' }}>{props.note4}</p> : ''}
			<Divider />
			<div style={{ fontSize: '12px' }}>{props.description}</div>
		</Segment>
	</Grid.Column>
);
const MyDivider = (props) => (
	<Divider horizontal className="my-divider">
		<Header as="h4">
			<Icon name={props.icon} />
			{props.name}
		</Header>
	</Divider>
);
function formatTime(value) {
	let dur = moment.duration(value);
	return dur._data.days * 24 + dur._data.hours + ':' + moment(dur._data.minutes, 'm').format('mm') + ':' + moment(dur._data.seconds, 's').format('ss');
}
class Dashboard extends Component {
	state = {
		start_date: this.props.start_date,
		end_date: this.props.end_date,
		today: moment(),
		loading: true,
		pid: /* '1e00a47c670273a623c13c54dba44006' || */ /* '02cc63330cd0808f87c54ad9dd94b74a' || */ this.props.product.live.settings.live.token,
		ecommerce: false,
		direction: null,
		selectedAgent: 'all',
		column: null,
		loadingaverageTotalAfterActivate: true,
		loadingaverageTotalChatTime: true,
		loadingaverageTotalFirstResponse: true,
		loadingratedData: true,
		loadingsharedProductCount: true,
		loadingtemplateTotalData: true,
		loadingtotalClosedData: true,
		loadingtotalConversation: true,
		loadingtotalMessage: true,
		loadingtotalOrderData: true,
		loadingtotalOrderPrice: true,
		loadingtotalTakenByAgent: true,
		loadingtotalUnanswerByAgent: true,
		loadingtotalWaiting: true,
		loadinguniqueUsers: true,
		loadingcontactedData: true,
		loadingwaitingTotalData: true,
		loadingTotalWaitingConv: true,
		loadingGraph: true,
		selectedBarStyle: 'line',
		loadingAgentLastStatus: true,
		loadingforgottenBasketsGeneral: true,
	};
	async componentDidMount() {
		let select = [];
		let orderFilter,
			timeFilter,
			/* waitingFilter, */
			closedFilter = {};
		let { selectedAgent, pid } = this.state;
		let { start_date, end_date } = this.props;
		this.setState({ loadingGraph: true });
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		//let start_date_s = moment(start_date).add(-1, 'day').format();
		if (selectedAgent === 'all') {
			orderFilter = { type: ['orderCreated', 'orderCanceled', 'orderRefunded'], pid: this.state.pid };
			closedFilter = { type: ['closed'], pid: this.state.pid };
			timeFilter = { type: ['agentChatTime', 'chatTimeAfterActivate', 'agentFirstResponseTimeAfterActivate'], pid: this.state.pid };
			//waitingFilter = { type: ['activateAgent'], pid: this.state.pid };
		} else {
			orderFilter = { type: ['orderCreated', 'orderCanceled', 'orderRefunded'], pid: this.state.pid, agent: selectedAgent };
			timeFilter = { type: ['agentChatTime', 'chatTimeAfterActivate', 'agentFirstResponseTimeAfterActivate'], pid: this.state.pid, agent: selectedAgent };
			closedFilter = { type: ['closed'], pid: this.state.pid, agent: selectedAgent };
			//waitingFilter = { type: ['activateAgent'], pid: this.state.pid, agent: selectedAgent };
		}
		fetch('https://log.yapaytech.com/live/test/getInfo?mmm', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: orderFilter,
				select,
				group: ['type'],
				pairs: { agent: 'select5', kod: 'select2' },
				options: {
					sum: 1,
				},
				from: `(select  COALESCE(lr.total, lr2.total) as total, lr.type, lr.pid, lr.select1, lr.select2, lr.select3, lr.select4, lr.select5, lr.select6, lr.select7, lr.count, lr.date, lr.day, lr.hour, lr.platform  from (select *  from live_refined lr where date >= '${moment(
					start_date
				).format('YYYY-MM-DD HH:mm:ss')}.000' and date < '${moment(end_date).format('YYYY-MM-DD HH:mm:ss')}.000' and pid = '${
					this.state.pid
				}') lr left join live_refined lr2 on lr2.select2=lr.select2 and lr2.type='orderCreated' and lr.type <> 'orderCreated') as lr`,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let orderData = _.find(data.result, { type: 'orderCreated' });
				let canceledData = _.find(data.result, { type: 'orderCanceled' });
				let refundedData = _.find(data.result, { type: 'orderRefunded' });
				this.setState({
					loadingtotalOrderData: false,
					totalOrderData: orderData && orderData.count,
					totalOrderLen: orderData && orderData.len,
					productperbasket: orderData && orderData.len && (orderData.count / orderData.len).toFixed(2),
					loadingtotalOrderPrice: false,
					totalOrderPrice: orderData && orderData.sum,
					totalCancelPrice: canceledData && canceledData.sum,
					totalRefundPrice: refundedData && refundedData.sum,
					totalCancelCount: canceledData && canceledData.count,
					totalRefundCount: refundedData && refundedData.count,
				});
			});
		fetch('https://micro.yapaytech.com/api/dialogtab/forgottenBaskets/general', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				pid: this.state.pid,
				start_date,
				end_date,
				page: 0,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let forgottenBasketsGeneral = data.result;
				this.setState({ forgottenBasketsGeneral, loadingforgottenBasketsGeneral: false });
			});
		fetch('https://log.yapaytech.com/live/test/getInfo?closeddata', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: closedFilter,
				select,
				group: ['type', 'agent'],
				pairs: { agent: 'select1' },
				options: {},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let filter = _.filter(data.result, function (it) {
					return it.agent !== '5e6f519560b2b4d07559fcf8';
				});
				let onlyAgentCounts = _.filter(data.result, function (it) {
					return it.agent !== pid;
				});

				this.setState({
					loadingtotalClosedData: false,
					totalClosedData: _.sumBy(filter, 'count') || 0,
					totalAgentClosedData: _.sumBy(onlyAgentCounts, 'count'),
				});
			});
		fetch('https://log.yapaytech.com/live/test/getInfo?dayHho', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: { type: ['closed'], pid },
				select,
				group: ['type', 'agent', 'day', 'hour'],
				pairs: { agent: 'select1' },
				options: {},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				_.each(data.result, function (it) {
					return (it.dayNumber = moment(it.day).dayOfYear());
				});
				let filter = _.filter(data.result, function (it) {
					return it.agent !== '5e6f519560b2b4d07559fcf8';
				});
				let onlyAgentCounts = _.groupBy(
					_.filter(data.result, function (it) {
						return it.agent !== pid && it.type === 'closed';
					}),
					'dayNumber'
				);

				this.setState({
					loadingtotalClosedDataHourly: false,
					totalClosedDataHourly: _.sumBy(filter, 'count') || 0,
					totalAgentClosedDataHourly: onlyAgentCounts,
				});
			});

		fetch('https://log.yapaytech.com/live/test/getInfo?dayHho', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: { type: ['contacted'], pid },
				select,
				group: ['type', 'agent', 'day', 'hour'],
				pairs: { agent: 'select1' },
				options: {
					cids: 'count(distinct select2)',
				},
				from: `(select lr.* from (select cid from live_event le where pid = '${this.state.pid}' and created_at between '${moment(start_date).format(
					'YYYY-MM-DD'
				)}' and '${moment(end_date).add('1', 'd').format('YYYY-MM-DD')}' and label='contacted' intersect select cid from live_event le2 where pid = '${
					this.state.pid
				}' and created_at between '${moment(start_date).format('YYYY-MM-DD')}' and '${moment(end_date)
					.add('1', 'd')
					.format('YYYY-MM-DD')}' and label='activateAgent') le left join live_refined lr on lr.select2 =le.cid) lr`,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				_.each(data.result, function (it) {
					return (it.dayNumber = moment(it.day).dayOfYear());
				});
				_.each(data.result, function (it) {
					return (it.count = parseInt(it.cids));
				});
				let contactedDataHourly = _.groupBy(
					_.filter(data.result, function (it) {
						return it.type === 'contacted';
					}),
					'dayNumber'
				);
				this.setState({
					contactedDataHourly,
				});
			});
		fetch('https://log.yapaytech.com/live/test/getInfo?dayHho', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: { type: ['closed'], pid },
				select,
				group: ['type', 'agent', 'hour'],
				pairs: { agent: 'select1' },
				options: {},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let filter = _.filter(data.result, function (it) {
					return it.agent !== '5e6f519560b2b4d07559fcf8';
				});
				let onlyAgentCounts = _.groupBy(
					_.filter(data.result, function (it) {
						return it.agent !== pid && it.type === 'closed';
					}),
					'hour'
				);
				this.setState({
					loadingtotalClosedDataDayHourly: false,
					totalClosedDataDayHourly: _.sumBy(filter, 'count') || 0,
					totalAgentClosedDataDayHourly: onlyAgentCounts,
				});
			});
		fetch('https://log.yapaytech.com/live/test/getInfo?dayHho', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: { type: ['contacted'], pid },
				select,
				group: ['type', 'agent', 'hour'],
				pairs: { agent: 'select1' },
				options: {
					cids: 'count(distinct select2)',
				},
				from: `(select lr.* from (select cid from live_event le where pid = '${this.state.pid}' and created_at between '${moment(start_date).format(
					'YYYY-MM-DD'
				)}' and '${moment(end_date).add('1', 'd').format('YYYY-MM-DD')}' and label='contacted' intersect select cid from live_event le2 where pid = '${
					this.state.pid
				}' and created_at between '${moment(start_date).format('YYYY-MM-DD')}' and '${moment(end_date)
					.add('1', 'd')
					.format('YYYY-MM-DD')}' and label='activateAgent') le left join live_refined lr on lr.select2 =le.cid) lr`,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				_.each(data.result, function (it) {
					return (it.count = parseInt(it.cids));
				});
				let contactedDataDayHourly = _.groupBy(
					_.filter(data.result, function (it) {
						return it.type === 'contacted';
					}),
					'hour'
				);
				this.setState({
					contactedDataDayHourly,
				});
			});
		fetch('https://log.yapaytech.com/live/test/getInfo?sss', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: timeFilter,
				select,
				group: ['type'],
				pairs: {},
				options: { sum: 1, avg: 1 },
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				function filter(value) {
					return (
						(data.result &&
							_.find(data.result, { type: value }) &&
							_.find(data.result, { type: value }).avg &&
							formatTime(_.find(data.result, { type: value }).avg)) ||
						0
					);
				}
				function filterTotal(value) {
					return (
						(data.result &&
							_.find(data.result, { type: value }) &&
							_.find(data.result, { type: value }).sum &&
							formatTime(_.find(data.result, { type: value }).sum)) ||
						0
					);
				}
				this.setState({
					loadingaverageTotalChatTime: false,
					loadingaverageTotalAfterActivate: false,
					loadingaverageTotalFirstResponse: false,
					averageTotalChatTime: filter('agentChatTime'),
					averageTotalAfterActivate: filter('chatTimeAfterActivate'),
					averageTotalFirstResponse: filter('agentFirstResponseTimeAfterActivate'),
					totalTotalChatTime: filterTotal('agentChatTime'),
					totalTotalAfterActivate: filterTotal('chatTimeAfterActivate'),
					totalTotalFirstResponse: filterTotal('agentFirstResponseTimeAfterActivate'),
				});
			});
		fetch('https://log.yapaytech.com/live/getEventInfoGeneral?filtertest', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				filter: {
					label: 'activateAgent',
					pid: this.state.pid,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					totalWaitingConv: data.result.total,
					totalWaitingConvHourly: data.result.result,
				});
			});
		fetch('https://log.yapaytech.com/live/getBotForwardInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let result = data.result;
				let total = _.sumBy(result, (item) => Number(item.total));
				this.setState({
					totalWaitingcConv: total,
					loadingTotalWaitingConv: false,
				});
			});
		fetch('https://log.yapaytech.com/live/getEventInfoGeneralByHour', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				filter: {
					label: 'activateAgent',
					pid: this.state.pid,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					totalWaitingConvDayHourly: data.result.result,
				});
			});
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: {
					type: ['closed'],
					pid: this.state.pid,
				},
				select: [],
				group: ['type', 'rate'],
				pairs: { rate: 'select5' },
				options: {},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					loadingratedData: false,
					ratedData:
						data.result &&
						_.filter(data.result, function (o) {
							return o.rate;
						}),
				});
			});

		fetch('https://log.yapaytech.com/live/test/getInfo?contacted', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: {
					type: ['contacted'],
					pid: this.state.pid,
				},
				select: [],
				group: ['type'],
				pairs: { agent: 'type1' },
				options: {
					cids: 'count(distinct select2)',
				},
				from: `(select lr.* from (select cid from live_event le where pid = '${this.state.pid}' and created_at between '${moment(start_date).format(
					'YYYY-MM-DD'
				)}' and '${moment(end_date).add('1', 'd').format('YYYY-MM-DD')}' and label='contacted' intersect select cid from live_event le2 where pid = '${
					this.state.pid
				}' and created_at between '${moment(start_date).format('YYYY-MM-DD')}' and '${moment(end_date)
					.add('1', 'd')
					.format('YYYY-MM-DD')}' and label='activateAgent') le left join live_refined lr on lr.select2 =le.cid) lr`,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					loadingcontactedData: false,
					contactedData: (data && data.result && data.result[0] && data.result[0].cids && parseInt(data.result[0].cids)) || 0,
				});
			});
		/* fetch('https://live.dahi.ai/analytics/dialogtab/messageCounts', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					loadingtotalMessage: false,
					totalMessage: (data.data && data.data[0] && data.data[0].is_human) || 0,
					totalSendMessage: (data.data && data.data[0] && data.data[0].is_agent) || 0,
					totalComeMessage: (data.data && data.data[0] && data.data[0].is_user) || 0,
				});
			}); */
		/* 		fetch('https://live.dahi.ai/analytics/dialogtab/uniqueUserCountsByMessage', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					loadinguniqueUsers: false,
					uniqueUsers: data && data.data,
				});
			}); */

		fetch('https://live.dahi.ai/analytics/dialogtab/waitingCounts', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					loadingtotalWaiting: false,
					totalWaiting: data.data,
				});
			});
		fetch('https://live.dahi.ai/analytics/statusInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					loadingtotalConversation: false,
					totalConversation:
						(data.data &&
							data.data.general &&
							_.find(data.data.general, { title: 'Toplam' }) &&
							_.find(data.data.general, { title: 'Toplam' }).count) ||
						0,
				});
			});
		fetch('https://live.dahi.ai/message/getStateCounts/' + this.state.pid, {
			method: 'get',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					totalTakenByAgent: _.sumBy(data.result.agents, 'count') || 0,
					loadingtotalTakenByAgent: false,
					totalUnanswerByAgent: _.sumBy(data.result.agents, 'unanswer') || 0,
					momAgentData: data.result.agents,
					loadingtotalUnanswerByAgent: false,
					loadingGraph: false,
				});
			});
		fetch('https://live.dahi.ai/analytics/sharedProductCount', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					sharedProductCount: data.data && data.data.byAgent,
					loadingsharedProductCount: false,
				});
			});
		await fetch('/api/dahi/message/getLiveChat', {})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					productAgentList:
						data.result &&
						data.result.agents &&
						_.filter(data.result.agents, function (it) {
							return it.type !== 'viewer';
						}),
					botId: data.result && data.result.bot.bid,
					bot_id: data.result && data.result.bot.id,
					ecommerce: data.result && data.result.ecommerce,
					sendProduct: data.result && data.result.sendProduct,
					ratePoint: data.result && data.result.ratePoint,
					showTemplate: data.result && data.result.showTemplate,
				});
			});
		fetch('https://log.yapaytech.com/live/getEventInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				filter: {
					label: 'template',
					pid: this.state.pid,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ templateTotalData: data && data.result && data.result.total, loadingtemplateTotalData: false });
			});
		fetch('https://log.yapaytech.com/live/getWaitingInfo1', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ waitingTotalData: data && data.result && data.result.total, loadingwaitingTotalData: false, loading: false });
			});
		fetch('https://live.dahi.ai/analytics/dialogtab/uniqueUserCountsByMessage', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
				hourly: true,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ loadingGraph: false, loadinguniqueUsersHourly: false, uniqueUsersHourly: data && data.data });
			});
		fetch('https://log.yapaytech.com/live/test/getLastAgentStatuses', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ loadingAgentLastStatus: false, agentLastStatus: data.result });
			});
	}
	async getMomentData() {
		this.setState({ btnMomentData: true, loadingAgentLastStatus: true });
		fetch('https://live.dahi.ai/message/getStateCounts/' + this.state.pid, {
			method: 'get',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					momAgentData: data.result.agents,
					btnMomentData: false,
				});
			});
		fetch('https://log.yapaytech.com/live/test/getLastAgentStatuses', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ loadingAgentLastStatus: false, agentLastStatus: data.result });
			});
	}
	dateChange = ({ selection }) => {
		let start_date = moment(selection.startDate).startOf('day');
		let end_date = moment(selection.endDate);
		if (end_date.format('YYYY-MM-DD') !== this.state.today.format('YYYY-MM-DD')) {
			end_date = end_date.endOf('day');
		} else {
			end_date = moment();
		}
		this.setState({ start_date, end_date });
	};
	componentDidUpdate(oldProps, oldState) {
		const check_list = ['start_date', 'end_date', 'value'];
		for (let i = 0; i < check_list.length; i++) {
			const key = check_list[i];
			let timer;
			if (key === 'start_date' || key === 'end_date') {
				if (this.props[key] !== oldProps[key]) {
					window.clearTimeout(timer);
					timer = setTimeout(() => this.componentDidMount(), 3000);
					break;
				}
			}
			if (!_.isArray(this.state[key])) {
				if (this.state[key] !== oldState[key]) {
					window.clearTimeout(timer);
					timer = setTimeout(() => this.componentDidMount(), 3000);
					break;
				}
			} else if (!_.isEqual(this.state[key], oldState[key])) {
				window.clearTimeout(timer);
				timer = setTimeout(() => this.componentDidMount(), 3000);
				break;
			}
		}
	}
	handleChange = (e, { name, value }) => this.setState({ [name]: value });
	handleFilterChange = (e, { text, value }) => this.setState({ selectedAgent: value, selectedAgentName: text });
	loadingText(text, load, title, popup) {
		if (load) {
			return title ? (
				<p>
					{title + ': '}
					<Icon name="spinner" loading></Icon>
				</p>
			) : (
				<Icon name="spinner" loading></Icon>
			);
		}

		return title ? (
			<p>
				{popup && <Popup trigger={<Icon name="question circle outline" />} content={popup} size="mini" position={'bottom'} />}{' '}
				{title + ': ' + text || 0}
			</p>
		) : (
			text || 0
		);
	}

	render() {
		const { start_date, end_date } = this.props;
		let {
			totalOrderData,
			totalOrderLen,
			//totalMessage,
			totalConversation,
			totalTakenByAgent,
			totalUnanswerByAgent,
			totalWaiting,
			totalOrderPrice,
			totalCancelPrice,
			totalRefundPrice,
			totalCancelCount,
			totalRefundCount,
			sharedProductCount,
			ratedData,
			averageTotalChatTime,
			averageTotalFirstResponse,
			averageTotalAfterActivate,
			//totalTotalChatTime,
			//totalTotalFirstResponse,
			totalTotalAfterActivate,
			totalClosedData,
			templateTotalData,
			//uniqueUsers,
			uniqueUsersHourly,
			loadingaverageTotalAfterActivate,
			loadingaverageTotalChatTime,
			loadingaverageTotalFirstResponse,
			loadingratedData,
			loadingsharedProductCount,
			loadingtemplateTotalData,
			loadingtotalClosedData,
			loadingtotalConversation,
			//loadingtotalMessage,
			loadingtotalOrderData,
			//loadingtotalOrderPrice,
			loadingtotalTakenByAgent,
			loadingtotalUnanswerByAgent,
			loadingtotalWaiting,
			//loadinguniqueUsers,
			//totalSendMessage,
			//totalComeMessage,
			//totalOrderLen,
			productperbasket,
			waitingTotalData,
			loadingwaitingTotalData,
			loadingcontactedData,
			contactedData,
			contactedDataHourly,
			loadingTotalWaitingConv,
			//totalWaitingConv,
			totalAgentClosedData,
			totalAgentClosedDataHourly,
			loadingGraph,
			selectedBarStyle,
			totalWaitingcConv,
			totalWaitingConvHourly,
			totalAgentClosedDataDayHourly,
			contactedDataDayHourly,
			totalWaitingConvDayHourly,
			momAgentData,
			productAgentList,
			agentLastStatus,
			loadingAgentLastStatus,
			forgottenBasketsGeneral,
			loadingforgottenBasketsGeneral,
		} = this.state;
		const { t } = this.props;
		let ecommerce, ratePoint, sendProduct, showTemplate, generalTable;
		let currency = (this.props.product && this.props.product.live && this.props.product.live.settings.currency) || 'try';
		if (this.props.product.live.settings.analyticView) {
			ecommerce = this.props.product.live.settings.analyticView.ecommerce;
			sendProduct = this.props.product.live.settings.analyticView.sendProduct;
			//conversationGroup = this.props.product.live.settings.analyticView.conversationGroup;
			showTemplate = this.props.product.live.settings.analyticView.showTemplate;
			ratePoint = this.props.product.live.settings.analyticView.ratePoint;
			generalTable = this.props.product.live.settings.analyticView.generalTable;
			//userlist = this.props.product.live.settings.analyticView.userlist;
		}
		function showingBar(data, barStyle) {
			switch (barStyle) {
				case 'line':
					return <Line data={data} options={barOptions} />;
				case 'bar':
					return <Bar data={data} options={barOptions} />;
				case 'stackbar':
					return <Bar data={data} options={barOptionsStack} />;
				case 'radar':
					return <Radar data={data} />;
				default:
					return <Line data={data} options={barOptions} />;
			}
		}
		let barStyleDropdown = [
			{
				text: t('Line'),
				value: 'line',
			},
			{
				text: t('Bar'),
				value: 'bar',
			},
			{
				text: t('StackBar'),
				value: 'stackbar',
			},
		];
		if (this.state.loading)
			return (
				<Container>
					<Segment>
						<Page.Loader></Page.Loader>
					</Segment>
				</Container>
			);
		let sellingClosed = totalOrderData && totalClosedData ? ((100 * totalOrderData) / totalClosedData).toFixed(2) + ' %' : '0';
		let sellingConversation = totalOrderData && totalConversation ? ((100 * totalOrderData) / totalConversation).toFixed(2) + ' %' : '0';
		let totalSharedProduct = (sharedProductCount && _.sumBy(sharedProductCount, 'total')) || 0;
		let priceperbasket = (totalOrderLen && totalOrderPrice / totalOrderLen) || 0;
		//console.log('totalOrderPrice', totalOrderPrice, totalOrderData);
		let ratedTotalData,
			averageRateData = [];
		let dontAnswer = waitingTotalData - contactedData < 0 ? 0 : waitingTotalData - contactedData;
		let data = [],
			dataDayHourly = [];
		if (generalTable) {
			data = chartData(uniqueUsersHourly, contactedDataHourly, totalAgentClosedDataHourly, totalWaitingConvHourly, start_date, end_date, t);
			dataDayHourly = chartDataDayHourly(totalWaitingConvDayHourly, contactedDataDayHourly, totalAgentClosedDataDayHourly, start_date, end_date, t);
		}
		let ciro = totalOrderPrice - (totalCancelPrice || 0) - (totalRefundPrice || 0);
		if (ratedData) {
			ratedTotalData = _.map(ratedData, function (it) {
				return {
					count: it.count,
					rate: it.rate,
					totalRate: it.count * it.rate,
				};
			});
			averageRateData = {
				count: _.sumBy(ratedTotalData, 'count'),
				totalRage: _.sumBy(ratedTotalData, 'totalRate'),
				average: (_.sumBy(ratedTotalData, 'count') && (_.sumBy(ratedTotalData, 'totalRate') / _.sumBy(ratedTotalData, 'count')).toFixed(2)) || 0,
			};
		}
		return (
			<Container>
				<Segment>
					<Grid stackable columns={2} style={{ width: '100%', margin: 0 }}>
						<MyDivider name={t('conversation')} icon="conversation"></MyDivider>
						<CardWrap
							width={4}
							title={t('totalUser')}
							color="red"
							data={this.loadingText(totalWaitingcConv, loadingTotalWaitingConv)}
							description={t('totalWaitingAllPopupC')}
						/>
						{/* note={this.loadingText(uniqueUsers, loadinguniqueUsers, t('totalUserTitle'), t('totalUserDesc'))} */}
						{/* <CardWrap
							width={4}
							title={t('totalMessage')}
							color="orange"
							data={this.loadingText(totalMessage, loadingtotalMessage)}
							note={this.loadingText(totalSendMessage, loadingtotalMessage, t('totalSendingMessage'), t('totalSendingMessagePopup'))}
							note2={this.loadingText(totalComeMessage, loadingtotalMessage, t('totalComingMessage'), t('totalComingMessagePopup'))}
							description={t('totalMessageDesc')}
						/> */}
						<CardWrap
							width={4}
							title={t('totalWaiting')}
							color="green"
							data={this.loadingText(totalWaiting, loadingtotalWaiting)}
							description={t('totalWaitingDesc')}
						/>
						<CardWrap
							width={4}
							title={t('totalTakingAgent')}
							color="blue"
							data={this.loadingText(totalTakenByAgent, loadingtotalTakenByAgent)}
							description={t('totalTakingAgentDesc')}
							note={this.loadingText(
								totalUnanswerByAgent,
								loadingtotalUnanswerByAgent,
								t('unanswerConversation'),
								t('unanswerConversationPopup')
							)}
						/>
						{ratePoint ? (
							<>
								<CardWrap
									width={4}
									title={t('averageRatePoint')}
									color="red"
									data={this.loadingText(averageRateData.average, loadingratedData)}
									note={this.loadingText(averageRateData.count, loadingratedData, t('averageRatePointNote'), t('averageRatePointNotePopup'))}
									description={t('averageRatePointDesc')}
								/>
							</>
						) : (
							''
						)}
						<CardWrap
							width={4}
							title={t('averageChatTime')}
							color="orange"
							data={this.loadingText(averageTotalChatTime, loadingaverageTotalChatTime)}
							note={this.loadingText(
								averageTotalAfterActivate,
								loadingaverageTotalAfterActivate,
								t('averageChatTimeNote1'),
								t('averageChatTimeNote1Popup')
							)}
							note2={this.loadingText(
								averageTotalFirstResponse,
								loadingaverageTotalFirstResponse,
								t('averageChatTimeNote2'),
								t('averageChatTimeNote2Popup')
							)}
							description={t('averageChatTimeDesc')}
						/>
						<CardWrap
							width={4}
							title={t('totalAgentClosed')}
							color="yellow"
							note2={this.loadingText(totalClosedData, loadingtotalClosedData, t('totalClosed'), t('totalClosedBetween'))}
							note={this.loadingText(totalConversation, loadingtotalConversation, t('totalConversation'), t('totalConversationPopup'))}
							data={this.loadingText(totalAgentClosedData, loadingtotalClosedData)}
							description={t('totalClosedPopup')}
						/>
						{showTemplate ? (
							<>
								<CardWrap
									width={4}
									title={t('templateMessage')}
									color="green"
									data={this.loadingText(templateTotalData, loadingtemplateTotalData)}
									description={t('templateMessageDesc')}
								/>
							</>
						) : (
							''
						)}
						{generalTable && <MyDivider name={t('momentaryAgentStatus')} icon="users"></MyDivider>}
						{generalTable && (
							<Table className="dashboard-table">
								<Table.Header>
									<Table.HeaderCell>{t('agent')}</Table.HeaderCell>
									<Table.HeaderCell>{t('onAgent')}</Table.HeaderCell>
									<Table.HeaderCell>{t('unanswer')}</Table.HeaderCell>
									<Table.HeaderCell>{t('status')}</Table.HeaderCell>
									<Table.HeaderCell width={1}>
										<Button
											icon
											basic
											onClick={() => this.getMomentData()}
											loading={this.state.btnMomentData}
											disabled={this.state.btnMomentData}
										>
											<Icon name="redo"></Icon>
										</Button>
									</Table.HeaderCell>
								</Table.Header>
								<Table.Body>
									{_.map(productAgentList, (it) => {
										let data = _.find(momAgentData, { _id: it.id });
										let displayName_2 = this.props.displayName === 'name' ? 'fullname' : 'developer';
										let last_status = _.find(agentLastStatus, { uid: it.id }) || { label: 'offline' };
										let colors = {
											online: '#4ed34e',
											offline: '#d34e4e',
											unknown: '#d4d4d5',
											idle_agent: '#F4B400',
										};
										if (this.state.btnMomentData)
											return (
												<Table.Row>
													<Table.Cell>{it[displayName_2] || it.developer}</Table.Cell>
													<Table.Cell>
														<Icon name="spinner" loading></Icon>
													</Table.Cell>
													<Table.Cell>
														<Icon name="spinner" loading></Icon>
													</Table.Cell>
													<Table.Cell>
														<Icon name="spinner" loading={loadingAgentLastStatus}></Icon>
													</Table.Cell>
													<Table.Cell></Table.Cell>
												</Table.Row>
											);
										return (
											<Table.Row>
												<Table.Cell>{it[displayName_2] || it.developer}</Table.Cell>
												<Table.Cell>{(data && data.count) || 0}</Table.Cell>
												<Table.Cell>{(data && data.unanswer) || 0}</Table.Cell>
												<Table.Cell style={{ color: (last_status && colors[last_status.label]) || 'black' }}>
													{last_status && t(last_status.label)}
												</Table.Cell>
												<Table.Cell></Table.Cell>
											</Table.Row>
										);
									})}
								</Table.Body>
							</Table>
						)}
						{generalTable && <MyDivider name={t('generalAgent')} icon="table"></MyDivider>}

						{generalTable ? (
							<Table className="dashboard-table">
								<Table.Body>
									{/* <Table.Row>
										<Table.Cell width={12}>
											{
												<p>
													<Popup
														trigger={<Icon name="question circle outline" />}
														content={t('totalUserPopup')}
														size="mini"
														position={'bottom'}
													/>
													{t('totalUser')}
												</p>
											}
										</Table.Cell>
										<Table.Cell>{this.loadingText(uniqueUsers, loadinguniqueUsers)}</Table.Cell>
									</Table.Row> */}
									<Table.Row>
										<Table.Cell width={12}>
											<p>
												<Popup
													trigger={<Icon name="question circle outline" />}
													content={t('totalWaitingAllPopupC')}
													size="mini"
													position={'bottom'}
												/>
												{t('totalWaitingAllC')}
											</p>
										</Table.Cell>
										<Table.Cell>{this.loadingText(waitingTotalData, loadingTotalWaitingConv)}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell width={12}>
											<p>
												<Popup
													trigger={<Icon name="question circle outline" />}
													content={t('totalContactedPopup')}
													size="mini"
													position={'bottom'}
												/>
												{t('totalContacted')}
											</p>
										</Table.Cell>
										<Table.Cell>
											{this.loadingText(contactedData, loadingcontactedData)}
											{'      '}
											{' (' + this.loadingText(((contactedData / totalWaitingcConv) * 100).toFixed(1) + '%)', loadingcontactedData)}{' '}
											<Popup
												trigger={<Icon name="question circle outline" />}
												content={t('rateContact')}
												size="mini"
												position={'bottom'}
											/>
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell width={12}>
											<p>
												<Popup
													trigger={<Icon name="question circle outline" />}
													content={t('dontConnecttedDescriptionPopup')}
													size="mini"
													position={'bottom'}
												/>
												{t('dontConnecttedDescription')}
											</p>
										</Table.Cell>
										<Table.Cell>
											{this.loadingText(dontAnswer, loadingwaitingTotalData)}{' '}
											{' (' + this.loadingText(((dontAnswer / totalWaitingcConv) * 100).toFixed(1) + '%)', loadingwaitingTotalData)}{' '}
											<Popup
												trigger={<Icon name="question circle outline" />}
												content={t('rateMissing')}
												size="mini"
												position={'bottom'}
											/>
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell width={12}>
											<p>
												<Popup
													trigger={<Icon name="question circle outline" />}
													content={t('totalClosedPopup')}
													size="mini"
													position={'bottom'}
												/>
												{t('totalClosed')}
											</p>
										</Table.Cell>
										<Table.Cell>{this.loadingText(totalAgentClosedData, loadingtotalClosedData)}</Table.Cell>
									</Table.Row>
									{/* <Table.Row>
										<Table.Cell width={12}>
											<p>
												<Popup
													trigger={<Icon name="question circle outline" />}
													content={t('averageTakenTimePopup')}
													size="mini"
													position={'bottom'}
												/>
												{t('averageTakenTime')}
											</p>
										</Table.Cell>
										<Table.Cell>{this.loadingText(totalAgentClosedData, loadingaverageTotalChatTime)}</Table.Cell>
									</Table.Row> */}
									{/* <Table.Row>
										<Table.Cell width={12}>
											<p>
												<Popup
													trigger={<Icon name="question circle outline" />}
													content={t('firstMessageTimePopup')}
													size="mini"
													position={'bottom'}
												/>
												{t('firstMessageTime')}
											</p>
										</Table.Cell>
										<Table.Cell>{this.loadingText(totalTotalFirstResponse, loadingaverageTotalChatTime)}</Table.Cell>
									</Table.Row> */}
									<Table.Row>
										<Table.Cell width={12}>
											<p>
												<Popup
													trigger={<Icon name="question circle outline" />}
													content={t('averageChatTimeNote2Popup')}
													size="mini"
													position={'bottom'}
												/>
												{t('averageChatTimeNote2')}
											</p>
										</Table.Cell>
										<Table.Cell>{this.loadingText(averageTotalFirstResponse, loadingaverageTotalChatTime)}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell width={12}>
											<p>
												<Popup
													trigger={<Icon name="question circle outline" />}
													content={t('totalChatTimeNote1Popup')}
													size="mini"
													position={'bottom'}
												/>
												{t('totalChatTimeNote1')}
											</p>
										</Table.Cell>
										<Table.Cell>{this.loadingText(totalTotalAfterActivate, loadingaverageTotalAfterActivate)}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell width={12}>
											<p>
												<Popup
													trigger={<Icon name="question circle outline" />}
													content={t('averageChatTimeNote1Popup')}
													size="mini"
													position={'bottom'}
												/>
												{t('averageChatTimeNote1')}
											</p>
										</Table.Cell>
										<Table.Cell>{this.loadingText(averageTotalAfterActivate, loadingaverageTotalAfterActivate)}</Table.Cell>
									</Table.Row>
								</Table.Body>
							</Table>
						) : (
							''
						)}
						{generalTable && (
							<ChartWrap
								width={16}
								title={t('graph')}
								content={
									<div style={{ marginLeft: 'auto', display: loadingGraph ? 'none' : 'block' }}>
										<Dropdown
											options={barStyleDropdown}
											value={selectedBarStyle}
											name="selectedBarStyle"
											onChange={this.handleChange}
											style={{ marginRight: '30px' }}
										/>
									</div>
								}
							>
								{loadingGraph ? <Page.Loader /> : showingBar(dataDayHourly, selectedBarStyle)}
							</ChartWrap>
						)}
						{generalTable && (
							<ChartWrap
								width={16}
								title={t('graphHourly')}
								content={
									<div style={{ marginLeft: 'auto', display: loadingGraph ? 'none' : 'block' }}>
										<Dropdown
											options={barStyleDropdown}
											value={selectedBarStyle}
											name="selectedBarStyle"
											onChange={this.handleChange}
											style={{ marginRight: '30px' }}
										/>
									</div>
								}
							>
								{loadingGraph ? <Page.Loader /> : showingBar(data, selectedBarStyle)}
							</ChartWrap>
						)}

						{ecommerce || sendProduct ? <MyDivider name={t('eCommerce')} icon="shopping cart"></MyDivider> : ''}
						{ecommerce ? (
							<>
								{totalCancelCount || totalRefundCount ? (
									<CardWrap
										width={4}
										title={t('totalSellingNumber')}
										color="green"
										data={this.loadingText(totalOrderData, loadingtotalOrderData)}
										note3={this.loadingText(productperbasket || 0, loadingtotalOrderData, t('productPerBasketCount'))}
										note={this.loadingText(totalCancelCount || 0, loadingtotalOrderData, t('productCanceled'))}
										note2={this.loadingText(totalRefundCount || 0, loadingtotalOrderData, t('productRefunded'))}
										description={t('totalSellingNumberDesc')}
									/>
								) : (
									<CardWrap
										width={4}
										title={t('totalSellingNumber')}
										color="green"
										data={this.loadingText(totalOrderData, loadingtotalOrderData)}
										note3={this.loadingText(productperbasket || 0, loadingtotalOrderData, t('productPerBasketCount'))}
										description={t('totalSellingNumberDesc')}
									/>
								)}

								{totalCancelPrice || totalRefundPrice ? (
									<CardWrap
										width={4}
										title={t('generalSum')}
										color="blue"
										data={this.loadingText(
											(ciro && ciro.toLocaleString('tr-TR', { style: 'currency', currency })) || 0,
											loadingtotalOrderData
										)}
										note={this.loadingText(
											(totalOrderPrice && totalOrderPrice.toLocaleString('tr-TR', { style: 'currency', currency })) || 0,
											loadingtotalOrderData,
											t('totalSellingPrice')
										)}
										note2={this.loadingText(
											(totalCancelPrice && totalCancelPrice.toLocaleString('tr-TR', { style: 'currency', currency })) || 0,
											loadingtotalOrderData,
											t('orderCanceledSum')
										)}
										note3={this.loadingText(
											(totalRefundPrice && totalRefundPrice.toLocaleString('tr-TR', { style: 'currency', currency })) || 0,
											loadingtotalOrderData,
											t('orderRefundedSum')
										)}
										note4={this.loadingText(
											priceperbasket.toLocaleString('tr-TR', { style: 'currency', currency }),
											loadingtotalOrderData,
											t('priceperbasketnote')
										)}
										description={t('totalSellingPriceDesc')}
									/>
								) : (
									<CardWrap
										width={4}
										title={t('generalSum')}
										color="blue"
										data={this.loadingText(
											(ciro && ciro.toLocaleString('tr-TR', { style: 'currency', currency })) || 0,
											loadingtotalOrderData
										)}
										note4={this.loadingText(
											priceperbasket.toLocaleString('tr-TR', { style: 'currency', currency }),
											loadingtotalOrderData,
											t('priceperbasketnote')
										)}
										description={t('totalSellingPriceDesc')}
									/>
								)}

								<CardWrap
									width={4}
									title={t('rateSellingConv')}
									color="yellow"
									data={this.loadingText(sellingClosed, loadingtotalConversation)}
									note={this.loadingText(sellingConversation, loadingtotalConversation, t('totalSellingRate'))}
									description={t('rateSellingConvDesc')}
								/>
								<CardWrap
									width={4}
									title={t('totalPriceForgotten')}
									color="red"
									data={this.loadingText(
										(forgottenBasketsGeneral &&
											forgottenBasketsGeneral.basketTotal &&
											forgottenBasketsGeneral.basketTotal.toLocaleString('tr-TR', { style: 'currency', currency })) ||
											0,
										loadingforgottenBasketsGeneral
									)}
									note={this.loadingText(
										(forgottenBasketsGeneral && forgottenBasketsGeneral.userTotal) || 0,
										loadingforgottenBasketsGeneral,
										t('totalUserForgotten')
									)}
									note2={this.loadingText(
										(forgottenBasketsGeneral && forgottenBasketsGeneral.itemTotal) || 0,
										loadingforgottenBasketsGeneral,
										t('totalItemForgotten')
									)}
									description={t('totalUserForgottenDesc')}
								/>
							</>
						) : (
							''
						)}
						{sendProduct ? (
							<>
								<CardWrap
									width={4}
									title={t('totalSendingProduct')}
									color="purple"
									data={this.loadingText(totalSharedProduct, loadingsharedProductCount)}
									description={t('totalSendingProductDesc')}
								/>
							</>
						) : (
							''
						)}
					</Grid>
				</Segment>
			</Container>
		);
	}
}
// date array
var getDateArray = function (start, end) {
	var arr = [],
		dt = new Date(start);

	while (dt <= end) {
		arr.push(new Date(dt));
		dt.setDate(dt.getDate() + 1);
	}

	return arr;
};
var getHourArray = function (start, end) {
	var arr = [],
		dt = 0;

	while (dt < 24) {
		arr.push(dt);
		dt++;
	}
	return arr;
};

function chartData(data, contactedDataHourly, totalAgentClosedDataHourly, totalWaitingConvHourly, start_date, end_date, t) {
	let startDate = new Date(start_date);
	let endDate = new Date(end_date);
	let labels = [];
	var dateArr = getDateArray(startDate, endDate);
	var hourArr = getHourArray();
	_.map(dateArr, (it) => {
		return _.map(hourArr, (o) => {
			labels.push(moment(it).format('DD/MM') + ' - ' + moment(o, 'H').format('HH'));
		});
	});
	let uniqueUserCountsLineData = [],
		contactedDataLineData = [],
		closedDataLineData = [],
		totalWaitingConvLineData = [],
		obj,
		contactedDataHourlyObj,
		closedDataHourlyObj,
		totalWaitingConvHourlyObj;
	_.map(dateArr, (item, i) => {
		return _.map(hourArr, (it) => {
			obj = _.find(data, {
				date: moment(item).format('YYYY-MM-DD'),
				hour: moment(it, 'H').format('HH'),
			});

			totalWaitingConvHourlyObj = _.find(totalWaitingConvHourly, function (o) {
				return moment(o.date).dayOfYear() === moment(item).dayOfYear() && moment(o.date).hour() === it;
			});
			/* if (it >= 0) {
				contactedDataHourlyObj = _.filter(contactedDataHourly[moment(item).dayOfYear()], {
					hour: it,
				});
				closedDataHourlyObj = _.filter(totalAgentClosedDataHourly[moment(item).dayOfYear()], {
					hour: it,
				});
			} else {
				contactedDataHourlyObj = _.filter(contactedDataHourly[moment(item).format('YYYY-MM-DD') + 'T00:00:00.000Z'], {
					hour: it + 23,
				});
				closedDataHourlyObj = _.filter(totalAgentClosedDataHourly[moment(item).format('YYYY-MM-DD') + 'T00:00:00.000Z'], {
					hour: it + 23,
				});
			} */
			contactedDataHourlyObj =
				contactedDataHourly &&
				contactedDataHourly[moment(item).dayOfYear()] &&
				_.filter(contactedDataHourly[moment(item).dayOfYear()], {
					hour: it,
				});
			closedDataHourlyObj =
				totalAgentClosedDataHourly &&
				totalAgentClosedDataHourly[moment(item).dayOfYear()] &&
				_.filter(totalAgentClosedDataHourly[moment(item).dayOfYear()], {
					hour: it,
				});
			/* 	console.log('contactedDataHourlyObj', contactedDataHourlyObj); */
			uniqueUserCountsLineData.push((obj && obj.total_user) || 0);
			contactedDataLineData.push((contactedDataHourlyObj && _.sumBy(contactedDataHourlyObj, 'count')) || 0);
			closedDataLineData.push((closedDataHourlyObj && _.sumBy(closedDataHourlyObj, 'count')) || 0);
			totalWaitingConvLineData.push((totalWaitingConvHourlyObj && totalWaitingConvHourlyObj.cid_count) || 0);
		});
	});
	let missingDataLineData = _.map(totalWaitingConvLineData, (it, i) => Math.max(it - contactedDataLineData[i], 0));

	let totalWaitingConvLine = {
			label: t('totalWaitingAllC'),
			data: totalWaitingConvLineData,
			borderColor: 'orange',
			backgroundColor: 'orange',
			fill: false,
		},
		contactedDataLine = {
			label: t('totalContacted'),
			data: contactedDataLineData,
			borderColor: 'blue',
			backgroundColor: 'blue',
			fill: false,
		},
		missingDataLine = {
			label: t('dontConnecttedDescription'),
			data: missingDataLineData,
			borderColor: 'red',
			backgroundColor: 'red',
			fill: false,
		},
		closedDataLine = {
			label: t('totalClosed'),
			data: closedDataLineData,
			borderColor: 'green',
			backgroundColor: 'green',
			fill: false,
		};
	//let datasets = [uniqueUserCountsLine, totalWaitingConvLine, contactedDataLine, closedDataLine];
	let datasets = [totalWaitingConvLine, contactedDataLine, missingDataLine, closedDataLine];
	return {
		labels,
		datasets,
	};
}
function chartDataDayHourly(totalWaitingConvDayHourly, contactedDataDayHourly, totalAgentClosedDataDayHourly, start_date, end_date, t) {
	//let startDate = new Date(start_date);
	//let endDate = new Date(end_date);
	let labels = [];
	//var dateArr = getDateArray(startDate, endDate);
	var hourArr = getHourArray();
	labels = hourArr;
	let totalWaitingConvDayHourlyData = [],
		contactedDataDayHourlyLineData = [],
		totalAgentClosedDataDayHourlyLineData = [],
		twObj,
		cObj,
		tcObj;
	//console.log('contactedDataDayHourly', totalWaitingConvDayHourly);
	_.map(hourArr, (it, i) => {
		twObj = _.find(totalWaitingConvDayHourly, (o) => o.hour === it);
		cObj = (contactedDataDayHourly && contactedDataDayHourly[i] && _.sumBy(contactedDataDayHourly[i], 'count')) || 0;
		tcObj = (totalAgentClosedDataDayHourly && totalAgentClosedDataDayHourly[i] && _.sumBy(totalAgentClosedDataDayHourly[i], 'count')) || 0;
		totalWaitingConvDayHourlyData.push((twObj && twObj.count) || 0);
		contactedDataDayHourlyLineData.push(cObj || 0);
		totalAgentClosedDataDayHourlyLineData.push(tcObj || 0);
	});

	let missingDataLineData = _.map(totalWaitingConvDayHourlyData, (it, i) => Math.max(it - contactedDataDayHourlyLineData[i], 0));
	/* console.log(totalWaitingConvLineData, contactedDataLineData, missingDataLineData); */
	let totalWaitingConvLine = {
			label: t('totalWaitingAllC'),
			data: totalWaitingConvDayHourlyData,
			borderColor: 'orange',
			backgroundColor: 'orange',
			fill: false,
		},
		contactedDataLine = {
			label: t('totalContacted'),
			data: contactedDataDayHourlyLineData,
			borderColor: 'blue',
			backgroundColor: 'blue',
			fill: false,
		},
		missingDataLine = {
			label: t('dontConnecttedDescription'),
			data: missingDataLineData,
			borderColor: 'red',
			backgroundColor: 'red',
			fill: false,
		},
		closedDataLine = {
			label: t('totalClosed'),
			data: totalAgentClosedDataDayHourlyLineData,
			borderColor: 'green',
			backgroundColor: 'green',
			fill: false,
		};
	//let datasets = [uniqueUserCountsLine, totalWaitingConvLine, contactedDataLine, closedDataLine];
	let datasets = [totalWaitingConvLine, contactedDataLine, missingDataLine, closedDataLine];
	return {
		labels,
		datasets,
	};
}

const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product });

export default connect(stateToProps)(withTranslation()(Dashboard));
