import React, { Component } from 'react';
import { Icon, Segment, Table, Dropdown, Container, Button, Checkbox } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import { withTranslation } from 'react-i18next';
import 'moment/locale/tr';

class Analytic extends Component {
	state = {
		filterType: false,
		filterAgent: false,
		loading: true,
		start_date: this.props.start_date,
		end_date: this.props.end_date,
		today: moment(),
		group: ['agent'],
		value: [5, 4, 3, 2, 1],
		agent_role: this.props.access.user.loggedUserObj.role,
		agent_id: this.props.access.user.loggedUserObj.extra.user_id,
		pid: /* '02cc63330cd0808f87c54ad9dd94b74a' ||  */ this.props.product.live.settings.live.token,
		selectedAgent: 'all',
		selectedDate: 'daily',
		groupBy: 'agent',
		selectedTopic: 'all',
		column: 'rate',
		botId: '',
		direction: 'descending',
		dataloading: true,
		displayName: 'developer',
		selectedBarStyle: 'line',
		loadingGraph: true,
		rate: 'all',
	};
	componentDidMount() {
		this.getData();
	}
	componentDidUpdate(oldProps, oldState) {
		const check_list = ['start_date', 'end_date', 'selectedAgent', 'value'];
		for (let i = 0; i < check_list.length; i++) {
			const key = check_list[i];
			if (key === 'start_date' || key === 'end_date') {
				if (this.props[key] !== oldProps[key]) {
					this.getData();
					break;
				}
			}
			if (this.state[key] !== oldState[key]) {
				this.getData();
				break;
			}
		}
	}
	getData() {
		let { pid, selectedAgent } = this.state;
		let { start_date, end_date } = this.props;
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		let filter = {};
		if (selectedAgent === 'all') {
			filter = { type: ['closed'], pid };
		} else {
			filter = { type: ['closed'], pid, agent: selectedAgent };
		}
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select: [],
				group: ['type', 'agent', 'rate', 'cid', 'date', 'platform', 'topic'],
				pairs: {
					agent: 'select1',
					topic: 'select2',
					cid: 'select4',
					rate: 'select5',
					platform: 'platform',
				},
				options: {
					sum: 0,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let list = _.filter(data.result, (o) => o.rate);
				this.setState({ list });
			});
		fetch('/api/dahi/message/getLiveChat', {})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					productAgentList:
						data.result &&
						data.result.agents &&
						_.filter(data.result.agents, function (it) {
							return it.type !== 'viewer';
						}),
					botId: data.result && data.result.bot.bid,
					bot_id: data.result && data.result.bot.id,
					staticOptions: data.result && data.result.staticOptions,
					closeTags: data.result && data.result.staticOptions && data.result.staticOptions.closeTags,
					loading: false,
				});
			});
	}
	handleFilterChange = (e, { text, value }) => this.setState({ selectedAgent: value, selectedAgentName: text });
	handleFilterChangeRate = (e, { text, value }) => this.setState({ rate: value });
	handleChange = (e, { name, value }) => this.setState({ [name]: value });
	handleFilterCheck(value) {
		let array = this.state.value || [];
		let exists = _.includes(array, value);
		let valueArr = exists ? _.filter(array, (it) => it !== value) : _.concat([], array, value);
		this.setState({ value: valueArr });
	}
	handleSort = (clickedColumn) => () => {
		const { column, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				direction: 'descending',
			});

			return;
		}
		this.setState({
			direction: direction === 'ascending' ? 'descending' : 'ascending',
		});
	};
	render() {
		let { t, displayName } = this.props;
		let { loading, list, value, closeTags, search_text, column, direction } = this.state;
		let productAgentList = this.state.productAgentList;
		let dropAgentList = _.map(productAgentList, function (it) {
			return { text: it.developer, key: it.id, value: it.id };
		});
		dropAgentList = _.concat({ text: t('allAgent'), key: 'all', value: 'all' }, dropAgentList);

		let ratesDropdown = [
			{
				text: '5',
				key: 5,
				value: 5,
			},
			{
				text: '4',
				key: 4,
				value: 4,
			},
			{
				text: '3',
				key: 3,
				value: 3,
			},
			{
				text: '2',
				key: 2,
				value: 2,
			},
			{
				text: '1',
				key: 1,
				value: 1,
			},
		];
		let filteredList = _.filter(list, (o) => _.includes(value, parseInt(o.rate)));
		if (search_text !== '') {
			let regex = new RegExp(search_text);
			filteredList = _.filter(filteredList, (o) => regex.test(o.cid));
		}
		filteredList = _.orderBy(filteredList, [column], [direction === 'ascending' ? 'asc' : 'desc']);
		//console.log('productAgentList', filteredList);
		if (loading)
			return (
				<Container>
					<Segment style={{ marginBottom: '20px' }}>
						<Page.Loader></Page.Loader>
					</Segment>
				</Container>
			);
		return (
			<Container>
				<Segment style={{ marginBottom: '20px' }}>
					<div style={{ display: 'flex' }}>
						<Dropdown
							options={dropAgentList}
							selection
							defaultValue={dropAgentList[0].value}
							icon="filter"
							floating
							labeled
							button
							className="icon"
							onChange={this.handleFilterChange}
						></Dropdown>
						{/* <Dropdown
							options={ratesDropdown}
							selection
							defaultValue={ratesDropdown[0].value}
							icon="filter"
							floating
							labeled
							button
							className="icon"
							onChange={this.handleFilterChangeRate}
						></Dropdown> */}
						{_.map(ratesDropdown, (it, i) => (
							<Checkbox
								style={{ margin: '10px' }}
								checked={_.includes(value, it.value)}
								label={it.text}
								key={i}
								onChange={() => this.handleFilterCheck(it.value)}
							></Checkbox>
						))}
					</div>
					{/* <Form style={{ marginTop: '20px' }}>
						<Form.Input
							icon="search"
							iconPosition="left"
							placeholder={t('conversationId')}
							value={search_text}
							name="search_text"
							onChange={this.handleChange}
						/>
					</Form> */}
					<Table sortable>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>{t('name')}</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'rate' ? direction : null} onClick={this.handleSort('rate')}>
									{t('Rate')}
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'topic' ? direction : null} onClick={this.handleSort('topic')}>
									{t('topic')}
								</Table.HeaderCell>
								<Table.HeaderCell width={4} sorted={column === 'date' ? direction : null} onClick={this.handleSort('date')}>
									{t('date')}
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'platform' ? direction : null} onClick={this.handleSort('platform')}>
									{t('platform')}
								</Table.HeaderCell>
								<Table.HeaderCell width="1"></Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{_.map(filteredList, function (it) {
								let agent = _.find(productAgentList, { id: it.agent });
								let topic = _.find(closeTags, { id: it.topic });
								//console.log('agent', agent);
								let displayName_2 = displayName === 'name' ? 'fullname' : 'developer';
								if (agent)
									return (
										<Table.Row>
											<Table.Cell>{(agent && (agent[displayName_2] || agent.developer)) || it.agent}</Table.Cell>
											<Table.Cell>{it.rate}</Table.Cell>
											<Table.Cell>{(topic && topic.title) || it.topic}</Table.Cell>
											<Table.Cell>{moment(it.date).format('DD-MM-YYYY')}</Table.Cell>
											<Table.Cell>{it.platform}</Table.Cell>
											<Table.Cell>
												<Button
													icon
													primary
													onClick={() => {
														const win = window.open('/#' + it.cid, '_blank');
														win.focus();
													}}
												>
													<Icon name="chat"></Icon>
												</Button>
											</Table.Cell>
										</Table.Row>
									);
							})}
						</Table.Body>
					</Table>
				</Segment>
			</Container>
		);
	}
}

const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product });
export default connect(stateToProps)(withTranslation()(Analytic));
