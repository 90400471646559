import React, { Component } from 'react';
import Page from '../../components/Page';
import { get, post } from '../../util/api';
import { Form, Segment, Header, Dimmer, Loader, Breadcrumb } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import MyMenu from './menu';
import { withTranslation } from 'react-i18next';
const def = {
	icon: '/default.png',
	title: 'YourFirm',
};
class Config extends Component {
	state = { loading: true, save: 'Save', btncolor: 'blue', btnloading: false };
	componentDidMount() {
		this.getData();
	}
	async getData() {
		await get('/reseller/product/getConfig').then((data) => {
			this.setState({
				data: data.result,
				icon: (data.result && data.result.config && data.result.config.icon) || def.icon,
				title: (data.result && data.result.config && data.result.config.title) || def.title,
				domain: data.result && data.result.host,
				terms: data.result && data.result.config && data.result.config.tos,
				privacy: data.result && data.result.config && data.result.config.privacy,
				loading: false,
			});
		});
	}
	async postData() {
		await post('/reseller/product/setConfig', {
			config: { icon: this.state.icon, title: this.state.title, tos: this.state.terms, privacy: this.state.privacy },
			host: this.state.domain,
		}).then((data) => {
			if (data.data.success) {
				this.setState({ save: 'Saved', btnloading: false, btncolor: 'green' });
			} else {
				this.setState({ message: data.data.message });
			}
		});
	}
	handleChange = (e, { name, value }) => this.setState({ [name]: value });
	handleSubmit = (values) => {
		this.setState({ btnloading: true });
		this.postData();
	};
	render() {
		let { loading } = this.state;
		let { t } = this.props;
		if (loading) {
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/reseller`}>
								{t('Partnership')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
					flex
				>
					<MyMenu activeItem="config">
						<Dimmer active inverted>
							<Loader inverted></Loader>
						</Dimmer>
					</MyMenu>
				</Page>
			);
		}
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/reseller`}>
							{t('Partnership')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />
						<Breadcrumb.Section as={Link} to={`/reseller/configs`}>
							{t('configs')}
						</Breadcrumb.Section>
					</Breadcrumb>
				}
				flex
			>
				<MyMenu activeItem="config">
					<Page.Wrap>
						<Segment>
							<Header>{t('AccountInformations')}</Header>
							<Form onSubmit={this.handleSubmit}>
								<Form.Input label="Domain" placeholder="Host Domain" name="domain" readOnly value={this.state.domain}></Form.Input>
								<Form.Input
									label={t('FirmTitle')}
									placeholder={t('name')}
									name="title"
									value={this.state.title}
									onChange={this.handleChange}
									required
								></Form.Input>
								<Form.Input
									label={t('Icon')}
									placeholder={t('Icon Url')}
									name="icon"
									value={this.state.icon}
									onChange={this.handleChange}
									required
								></Form.Input>
								<Form.Input
									label={t('TermsofService')}
									placeholder={t('EnterUrl')}
									name="terms"
									value={this.state.terms}
									onChange={this.handleChange}
								></Form.Input>
								<Form.Input
									label={t('PrivacyPolicy')}
									placeholder={t('EnterUrl')}
									name="privacy"
									value={this.state.privacy}
									onChange={this.handleChange}
								></Form.Input>
								<Form.Button type="submit" color={this.state.btncolor} loading={this.state.btnloading}>
									{t('' + this.state.save)}
								</Form.Button>
							</Form>
						</Segment>
					</Page.Wrap>
				</MyMenu>
			</Page>
		);
	}
}

export default withTranslation()(Config);
