import React, { Component } from 'react';
import { Line, Bar, Radar } from 'react-chartjs-2';
import { Grid, Header, Icon, Segment, Table, Dropdown, Container, Radio } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import TypeList from '../filter4.json';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import { withTranslation } from 'react-i18next';
import 'moment/locale/tr';
import { colors2 } from '../../constants/colors';
import MockAgents from './mock.json';

const ChartWrap = (props) => (
	<Grid.Column width={props.width}>
		<Header as="h4" attached="top" style={{ display: 'flex' }}>
			<Icon name={props.icon || 'chart bar'} />
			<Header.Content>{props.title}</Header.Content>
			{props.content}
		</Header>
		<Segment attached>{props.children}</Segment>
	</Grid.Column>
);

const barOptions = {
	scales: {
		xAxes: [{ stacked: false }],
		yAxes: [{ stacked: false, ticks: { min: 0 } }],
	},
	tooltips: {
		mode: 'index',
		intersect: false,
		filter: (a) => a.yLabel,
	},
	hover: {
		mode: 'nearest',
		intersect: true,
	},
	legend: {
		display: true,
		labels: { fill: false },
	},
};
const barOptionsStack = {
	scales: {
		xAxes: [{ stacked: true }],
		yAxes: [{ stacked: true, ticks: { min: 0 } }],
	},
	tooltips: {
		mode: 'index',
		intersect: true,
		filter: (a) => a.yLabel,
	},
	hover: {
		mode: 'nearest',
		intersect: true,
	},
	legend: {
		display: true,
		labels: { fill: false },
	},
};

class Analytic extends Component {
	state = {
		filterType: false,
		filterAgent: false,
		loading: true,
		start_date: this.props.start_date,
		end_date: this.props.end_date,
		today: moment(),
		group: ['agent'],
		value: _.map(TypeList, (it) => {
			return it;
		}),
		agent_role: this.props.access.user.loggedUserObj.role,
		agent_id: this.props.access.user.loggedUserObj.extra.user_id,
		pid: /* '02cc63330cd0808f87c54ad9dd94b74a' ||  */ this.props.product.live.settings.live.token,
		selectedAgent: 'all',
		selectedDate: 'daily',
		groupBy: 'agent',
		selectedTopic: 'all',
		column: null,
		botId: '',
		direction: null,
		dataloading: true,
		displayName: 'developer',
		selectedBarStyle: 'line',
		loadingGraph: true,
		selectedPlatform: 'all',
	};
	handleSort = (clickedColumn) => () => {
		const { column, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				direction: 'desc',
			});

			return;
		}
		this.setState({
			direction: direction === 'asc' ? 'desc' : 'asc',
		});
	};
	TableHead = (data, keys, head, column, direction, tcloumn, ratePoint, t) => (
		<Table.Header>
			<Table.Row>
				<Table.HeaderCell>{t('tag')}</Table.HeaderCell>
				<Table.HeaderCell sorted={column === tcloumn ? direction : null} onClick={this.handleSort('count')}>
					{t('count')}
				</Table.HeaderCell>
				{ratePoint ? (
					<Table.HeaderCell sorted={column === tcloumn ? direction : null} onClick={this.handleSort('ratedCount')}>
						{t('rateCount')}
					</Table.HeaderCell>
				) : (
					''
				)}
				{ratePoint ? (
					<Table.HeaderCell sorted={column === tcloumn ? direction : null} onClick={this.handleSort('avgRate')}>
						{t('averageRate')}
					</Table.HeaderCell>
				) : (
					''
				)}
				{ratePoint ? (
					<Table.HeaderCell sorted={column === tcloumn ? direction : null} width={1}>
						{1}
					</Table.HeaderCell>
				) : (
					''
				)}
				{ratePoint ? (
					<Table.HeaderCell sorted={column === tcloumn ? direction : null} width={1}>
						{2}
					</Table.HeaderCell>
				) : (
					''
				)}
				{ratePoint ? (
					<Table.HeaderCell sorted={column === tcloumn ? direction : null} width={1}>
						{3}
					</Table.HeaderCell>
				) : (
					''
				)}
				{ratePoint ? (
					<Table.HeaderCell sorted={column === tcloumn ? direction : null} width={1}>
						{4}
					</Table.HeaderCell>
				) : (
					''
				)}
				{ratePoint ? (
					<Table.HeaderCell sorted={column === tcloumn ? direction : null} width={1}>
						{5}
					</Table.HeaderCell>
				) : (
					''
				)}
			</Table.Row>
		</Table.Header>
	);
	TableLine = (
		data,
		productAgentList,
		closeTags,
		group,
		productAgentList2,
		column,
		direction,
		ratePoint,
		selectedAgent,
		sumFilterData,
		t,
		selectedTopic,
		othersdata
	) => (
		<Table.Body>
			{group === 'topic' ? (
				_.map(_.orderBy(data, [column], [direction]), (val, index) => (
					<Table.Row key={index}>
						<Table.Cell>{(_.find(closeTags, { id: val.topic }) && _.find(closeTags, { id: val.topic }).title) || val.topic}</Table.Cell>
						<Table.Cell>{(val && val.count) || 0}</Table.Cell>
						{ratePoint ? <Table.Cell>{(val && val.ratedCount) || 0}</Table.Cell> : ''}
						{ratePoint ? <Table.Cell>{val && val.avgRate ? (val && val.avgRate).toFixed(2) : '-'}</Table.Cell> : ''}
						{ratePoint ? <Table.Cell>{(val && val.rates.one) || 0}</Table.Cell> : ''}
						{ratePoint ? <Table.Cell>{(val && val.rates.two) || 0}</Table.Cell> : ''}
						{ratePoint ? <Table.Cell>{(val && val.rates.three) || 0}</Table.Cell> : ''}
						{ratePoint ? <Table.Cell>{(val && val.rates.four) || 0}</Table.Cell> : ''}
						{ratePoint ? <Table.Cell>{(val && val.rates.five) || 0}</Table.Cell> : ''}
					</Table.Row>
				))
			) : selectedAgent === 'all' ? (
				<>
					{_.map(
						_.orderBy(
							_.filter(productAgentList2, function (it) {
								return it.key !== '5e6f519560b2b4d07559fcf8';
							}),
							[column],
							[direction]
						),
						(val, index) => (
							<Table.Row key={index}>
								<Table.Cell>{val[this.props.displayName] || val.key || 'Bot'}</Table.Cell>
								<Table.Cell>{(val && val.count) || 0}</Table.Cell>
								{ratePoint ? <Table.Cell>{(val && val.ratedCount) || 0}</Table.Cell> : ''}
								{ratePoint ? <Table.Cell>{val && val.avgRate ? (val && val.avgRate).toFixed(2) : '-'}</Table.Cell> : ''}
								{ratePoint ? <Table.Cell>{(val && val.rates.one) || 0}</Table.Cell> : ''}
								{ratePoint ? <Table.Cell>{(val && val.rates.two) || 0}</Table.Cell> : ''}
								{ratePoint ? <Table.Cell>{(val && val.rates.three) || 0}</Table.Cell> : ''}
								{ratePoint ? <Table.Cell>{(val && val.rates.four) || 0}</Table.Cell> : ''}
								{ratePoint ? <Table.Cell>{(val && val.rates.five) || 0}</Table.Cell> : ''}
							</Table.Row>
						)
					)}
					{othersdata.count > 0 ? (
						<Table.Row key={'others'}>
							<Table.Cell>{t('oldAgents')}</Table.Cell>
							<Table.Cell>{othersdata && othersdata.count}</Table.Cell>
							{ratePoint ? <Table.Cell>{'-'}</Table.Cell> : ''}
							{ratePoint ? <Table.Cell>{'-'}</Table.Cell> : ''}
							{ratePoint ? <Table.Cell>{'-'}</Table.Cell> : ''}
							{ratePoint ? <Table.Cell>{'-'}</Table.Cell> : ''}
							{ratePoint ? <Table.Cell>{'-'}</Table.Cell> : ''}
							{ratePoint ? <Table.Cell>{'-'}</Table.Cell> : ''}
							{ratePoint ? <Table.Cell>{'-'}</Table.Cell> : ''}
						</Table.Row>
					) : (
						''
					)}
				</>
			) : (
				_.map(
					_.orderBy(
						_.filter(productAgentList2, function (it) {
							return it.key === selectedAgent;
						}),
						[column],
						[direction]
					),
					(val, index) => (
						<Table.Row key={index}>
							<Table.Cell>{val[this.props.displayName] || val.key || 'Bot'}</Table.Cell>
							<Table.Cell>{(val && val.count) || 0}</Table.Cell>
							{ratePoint ? <Table.Cell>{(val && val.ratedCount) || 0}</Table.Cell> : ''}
							{ratePoint ? <Table.Cell>{val && val.avgRate ? (val && val.avgRate).toFixed(2) : '-'}</Table.Cell> : ''}
							{ratePoint ? <Table.Cell>{(val && val.rates.one) || 0}</Table.Cell> : ''}
							{ratePoint ? <Table.Cell>{(val && val.rates.two) || 0}</Table.Cell> : ''}
							{ratePoint ? <Table.Cell>{(val && val.rates.three) || 0}</Table.Cell> : ''}
							{ratePoint ? <Table.Cell>{(val && val.rates.four) || 0}</Table.Cell> : ''}
							{ratePoint ? <Table.Cell>{(val && val.rates.five) || 0}</Table.Cell> : ''}
						</Table.Row>
					)
				)
			)}
			<Table.Row key={'all'}>
				<Table.Cell>{t('all')}</Table.Cell>
				<Table.Cell>{(sumFilterData && sumFilterData.count) || '0'}</Table.Cell>
				{ratePoint ? <Table.Cell>{(sumFilterData && sumFilterData.ratedCount) || '0'}</Table.Cell> : ''}
				{ratePoint ? (
					<Table.Cell>{sumFilterData && sumFilterData.ratedCount && (sumFilterData.total / sumFilterData.ratedCount).toFixed(2)}</Table.Cell>
				) : (
					''
				)}
				{ratePoint ? <Table.Cell>{sumFilterData.rates.one}</Table.Cell> : ''}
				{ratePoint ? <Table.Cell>{sumFilterData.rates.two}</Table.Cell> : ''}
				{ratePoint ? <Table.Cell>{sumFilterData.rates.three}</Table.Cell> : ''}
				{ratePoint ? <Table.Cell>{sumFilterData.rates.four}</Table.Cell> : ''}
				{ratePoint ? <Table.Cell>{sumFilterData.rates.five}</Table.Cell> : ''}
			</Table.Row>
		</Table.Body>
	);
	componentDidMount() {
		let typeQuery = [];
		let pairs = {};
		let group = [];
		let select = [];
		let filter = [];
		let { start_date, end_date, pid, agent_role, agent_id, selecteGroup, selectedPlatform } = this.state;
		let valueArr = this.state.value.length ? this.state.value : TypeList;
		_.each(valueArr, (it) => {
			typeQuery = _.concat(typeQuery, it.type);
			if (it.group) {
				if (group.length) group = _.intersection(group, it.group);
				else group = it.group;
			}
			if (it.select) {
				if (select.length) select = _.intersection(select, it.select);
				else select = it.select;
			}
			if (it.pairs) pairs = _.assignIn(it.pairs);
		});

		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		typeQuery = _.uniq(typeQuery);
		if (agent_role === 'agent') {
			filter = { type: typeQuery, pid: this.state.pid, agent: agent_id };
			this.setState({ selectedAgent: agent_id });
		} else {
			filter = { type: typeQuery, pid: this.state.pid };
		}
		if (selectedPlatform !== 'all') _.set(filter, 'platform', selectedPlatform);
		else _.unset(filter, 'platform');
		pairs.platform = 'platform';
		if (group.length) group = _.union(group, ['type']);
		if (select.length) select = _.union(select, ['type', 'day']);
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select,
				group: ['type', 'agent', 'rate'],
				pairs: { agent: 'select1', topic: 'select2', rate: 'select5', platform: 'platform' },
				options: {
					sum: 0,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let agentList = _.map(data.result, function (it) {
					return { key: it.agent, text: it.agent, value: it.agent, id: it.agent };
				});
				this.setState({ head: typeQuery });
				agentList = _.uniqBy(agentList, 'key');

				this.setState({
					gettingData: _.sortBy(
						_.filter(data.result, (it) => (selecteGroup === 'topic' ? it.topic !== 'auto_close' : it.agent !== pid)),
						['agent', 'asc']
					),
					agentList: agentList,
				});
			});
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select,
				group: ['type', 'agent', 'rate'],
				pairs: { agent: 'select1', topic: 'select2', rate: 'select5', platform: 'platform' },
				options: {
					sum: 0,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					filteredData: _.filter(data.result, function (it) {
						return it.agent !== pid;
					}),
				});
			});
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select,
				group: ['type', 'agent', 'day'],
				pairs,
				options: {
					sum: 0,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				_.each(data.result, function (it) {
					return (it.dayNumber = moment(it.day).dayOfYear());
				});
				this.setState({
					gettingDataDate: _.orderBy(
						_.filter(data.result, (it) => (selecteGroup === 'topic' ? it.topic !== 'auto_close' : it.agent !== pid)),
						['day', 'asc']
					),
				});
			});
		fetch('https://log.yapaytech.com/live/test/getInfo?hourly', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select,
				group: ['type', 'agent', 'day', 'hour'],
				pairs,
				options: {
					sum: 0,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				_.each(data.result, function (it) {
					return (it.dayNumber = moment(it.day).dayOfYear());
				});
				this.setState({
					loadingGraph: false,
					gettingDataDateHour: _.orderBy(
						_.filter(data.result, (it) => (selecteGroup === 'topic' ? it.topic !== 'auto_close' : it.agent !== pid)),
						['day', 'asc']
					),
				});
			});
		fetch('/api/live/agent/list')
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ newList: data.agents });
			});
		fetch('/api/dahi/message/getLiveChat', {})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					productAgentList:
						data.result &&
						data.result.agents &&
						_.filter(data.result.agents, function (it) {
							return it.type !== 'viewer';
						}),
					closeTags: data.result && data.result.staticOptions && data.result.staticOptions.closeTags,
					botId: data.result && data.result.bot.bid,
					bot_id: data.result && data.result.bot.id,
					loading: false,
				});
			});
	}
	handleFilterChange = (e, { text, value }) => this.setState({ selectedAgent: value, selectedAgentName: text });
	handleTopicChange = (e, { text, value }) => this.setState({ selectedTopic: value, selectedTopicName: text });
	handleGroupChange = (e, { text, value }) => this.setState({ groupBy: value });
	handleChange = (e, { name, value }) => this.setState({ [name]: value });
	handleFilterCheck(value) {
		let array = this.state.value || [];
		let exists = _.find(array, value);
		let valueArr = exists ? _.filter(array, (it) => it !== value) : _.concat([], array, value);
		this.setState({ value: valueArr });
	}
	filterSelectType = () => {
		this.setState({ filterType: !this.state.filterType });
	};
	toggle = () => this.setState((prevState) => ({ selectedDate: prevState.selectedDate === 'daily' ? 'hourly' : 'daily' }));
	componentDidUpdate(oldProps, oldState) {
		const check_list = ['start_date', 'end_date', 'selectedAgent', 'value', 'selectedDate', 'groupBy', 'selectedTopic', 'selectedPlatform'];
		for (let i = 0; i < check_list.length; i++) {
			const key = check_list[i];
			let timer;
			if (key === 'start_date' || key === 'end_date') {
				if (this.props[key] !== oldProps[key]) {
					window.clearTimeout(timer);
					timer = setTimeout(() => this.getData(), 3000);
					break;
				}
			}
			if (!_.isArray(this.state[key])) {
				if (this.state[key] !== oldState[key]) {
					window.clearTimeout(timer);
					timer = setTimeout(() => this.getData(), 3000);
					break;
				}
			} else if (!_.isEqual(this.state[key], oldState[key])) {
				window.clearTimeout(timer);
				timer = setTimeout(() => this.getData(), 3000);
				break;
			}
		}
	}
	async getData() {
		let typeQuery = [];
		let group = [];
		let selecteGroup = this.state.groupBy;
		let dayGroup = ['type', 'day', selecteGroup];
		let hourGroup = ['type', 'day', selecteGroup, 'hour'];
		let select = [];
		let { selectedDate, selectedTopic, selectedAgent, pid, selectedPlatform } = this.state;
		let { start_date, end_date } = this.props;
		_.each(this.state.value, (it) => {
			typeQuery = _.concat(typeQuery, it.type);
			if (it.group) {
				if (group.length) group = _.intersection(group, it.group);
				else group = it.group;
			}
			if (it.select) {
				if (select.length) select = _.intersection(select, it.select);
				else select = it.select;
			}
		});

		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		typeQuery = _.uniq(typeQuery);
		if (group.length) group = _.union(group, ['type']);
		if (select.length) select = _.union(select, ['type', 'day']);
		let filter = { type: typeQuery, pid: this.state.pid };
		if (selectedAgent !== 'all') {
			filter.agent = selectedAgent;
		} else {
			delete filter.agent;
		}
		if (selectedTopic !== 'all') {
			filter.topic = selectedTopic;
		} else {
			delete filter.topic;
		}
		if (!(selectedDate === 'daily')) {
			//filter.day = selectedDate;
			dayGroup = ['type', selecteGroup, 'hour'];
		}
		if (selectedPlatform !== 'all') _.set(filter, 'platform', selectedPlatform);
		else _.unset(filter, 'platform');
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select: [],
				group: ['type', selecteGroup, 'rate'],
				pairs: { agent: 'select1', topic: 'select2', rate: 'select5', platform: 'platform' },
				options: {
					sum: 0,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let agentList = _.map(data.result, function (it) {
					return { key: it.agent, text: it.agent, value: it.agent };
				});
				this.setState({ head: typeQuery });
				agentList = _.uniqBy(agentList, 'key');
				this.setState({
					gettingData: _.sortBy(
						_.filter(data.result, (it) => (selecteGroup === 'topic' ? it.topic !== 'auto_close' : it.agent !== pid)),
						['agent', 'asc']
					),
					agentList: agentList,
				});
			});
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select,
				group: ['type', 'agent', 'rate'],
				pairs: { agent: 'select1', topic: 'select2', rate: 'select5', platform: 'platform' },
				options: {
					sum: 0,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					filteredData: _.filter(data.result, function (it) {
						return it.agent !== pid;
					}),
				});
			});
		if (typeQuery.length) {
			fetch('https://log.yapaytech.com/live/test/getInfo', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					start_date: start_date,
					end_date: end_date,
					include_hour: true,
					filter,
					select,
					group: dayGroup,
					pairs: { agent: 'select1', topic: 'select2', platform: 'platform' },
					options: {
						sum: 0,
						avg: 0,
					},
				}),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (!(selectedDate === 'daily')) {
						/* _.each(data.result, function (it) {
							return (it.hour = (it.hour + 2) % 24);
						}); */
					} else {
						_.each(data.result, function (it) {
							return (it.dayNumber = moment(it.day).dayOfYear());
						});
					}
					this.setState({
						gettingDataDate: _.orderBy(
							_.filter(data.result, (it) => (selecteGroup === 'topic' ? it.topic !== 'auto_close' : it.agent !== pid)),
							['day', 'asc']
						),
						loading: false,
					});
				});
			fetch('https://log.yapaytech.com/live/test/getInfo?hourly', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					start_date: start_date,
					end_date: end_date,
					include_hour: true,
					filter,
					select,
					group: hourGroup,
					pairs: { agent: 'select1', topic: 'select2', platform: 'platform' },
					options: {
						sum: 0,
						avg: 0,
					},
				}),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					/* 	if (!(selectedDate === 'daily')) {
						_.each(data.result, function (it) {
							return (it.hour = (it.hour + 2) % 24);
						});
					} else {
						_.each(data.result, function (it) {
							return (it.dayNumber = moment(it.day).dayOfYear());
						});
					} */
					_.map(data.result, (it) => (it.dayNumber = moment(it.day).dayOfYear()));
					this.setState({
						gettingDataDateHour: _.orderBy(
							_.filter(data.result, (it) => (selecteGroup === 'topic' ? it.topic !== 'auto_close' : it.agent !== pid)),
							['day', 'asc']
						),
						loading: false,
					});
				});
		} else {
			this.setState({ gettingDataDate: [], gettingDataDateHour: [], loading: false });
		}
	}

	dateChange = ({ selection }) => {
		let start_date = moment(selection.startDate).startOf('day');
		let end_date = moment(selection.endDate);
		if (end_date.format('YYYY-MM-DD') !== this.state.today.format('YYYY-MM-DD')) {
			end_date = end_date.endOf('day');
		} else {
			end_date = moment();
		}
		this.setState({ start_date, end_date });
	};
	showingBar(data, barStyle) {
		switch (barStyle) {
			case 'line':
				return <Line data={data} options={barOptions} />;
			case 'bar':
				return <Bar data={data} options={barOptions} />;
			case 'stackbar':
				return <Bar data={data} options={barOptionsStack} />;
			case 'radar':
				return <Radar data={data} />;
			default:
				return <Line data={data} options={barOptions} />;
		}
	}
	render() {
		const { selectedAgent, selectedTopic, selectedBarStyle, pid, gettingDataDateHour, loadingGraph, selectedPlatform } = this.state;
		let { start_date, end_date, t, i18n, product, platformList } = this.props;
		let { agent_role } = this.state;
		let ratePoint;
		if (this.props.product.live.settings.analyticView) {
			ratePoint = this.props.product.live.settings.analyticView.ratePoint;
		}
		let isMock = product.live && product.live.settings.mock_analytic;
		let gettingData = isMock ? MockAgents.closedByTopic : this.state.gettingData;
		let newList = this.state.newList;
		let ng = _.map(gettingData, function (it) {
			return {
				count: it.count,
				type: it.type,
				agent: it.agent,
				topic: it.topic,
				rate: parseInt(it.rate) || 0,
				total: it.count * (parseInt(it.rate) || 0),
			};
		});
		let fd = _.map(this.state.filteredData, function (it) {
			return {
				count: it.count,
				type: it.type,
				agent: it.agent,
				rate: parseInt(it.rate) || 0,
				total: it.count * (parseInt(it.rate) || 0),
			};
		});
		let dataloading = this.state.dataloading;
		let gGettingData = _.groupBy(ng, this.state.groupBy);
		let agentList = this.state.agentList;
		let productAgentList2 = this.state.productAgentList;
		let closeTags = this.state.closeTags;
		let head = this.state.head;
		let productAgentList = this.state.productAgentList;
		let ownerName =
			this.props && this.props.access && this.props.access.user && this.props.access.user.loggedUserObj && this.props.access.user.loggedUserObj.name;
		_.map(productAgentList2, function (it) {
			return _.map(head, function (d) {
				return _.assign(it, { count: 0, ratedCount: 0, avgRate: 0, rates: { one: 0, two: 0, three: 0, four: 0, five: 0 } });
			});
		});
		let newData = [];
		let sumFilterData = {
			count: _.sumBy(fd, 'count'),
			ratedCount: _.sumBy(
				_.filter(fd, function (it) {
					return it.rate > 0;
				}),
				'count'
			),
			total: _.sumBy(fd, 'total'),
			rates: {
				one: _.sumBy(
					_.filter(fd, (o) => o.rate === 1),
					'count'
				),
				two: _.sumBy(
					_.filter(fd, (o) => o.rate === 2),
					'count'
				),
				three: _.sumBy(
					_.filter(fd, (o) => o.rate === 3),
					'count'
				),
				four: _.sumBy(
					_.filter(fd, (o) => o.rate === 4),
					'count'
				),
				five: _.sumBy(
					_.filter(fd, (o) => o.rate === 5),
					'count'
				),
			},
		};

		newData = _.map(gGettingData, function (it, i) {
			return {
				count: _.sumBy(it, 'count'),
				type: it[0].type,
				agent: it[0].agent,
				topic: (_.find(closeTags, { id: it[0].topic }) && _.find(closeTags, { id: it[0].topic }).title) || it[0].topic,
				ratedCount: _.sumBy(
					_.filter(it, function (o) {
						return o.rate > 0;
					}),
					'count'
				),
				total: _.sumBy(it, 'total'),
				avgRate:
					_.sumBy(it, 'total') /
						_.sumBy(
							_.filter(it, function (o) {
								return o.rate > 0;
							}),
							'count'
						) || 0,
				rates: {
					one: _.find(it, (o) => o.rate === 1) && _.find(it, (o) => o.rate === 1).count,
					two: _.find(it, (o) => o.rate === 2) && _.find(it, (o) => o.rate === 2).count,
					three: _.find(it, (o) => o.rate === 3) && _.find(it, (o) => o.rate === 3).count,
					four: _.find(it, (o) => o.rate === 4) && _.find(it, (o) => o.rate === 4).count,
					five: _.find(it, (o) => o.rate === 5) && _.find(it, (o) => o.rate === 5).count,
				},
			};
		});
		if (this.state.groupBy === 'agent') {
			_.map(newData, function (it) {
				var e = _.findIndex(agentList, function (o) {
					return o.key === it.agent;
				});
				return _.assign(agentList[e], { count: it.count, ratedCount: it.ratedCount, avgRate: it.avgRate, total: it.total, rates: it.rates });
			});
		}
		let gettingDataDate = isMock ? MockAgents.closedByDate : this.state.gettingDataDate;
		_.map(productAgentList, function (it, i) {
			let name = _.find(newList, { email: it.developer }) && _.find(newList, { email: it.developer }).name;
			if (i === 0) {
				return (it.name = ownerName || it.developer);
			}
			return (it.name = name ? name : it.developer);
		});
		let data = chartData(
			gettingDataDate,
			start_date,
			end_date,
			this.state.selectedDate,
			closeTags,
			this.state.groupBy,
			productAgentList,
			this.props.displayName,
			t,
			i18n
		);
		let data_hourly = chartDataHourly(
			gettingDataDateHour,
			this.state.groupBy,
			start_date,
			end_date,
			closeTags,
			t,
			productAgentList,
			this.props.displayName
		);
		productAgentList = _.filter(productAgentList, function (it) {
			return it.id !== '5e6f519560b2b4d07559fcf8';
		});
		let dropAgentList = _.map(productAgentList, function (it) {
			return { text: it.developer, key: it.id, value: it.id };
		});

		let dropCloseList = _.map(closeTags, function (it) {
			return { text: it.title, key: it.id, value: it.id };
		});
		var groupBy = [
			{
				text: t('topic'),
				key: 'topic',
				value: 'topic',
			},
			{
				text: t('agent'),
				key: 'agent',
				value: 'agent',
			},
		];
		dropAgentList = _.concat({ text: t('allAgent'), key: 'all', value: 'all' }, dropAgentList);
		dropCloseList = _.concat({ text: t('allTopic'), key: 'all', value: 'all' }, dropCloseList);
		let othersdata = {
			count: 0,
			id: 'others',
			key: 'others',
			taken: 0,
			text: 'others',
			value: 'others',
		};
		_.map(agentList, function (it) {
			let n = _.findIndex(productAgentList2, { id: it.key });
			if (n >= 0) {
				dataloading = false;
				return _.assign(productAgentList2[n], it);
			} else if (/bot/.test(it.key) || it.key === pid) {
				console.log('bot');
			} else {
				othersdata = _.assign({
					count: othersdata.count + (it.count || 0),
				});
			}
		});
		othersdata = {
			count: othersdata.count,
			id: 'others',
			key: 'others',
			text: 'others',
			value: 'others',
			developer: t('oldAgents'),
		};
		productAgentList2 = _.filter(productAgentList2, function (it) {
			return it && it.id && it.id !== '5e6f519560b2b4d07559fcf8';
		});
		let tcloumn = this.state.groupBy === 'topic' ? 'count' : 'closed';
		if (agentList) {
			dataloading = false;
		}
		if (this.state.loading || dataloading)
			return (
				<Container>
					<Segment style={{ marginBottom: '20px' }}>
						<Page.Loader></Page.Loader>
					</Segment>
				</Container>
			);
		_.map(productAgentList2, function (it, i) {
			let name = _.find(newList, { email: it.developer }) && _.find(newList, { email: it.developer }).name;

			if (i === 0) {
				return (it.name = ownerName || it.developer);
			}
			return (it.name = name ? name : it.developer);
		});
		let barStyleDropdown = [
			{
				text: t('Line'),
				value: 'line',
			},
			{
				text: t('Bar'),
				value: 'bar',
			},
			{
				text: t('StackBar'),
				value: 'stackbar',
			},
			{
				text: t('Radar'),
				value: 'radar',
			},
		];
		return (
			<Container>
				<Segment style={{ marginBottom: '20px' }}>
					<Segment attached="bottom">
						<div style={{ display: 'flex' }}>
							<Dropdown
								selection
								options={platformList}
								value={selectedPlatform}
								floating
								labeled
								button
								icon="filter"
								className="icon"
								name="selectedPlatform"
								onChange={(e, { name, value }) => this.setState({ selectedPlatform: value })}
							/>
							{agent_role !== 'agent' ? (
								<Dropdown
									options={dropAgentList}
									selection
									defaultValue={dropAgentList[0].value}
									icon="filter"
									floating
									labeled
									button
									className="icon"
									onChange={this.handleFilterChange}
								></Dropdown>
							) : (
								''
							)}

							<Dropdown
								placeholder="Select Topic"
								options={dropCloseList}
								selection
								defaultValue={dropCloseList[0].value}
								icon="filter"
								floating
								labeled
								button
								className="icon"
								onChange={this.handleTopicChange}
							></Dropdown>
							<Dropdown
								placeholder="Group By"
								options={groupBy}
								selection
								defaultValue={groupBy[1].value}
								icon="circle outline"
								floating
								labeled
								button
								className="icon"
								onChange={this.handleGroupChange}
							></Dropdown>
							{/* <Button
								icon
								labelPosition="left"
								color="teal"
								onClick={() =>
									this.exportExcel(
										this.state.groupBy === 'topic' ? newData : productAgentList2,
										this.state.pid,
										this.state.groupBy,
										ratePoint
									)
								}
							>
								<Icon name="file excel outline" />
								Download Page
							</Button> */}
						</div>
					</Segment>
					<Table sortable fixed width={16} style={{ padding: '0px' }}>
						{this.TableHead(gGettingData, [], this.state.head, this.state.column, this.state.direction, tcloumn, ratePoint, t)}
						{this.TableLine(
							newData,
							productAgentList,
							closeTags,
							this.state.groupBy,
							productAgentList2,
							this.state.column,
							this.state.direction,
							ratePoint,
							selectedAgent,
							sumFilterData,
							t,
							selectedTopic,
							othersdata
						)}
					</Table>
					<ChartWrap
						width={8}
						title={t('graph')}
						agentList={this.state.agentList}
						content={
							<div style={{ marginLeft: 'auto' }}>
								<Dropdown
									options={barStyleDropdown}
									value={selectedBarStyle}
									name="selectedBarStyle"
									onChange={this.handleChange}
									style={{ marginRight: '30px' }}
								></Dropdown>
								<Radio style={{ marginLeft: 'auto' }} toggle label={t('24HourDist')} onChange={this.toggle}></Radio>
							</div>
						}
					>
						{this.showingBar(data, selectedBarStyle)}
					</ChartWrap>
					<ChartWrap
						width={16}
						title={t('graphHourly')}
						content={
							<div style={{ marginLeft: 'auto', display: loadingGraph ? 'none' : 'block' }}>
								<Dropdown
									options={barStyleDropdown}
									value={selectedBarStyle}
									name="selectedBarStyle"
									onChange={this.handleChange}
									style={{ marginRight: '30px' }}
								/>
							</div>
						}
					>
						{loadingGraph ? <Page.Loader /> : this.showingBar(data_hourly, selectedBarStyle)}
					</ChartWrap>
				</Segment>
			</Container>
		);
	}
}
// date array
var getDateArray = function (start, end) {
	var arr = [],
		dt = new Date(moment(start).startOf('d'));
	while (dt <= new Date(moment(end).startOf('d'))) {
		arr.push(new Date(dt));
		dt.setDate(dt.getDate() + 1);
	}
	return arr;
};
var getHourArray = function () {
	var arr = [],
		dt = 0;

	while (dt < 24) {
		arr.push(dt);
		dt++;
	}
	return arr;
};
function chartData(data, start_date, end_date, selectedDate, closeTags, group, productAgentList, displayName, t, i18n) {
	moment.locale(i18n.language);
	let startDate = new Date(start_date);
	let endDate = new Date(end_date);
	let labels = [];
	var dateArr = getDateArray(startDate, endDate);
	var hourArr = getHourArray();
	let gdata = _.groupBy(data, group);
	if (selectedDate === 'daily') {
		_.map(gdata, function (it, index) {
			return _.each(dateArr, function (d, i) {
				if (_.findIndex(it, { dayNumber: moment(d).dayOfYear() }) === -1) {
					it.push({ type: it[0].type, topic: it[0].topic, agent: it[0].agent, day: moment(d).format(), dayNumber: moment(d).dayOfYear(), count: 0 });
				}
			});
		});
	} else {
		_.map(gdata, function (it, index) {
			return _.each(hourArr, function (d, i) {
				if (_.findIndex(it, { hour: d }) === -1) {
					it.push({ type: it[0].type, topic: it[0].topic, agent: it[0].agent, day: it[0].day, hour: d, dayNumber: it[0].dayNumber, count: 0 });
				}
			});
		});
	}
	if (selectedDate === 'daily') {
		labels = _.map(dateArr, (it) => moment(it).format('DD-MMM'));
	} else {
		labels = hourArr;
	}
	let i = 0;
	let datasets = _.map(gdata, function (it) {
		if (selectedDate === 'daily') {
			it = _.sortBy(it, ['day', 'asc']);
		} else {
			it = _.sortBy(it, ['hour', 'asc']);
		}
		let item = '';
		let title = '';
		if (group === 'topic') {
			item = _.find(closeTags, { id: it[0].topic });
			title = item && item.title;
		} else {
			item = _.find(productAgentList, { id: it[0].agent });
			title = item && item[displayName];
		}
		let color = colors2[i++ % colors2.length]; //randomMC.getColor({ shades: ['200', '700'], text: title || it[0][group] });
		return {
			label: title || it[0][group],
			day: _.map(it, (item) => moment(item.day, 'DD-MM').format()),
			data: _.map(it, (item) => item.count),
			borderColor: color,
			backgroundColor: color,
			fill: false,
		};
	});
	return {
		labels,
		datasets,
	};
}
function chartDataHourly(data, group, start_date, end_date, closeTags, t, productAgentList, displayName) {
	let startDate = new Date(start_date);
	let endDate = new Date(end_date);
	let labels = [];
	var dateArr = getDateArray(startDate, endDate);
	var hourArr = getHourArray();
	let gdata = _.groupBy(data, group);
	_.map(dateArr, (it) => {
		return _.map(hourArr, (o) => {
			labels.push(moment(it).format('DD/MM') + ' - ' + moment(o, 'H').format('HH'));
		});
	});
	let datasets = [];
	let i = 0;
	datasets = _.map(gdata, (o) => {
		let color = colors2[i++ % colors2.length];
		let hobj;
		let data_arr = [];
		let oitem = '';
		let title = '';
		if (group === 'topic') {
			oitem = _.find(closeTags, { id: o[0].topic });
			title = oitem && oitem.title;
		} else {
			oitem = _.find(productAgentList, { id: o[0].agent });
			title = oitem && oitem[displayName];
		}
		_.map(dateArr, (ditem) => {
			return _.map(hourArr, (it) => {
				/* if (it >= 2) {
					hobj = _.find(o, {
						dayNumber: moment(ditem).dayOfYear(),
						hour: it - 2,
					});
				} else if (it === 0) {
					hobj = _.find(o, {
						dayNumber: moment(ditem).dayOfYear() - 1,
						hour: it + 22,
					});
				} else {
					hobj = _.find(o, {
						dayNumber: moment(ditem).dayOfYear(),
						hour: it + 22,
					});
				} */
				hobj = _.find(o, {
					dayNumber: moment(ditem).dayOfYear(),
					hour: it,
				});
				return data_arr.push((hobj && hobj.count) || 0);
			});
		});
		return {
			label: title || o[0][group],
			data: data_arr,
			borderColor: color,
			backgroundColor: color,
			fill: false,
		};
	});
	return {
		labels,
		datasets,
	};
}
const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product });

export default connect(stateToProps)(withTranslation()(Analytic));
