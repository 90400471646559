import React, { useState } from 'react';
import Page from '../../../components/Page';
import { Icon, Breadcrumb, Form, FormGroup, Table, Button, Segment, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import useFetch from 'use-http';
import IntlTelInput from 'react-intl-tel-input';
import MyMenu from '../menu';
import 'react-intl-tel-input/dist/main.css';
import AccountProgress from '../../../components/AccountProgress';
import { useTranslation } from 'react-i18next';

const fetch_config = { responseType: 'json', cachePolicy: 'no-cache' };

export default function SandBox() {
	const { t } = useTranslation();
	const [reload, setReload] = useState(0);
	const { loading, response } = useFetch('/api/live/product/sandbox/list', fetch_config, [reload]);
	const { response: hash } = useFetch('/api/live/product/hash', fetch_config, [reload]);
	const [state, setState] = useState({
		error: null,
		deleteBtnClicked: false,
	});
	if (loading)
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/settings`}>
							<Icon name="setting" />
							{t('settings')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />

						<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
							{t('integrations')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
					</Breadcrumb>
				}
				flex
			>
				<MyMenu activeItem="sandbox">
					<Page.Loader />
				</MyMenu>
			</Page>
		);

	let phones = response && response.data && response.data.result && response.data.result.result;
	let hashCode = hash && hash.data && hash.data.hash;
	function reset() {
		setState({
			phone: '',
			status: '',
			error: null,
		});
	}

	function handleAddPhone() {
		if (phones.length > 9) {
			setState((state) => ({ ...state, error: t('sandboxLimitError') }));
		} else {
			if (state.status) {
				fetch('/api/live/product/sandbox/connect', {
					method: 'POST',
					body: JSON.stringify({ phone: state.number.replace(/\+| /gm, ''), countryData: state.countryData, number: state.number }),
					headers: {
						'Content-Type': 'application/json',
					},
				})
					.then((response) => response.json())
					.then((data) => {
						if (data.result.success) {
							reset();
							setReload(reload + 1);
						} else {
							setState((state) => ({ ...state, error: '' + data.result.error }));
						}
					});
			} else {
				setState((state) => ({ ...state, error: 'No Valid' }));
			}
		}
	}
	function handleDeletePhone(value) {
		fetch('/api/live/product/sandbox/disconnect', {
			method: 'POST',
			body: JSON.stringify({ phone: value }),
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					reset();
					setReload(reload + 1);
				} else {
					setState((state) => ({ ...state, error: '' + data.message }));
				}
			});
	}

	function handleChangePhone(value, status, countryData, number, id) {
		setState((state) => ({ ...state, phone: value, status, number, countryData }));
	}
/* 	function handleClickDelete() {
		handleDeletePhone();
	}
	function closeDeleteButton() {
		setState({ deleteBtnClicked: false, goToSubs: false });
	} */
	return (
		<Page
			header={
				<Breadcrumb size="big">
					<Breadcrumb.Section as={Link} to={`/settings`}>
						<Icon name="setting" />
						{t('settings')}
					</Breadcrumb.Section>
					<Breadcrumb.Divider icon="right chevron" />

					<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
						{t('integrations')}
					</Breadcrumb.Section>
					<Breadcrumb.Divider icon="right chevron" />
					<Breadcrumb.Section>SandBox</Breadcrumb.Section>
				</Breadcrumb>
			}
			extra={
				<div className="ml-auto">
					<AccountProgress></AccountProgress>
				</div>
			}
			flex
		>
			<MyMenu activeItem="sandbox">
				<Page.Wrap>
					<Segment>
						<Message info style={{ textAlign: 'center' }}>
							{t('sanboxDescription')}
						</Message>
						<div style={{ display: 'flex', height: '36px', marginBottom: '10px' }}>
							<Form autoComplete="off" onSubmit={() => handleAddPhone()}>
								<FormGroup widths="equal" style={{ paddingLeft: '3px' }}>
									<IntlTelInput
										containerClassName="intl-tel-input"
										name="phone"
										value={state.phone}
										inputClassName="form-control"
										preferredCountries={['tr']}
										onPhoneNumberChange={(status, value, countryData, number, id) => {
											handleChangePhone(value, status, countryData, number, id);
										}}
										style={{ marginLeft: '5px' }}
									/>

									<div className="d-flex">
										<Form.Button positive content={t('add') + ' (' + phones.length + '/10)'} />
									</div>
								</FormGroup>
							</Form>

							{state.error && (
								<div style={{ marginLeft: '10px', marginBottom: 'auto', marginTop: 'auto', color: 'red' }}>
									<p>{state.error}</p>
								</div>
							)}
						</div>
						<p>{t('sandboxAddNumbers')}</p>
						<br />
						<Segment>
							<p>
								{t('demoNumber')}:<Icon name="whatsapp" style={{ marginLeft: '10px' }} color="green"></Icon>
								<a href={'https://wa.me/908503462981?text=join ' + hashCode} target="_blank" rel="noopener noreferrer">
									908503462981
								</a>
							</p>
							<p>
								{t('useThisNumberWB')}
								<a
									href={'https://wa.me/908503462981?text=join ' + hashCode}
									target="_blank"
									style={{ marginLeft: '10px' }}
									rel="noopener noreferrer"
								>
									{t('clickToHereSendMessage')}
								</a>
							</p>
						</Segment>

						<Table>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell width="6">{t('phone')}</Table.HeaderCell>
									<Table.HeaderCell width="6">{t('country')}</Table.HeaderCell>
									<Table.HeaderCell></Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{phones.length ? (
									_.map(phones, (data, index) => (
										<Table.Row key={index}>
											<Table.Cell>{data.number || data.phone}</Table.Cell>
											<Table.Cell>{(data.countryData && data.countryData.name) || ''}</Table.Cell>
											<Table.Cell style={{ textAlign: 'right' }}>
												<Button negative size="tiny" onClick={() => handleDeletePhone(data.phone)}>
													<i className="trash icon" style={{ color: 'white', opacity: 1, margin: 0, textAlign: 'center' }}></i>
												</Button>
											</Table.Cell>
										</Table.Row>
									))
								) : (
									<Table.Row>
										<Table.Cell>{t('sandboxHaventNumber')}</Table.Cell>
									</Table.Row>
								)}
							</Table.Body>
						</Table>
					</Segment>
				</Page.Wrap>
			</MyMenu>
		</Page>
	);
}
