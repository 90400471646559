import React, { Component } from 'react';
import { Segment, Table, Container } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import TypeList from '../filter3.json';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import { withTranslation } from 'react-i18next';
import 'moment/locale/tr';

const TableHead = (t) => (
	<Table.Header>
		<Table.HeaderCell>{t('group')}</Table.HeaderCell>
		<Table.HeaderCell>{t('count')}</Table.HeaderCell>
	</Table.Header>
);
const TableLine = (data, groups) => (
	<Table.Body>
		{_.map(data, (val, index) => (
			<Table.Row key={index}>
				<Table.Cell>
					{(_.find(groups, function (it) {
						return it.id === val._id;
					}) &&
						_.find(groups, function (it) {
							return it.id === val._id;
						}).label) ||
						val._id}
				</Table.Cell>
				<Table.Cell>{val.total}</Table.Cell>
			</Table.Row>
		))}
	</Table.Body>
);

class Analytic extends Component {
	state = {
		filterType: false,
		filterAgent: false,
		loading: true,
		start_date: this.props.start_date,
		end_date: this.props.end_date,
		today: moment(),
		group: ['agent'],
		value: _.map(TypeList, (it) => {
			return it;
		}),
		pid: /* '1e00a47c670273a623c13c54dba44006' || */ /* '02cc63330cd0808f87c54ad9dd94b74a' || */ this.props.product.live.settings.live.token,
		selectedAgent: 'all',
		selectedDate: 'daily',
		botId: '',
		tableStart: 0,
		tableEnd: 20,
	};
	_i = 0;
	componentDidMount() {
		let typeQuery = [];
		let group = [];
		let select = [];
		let { start_date, end_date } = this.state;
		let valueArr = this.state.value.length ? this.state.value : TypeList;
		_.each(valueArr, (it) => {
			typeQuery = _.concat(typeQuery, it.type);
			if (it.group) {
				if (group.length) group = _.intersection(group, it.group);
				else group = it.group;
			}
			if (it.select) {
				if (select.length) select = _.intersection(select, it.select);
				else select = it.select;
			}
		});
		typeQuery = _.uniq(typeQuery);
		this.setState({ head: typeQuery });
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		if (group.length) group = _.union(group, ['type']);
		if (select.length) select = _.union(select, ['type', 'day']);
		fetch('/api/dahi/message/getLiveChat', {})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					productAgentList:
						data.result &&
						data.result.agents &&
						_.filter(data.result.agents, function (it) {
							return it.type !== 'viewer';
						}),
					botId: data.result && data.result.bot.bid,
					bot_id: data.result && data.result.bot.id,
					groups: data.result && data.result.staticOptions && data.result.staticOptions.groups,
				});
			});
		fetch('https://live.dahi.ai/analytics/conversationByGroup', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					data: data.data,
					loading: false,
				});
			});
	}
	componentDidUpdate(oldProps, oldState) {
		const check_list = ['start_date', 'end_date', 'selectedDate'];
		for (let i = 0; i < check_list.length; i++) {
			const key = check_list[i];
			if (key === 'start_date' || key === 'end_date') {
				if (this.props[key] !== oldProps[key]) {
					this.getData()();
					break;
				}
			}
			if (this.state[key] !== oldState[key]) {
				this.getData()();
				break;
			}
		}
	}

	getData = (value) => async () => {
		let typeQuery = [];
		let group = [];
		let select = [];
		let { start_date, end_date } = this.props;
		_.each(this.state.value, (it) => {
			typeQuery = _.concat(typeQuery, it.type);
			if (it.group) {
				if (group.length) group = _.intersection(group, it.group);
				else group = it.group;
			}
			if (it.select) {
				if (select.length) select = _.intersection(select, it.select);
				else select = it.select;
			}
		});
		typeQuery = _.uniq(typeQuery);
		if (group.length) group = _.union(group, ['type']);
		if (select.length) select = _.union(select, ['type', 'day']);

		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		fetch('https://live.dahi.ai/analytics/conversationByGroup', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					data: data.data,
				});
			});
	};
	render() {
		const { t } = this.props;
		let gettingData = this.state.data;
		let conGroups = this.state.groups;
		/* _.map(productAgentList, function(it, i) {
			let n = _.findIndex(gettingData, { _id: it.id });
			if (n >= 0) {
				return _.assign(productAgentList[i], gettingData[n]);
			} else {
				return _.assign(productAgentList[i], { email: gettingData[n].e, total: 0 });
			}
		}); */
		if (this.state.loading)
			return (
				<Container>
					<Segment style={{ marginBottom: '20px' }}>
						<Page.Loader></Page.Loader>
					</Segment>
				</Container>
			);
		return (
			<Container>
				<Segment style={{ marginBottom: '20px' }}>
					<Table width={16} fixed style={{ padding: '5px' }}>
						{TableHead(t)}
						{TableLine(gettingData, conGroups)}
					</Table>
				</Segment>
			</Container>
		);
	}
}
const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product });

export default connect(stateToProps)(withTranslation()(Analytic));
