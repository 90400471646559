import React from 'react';
import { Button, Icon, Header, Image, Form, Message, Dropdown } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { getProfile } from '../actions/access.actions';
import { connect } from 'react-redux';
import { login, register, logout } from '../actions/access.actions';
import toggleLogin from '../actions/modals.actions';
import * as Yup from 'yup';
import { FormikInput, FormikCheckbox, TrimmedFormikInput } from '../components/FormikInput';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import { post } from '../util/api';
import _ from 'lodash';

import { Formik } from 'formik';

const RegisterFormValidation = Yup.object().shape({
	email: Yup.string().email('E-mail is not valid!').required('E-mail is required!'),
	password: Yup.string()
		.required('Password is required!')
		.matches(
			/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
			'Min 8, at least one uppercase, lowercase, number and special.'
		),
	name: Yup.string().required('Full Name is required').matches(/.* .*/, 'Full Name is at least two words.'),
	passwordConfirmation: Yup.string()
		.oneOf([Yup.ref('password')], 'Passwords are not the same!')
		.required('Password confirmation is required!'),
	consent: Yup.bool()
		.test('consent', 'You have to agree with our Terms and Conditions!', (value) => value === true)
		.required('You have to agree with our Terms and Conditions!'),
});

const RegisterFormValidationTR = Yup.object().shape({
	email: Yup.string().email('E-mail geçerli değil!').required('E-mail zorunlu!'),
	password: Yup.string()
		.required('Password zorunlu!')
		.matches(
			/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
			'En az 8 ve bir büyük, küçük, sayı ve özel karakter olmalıdır.'
		),
	name: Yup.string().required('Tam isim zorunlu').matches(/.* .*/, 'Tam isim iki kelime olmalı.'),
	passwordConfirmation: Yup.string()
		.oneOf([Yup.ref('password')], 'Şifreler aynı değil!')
		.required('Şifreyi doğrula zorunlu!'),
	consent: Yup.bool()
		.test('consent', 'Şartları ve Politikayı kabul etmelisiniz!', (value) => value === true)
		.required('Şartlar ve Politika zorunlu!'),
});

const LoginFormValidation = Yup.object().shape({
	email: Yup.string().email('E-mail is not valid!').required('E-mail is required!'),
	password: Yup.string().min(6, 'Password has to be longer than 6 characters!').required('Password is required!'),
});

const ForgotFormValidation = Yup.object().shape({
	email: Yup.string().email('E-mail is not valid!').required('E-mail is required!'),
});
const ResetFormValidation = Yup.object().shape({
	password: Yup.string()
		.required('Password is required!')
		.matches(
			/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
			'Min 8, at least one uppercase, lowercase, number and special.'
		),
	passwordConfirmation: Yup.string()
		.oneOf([Yup.ref('password')], 'Passwords are not the same!')
		.required('Password confirmation is required!'),
});

const RegisterForm = (props) => {
	const { t, i18n } = useTranslation();
	if (props.userObj && props.userObj.isAuthenticated) return <Redirect to={{ pathname: '/' }} />;
	let email = new URLSearchParams(window.location.search).get('email') || '';
	let valid = i18n.language === 'tr' ? RegisterFormValidationTR : RegisterFormValidation;
	return (
		<div
			css={`
				margin-left: 20px;
				margin-right: 20px;
				display: flex;
				height: 100%;
				align-items: center;
				justify-content: center;
			`}
		>
			<div
				css={`
					max-width: 100%;
					width: 450px;
					.ui.header {
						margin-top: 10px !important;
						padding: 12px;
					}
				`}
			>
				<div style={{ display: 'flex' }}>
					<Button as={Link} to="/login" color="teal" icon labelPosition="left">
						<Icon name="arrow left" />
						{t('gotoLoginPage')}
					</Button>
					<ChangeLanguage style={{ marginLeft: 'auto', background: '#499DF3', color: 'white' }} />
				</div>
				<Header as="h2" textAlign="center">
					<Image src={window.CONFIG.icon} style={{ width: '6rem', margin: '10px', background: 'white', borderRadius: 3 }} />
					<div>{t('registerPage')}</div>
				</Header>
				{props.error && (
					<Message error header={'The mail already used.'}>
						<p>
							{t('ifForgetPassword')} <Link to="/forgot">{t('youResetPassword')}</Link>
						</p>
					</Message>
				)}
				<Formik
					initialValues={{ email, password: '', passwordConfirmation: '', consent: false, name: '' }}
					validationSchema={valid}
					onSubmit={(values) => props.registerUser(values.email, values.password, values.name)}
				>
					{(state) => {
						return (
							<Form onSubmit={state.handleSubmit}>
								<TrimmedFormikInput label={t('workEmail')} name="email" iconPosition="left" icon="mail" placeholder="user@mail.com" />
								<FormikInput label={t('fullName')} name="name" iconPosition="left" icon="user" placeholder={t('fullName')} />
								<FormikInput
									label={t('password')}
									name="password"
									iconPosition="left"
									icon="lock"
									placeholder={t('password')}
									type="password"
								/>
								<FormikInput
									label={t('confirmPassword')}
									name="passwordConfirmation"
									iconPosition="left"
									icon="lock"
									placeholder={t('confirmPassword')}
									type="password"
								/>
								<FormikCheckbox
									label={
										<label>
											<p>
												{t('iagreetothe')}{' '}
												<a target="_blank" rel="noopener noreferrer" href={window.CONFIG.tos} pass>
													{t('termsOfService')}
												</a>{' '}
												{t('and')}{' '}
												<a target="_blank" rel="noopener noreferrer" href={window.CONFIG.privacy}>
													{t('privacyPolicy')}
												</a>
											</p>
										</label>
									}
									name="consent"
								/>
								<Button fluid type="submit" style={{ background: '#499DF3', color: 'white', marginTop: '20px' }}>
									{t('register')}
								</Button>

								{/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
							</Form>
						);
					}}
				</Formik>
			</div>
		</div>
	);
};
function ChangeLanguage(props) {
	let langs = [
		{ flag: 'gb', value: 'en', text: 'English' },
		{ flag: 'tr', value: 'tr', text: 'Türkçe' },
		{ flag: 'de', value: 'de', text: 'Deutsche' },
		{ flag: 'fr', value: 'fr', text: 'Français' },
		{ flag: 'es', value: 'es', text: 'Español' },
		{ flag: 'pt', value: 'pt', text: 'Português' },
		{ flag: 'ru', value: 'ru', text: 'Pусский' },
	];
	const { i18n } = useTranslation();
	let selectedLang = _.find(langs, { value: i18n.language }) || _.find(langs, { value: 'en' });

	return (
		<Dropdown
			button
			className="icon"
			color="blue"
			floating
			labeled
			icon="world"
			options={langs}
			text={selectedLang.text}
			style={props.style}
			onChange={(e, { value }) => i18n.changeLanguage(value)}
		/>
	);
}

const LoginForm = (props) => {
	const { t } = useTranslation();

	if (props.userObj && props.userObj.isAuthenticated) return <Redirect to={{ pathname: '/' }} />;
	return (
		<div
			css={`
				margin-left: 20px;
				margin-right: 20px;
				display: flex;
				height: 100%;
				align-items: center;
				justify-content: center;
			`}
		>
			<div
				css={`
					max-width: 100%;
					width: 450px;
					display: flex;
					flex-direction: column;
					cd .ui.header {
						margin-top: 10px !important;
						border: 1px solid rgba(34, 36, 38, 0.15);
						border-radius: 0.28571429rem;
						padding: 12px;
					}
				`}
			>
				<div style={{ display: 'flex' }}>
					<ChangeLanguage style={{ background: '#499DF3', color: 'white' }} />
					<Button as={Link} to="/register" style={{ marginLeft: 'auto' }} color="teal" icon labelPosition="left">
						{t('gotoRegisterPage')}
						<Icon name="arrow right" />
					</Button>
				</div>

				<Header as="h2" textAlign="center">
					<Image src={window.CONFIG.icon} style={{ width: '6rem', margin: '10px', background: 'white', borderRadius: 3 }} />
					<div>{t('loginPage')}</div>
				</Header>
				{props.error && <Message error header={'Login Error...'} content="The mail or password is wrong.Please double-check and try again." />}
				<Formik
					initialValues={{ email: '', password: '' }}
					validationSchema={LoginFormValidation}
					onSubmit={(values) => props.loginUser(values.email, values.password)}
					render={(state) => {
						return (
							<Form onSubmit={state.handleSubmit}>
								<TrimmedFormikInput label={t('workEmail')} name="email" iconPosition="left" icon="mail" placeholder="user@mail.com" />
								<FormikInput
									label={t('password')}
									name="password"
									iconPosition="left"
									icon="lock"
									placeholder={t('password')}
									type="password"
								/>
								<p>
									{t('forgotpasswordQ')} <Link to="/forgot">{t('resetpassword')}</Link>
								</p>
								<Button
									loading={props.loading === 'login'}
									type="submit"
									fluid
									style={{ background: '#499DF3', color: 'white', marginTop: '20px' }}
								>
									{t('login')}
								</Button>
								<p>
									<a target="_blank" rel="noopener noreferrer" href={window.CONFIG.tos}>
										{t('termsOfService')}
									</a>{' '}
									{t('and')}{' '}
									<a target="_blank" rel="noopener noreferrer" href={window.CONFIG.privacy}>
										{t('privacyPolicy')}.
									</a>
								</p>
								{/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
							</Form>
						);
					}}
				/>
			</div>
		</div>
	);
};

export const ForgetPasswordPage = (props) => {
	const { t } = useTranslation();
	const [sending, setSending] = React.useState(false);
	const [redirect, setRedirect] = React.useState(false);
	const [err, setError] = React.useState(null);
	const handleSubmitForgot = (values) => {
		if (sending) return;
		setSending(true);
		post('/mailer/resetPassword', {
			from: 'live',
			email: values.email,
		})
			.then((data) => {
				setSending(false);
				if (data.data.success) {
					setRedirect(values.email);
				} else setError(data.data.message || 'Error..!');
			})
			.catch((err) => {
				setSending(false);
				setError(err.message);
			});
	};
	if (redirect) return <Redirect to={{ pathname: '/forgot/checkyourmail', search: '?mail=' + redirect }} />;
	return (
		<div
			css={`
				margin-left: 20px;
				margin-right: 20px;
				display: flex;
				height: 100%;
				align-items: center;
				justify-content: center;
			`}
		>
			<div
				css={`
					max-width: 100%;
					width: 450px;
					display: flex;
					flex-direction: column;
					cd .ui.header {
						margin-top: 10px !important;
						border: 1px solid rgba(34, 36, 38, 0.15);
						border-radius: 0.28571429rem;
						padding: 12px;
					}
				`}
			>
				<div style={{ display: 'flex' }}>
					<ChangeLanguage style={{ background: '#499DF3', color: 'white' }} />
					<Button as={Link} to="/login" style={{ marginLeft: 'auto' }} color="teal" icon labelPosition="left">
						<Icon name="arrow left" />
						{t('gotoLoginPage')}
					</Button>
				</div>
				<Header as="h2" textAlign="center">
					<div>{t('forgotPassword')}</div>
					<Image src={window.CONFIG.icon} style={{ width: '6rem', margin: '10px' }} />
					<div style={{ fontSize: '18px' }}>
						<p style={{ margin: 0 }}>{window.CONFIG.title}</p>
					</div>
				</Header>
				{err && <Message error header={err} content={t('forgotPasswordError')} />}
				<Formik
					initialValues={{ email: '' }}
					validationSchema={ForgotFormValidation}
					onSubmit={handleSubmitForgot}
					render={(state) => {
						const { handleSubmit } = state;
						return (
							<Form onSubmit={handleSubmit}>
								<TrimmedFormikInput label={t('workEmail')} name="email" iconPosition="left" icon="mail" placeholder="user@mail.com" />
								<Button className="d-button" loading={sending} type="submit" fluid style={{ background: '#499DF3', color: 'white' }}>
									{t('sendMail')}
								</Button>
								{/*<pre>{JSON.stringify(state, null, 2)}</pre>*/}
							</Form>
						);
					}}
				/>
			</div>
		</div>
	);
};
export const ResetPasswordPage = (props) => {
	const { t } = useTranslation();
	const [sending, setSending] = React.useState(false);
	const [redirect, setRedirect] = React.useState(false);

	const [setError] = React.useState(null);
	//TODO ERROR
	const handleSubmitReset = (values) => {
		if (sending) return;
		setSending(true);
		post('/verify/resetPassword', {
			password: values.password,
			token: window.location.pathname.replace('/verify/resetPassword/', ''),
		})
			.then((data) => {
				setSending(false);
				if (data.data.success) {
					setRedirect(values.password);
				} else setError(data.data.message || 'Error..!');
			})
			.catch((err) => {
				setSending(false);
				setError(err.message);
			});
	};
	if (redirect) return <Redirect to={{ pathname: '/login' }} />;
	return (
		<div
			css={`
				margin-left: 20px;
				margin-right: 20px;
				display: flex;
				height: 100%;
				align-items: center;
				justify-content: center;
			`}
		>
			<div
				css={`
					max-width: 100%;
					width: 450px;
					display: flex;
					flex-direction: column;
					cd .ui.header {
						margin-top: 10px !important;
						border: 1px solid rgba(34, 36, 38, 0.15);
						border-radius: 0.28571429rem;
						padding: 12px;
					}
				`}
			>
				<div style={{ display: 'flex' }}>
					<ChangeLanguage style={{ background: '#499DF3', color: 'white' }} />
					<Button as={Link} to="/login" style={{ marginLeft: 'auto' }} color="teal" icon labelPosition="left">
						{t('gotoLoginPage')}
						<Icon name="arrow right" />
					</Button>
				</div>
				<Header as="h2" textAlign="center">
					<div>{t('resetpassword')}</div>
					<Image src={window.CONFIG.icon} style={{ width: '6rem', margin: '10px' }} />
					<div style={{ fontSize: '18px' }}>
						<p style={{ margin: 0 }}>{window.CONFIG.title}</p>
					</div>
				</Header>
				<Formik
					initialValues={{ password: '', passwordConfirmation: '' }}
					validationSchema={ResetFormValidation}
					onSubmit={handleSubmitReset}
					render={(state) => {
						const { handleSubmit } = state;
						return (
							<Form onSubmit={handleSubmit}>
								<FormikInput
									label={t('newPassword')}
									name="password"
									iconPosition="left"
									icon="key"
									placeholder={t('enterNewPassword')}
									type="password"
								/>
								<FormikInput
									label={t('confirmPassword')}
									name="passwordConfirmation"
									iconPosition="left"
									icon="key"
									placeholder={t('enterNewPasswordAgain')}
									type="password"
								/>
								<Button loading={sending} type="submit" fluid style={{ background: '#499DF3', color: 'white' }}>
									{t('changePassword')}
								</Button>
								{/*<pre>{JSON.stringify(state, null, 2)}</pre>*/}
							</Form>
						);
					}}
				/>
			</div>
		</div>
	);
};

export const VerifyPage = (props) => {
	const { t } = useTranslation();
	//const [sending, setSending] = React.useState(false);
	//const [redirect, setRedirect] = React.useState(false);
	const [setError] = React.useState(null);
	//TODO ERROR
	post('/verify/verify', {
		token: window.location.pathname.replace('/verify/account/', ''),
	})
		.then((data) => {
			//setSending(false);
			if (data.data.success) {
				//TODO
			} else setError(data.data.message || 'Error..!');
		})
		.catch((err) => {
			//setSending(false);
		});

	//if (redirect) return <Redirect to={{ pathname: '/login' }} />;
	return (
		<div
			css={`
				margin-left: 20px;
				margin-right: 20px;
				display: flex;
				height: 100%;
				align-items: center;
				justify-content: center;
			`}
		>
			<div
				css={`
					max-width: 100%;
					width: 450px;
					display: flex;
					flex-direction: column;
					cd .ui.header {
						margin-top: 10px !important;
						border: 1px solid rgba(34, 36, 38, 0.15);
						border-radius: 0.28571429rem;
						padding: 12px;
					}
				`}
			>
				<div style={{ display: 'flex' }}>
					<ChangeLanguage style={{ background: '#499DF3', color: 'white' }} />
					<Button as={Link} to="/login" style={{ marginLeft: 'auto' }} color="teal" icon labelPosition="left">
						{t('gotoLoginPage')}
						<Icon name="arrow right" />
					</Button>
				</div>
				<Header as="h2" textAlign="center">
					<Image src={window.CONFIG.icon} style={{ width: '6rem', margin: '10px' }} />
					<div style={{ fontSize: '18px' }}>
						<p style={{ margin: 0 }}>{window.CONFIG.title}</p>
					</div>
					<Message positive style={{ fontSize: '16px' }}>
						<p>{t('accountVerified')}</p>
						<p>
							You can <a href="/login">go to console.</a>
						</p>
					</Message>
				</Header>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	userObj: state.access.user,
	error: state.access.error,
	loading: state.access.loading,
});

const mapDispatchToProps = (dispatch) => ({
	getProfile: () => dispatch(getProfile()),
	logoutUser: () => dispatch(logout()),
	loginUser: (email, password) => dispatch(login(email, password)),
	toggleLogin: (newState) => dispatch(toggleLogin(newState)),
});
export const LoginPage = connect(mapStateToProps, mapDispatchToProps)(LoginForm);

export const RegisterPage = connect(mapStateToProps, (dispatch) => ({ registerUser: (email, password, name) => dispatch(register(email, password, name)) }))(
	RegisterForm
);
