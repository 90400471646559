import React, { Component } from 'react';
import { Table, Tab, Segment, Button, Grid, Label, Divider, Popup, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import { post } from '../../util/api';
import moment from 'moment';
import Picker from '../../components/DateRangePicker';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CardWrap = (props) => (
	<Grid.Column width={props.width}>
		<Segment textAlign="center" style={{ paddingTop: 40, height: 200 }} size="massive">
			<Label size="large" color={props.color} attached="top">
				{props.title}
			</Label>
			<div style={{ fontSize: '16px' }}>{props.data}</div>
			<Divider />
			<div style={{ fontSize: '16px' }}>{props.data_two}</div>
			{props.data_two ? <Divider /> : ''}
		</Segment>
	</Grid.Column>
);

class Admin extends Component {
	state = {
		column: null,
		direction: null,
		loading: true,
		start_date: moment().subtract(30, 'day').startOf('day'),
		end_date: moment().subtract(1, 'days').endOf('day'),
		today: moment(),
		s_start_date: moment().subtract(60, 'day').startOf('day'),
		s_end_date: moment().subtract(31, 'day').endOf('day'),
	};
	componentDidMount() {
		this.getData();
	}
	componentDidUpdate(oldProps, oldState) {
		const check_list = ['start_date', 'end_date', 'selectedAgent', 'selectedDate', 'value'];
		for (let i = 0; i < check_list.length; i++) {
			const key = check_list[i];
			if (key === 'start_date' || key === 'end_date') {
				if (this.props[key] !== oldProps[key]) {
					this.getData();
					break;
				}
			}
			if (this.state[key] !== oldState[key]) {
				this.getData();
				break;
			}
		}
	}
	async getData() {
		let { start_date, end_date, s_start_date, s_end_date } = this.state;
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				compare: {
					start_date: s_start_date,
					end_date: moment(s_end_date).subtract(1, 'millisecond'),
					keys: ['count', 'sum', 'len'],
				},
				filter: {
					type: ['orderCreated', 'orderCanceled', 'orderRefunded'],
				},
				select: [],
				group: ['pid', 'type'],
				pairs: {
					agent: 'select5',
					kod: 'select2',
				},
				options: {
					sum: 1,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ ecommerce_data: data.result });
			});
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				compare: {
					start_date: s_start_date,
					end_date: moment(s_end_date).subtract(1, 'millisecond'),
					keys: ['count'],
				},
				start_date: start_date,
				end_date: end_date,
				filter: {
					type: ['closed'],
				},
				select: [],
				group: ['pid', 'type'],
				pairs: {},
				options: {},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					rate: _.filter(data.result, function (it) {
						return it.rate;
					}),
					closed_data: data.result,
				});
			});
		post('/live/admin/dashboard').then((data) => this.setState({ data: _.orderBy(data.data.result, 'owner_id', 'desc'), loading: false }));
	}
	handleSort = (clickedColumn, data) => () => {
		const { column, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				data: _.sortBy(data, [clickedColumn]),
				direction: 'ascending',
			});

			return;
		}

		this.setState({
			data: data.reverse(),
			direction: direction === 'ascending' ? 'descending' : 'ascending',
		});
	};

	TableLineSelling = (data) =>
		_.map(data, (val, index) => (
			<>
				<Table.Row key={index}>
					<Table.Cell>{index + 1}</Table.Cell>
					<Table.Cell>{val && val.owner_email}</Table.Cell>
					<Table.Cell>
						<div className="d-flex">
							<p>{val && val.closed_count}</p>
							<p style={{ fontSize: '12px', marginLeft: '10px', color: 'grey' }}>{val.closed_compare ? '(% ' + val.closed_compare + ')' : ''}</p>
						</div>
					</Table.Cell>
					<Table.Cell>{val && val.closed_count > 0 ? ((parseFloat(val.orderCreated_len) / val.closed_count) * 100).toFixed(2) + '%' : 0}</Table.Cell>
					<Table.Cell>
						<div className="d-flex">
							<p>{val && val.orderCreated_len}</p>
							<p style={{ fontSize: '12px', marginLeft: '10px', color: 'grey' }}>
								{val.orderCreated_compare && val.orderCreated_compare.len ? '(% ' + val.orderCreated_compare.len.change + ')' : ''}
							</p>
						</div>
					</Table.Cell>
					<Table.Cell>
						<div className="d-flex">
							<p>{val && val.orderCreated_count}</p>
							<p style={{ fontSize: '12px', marginLeft: '10px', color: 'grey' }}>
								{val.orderCreated_compare && val.orderCreated_compare.count ? '(% ' + val.orderCreated_compare.count.change + ')' : ''}
							</p>
						</div>
					</Table.Cell>
					<Table.Cell>
						<div className="d-flex">
							<p>{val && val.orderCreated_sum && val.orderCreated_sum.toLocaleString('tr-TR', { style: 'currency', currency: val.currency })}</p>
							<p style={{ fontSize: '12px', marginLeft: '10px', color: 'grey' }}>
								{val.orderCreated_compare && val.orderCreated_compare.sum ? '(% ' + val.orderCreated_compare.sum.change + ')' : ''}
							</p>
						</div>
					</Table.Cell>
					<Table.Cell>
						<CopyToClipboard text={val.pid}>
							<Button icon size="tiny" color="teal">
								<Icon name="copy" style={{ margin: 0 }}></Icon>
							</Button>
						</CopyToClipboard>
					</Table.Cell>
					{/* 	<Table.Cell>{val && val.orderCanceled_len}</Table.Cell>
				<Table.Cell>{val && val.orderCanceled_count}</Table.Cell> */}
					{/* 	<Table.Cell>{val && val.orderRefunded_len}</Table.Cell>
				<Table.Cell>{val && val.orderRefunded_count}</Table.Cell> */}
				</Table.Row>
				{/* {val.orderCreated_compare ? (
					<Table.Row key={index}>
						<Table.Cell></Table.Cell>
						<Table.Cell></Table.Cell>
						<Table.Cell></Table.Cell>
						<Table.Cell></Table.Cell>
						<Table.Cell>{val.orderCreated_compare.len.from}</Table.Cell>
						<Table.Cell>{val.orderCreated_compare.count.from}</Table.Cell>
						<Table.Cell>{val.orderCreated_compare.sum.from.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })} </Table.Cell>
					</Table.Row>
				) : (
					''
				)} */}
			</>
		));

	dateChange = ({ selection }) => {
		let start_date = moment(selection.startDate).startOf('day');
		let end_date = moment(selection.endDate).endOf('day');
		if (end_date.format('YYYY-MM-DD') !== this.state.today.format('YYYY-MM-DD')) {
			end_date = end_date.endOf('day');
		} else {
			end_date = moment();
		}
		let dayDiff = start_date.diff(end_date, 'days');
		dayDiff = dayDiff - 1;
		let s_end_date = start_date;
		let s_start_date = moment(s_end_date).add(dayDiff, 'days');
		let s_diff = moment(s_start_date).diff(s_end_date, 'days');
		if (s_diff === 0) {
			s_start_date = moment(s_start_date).add(-1, 'days');
		}
		this.setState({ start_date, end_date, s_start_date, s_end_date });
	};
	render() {
		let { loading, data, column, direction, ecommerce_data, closed_data, start_date, end_date } = this.state;

		let createdData = _.filter(ecommerce_data, { type: 'orderCreated' });
		let dg = _.map(data, function (it) {
			it = {
				agent_limit: it.agent_limit || 0,
				phone_limit: it.phone_limit || 0,
				instagram_limit: it.instagram_limit || 0,
				owner_id: it.owner_id,
				owner_name: it.owner_name,
				is_reseller: it.is_reseller,
				is_enterprise: it.is_enterprise,
				is_eticaret: it.is_eticaret,
				owner_email: it.owner_email,
				pid: it.pid,
				token: it.token,
				agents: it.agents,
				phones: it.phones,
				instagrams: it.instagrams,
				package: it.package,
				verified: it.verified,
				total_agent: it.total_agent,
				wapi_pid: it.wapi_pid,
				wapi_token: it.wapi_token,
				analyticview: it.analyticview,
				userlist: (it.analyticview && it.analyticview.userlist) || false,
				ecommerce: (it.analyticview && it.analyticview.ecommerce) || false,
				ratePoint: (it.analyticview && it.analyticview.ratePoint) || false,
				sendProduct: (it.analyticview && it.analyticview.sendProduct) || false,
				showTemplate: (it.analyticview && it.analyticview.showTemplate) || false,
				conversationGroup: (it.analyticview && it.analyticview.conversationGroup) || false,
				orderRefunded_count: 0,
				orderRefunded_len: 0,
				orderCanceled_count: 0,
				orderCanceled_len: 0,
				test_account: it.test_account || false,
				currency: it.currency || 'try',
			};
			return it;
		});
		_.map(ecommerce_data, function (it) {
			let e = _.find(dg, { token: it.pid });
			let e_data = {
				[it.type + '_count']: it.count || 0,
				[it.type + '_len']: parseFloat(it.len) || 0,
				[it.type + '_sum']: it.sum || 0,
				[it.type + '_compare']: it.compare,
			};
			if (e) {
				return _.assignWith(e, e_data);
			}
		});
		let sellingData = _.filter(dg, function (it) {
			return it.orderCreated_count && !it.test_account;
		});
		_.map(sellingData, function (it) {
			let e = _.find(closed_data, { pid: it.token });
			let e_data = {
				closed_count: (e && e.count) || 0,
				closed_compare: (e && e.compare && e.compare.count && e.compare.count.change) || 0,
			};
			if (e) {
				return _.assignWith(it, e_data);
			}
		});
		let total_selling_closed = _.sumBy(sellingData, 'closed_count');
		let closed_selling_rate = total_selling_closed > 0 ? ((_.sumBy(createdData, (item) => Number(item.len)) / total_selling_closed) * 100).toFixed(2) : 0;
		let trySellingData = _.filter(sellingData, { currency: 'try' });
		let eurSellingData = _.filter(sellingData, { currency: 'eur' });
		let gydSellingData = _.filter(sellingData, { currency: 'gyd' });
		let total_price =
			(_.sumBy(trySellingData, 'orderCreated_sum') &&
				_.sumBy(trySellingData, 'orderCreated_sum').toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })) ||
			0;
		let total_price_eur =
			(_.sumBy(eurSellingData, 'orderCreated_sum') &&
				_.sumBy(eurSellingData, 'orderCreated_sum').toLocaleString('tr-TR', { style: 'currency', currency: 'EUR' })) ||
			0;
		let total_price_gyd =
			(_.sumBy(gydSellingData, 'orderCreated_sum') &&
				_.sumBy(gydSellingData, 'orderCreated_sum').toLocaleString('tr-TR', { style: 'currency', currency: 'GYD' })) ||
			0;
		let created_compare_data = _.filter(trySellingData, function (it) {
			return it.orderCreated_compare;
		});
		let created_compare_data_eur = _.filter(eurSellingData, function (it) {
			return it.orderCreated_compare;
		});
		let created_compare_data_gyd = _.filter(gydSellingData, function (it) {
			return it.orderCreated_compare;
		});
		let total_price_past =
			(_.sumBy(created_compare_data, function (it) {
				return it.orderCreated_compare.sum.from;
			}) &&
				_.sumBy(created_compare_data, function (it) {
					return it.orderCreated_compare.sum.from;
				}).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })) ||
			0;
		let total_price_past_eur =
			(_.sumBy(created_compare_data_eur, function (it) {
				return it.orderCreated_compare.sum.from;
			}) &&
				_.sumBy(created_compare_data_eur, function (it) {
					return it.orderCreated_compare.sum.from;
				}).toLocaleString('tr-TR', { style: 'currency', currency: 'EUR' })) ||
			0;
		let total_price_past_gyd =
			(_.sumBy(created_compare_data_gyd, function (it) {
				return it.orderCreated_compare.sum.from;
			}) &&
				_.sumBy(created_compare_data_gyd, function (it) {
					return it.orderCreated_compare.sum.from;
				}).toLocaleString('tr-TR', { style: 'currency', currency: 'GYD' })) ||
			0;

		if (loading) return <Segment attached="bottom">loading...</Segment>;

		return (
			<Tab.Pane attached={true} style={{ maxHeight: '95%', overflowX: 'auto' }}>
				<Popup
					popperModifiers={{
						preventOverflow: { enabled: false },
					}}
					trigger={
						<Button
							primary
							style={{ marginRight: '10px' }}
							content={`${moment(start_date).format('MMM DD, YYYY')} - ${moment(end_date).format('MMM DD, YYYY')}`}
							floated="right"
						/>
					}
					content={<Picker dateChange={this.dateChange} startDate={start_date.toDate()} endDate={end_date.toDate()} lang="en" />}
					on="click"
					position="bottom right"
					flowing
				/>
				<Grid stackable columns={2} style={{ width: '100%', margin: 0 }}>
					<CardWrap
						width={3}
						title={'Total Selling Count'}
						color="green"
						data={'Product Count: ' + _.sumBy(createdData, 'count')}
						data_two={'Order Count: ' + _.sumBy(createdData, (item) => Number(item.len))}
					/>
					<CardWrap width={3} title={'Total Selling Price(₺)'} color="blue" data={total_price} data_two={'Before: ' + total_price_past} />
					<CardWrap width={3} title={'Total Selling Price(€)'} color="purple" data={total_price_eur} data_two={'Before: ' + total_price_past_eur} />
					<CardWrap width={3} title={'Total Selling Price(GYD)'} color="red" data={total_price_gyd} data_two={'Before: ' + total_price_past_gyd} />
					<CardWrap width={3} title={'Selling Closed Rate'} color="yellow" data={'%' + closed_selling_rate} />
				</Grid>
				<div style={{ maxHeight: '500px', overflowY: 'auto' }}>
					<Table celled sortable>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>#</Table.HeaderCell>
								{/* <Table.HeaderCell sorted={column === 'pid' ? direction : null} onClick={this.handleSort('pid', sellingData)}>
													pid
												</Table.HeaderCell> */}
								<Table.HeaderCell sorted={column === 'owner_email' ? direction : null} onClick={this.handleSort('owner_email', sellingData)}>
									Email
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'closed_count' ? direction : null} onClick={this.handleSort('closed_count', sellingData)}>
									Closed
								</Table.HeaderCell>
								<Table.HeaderCell>Seling/Closed Rate</Table.HeaderCell>
								<Table.HeaderCell
									sorted={column === 'orderCreated_count' ? direction : null}
									onClick={this.handleSort('orderCreated_count', sellingData)}
								>
									Created Order Count
								</Table.HeaderCell>
								<Table.HeaderCell
									sorted={column === 'orderCreated_len' ? direction : null}
									onClick={this.handleSort('orderCreated_len', sellingData)}
								>
									Created Product Count
								</Table.HeaderCell>
								<Table.HeaderCell
									sorted={column === 'orderCreated_sum' ? direction : null}
									onClick={this.handleSort('orderCreated_sum', sellingData)}
								>
									Created Sum
								</Table.HeaderCell>
								<Table.HeaderCell></Table.HeaderCell>
								{/* <Table.HeaderCell
													sorted={column === 'orderCanceled_count' ? direction : null}
													onClick={this.handleSort('orderCanceled_count', sellingData)}
												>
													Canceled Order Count
												</Table.HeaderCell>
												<Table.HeaderCell
													sorted={column === 'orderCanceled_len' ? direction : null}
													onClick={this.handleSort('orderCanceled_len', sellingData)}
												>
													Canceled Product Count
												</Table.HeaderCell> */}
								{/*<Table.HeaderCell
													sorted={column === 'orderCanceled_sum' ? direction : null}
													onClick={this.handleSort('orderCanceled_sum', sellingData)}
												>
													Canceled Sum
												</Table.HeaderCell> */}
								{/* <Table.HeaderCell
													sorted={column === 'orderRefunded_count' ? direction : null}
													onClick={this.handleSort('orderRefunded_count', sellingData)}
												>
													Refunded Order Count
												</Table.HeaderCell>
												<Table.HeaderCell
													sorted={column === 'orderRefunded_len' ? direction : null}
													onClick={this.handleSort('orderRefunded_len', sellingData)}
												>
													Refunded Product Count
												</Table.HeaderCell> */}
								{/* <Table.HeaderCell
													sorted={column === 'orderRefunded_sum' ? direction : null}
													onClick={this.handleSort('orderRefunded_sum', sellingData)}
												>
													Refunded Sum
												</Table.HeaderCell> */}
							</Table.Row>
						</Table.Header>
						<Table.Body>{this.TableLineSelling(sellingData)}</Table.Body>
					</Table>
				</div>
			</Tab.Pane>
		);
	}
}
export default Admin;
