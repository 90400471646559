import React from 'react';
import Page from '../../components/Page';
import { Message, Icon, Dropdown, Label, Button, Table, Header, Responsive, Popup, Modal } from 'semantic-ui-react';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import { get, post } from '../../util/api';
import { MaterialInput } from '../../components/Helpers';
//import { Goto } from '../../components/Url';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const status = {
	active: { text: 'Active', color: 'green' },
	hidden: { text: 'Hidden', color: 'grey' },
	disabled: { text: 'Disabled', color: 'grey' },
};

const DateLabel = styled(Label)`
	margin: 0 0 5px 0 !important;
	width: 100%;
	text-align: right;
	.detail {
		text-align: right;
		width: 40px;
	}
`;

const getName = (data = {}) => (data && data.data && data.data.info && data.data.info.title) || `action #${data.id}`;
class ActionList extends React.Component {
	state = {
		loading: true,
		template_modal: false,
		results: [],
		search: '',
		searching: false,
		remove: false,
		column: null,
		data: '',
		direction: null,
		open: false,
		developer: (localStorage && localStorage.developer) || false,
	};
	_first = React.createRef();
	handleSort = (clickedColumn) => () => {
		const { column, data, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				data: _.sortBy(data, [clickedColumn]),
				direction: 'ascending',
			});
			return;
		}
		this.setState({
			data: data.reverse(),
			direction: direction === 'ascending' ? 'descending' : 'ascending',
		});
	};
	componentDidMount() {
		this.getList();
	}
	async getList() {
		let data = await get('/ext/apps/list');
		let actiondata = await get('/ext/apps/all');
		let list = data.data || [];
		let actionList = actiondata.data;
		list = list && list.length && _.filter(list, (it) => it.data.payment_status !== 'deleted');
		this.setState({ loading: false, list, results: _.fill(Array(list.length), true), actionList });
	}
	onChange = (e, { name, value }) => {
		if (name === 'search') {
			this.setState({ search: value, searching: true });

			clearTimeout(this._search);
			if (!value) return this.setState({ searching: false, search: '', results: _.fill(Array(this.state.list.length), true) });
			else this.setState({ search: value, searching: true });
			this._search = setTimeout(() => {
				const re = new RegExp(_.escapeRegExp(value), 'i');
				const isMatch = (result) => re.test(result.name) || re.test(result.id);

				this.setState({
					searching: false,
					results: _.map(this.state.list, isMatch),
				});
			}, 300);
		} else this.setState({ [name]: value });
	};

	_toggle = async (e, data) => {
		e.stopPropagation();
		e.preventDefault();
		let { value, index } = data;
		if (!value) return;
		let it;
		this.setState({
			list: _.map(this.state.list, (data, i) => {
				if (i !== index) return data;
				it = data;
				data.statusLoading = true;
				return data;
			}),
		});
		it.data.status = value;
		let obj = _.omit(it, ['statusLoading']);
		await post('/ext/apps/save/' + it.id, obj).then(() => {
			this.setState({
				list: _.map(this.state.list, (data, i) => {
					if (i !== index) return data;
					data.statusLoading = false;
					data.status = value;
					return data;
				}),
			});
		});
		await post(`/ext/apps/build`, { pid: this.props.product.id }).then((response) => console.log('ss', response));
	};
	handleRemove = async () => {
		let selected = this.state.selected;
		this.setState({ remove: true });
		await post('/campaign/delete', {
			id: selected.id,
		}).then(() => {
			this.close();
			this.getList();
			let newlist = _.filter(this.state.list, function (it) {
				return it.id !== selected.id;
			});
			let is_recomentation = !!_.filter(newlist, function (it) {
				return it._type === 'recommendation';
			}).length;
			if (!is_recomentation) {
				post('/personalize/recommendationSave', { force: false }).then((data) => {});
			}
		});
	};
	closeConfigShow = (id) => () => {
		let selected = _.find(this.state.list, { id });
		this.setState({ open: true, remove: false, selected });
	};
	close = () => this.setState({ open: false });
	inside() {
		let { t } = this.props;
		//let productType = product.type;
		let { actionList } = this.state;
		if (this.state.loading) return <Page.Loader />;
		const len = this.state.list.length;
		//let ecommerceType = 'ticimax'; //product.type;
		const checkActive = _.findIndex(this.state.list, { status: 'active' });
		const { open, results } = this.state;
		const options = [
			{ key: 'active', icon: 'check', text: t('Active'), value: 'active' },
			//	{ key: 'hidden', icon: 'hide', text: 'Hidden', value: 'hidden' },
			{ key: 'disabled', icon: 'close', text: t('Disabled'), value: 'disabled' },
		];
		if (len)
			return (
				<Page.Wrap>
					<MaterialInput
						value={this.state.search}
						onChange={this.onChange}
						name="search"
						loading={this.state.searching}
						icon="search"
						placeholder={t('Search')}
					/>
					<Modal open={open} onClose={this.close}>
						<Header icon="trash" content={`${t('Delete')} ${getName(this.state.selected)}`} />
						<Modal.Content>
							<p>{t('deleteAction')}</p>
						</Modal.Content>
						<Modal.Actions>
							<Button color="red" onClick={this.close}>
								<Icon name="remove" /> {t('no')}
							</Button>
							<Button loading={this.state.remove} color="green" onClick={this.handleRemove}>
								<Icon name="checkmark" /> {t('yes')}
							</Button>
						</Modal.Actions>
					</Modal>
					<Table selectable compact>
						<Responsive as={Table.Header} minWidth={Responsive.onlyTablet.minWidth}>
							<Table.Row>
								<Table.HeaderCell width="6">{t('Name')}</Table.HeaderCell>
								<Table.HeaderCell width="2">{t('Type')}</Table.HeaderCell>
								<Table.HeaderCell width="5">{t('Updated')}</Table.HeaderCell>
								<Table.HeaderCell width="3" textAlign="center">
									{t('Status')}
								</Table.HeaderCell>
							</Table.Row>
						</Responsive>
						<Table.Body>
							{_.includes(results, true) ? (
								_.map(this.state.list, (data, index) => {
									if (!results[index]) {
										return null;
									}
									let selectedApp = _.find(actionList, { id: data.data.ext_id });
									return (
										<Table.Row key={data.id}>
											<Table.Cell selectable>
												<Link
													css={`
														text-transform: capitalize;
														@media (max-width: ${Responsive.onlyTablet.minWidth - 1}px) {
															padding-left: 0 !important;
														}
													`}
													to={`/appstore/app/edit/${data.id}`}
												>
													{getName(data)}
													{/* 	{data._type === 'schedule' ||
													(_.includes(actionList,)? (
														''
													) : (
														<Popup
															content={t('thisActionDontUseType', { type: productType })}
															trigger={<Icon color="red" name="warning sign" style={{ marginLeft: '10px' }} />}
														/>
													)} */}
												</Link>
												<Responsive
													{...Responsive.onlyMobile}
													css={`
														display: flex;
														flex-direction: row;
														align-items: center;
														justify-content: space-between;
														.ui.label {
															margin: 0 !important;
														}
													`}
												>
													<Link
														css={`
															padding-left: 0 !important;
															color: #333;
														`}
														to={`/appstore/app/${data.id}`}
													>
														Updated: {moment(data.updated).format('LL - HH:mm')}
													</Link>
													<Dropdown
														onChange={this._toggle}
														size="tiny"
														direction="left"
														index={index}
														options={options}
														value={data.status || 'active'}
														icon={false}
														trigger={
															<DateLabel color={status[data.status || 'active'].color} size="tiny">
																{data.statusLoading && <Icon name="asterisk" loading />}
																{t('' + status[data.status || 'active'].text)}
															</DateLabel>
														}
													/>
													<Button
														color="red"
														icon="trash"
														alt={data.id}
														onClick={this.closeConfigShow(data.id)}
														style={{ margin: '0 0 0 4px', fontSize: '10px', padding: '.5833em .833em' }}
													/>
												</Responsive>
											</Table.Cell>
											<Table.Cell>{data._type === 'schedule' ? t('Schedule') : t('Action')}</Table.Cell>
											<Responsive as={Table.Cell} minWidth={Responsive.onlyTablet.minWidth}>
												{moment(data.updated_at).format('LL - HH:mm')}
											</Responsive>
											<Responsive as={Table.Cell} minWidth={Responsive.onlyTablet.minWidth} textAlign="right">
												{index === 0 && (
													<Popup
														header={t('dontHaveActiveCampaign')}
														content={t('ClickToActivate')}
														open={checkActive === -1 && data.status === 'disabled'}
														position="top center"
														style={{ zIndex: 1 }}
														context={this._first}
													/>
												)}
												<Dropdown
													onChange={this._toggle}
													size="big"
													direction="left"
													index={index}
													options={options}
													value={data.data.status || 'active'}
													icon={false}
													trigger={
														<Button size="tiny" color={status[data.data.status || 'active'].color}>
															{data.statusLoading && <Icon name="asterisk" loading />}
															{t('' + status[data.data.status || 'active'].text)}
															{index === 0 && <span className="ref-span" ref={this._first} />}
														</Button>
													}
												/>

												<Button color="red" icon="trash" size="tiny" alt={data.id} onClick={this.closeConfigShow(data.id)} />
											</Responsive>
										</Table.Row>
									);
								})
							) : (
								<Table.Row>
									<Table.Cell style={{ textAlign: 'center' }} colSpan="3">
										{t('NoDataShowing')}
									</Table.Cell>
								</Table.Row>
							)}
						</Table.Body>
					</Table>

					{/* <Segment
						textAlign="center"
						css={`
							width: 600px;
							max-width: 100%;
							margin: 0 auto !important;
						`}
					>
						<p>{t('WatchTutorial')}</p>
						<Link to="/help/videos">
							<Icon name="video" /> {t('GotoTutorials')}
						</Link>
					</Segment> */}
				</Page.Wrap>
			);
		return (
			<Page.Wrap>
				<Message
					css={`
						text-align: center;
					`}
					info
				>
					<Message.Header>{t('dontHaveActiveCampaign')}</Message.Header>
					<br></br>
					<p>
						<Button as={Link} to="/appstore" primary>
							{t('CreateAction')}
						</Button>
					</p>
				</Message>
				{/* <div
					css={`
						text-align: center;
						margin-top: 60px;
					`}
				>
					<h3>{t('GetStartedWithCollectAction')}</h3>
					<div
						css={`
							max-width: 100%;
							width: 720px;
							margin: 10px auto;
						`}
					>
						<Embed
							css={`
								border-radius: 6px;
							`}
							iframe={{
								allowFullScreen: true,
								style: {},
								src:
									'//www.youtube.com/embed/uVtG0CXye1c?autohide=true&amp;amp;autoplay=0&amp;amp;color=%23444444&amp;amp;hq=true&amp;amp;jsapi=false&amp;amp;modestbranding=false&amp;amp;rel=1',
							}}
							defaultActive
						/>
					</div>
					<p style={{ marginTop: 50 }}>
						<h4>{t('CheckTutorialsPage')}</h4>
						<Goto to={`/help/videos`}>
							<Button primary>
								<Icon name="video" />
								{t('GotoTutorials')}
							</Button>
						</Goto>
					</p>
				</div>
		 */}{' '}
			</Page.Wrap>
		);
	}

	openModal = () => {
		this.setState({ template_modal: true });
	};

	render() {
		return this.inside();
	}
}

export default connect((state) => ({ user: state.access.user.loggedUserObj, product: state.product.live }))(withTranslation()(ActionList));
