import React, { Component } from 'react';
import Page from '../../components/Page';
import { connect } from 'react-redux';
import { Button, Table } from 'semantic-ui-react';
import { log } from '../../util/api';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';

class HistoryTable extends Component {
	state = {
		targets: [],
		loading: true,
	};
	componentDidMount() {
		this.getLogs();
	}

	async getLogs() {
		let { product } = this.props;
		let pid = product.id;
		await log('/live/groupMessageLog/all', { pid }).then((data) => {
			let response = data.data && data.data.message;
			let filtered_res = _.filter(response, (it) => it.log.type === 'valid' && it.log.data);
			this.setState({ logData: _.orderBy(filtered_res, ['date'], ['desc']), loading: false });
		});
	}
	closeModal = () => {
		this.setState({ openModal: false, startedSend: false });
	};
	closeModalSent = () => {
		this.setState({ openModalSend: false, startedSend: false });
	};
	exportExcel = async (data, date) => {
		this.setState({ buttonLoading: true });
		let { product } = this.props;
		let pid = product.id;
		//return;
		let excel_data = _.map(data, (o) => {
			return {
				full_mobile_number: o.input,
				user_id: o.user_id,
				status: o.status,
			};
		});
		//console.log(excel_data, selectedDate);
		let sheets = [
			{
				name: 'validation_result',
				x: ['full_mobile_number', 'user_id', 'status'],
				datasets: excel_data,
			},
		];
		await fetch('https://log.yapaytech.com/analytic/download', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				filename: moment(date, 'DD-MM-YYYY HH:mm').format('DD_MM_YYYY_HH_mm') + '_validation_result_' + pid,
				sheets,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let dataurl = data.url;
				var a = document.createElement('a');
				a.href = dataurl;
				a.setAttribute('download', dataurl);
				a.click();
				this.setState({ buttonLoading: false });
			});
	};

	render() {
		let { logData, loading } = this.state;
		let { t } = this.props;
		if (loading) return <Page.Loader></Page.Loader>;

		return (
			<Table>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>{t('date')}</Table.HeaderCell>
						<Table.HeaderCell>{t('Phones')}</Table.HeaderCell>
						<Table.HeaderCell width={2} textAlign="right"></Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{_.map(logData, ({ log, date }) => {
						return (
							<Table.Row key={date}>
								<Table.Cell>{log.date}</Table.Cell>
								<Table.Cell>{log && log.data && log.data.length}</Table.Cell>
								<Table.Cell>
									<Button
										icon
										positive
										onClick={() => this.exportExcel(log.data, log.date)}
										loading={this.state.buttonLoading}
										disabled={this.state.buttonLoading}
									>
										{t('DownloadExcel')}
									</Button>
								</Table.Cell>
							</Table.Row>
						);
					})}
				</Table.Body>
			</Table>
		);
	}
}

const stateToProps = (state) => ({
	access: state.access,
	location: state.router.location,
	product: state.product.live,
	user: state.access.user.loggedUserObj,
});
export default connect(stateToProps)(withTranslation()(HistoryTable));
