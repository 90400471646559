import * as ActionTypes from '../constants/actionTypes';

export const load_product = (product_type = 'live') => ({
	type: ActionTypes.PRODUCT_REQUESTED,
	product_type,
});

export const select_product = product => ({
	type: ActionTypes.PRODUCT_UPDATED,
	product,
});
