import React, { Component } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import 'react-intl-tel-input/dist/main.css';
import { withTranslation } from 'react-i18next';
import Page from '../../../components/Page';
import { Link } from 'react-router-dom';
class PlatformModal extends Component {
	state = {
		selectedPlatform: '',
		activePage: 1,
		btnLoading: false,
	};

	render() {
		let { t, user } = this.props;
		const roleAgent = user && user.role === 'agent';
		return (
			<Page.Wrap>
				<Segment>
					<Header>{t('help')}</Header>
					<div className="help">
						<Link to="/appstore/help/whatsapp">
							<Segment className="main-step">
								<div className="title">1. Whatsapp Business</div>
								<div className="description">
									<p>Whatsapp Business'ı nasıl kurabilirim, çoklu cihaz desteğini nasıl açabilirim?</p>
								</div>
							</Segment>
						</Link>
						<Link to="/appstore/help/label">
							<Segment className="main-step">
								<div className="title">2. Etiket</div>
								<div className="description">
									<p>Etiketleri nasıl tanımlarım, farklı agent konuşmalarını nasıl ayırabilirim?</p>
								</div>
							</Segment>
						</Link>
						{roleAgent ? null : (
							<Link to="/appstore/help/ecommerce">
								<Segment className="main-step">
									<div className="title">3. Eticaret Entegrasyonu</div>
									<div className="description">
										<p>Eticaret sitemi nasıl bağlayabilirim?</p>
									</div>
								</Segment>
							</Link>
						)}
					</div>
				</Segment>
			</Page.Wrap>
		);
	}
}
const stateToProps = (state) => ({ user: state.access.user.loggedUserObj, product: state.product.live });

export default connect(stateToProps)(withTranslation()(PlatformModal));
