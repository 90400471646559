import React, { Component } from 'react';
import Page from '../../components/Page';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Segment, Icon, Table, Button, Message } from 'semantic-ui-react';
import { getWG, postWg, toWapi } from '../../util/api';
import _ from 'lodash';
import moment from 'moment';

class WTable extends Component {
	state = {
		loading: true,
	};
	async componentDidMount() {
		await getWG('/list').then((data) => {
			this.setState({ list: data.result.folders });
		});
		await this.getListPhone();
	}
	async getListPhone() {
		let { product } = this.props;
		let pid = product.id;
		toWapi(`/api/${pid}/listPhones`, product.settings.wapi_token)
			.then((data) => {
				if (data.status !== 200) {
					return;
				}
				data = _.orderBy(
					_.filter(data.data, function (it) {
						return it.type === 'whatsapp';
					}),
					'id'
				);
				let activePhones = _.filter(data, (it) => it.status === 'active');
				let warningPhones = _.filter(data, (it) => it.status !== 'active');
				this.setState({ phones: data, activePhones, warningPhones, loading: false, btnLoading: false });
			})
			.catch((err) => {
				console.error(err);
			});
	}
	handleSubmit = async (main_folder, sub_folder) => {
		await postWg('/send', {
			main_folder,
			sub_folder,
		}).then((data) => {
			if (data.data.success) {
				this.setState({
					cardType: 'green',
					cardMessage: 'Seçmiş olduğunuz klasör atılmaya başlamıştır.',
				});
				this.componentDidMount();
			} else {
				this.setState({
					cardType: 'red',
					cardMessage: 'Bu klasör daha önce atılmıştır.',
				});
			}
		});
	};

	render() {
		let { loading, list, cardType, cardMessage, phones, activePhones, warningPhones } = this.state;
		if (loading) {
			return (
				<Page title={'WhatsApp Group'} icon="whatsapp">
					<Page.Loader></Page.Loader>
				</Page>
			);
		}
		return (
			<Page title={'WhatsApp Group'} icon="whatsapp">
				<Page.Wrap>
					<div className="wapi-state" style={{ display: 'flex' }}>
						<div className="but">
							<Button
								icon
								color="green"
								basic
								onClick={() => {
									this.setState({ btnLoading: true });
									this.componentDidMount();
								}}
							>
								<Icon name="refresh" loading={this.state.btnLoading} color="white"></Icon>
							</Button>
						</div>
						<div className="logo">
							<div class="wapi-logo" style={{ background: '#25D366' }}>
								<img src="/images/whatsapp.svg" alt="whatsapp" style={{ height: '36px', display: 'flex', padding: '6px' }}></img>
							</div>
						</div>
						<div className="info" style={{ width: '100%', display: 'flex' }}>
							<div className="active-part" style={{ display: 'flex', background: '#d4edda', lineHeight: '36px', color: '#155724' }}>
								<div style={{ marginLeft: '10px', marginRight: '10px' }}>
									<Icon name="check" color="green" /> Aktif {(activePhones && activePhones.length) + ' / ' + (phones && phones.length)}
								</div>
							</div>
							{warningPhones && warningPhones.length ? (
								<div className="warning-part" style={{ display: 'flex', background: '#fff3cd', lineHeight: '36px', color: '#856404' }}>
									<div style={{ marginLeft: '10px', marginRight: '10px' }}>
										<Icon name="warning" color="brown" />{' '}
										{_.map(warningPhones, (it, i) => (
											<span>{it.number + '(' + it.id + '): ' + it.status + (i !== warningPhones.length - 1 ? ', ' : ' ')}</span>
										))}
									</div>
								</div>
							) : (
								''
							)}
							{warningPhones && warningPhones.length ? (
								<div className="goto" style={{ display: 'flex', lineHeight: '36px', background: 'white', color: 'black' }}>
									<div style={{ marginLeft: '10px', marginRight: '10px' }}>
										<Link to="/settings/integrations/whatsapp-web-api" style={{ color: 'black' }}>
											WhatsApp Ayarlarına Git
										</Link>
									</div>
								</div>
							) : (
								''
							)}
							<div className="phonestat" style={{ display: 'flex', lineHeight: '36px', background: '#9696f378', color: 'darkblue' }}>
								<div style={{ marginLeft: '10px', marginRight: '10px' }}>
									<Icon name="mobile alternate" color="darkblue" />{' '}
									<Link to="/wgroup/phones_status" style={{ color: 'darkblue' }}>
										Kuyruk Durumuna Git
									</Link>
								</div>
							</div>
						</div>
					</div>
					<Segment>
						{cardType && <Message color={cardType} content={cardMessage}></Message>}
						{this.state.btnLoading ? (
							<Page.Loader></Page.Loader>
						) : (
							<Table celled>
								<Table.Header>
									<Table.HeaderCell>Ana Klasör</Table.HeaderCell>
									<Table.HeaderCell>Oluşturma Tarihi</Table.HeaderCell>
									<Table.HeaderCell>Detay</Table.HeaderCell>
								</Table.Header>
								<Table.Body>
									{_.map(list, (it, i) => {
										return (
											<Table.Row>
												<Table.Cell>{it.name}</Table.Cell>
												<Table.Cell>{moment(it.msCreate).format('DD-MM-YYYY HH:mm')}</Table.Cell>
												<Table.Cell>
													{
														<Button positive as={Link} to={'/wgroup/' + it.name}>
															Detay
														</Button>
													}
												</Table.Cell>
											</Table.Row>
										);
									})}
								</Table.Body>
							</Table>
						)}
					</Segment>
				</Page.Wrap>
			</Page>
		);
	}
}

const stateToProps = (state) => ({
	access: state.access,
	location: state.router.location,
	product: state.product.live,
	user: state.access.user.loggedUserObj,
});
export default connect(stateToProps)(withTranslation()(WTable));
