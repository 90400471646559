import React, { useState } from 'react';
import { Header, Button } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
function EmbedCode(props) {
	const { t } = useTranslation();
	let pid = _.get(props, 'product.settings.live.token');
	let scriptCode = `<script type="text/javascript">
window.DialogTab||(function(d,c,script){
	d.getElementById(c)||(script=d.createElement('script'),
	script.id=c,script.async=true,
	script.src='//cdn.dialogtab.com/live/slim.js',
	script.onload=function(){
	new DialogTab().init({pid:'${pid}'})},
	d.getElementsByTagName('head')[0].appendChild(script))
})(document,'DialogTab');
</script>`;
	const [copy, setCopy] = useState(t('copythescript'));
	const [color, setColor] = useState('blue');
	function handleClick() {
		setCopy('Copied');
		setColor('green');
	}
	return (
		<div>
			<div style={{ display: 'flex', padding: '5px' }}>
				<Header style={{ marginTop: 'auto', marginBottom: 'auto' }}>{t('embedCode')}</Header>
				<CopyToClipboard text={scriptCode}>
					<Button type="button" color={color} style={{ marginLeft: 'auto' }} onClick={() => handleClick()}>
						{copy}
					</Button>
				</CopyToClipboard>
			</div>

			<div style={{ display: 'flex', padding: '20px', background: '#EAEDF4', marginTop: '15px', marginBottom: '15px' }}>
				<pre readOnly name="code" id="code" cols="100" rows="10" style={{ margin: 0 }}>
					{scriptCode}
				</pre>
			</div>
			<div style={{ padding: 5 }}>{t('embedCodeDesc')}</div>
			<div style={{ display: 'flex', padding: '5px' }}>
				<Header style={{ marginTop: 'auto', marginBottom: 'auto' }}>{t('demoPage')}</Header>
			</div>
			<div>
				<p>{t('clickAndTest')}</p>
				<Button color="green" as="a" href={'https://dialogtab.com/demo/' + pid} target="_blank">
					{t('testonLive')}
				</Button>
			</div>
		</div>
	);
}

export default connect((state) => ({ product: state.product.live }))(EmbedCode);
