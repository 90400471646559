import React, { Component } from 'react';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Page from '../../components/Page';
import { Menu, Icon } from 'semantic-ui-react';
import AccountProgress from '../../components/AccountProgress';
import Users from './users';
import Company from './company';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class Account extends Component {
	render() {
		const { t, product } = this.props;
		let isExtension = product && product.settings && product.settings.panel && product.settings.panel === 'ext';
		if (isExtension) {
			return (
				<Page title={t('account')} icon="user" subtitle={t('manageAccount')}>
					<Page.Wrap>
						<Menu className="settings" pointing secondary>
							<Menu.Item as={NavLink} exact to="/account">
								<Icon name="setting" />
								{t('accountSettings')}
							</Menu.Item>
							<Menu.Item as={NavLink} exact to="/account/company">
								<Icon name="building" />
								{t('companySettings')}
							</Menu.Item>
						</Menu>
						<Switch>
							<Route path="/account/company" component={Company} />
							<Route path="/account" exact component={Users} />
							<Redirect to="/account" />
						</Switch>
					</Page.Wrap>
				</Page>
			);
		}
		return (
			<Page
				title={t('account')}
				icon="user"
				subtitle={t('manageAccount')}
				data={this.props}
				extra={
					<div className="ml-auto">
						<AccountProgress></AccountProgress>
					</div>
				}
			>
				<Page.Wrap>
					<Menu className="settings" pointing secondary>
						<Menu.Item as={NavLink} exact to="/account">
							<Icon name="setting" />
							{t('accountSettings')}
						</Menu.Item>
						<Menu.Item as={NavLink} exact to="/account/company">
							<Icon name="building" />
							{t('companySettings')}
						</Menu.Item>
					</Menu>
					<Switch>
						<Route path="/account/company" component={Company} />
						<Route path="/account" exact component={Users} />
						<Redirect to="/account" />
					</Switch>
				</Page.Wrap>
			</Page>
		);
	}
}
const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product.live });
export default connect(stateToProps)(withTranslation()(Account));
