import React, { Component } from 'react';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Page from '../../components/Page';
import { Menu, Icon, Button } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Config from './config';
import Categories from './category';
import Products from './products';

class WCart extends Component {
	render() {
		const { t, product } = this.props;
		let urlList = {
			'20cec87a-3163-4ae4-be35-f67c0b1fe2a0': 'enmodaenuygun',
			'f860fc1e-e975-4bdf-94d6-5e88f62a3963': 'terlikayakkabicanta',
			'6c3f19bf-71c2-4168-8918-8586a8b13aa7': 'toptanterlikalsat',
		};
		let path = urlList[product.id] ? urlList[product.id] : product.id;
		return (
			<Page
				title={t('WhatsApp Cart')}
				icon="cart"
				extra={
					<div className="ml-auto">
						<Button basic color="green" as="a" href={`https://whatsapp-shop.com/${path}`} target="_blank">
							{t('preview')}
						</Button>
					</div>
				}
			>
				<Page.Wrap>
					<Menu className="settings" pointing secondary>
						<Menu.Item as={NavLink} exact to="/wcart">
							<Icon name="setting" />
							{t('config')}
						</Menu.Item>
						<Menu.Item as={NavLink} exact to="/wcart/categories">
							<Icon name="list layout" />
							{t('categories')}
						</Menu.Item>
						<Menu.Item as={NavLink} exact to="/wcart/products">
							<Icon name="list layout" />
							{t('products')}
						</Menu.Item>
					</Menu>
					<Switch>
						<Route path="/wcart/products" exact component={Products}></Route>
						<Route path="/wcart/categories" exact component={Categories}></Route>
						<Route path="/wcart" exact component={Config}></Route>
						<Redirect to="/wcart" />
					</Switch>
				</Page.Wrap>
			</Page>
		);
	}
}
const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product.live });
export default connect(stateToProps)(withTranslation()(WCart));
