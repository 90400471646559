import React, { Component } from 'react';
import { Form, Segment, Button } from 'semantic-ui-react';
import { post } from '../util/api';
import { CopyToClipboard } from 'react-copy-to-clipboard';

class UserPage extends Component {
	state = {
		link: 'generate',
	};

	handleChange = (e, { name, value }) => this.setState({ [name]: value });

	handleSubmit = async () => {
		await post('/live/payment/custompaddlelink', {
			admin: true,
			custom_price: this.state.price,
			title: this.state.title,
			message: this.state.message,
			plan_id: 612520,
		}).then((data) => {
			this.setState({ link: data.data.response.url });
		});
	};
	render() {
		return (
			<Segment>
				<Form onSubmit={this.handleSubmit}>
					<Form.Input
						id="price"
						label="Price"
						name="price"
						icon="dollar"
						iconPosition="left"
						placeholder="Enter price"
						type="text"
						required
						value={this.state.price}
						onChange={this.handleChange}
					/>
					<Form.Input
						id="title"
						label="Title"
						icon="address card"
						required
						name="title"
						placeholder="Enter plan name"
						iconPosition="left"
						type="text"
						value={this.state.title}
						onChange={this.handleChange}
					/>
					<Form.Input
						id="message"
						label="Message"
						icon="address card"
						name="message"
						required
						placeholder="Enter message"
						iconPosition="left"
						type="text"
						value={this.state.message}
						onChange={this.handleChange}
					/>
					<Form.Button fluid type="submit" positive content="Generate" />
				</Form>
				<div style={{ marginTop: '10px', display: 'grid' }}>
					<CopyToClipboard text={this.state.link}>
						<Button type="copy" color="teal" style={{ marginLeft: 'auto', marginBottom: '10px' }}>
							Copy
						</Button>
					</CopyToClipboard>
					<p style={{ wordBreak: 'break-all' }}>{this.state.link}</p>
				</div>
			</Segment>
		);
	}
}

export default UserPage;
