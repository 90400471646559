import React, { PureComponent } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Table from './table';
import Config from './configs';
import Dashboard from './dashboard';

class Channel extends PureComponent {
	render() {
		return (
			<Switch>
				<Route path="/reseller" exact component={Dashboard}></Route>
				<Route path="/reseller/products" component={Table} />
				<Route path="/reseller/configs" component={Config}></Route>
				<Redirect to="/reseller" />
			</Switch>
		);
	}
}

export default Channel;
