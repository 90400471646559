import React, { Component } from 'react';
import { Segment, Container } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import TypeList from '../filter3.json';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import { Chart } from 'react-google-charts';
import { toWapi } from '../../util/api';
import { withTranslation } from 'react-i18next';
import MockAgents from './mock.json';

class Analytic extends Component {
	state = {
		filterType: false,
		filterAgent: false,
		loading: true,
		start_date: this.props.start_date,
		end_date: this.props.end_date,
		today: moment(),
		group: ['agent'],
		value: _.map(TypeList, (it) => {
			return it;
		}),
		pid: this.props.product.live.settings.live.token,
		phonepid: this.props.product.live.settings.wapi_pid || this.props.product.live.id,
		selectedAgent: 'all',
		selectedDate: 'daily',
		productAgentList: [],
		botId: '',
		tableStart: 0,
		tableEnd: 20,
		dataloading: true,
	};
	_i = 0;
	componentDidUpdate(oldProps, oldState) {
		const check_list = ['start_date', 'end_date', 'selectedAgent'];
		for (let i = 0; i < check_list.length; i++) {
			const key = check_list[i];
			let timer;
			if (key === 'start_date' || key === 'end_date') {
				if (this.props[key] !== oldProps[key]) {
					window.clearTimeout(timer);
					timer = setTimeout(() => this.getData(), 3000);
					break;
				}
			}
			if (this.state[key] !== oldState[key]) {
				window.clearTimeout(timer);
				timer = setTimeout(() => this.getData(), 3000);
				break;
			}
		}
	}
	componentDidMount() {
		let { pid, phonepid, end_date, start_date } = this.state;
		let product = this.props.product.live;
		let isMock = product && product.settings.mock_analytic;
		let token = product && product.settings && product.settings.wapi_token;
		fetch('/api/live/agent/list')
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					productAgentList: _.filter(data.agents, function (it) {
						return it.role !== 'viewer';
					}),
				});
			});

		if (token) {
			toWapi(`/api/${phonepid}/listPhones`, product.settings.wapi_token)
				.then((data) => {
					if (data.status !== 200) {
						return;
					}
					let phonedata = _.filter(data.data, function (it) {
						return it.type === 'whatsapp';
					});
					let instagramdata = _.filter(data.data, function (it) {
						return it.type === 'instagram';
					});
					this.setState({
						phones: _.map(phonedata, function (it) {
							return {
								phone: it.id,
								type: it.type,
							};
						}),
						instagrams: _.map(instagramdata, function (it) {
							return {
								phone: it.id,
								type: it.type,
							};
						}),
					});
				})
				.catch((err) => {
					console.error(err);
				});
			fetch('https://log.yapaytech.com/analytic/timeline/phone', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					start_date: start_date,
					end_date: end_date,
					pid: phonepid,
				}),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					let newData = _.map(data, (it) => ({
						phone: it.phone,
						last_status: it.last_status,
						changes: it.changes
							? _.filter(
									_.map(it.changes, function (a, i) {
										if (i === 0) {
											return a;
										} else {
											if (it.changes[i - 1].status !== it.changes[i].status) {
												return a;
											}
										}
									}),
									function (o) {
										return o && o.date;
									}
							  )
							: [],
					}));
					if (isMock) {
						newData = MockAgents.timelineByPhone;
					}
					this.setState({ phoneStatus: newData, phoneLength: data.length });
				});
		}

		fetch('https://log.yapaytech.com/live/timeline/agent ', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				pid: pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let newData = _.map(data, (it) => ({
					agent: it.agent,
					last_status: it.last_status,
					changes: it.changes
						? _.filter(
								_.map(it.changes, function (a, i) {
									if (i === 0) {
										return a;
									} else {
										if (it.changes[i - 1].status !== it.changes[i].status) {
											return a;
										}
									}
								}),
								function (o) {
									return o && o.date;
								}
						  )
						: [],
				}));
				this.setState({ agentStatus: newData, loading: false, agentLength: data.length });
			});
	}
	async getData() {
		let { start_date, end_date } = this.props;
		let product = this.props.product.live;
		let { pid, phonepid } = this.state;
		let token = product && product.settings && product.settings.wapi_token;
		this.setState({ loading: true, dataloading: true });
		if (token) {
			await fetch('https://log.yapaytech.com/analytic/timeline/phone', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					start_date: start_date,
					end_date: end_date,
					pid: phonepid,
				}),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					let newData = _.map(data, (it) => ({
						phone: it.phone,
						last_status: it.last_status,
						changes: it.changes
							? _.filter(
									_.map(it.changes, function (a, i) {
										if (i === 0) {
											return a;
										} else {
											if (it.changes[i - 1].status !== it.changes[i].status) {
												return a;
											}
										}
									}),
									function (o) {
										return o && o.date;
									}
							  )
							: [],
					}));

					this.setState({ phoneStatus: newData, phoneLength: data.length });
				});
		}
		await fetch('https://log.yapaytech.com/live/timeline/agent ', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				pid: pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let newData = _.map(data, (it) => ({
					agent: it.agent,
					last_status: it.last_status,
					changes: _.filter(
						_.map(it.changes, function (a, i) {
							if (i === 0) {
								return a;
							} else {
								if (it.changes[i - 1].status !== it.changes[i].status) {
									return a;
								}
							}
						}),
						function (o) {
							return o && o.date;
						}
					),
					changesno: _.map(it.changes, function (a, i) {
						if (i === 0) {
							return a;
						} else {
							if (it.changes[i - 1].status !== it.changes[i].status) {
								return a;
							}
						}
					}),
				}));
				this.setState({ agentStatus: newData, loading: false, agentLength: data.length, dataloading: false });
			});
	}
	dateChange = ({ selection }) => {
		let start_date = moment(selection.startDate).startOf('day');
		let end_date = moment(selection.endDate);
		if (end_date.format('YYYY-MM-DD') !== this.state.today.format('YYYY-MM-DD')) {
			end_date = end_date.endOf('day');
		} else {
			end_date = moment();
		}
		this.setState({ start_date, end_date });
	};
	render() {
		let { start_date, end_date, t, i18n } = this.props;
		//let isMock = product.live && product.live.settings.mock_analytic;
		if (this.state.loading)
			return (
				<Container>
					<Segment style={{ marginBottom: '20px' }}>
						<Page.Loader></Page.Loader>
					</Segment>
				</Container>
			);
		let timelineData = [],
			phoneTimeline = [],
			instagramTimeline = [];
		let colors = {
			online: '#4ed34e',
			offline: '#d34e4e',
			unknown: '#d4d4d5',
			idle_agent: '#F4B400',
		};
		let colorsphone = {
			active: '#4ed34e',
			idle: '#fbbd08',
			'qr-screen': '#f2711c',
			'phone-error': '#d34e4e',
			unknown: '#d4d4d5',
		};

		let _start = moment(start_date);
		let _end = moment() > moment(end_date) ? moment(end_date) : moment();
		let agentList = _.concat(this.state.productAgentList, this.props.user);
		let displayName = this.props.displayName === 'developer' ? 'email' : 'name';
		let phonesArray = _.intersectionBy(this.state.phoneStatus, this.state.phones, 'phone');
		let instagramsArray = _.intersectionBy(this.state.phoneStatus, this.state.instagrams, 'phone');
		_.each(this.state.agentStatus, function (it, s) {
			let name =
				(_.find(agentList, function (ag) {
					return ag.extra.user_id === it.agent;
				}) &&
					_.find(agentList, function (ag) {
						return ag.extra.user_id === it.agent;
					})[displayName]) ||
				(_.find(agentList, function (ag) {
					return ag.extra.user_id === it.agent;
				}) &&
					_.find(agentList, function (ag) {
						return ag.extra.user_id === it.agent;
					}).email);
			if (name) {
				if (it.changes && it.changes.length) {
					_.each(it.changes, (a, i) => {
						let start = moment(a.date);
						let end = it.changes[i + 1] && it.changes[i + 1].date ? moment(it.changes[i + 1].date) : _end;

						if (i === 0 && start > _start) {
							let tooltip = moment.duration(start.diff(_start)).humanize();

							timelineData.push([
								name || '' + it.agent,
								(it.last_status && t('' + it.last_status)) || t('unknown'),
								colors[it.last_status || 'unknown'],
								`${t('status')}: ${(it.last_status && t('' + it.last_status)) || t('unknown')} <br/>${t('agent')}: ${
									name || '' + it.agent
								}<br/>${t('duration')}: ${tooltip}<br/>${t('start')}: ${moment(_start).format('DD-MM HH:mm')}<br/>${t('end')}: ${moment(
									start
								).format('DD-MM HH:mm')}`,
								_start,
								start,
							]);
						}
						let tooltip = moment.duration(end.diff(start)).humanize();
						timelineData.push([
							name || '' + it.agent,
							t('' + a.status),
							colors[a.status],
							`${t('status')}: ${t('' + a.status)} <br/>${t('agent')}: ${name || '' + it.agent}<br/>${t('duration')}: ${tooltip}<br/>${t(
								'start'
							)}: ${moment(start).format('DD-MM HH:mm')}<br/>${t('end')}: ${moment(end).format('DD-MM HH:mm')}`,
							start,
							end,
						]);
					});
				} else {
					let tooltip = moment.duration(_end.diff(_start)).humanize();
					timelineData.push([
						name || '' + it.agent,
						t('' + it.last_status),
						colors[it.last_status],
						`${t('status')}: ${t('' + it.last_status)} <br/>${t('agent')}: ${name || '' + it.agent}<br/>${t('duration')}: ${tooltip}<br/>${t(
							'start'
						)}: ${moment(_start).format('DD-MM HH:mm')}<br/>${t('end')}: ${moment(_end).format('DD-MM HH:mm')}`,
						_start,
						_end,
					]);
				}
			}
		});
		_.each(phonesArray, function (it, s) {
			if (it.changes && it.changes.length) {
				_.each(it.changes, (a, i) => {
					let start = moment(a.date);
					let end = it.changes[i + 1] && it.changes[i + 1].date ? moment(it.changes[i + 1].date) : _end;

					if (i === 0 && start > _start) {
						let tooltip = moment.duration(start.diff(_start)).humanize();
						phoneTimeline.push([
							'' + it.phone,
							(it.last_status && t('' + it.last_status)) || t('unknown'),
							colorsphone[it.last_status || 'unknown'],
							`${t('status')}: ${(it.last_status && t('' + it.last_status)) || t('unknown')} <br/>${t('phone')}: ${it.phone}<br/>${t(
								'duration'
							)}: ${tooltip}<br/>${t('start')}: ${moment(_start).format('DD-MM HH:mm')}<br/>${t('end')}: ${moment(start).format('DD-MM HH:mm')}`,
							_start,
							start,
						]);
					}
					let tooltip = moment.duration(end.diff(start)).humanize();
					phoneTimeline.push([
						'' + it.phone,
						t('' + a.status),
						colorsphone[a.status],
						`${t('status')}: ${t('' + a.status)} <br/>${t('phone')}: ${it.phone}<br/>${t('duration')}: ${tooltip}<br/>${t('start')}: ${moment(
							start
						).format('DD-MM HH:mm')}<br/>${t('end')}: ${moment(end).format('DD-MM HH:mm')}`,
						start,
						end,
					]);
				});
			} else {
				let tooltip = moment.duration(_end.diff(_start)).humanize();
				phoneTimeline.push([
					'' + it.phone,
					t('' + it.last_status),
					colorsphone[it.last_status],
					`${t('status')}: ${t('' + it.last_status)} <br/>${t('phone')}: ${it.phone}<br/>${t('duration')}: ${tooltip}<br/>${t('start')}: ${moment(
						_start
					).format('DD-MM HH:mm')}<br/>${t('end')}: ${moment(_end).format('DD-MM HH:mm')}`,
					_start,
					_end,
				]);
			}
		});
		_.each(instagramsArray, function (it, s) {
			if (it.changes && it.changes.length) {
				_.each(it.changes, (a, i) => {
					let start = moment(a.date);
					let end = it.changes[i + 1] && it.changes[i + 1].date ? moment(it.changes[i + 1].date) : _end;

					if (i === 0 && start > _start) {
						let tooltip = moment.duration(start.diff(_start)).humanize();
						instagramTimeline.push([
							'' + it.phone,
							(it.last_status && t('' + it.last_status)) || t('unknown'),
							colorsphone[it.last_status || 'unknown'],
							`${t('status')}: ${(it.last_status && t('' + it.last_status)) || t('unknown')} <br/>${t('phone')}: ${it.phone}<br/>${t(
								'duration'
							)}: ${tooltip}<br/>${t('start')}: ${moment(_start).format('DD-MM HH:mm')}<br/>${t('end')}: ${moment(start).format('DD-MM HH:mm')}`,
							_start,
							start,
						]);
					}
					let tooltip = moment.duration(end.diff(start)).humanize();
					instagramTimeline.push([
						'' + it.phone,
						t('' + a.status),
						colorsphone[a.status],
						`${t('status')}: ${t('' + a.status)} <br/>${t('phone')}: ${it.phone}<br/>${t('duration')}: ${tooltip}<br/>${t('start')}: ${moment(
							start
						).format('DD-MM HH:mm')}<br/>${t('end')}: ${moment(end).format('DD-MM HH:mm')}`,
						start,
						end,
					]);
				});
			} else {
				let tooltip = moment.duration(_end.diff(_start)).humanize();

				instagramTimeline.push([
					'' + it.phone,
					t('' + it.last_status),
					colorsphone[it.last_status],
					`${t('status')}: ${t('' + it.last_status)} <br/>${t('phone')}: ${it.phone}<br/>${t('duration')}: ${tooltip}<br/>${t('start')}: ${moment(
						_start
					).format('DD-MM HH:mm')}<br/>${t('end')}: ${moment(_end).format('DD-MM HH:mm')}`,
					_start,
					_end,
				]);
			}
		});
		let main = [
			[
				{ type: 'string', id: 'Position' },
				{ type: 'string', id: 'Name' },
				{ type: 'string', role: 'style', id: 'style' },
				{ type: 'string', role: 'tooltip' },
				{ type: 'date', id: 'Start' },
				{ type: 'date', id: 'End' },
			],
		];
		let agentStatusList = _.concat(main, timelineData);
		let phoneStatusList = _.concat(main, phoneTimeline);
		//let instagramStatusList = _.concat(main, instagramTimeline);
		let phoneLength = this.state.phones && this.state.phones.length;
		//let instagramLength = this.state.instagrams && this.state.instagrams.length;
		return (
			<Container>
				<Segment>
					<h3 style={{ textAlign: 'center' }}>{t('agentsTimeline')}</h3>
					{this.state.agentLength ? (
						<div>
							<Chart
								width={'100%'}
								height={(agentList.length + 2) * 40 + 'px'}
								chartType="Timeline"
								loader={<Page.Loader></Page.Loader>}
								errorElement
								data={agentStatusList}
								chartLanguage={i18n.language}
								rootProps={{ 'data-testid': '3' }}
							/>
						</div>
					) : (
						<div>
							<p style={{ textAlign: 'center' }}>{t('thereisnodata')}</p>
						</div>
					)}
					{phoneLength ? (
						<div>
							<h3 style={{ textAlign: 'center' }}>{t('phonesTimeline')}</h3>

							<Chart
								width={'100%'}
								height={(phoneLength + 2) * 40 + 'px'}
								chartType="Timeline"
								loader={<Page.Loader></Page.Loader>}
								errorElement
								data={phoneStatusList}
								chartLanguage={i18n.language}
								rootProps={{ 'data-testid': '3' }}
							/>
						</div>
					) : null}
					{/* 	{instagramLength ? (
						<div>
							<h3 style={{ textAlign: 'center' }}>{t('instagramsTimeline')}</h3>

							<Chart
								width={'100%'}
								height={(instagramLength + 2) * 40 + 'px'}
								chartType="Timeline"
								loader={<Page.Loader></Page.Loader>}
								errorElement
								data={instagramStatusList}
								chartLanguage={i18n.language}
								rootProps={{ 'data-testid': '3' }}
							/>
						</div>
					) : null} */}
				</Segment>
				{process.env.NODE_ENV !== 'production' && (
					<div>
						<pre>{JSON.stringify(agentStatusList, null, 2)}</pre>
					</div>
				)}
			</Container>
		);
	}
}
const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product, user: state.access.user.loggedUserObj });

export default connect(stateToProps)(withTranslation()(Analytic));
