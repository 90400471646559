import React from 'react';
import Page from '../../../components/Page';
import { Icon, Breadcrumb, Table, Tab, Segment, Button, Dropdown } from 'semantic-ui-react';
import { Link, Prompt, Route, matchPath, NavLink } from 'react-router-dom';
import useFetch from 'use-http';
import { Formik, Field } from 'formik';
import _ from 'lodash';
import { BooleanInput, TextInput, DropdownInput, TableLine, ColorInput, UploadTextInput } from '../inputs';
import EmbedCode from '../../../components/EmbedCode';
import MyMenu from '../menu';
import AccountProgress from '../../../components/AccountProgress';
import { useTranslation } from 'react-i18next';

const fetch_config = { responseType: 'json', cachePolicy: 'no-cache' };

function RegisterInput(props) {
	const { t } = useTranslation();
	let REGISTER_LIST = [
		{
			key: 'required',
			value: 'required',
			text: t('askRequired'),
		},
		{
			key: 'optional',
			value: 'optional',
			text: t('optional'),
		},
		{
			key: 'dont',
			value: 'dont',
			text: t('dontAsk'),
		},
	];
	return <DropdownInput {...props} list={REGISTER_LIST} />;
}

function AdvancedConfigs() {
	const { t } = useTranslation();
	return (
		<Tab.Pane>
			<Table definition>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width={4}>
							<b>{t('interactiveOptions')}</b>
						</Table.HeaderCell>
						<Table.HeaderCell>{t('value')}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					<TableLine title={t('enabled')}>
						<BooleanInput name="client.interactive" />
					</TableLine>

					<TableLine title={t('title')}>
						<TextInput name="client.interactiveTitle" />
					</TableLine>

					<TableLine title={t('message')}>
						<TextInput name="client.interactiveMessage" />
					</TableLine>

					<TableLine title={t('disableInput')}>
						<BooleanInput name="client.interactiveDisableInput" />
					</TableLine>

					<TableLine title={t('disableSave')}>
						<BooleanInput name="client.interactiveDisableSave" />
					</TableLine>

					<TableLine title={t('onlyWorkingHours')}>
						<BooleanInput name="client.interactiveOnlyWorkingHours" />
					</TableLine>
				</Table.Body>
			</Table>
		</Tab.Pane>
	);
}

function RegisterConfigs(props) {
	const { t } = useTranslation();
	//let requiredRegister = (props.values && props.values.client && props.values.client.registerRequired) || false;
	return (
		<Tab.Pane>
			<Table definition>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width={4}>
							<b>{t('registerOptions')}</b>
						</Table.HeaderCell>
						<Table.HeaderCell>{t('value')}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					<TableLine title={t('nameSurname')}>
						<RegisterInput name="client.register.name" />
					</TableLine>
					<TableLine title={t('email')}>
						<RegisterInput name="client.register.email" />
					</TableLine>
					<TableLine title={t('phone')}>
						<RegisterInput name="client.register.phone" />
					</TableLine>
				</Table.Body>
			</Table>
		</Tab.Pane>
	);
}

function GeneralConfigs() {
	const { t } = useTranslation();
	return (
		<Tab.Pane>
			<Table definition>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width={4} />
						<Table.HeaderCell>{t('value')}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					<TableLine title={t('name')}>
						<TextInput name="name" placeholder="Live Chat" />
					</TableLine>

					<TableLine title={t('image')}>
						<UploadTextInput name="client.image" placeholder="Image Url" t={t} />
					</TableLine>

					<TableLine title={t('width')}>
						<TextInput name="client.sizeX" type="number" placeholder="350" />
					</TableLine>

					<TableLine title={t('height')}>
						<TextInput name="client.sizeY" type="number" placeholder="550" />
					</TableLine>

					<TableLine title={t('disabled')} description={t('disabledWebchat')}>
						<BooleanInput name="client.disabled" />
					</TableLine>

					<TableLine title={t('emojiSupport')} description={t('emojiSupportDescription')}>
						<BooleanInput name="client.emoji" />
					</TableLine>

					<TableLine title={t('attachmentSupport')} description={t('attachmentSupportDescription')}>
						<BooleanInput name="client.attach" />
					</TableLine>

					<TableLine title={t('workingHours')} description={t('workingHoursDescription')}>
						<BooleanInput name="client.disable_working_hours" />
					</TableLine>

					<TableLine title="Script">
						<Button primary as={Link} to="/settings/integrations/website-chat/script">
							{t('getScript')}
						</Button>
					</TableLine>
				</Table.Body>
			</Table>
		</Tab.Pane>
	);
}

function LanguagesConfig() {
	const { t } = useTranslation();
	const [lang, setlang] = React.useState('tr');
	let langOptions = [
		{ key: 'tr', text: t('turkish'), value: 'tr' },
		{ key: 'en', text: t('english'), value: 'en' },
	];
	return (
		<Tab.Pane style={{ maxHeight: '100%' }}>
			<Table definition>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width={4}>
							<Dropdown
								className="yt-line userdefinition"
								options={langOptions}
								value={lang}
								fluid
								selection
								onChange={(e, { value }) => {
									setlang(value);
								}}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>{t('value')}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body className={lang === 'tr' ? 'table-show' : 'table-hide'}>
					<TableLine title={t('noname')}>
						<TextInput name={`client.langData.tr.noname`} placeholder={t('nonamePlaceholder')} />
					</TableLine>
					<TableLine title={t('conversationEnd')}>
						<TextInput name={`client.langData.tr.conversationEnd`} placeholder={t('conversationEndPlaceholder')} />
					</TableLine>
					<TableLine title={t('conversationEndQuestion')}>
						<TextInput name={`client.langData.tr.conversationEndQuestion`} placeholder={t('conversationEndQuestionPlaceholder')} />
					</TableLine>
					<TableLine title={t('mailError')}>
						<TextInput name={`client.langData.tr.mailError`} placeholder={t('mailErrorPlaceholder')} />
					</TableLine>
					<TableLine title={t('mailErrorDesc')}>
						<TextInput name={`client.langData.tr.mailErrorDesc`} placeholder={t('mailErrorDescPlaceholder')} />
					</TableLine>
					<TableLine title={t('mailInviteError')}>
						<TextInput name={`client.langData.tr.mailInviteError`} placeholder={t('mailInviteErrorPlaceholder')} />
					</TableLine>
					<TableLine title={t('mailInvalidError')}>
						<TextInput name={`client.langData.tr.mailInvalidError`} placeholder={t('mailInvalidErrorPlaceholder')} />
					</TableLine>
					<TableLine title={t('nameError')}>
						<TextInput name={`client.langData.tr.nameError`} placeholder={t('nameErrorPlaceholder')} />
					</TableLine>
					<TableLine title={t('nameErrorDesc')}>
						<TextInput name={`client.langData.tr.nameErrorDesc`} placeholder={t('nameErrorDescPlaceholder')} />
					</TableLine>
					<TableLine title={t('formDesc')}>
						<TextInput name={`client.langData.tr.formDesc`} placeholder={t('formDescPlaceholder')} />
					</TableLine>
					<TableLine title={t('startConversation')}>
						<TextInput name={`client.langData.tr.startConversation`} placeholder={t('startConversationPlaceholder')} />
					</TableLine>
					<TableLine title={t('nameSurnamePlaceholder')}>
						<TextInput name={`client.langData.tr.nameSurnamePlaceholder`} placeholder={t('nameSurnamePlaceholderPlaceholder')} />
					</TableLine>
					<TableLine title={t('emailPlaceholder')}>
						<TextInput name={`client.langData.tr.emailPlaceholder`} placeholder={t('emailPlaceholderPlaceholder')} />
					</TableLine>
					<TableLine title={t('messagePlaceholder')}>
						<TextInput name={`client.langData.tr.messagePlaceholder`} placeholder={t('messagePlaceholderPlaceholder')} />
					</TableLine>
					<TableLine title={t('widgetTitle')}>
						<TextInput name={`client.langData.tr.widgetTitle`} placeholder={t('widgetTitlePlaceholder')} />
					</TableLine>
					<TableLine title={t('disconnectChat')}>
						<TextInput name={`client.langData.tr.disconnect`} placeholder={t('disconnectChatPlaceholder')} />
					</TableLine>
					<TableLine title={t('disconnectDesc')}>
						<TextInput name={`client.langData.tr.disconnectDesc`} placeholder={t('disconnectDescPlaceholder')} />
					</TableLine>
					<TableLine title={t('reconnect')}>
						<TextInput name={`client.langData.tr.reconnect`} placeholder={t('reconnectPlaceholder')} />
					</TableLine>
					<TableLine title={t('showMore')}>
						<TextInput name={`client.langData.tr.showMore`} placeholder={t('showMorePlaceholder')} />
					</TableLine>
					<TableLine title={t('showLess')}>
						<TextInput name={`client.langData.tr.showLess`} placeholder={t('showLessPlaceholder')} />
					</TableLine>
					<TableLine title={t('joinChat')}>
						<TextInput name={`client.langData.tr.joinChat`} placeholder={t('joinChatPlaceholder')} />
					</TableLine>
					<TableLine title={t('leftChat')}>
						<TextInput name={`client.langData.tr.leftChat`} placeholder={t('leftChatPlaceholder')} />
					</TableLine>
					<TableLine title={t('endChat')}>
						<TextInput name={`client.langData.tr.endChat`} placeholder={t('endChatPlaceholder')} />
					</TableLine>
					<TableLine title={t('invite')}>
						<TextInput name={`client.langData.tr.invite`} placeholder={t('invitePlaceholder')} />
					</TableLine>
					<TableLine title={t('minimize')}>
						<TextInput name={`client.langData.tr.minimize`} placeholder={t('minimizePlaceholder')} />
					</TableLine>
					<TableLine title={t('menu')}>
						<TextInput name={`client.langData.tr.menu`} placeholder={t('menuPlaceholder')} />
					</TableLine>
					<TableLine title={t('send')}>
						<TextInput name={`client.langData.tr.send`} placeholder={t('sendPlaceholder')} />
					</TableLine>
					<TableLine title={t('chatEnded')}>
						<TextInput name={`client.langData.tr.chatEnded`} placeholder={t('chatEndedPlaceholder')} />
					</TableLine>
					<TableLine title={t('createNewConv')}>
						<TextInput name={`client.langData.tr.createNewConv`} placeholder={t('createNewConvPlaceholder')} />
					</TableLine>
					<TableLine title={t('close')}>
						<TextInput name={`client.langData.tr.close`} placeholder={t('closePlaceholder')} />
					</TableLine>
					<TableLine title={t('share')}>
						<TextInput name={`client.langData.tr.share`} placeholder={t('sharePlaceholder')} />
					</TableLine>
					<TableLine title={t('hide')}>
						<TextInput name={`client.langData.tr.hide`} placeholder={t('hidePlaceholder')} />
					</TableLine>
					<TableLine title={t('openProductPage')}>
						<TextInput name={`client.langData.tr.openProductPage`} placeholder={t('openProductPagePlaceholder')} />
					</TableLine>
				</Table.Body>

				<Table.Body className={lang === 'en' ? 'table-show' : 'table-hide'}>
					<TableLine title={t('noname')}>
						<TextInput name={`client.langData.en.noname`} placeholder={t('nonamePlaceholderEn')} />
					</TableLine>
					<TableLine title={t('conversationEnd')}>
						<TextInput name={`client.langData.en.conversationEnd`} placeholder={t('conversationEndPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('conversationEndQuestion')}>
						<TextInput name={`client.langData.en.conversationEndQuestion`} placeholder={t('conversationEndQuestionPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('mailError')}>
						<TextInput name={`client.langData.en.mailError`} placeholder={t('mailErrorPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('mailErrorDesc')}>
						<TextInput name={`client.langData.en.mailErrorDesc`} placeholder={t('mailErrorDescPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('mailInviteError')}>
						<TextInput name={`client.langData.en.mailInviteError`} placeholder={t('mailInviteErrorPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('mailInvalidError')}>
						<TextInput name={`client.langData.en.mailInvalidError`} placeholder={t('mailInvalidErrorPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('nameError')}>
						<TextInput name={`client.langData.en.nameError`} placeholder={t('nameErrorPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('nameErrorDesc')}>
						<TextInput name={`client.langData.en.nameErrorDesc`} placeholder={t('nameErrorDescPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('formDesc')}>
						<TextInput name={`client.langData.en.formDesc`} placeholder={t('formDescPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('startConversation')}>
						<TextInput name={`client.langData.en.startConversation`} placeholder={t('startConversationPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('nameSurnamePlaceholder')}>
						<TextInput name={`client.langData.en.nameSurnamePlaceholder`} placeholder={t('nameSurnamePlaceholderPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('emailPlaceholder')}>
						<TextInput name={`client.langData.en.emailPlaceholder`} placeholder={t('emailPlaceholderPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('messagePlaceholder')}>
						<TextInput name={`client.langData.en.messagePlaceholder`} placeholder={t('messagePlaceholderPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('widgetTitle')}>
						<TextInput name={`client.langData.en.widgetTitle`} placeholder={t('widgetTitlePlaceholderEn')} />
					</TableLine>
					<TableLine title={t('disconnectChat')}>
						<TextInput name={`client.langData.en.disconnect`} placeholder={t('disconnectChatPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('disconnectDesc')}>
						<TextInput name={`client.langData.en.disconnectDesc`} placeholder={t('disconnectDescPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('reconnect')}>
						<TextInput name={`client.langData.en.reconnect`} placeholder={t('reconnectPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('showMore')}>
						<TextInput name={`client.langData.en.showMore`} placeholder={t('showMorePlaceholderEn')} />
					</TableLine>
					<TableLine title={t('showLess')}>
						<TextInput name={`client.langData.en.showLess`} placeholder={t('showLessPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('joinChat')}>
						<TextInput name={`client.langData.en.joinChat`} placeholder={t('joinChatPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('leftChat')}>
						<TextInput name={`client.langData.en.leftChat`} placeholder={t('leftChatPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('endChat')}>
						<TextInput name={`client.langData.en.endChat`} placeholder={t('endChatPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('invite')}>
						<TextInput name={`client.langData.en.invite`} placeholder={t('invitePlaceholderEn')} />
					</TableLine>
					<TableLine title={t('minimize')}>
						<TextInput name={`client.langData.en.minimize`} placeholder={t('minimizePlaceholderEn')} />
					</TableLine>
					<TableLine title={t('menu')}>
						<TextInput name={`client.langData.en.menu`} placeholder={t('menuPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('send')}>
						<TextInput name={`client.langData.en.send`} placeholder={t('sendPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('chatEnded')}>
						<TextInput name={`client.langData.en.chatEnded`} placeholder={t('chatEndedPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('createNewConv')}>
						<TextInput name={`client.langData.en.createNewConv`} placeholder={t('createNewConvPlaceholderEn')} />
					</TableLine>
					<TableLine title={t('close')}>
						<TextInput name={`client.langData.en.close`} placeholder={t('closePlaceholderEn')} />
					</TableLine>
					<TableLine title={t('share')}>
						<TextInput name={`client.langData.en.share`} placeholder={t('sharePlaceholderEn')} />
					</TableLine>
					<TableLine title={t('hide')}>
						<TextInput name={`client.langData.en.hide`} placeholder={t('hidePlaceholderEn')} />
					</TableLine>
					<TableLine title={t('openProductPage')}>
						<TextInput name={`client.langData.en.openProductPage`} placeholder={t('openProductPagePlaceholderEn')} />
					</TableLine>
				</Table.Body>
			</Table>
		</Tab.Pane>
	);
}
const DEFAULT_COLORS = {
	black: '#000',
	white: '#fff',
	form: '#333',
	pc: '#5d87da',
	bc: '#4f93ce',
	cc: '#337ab7',
	bfc: '#fdfdfd',
	sc: '#eee',
	pfc: '#fefefe',
	sfc: '#010101',
	btnc: '#fffffe',
	btnhc: '#ffffef',
	wbg: '#4080ff',
	wfc: '#ffffff',
	title_color: '#000',
};

function Preview(props) {
	const { i18n, t } = useTranslation();
	//let client = props.values && props.values.client;
	//console.log('p', props);
	//const { loading, response } = useFetch('/api/dahi/message/getLiveChat', fetch_config, []);
	let language = i18n.language;
	let messagePlaceholder = language === 'tr' ? t('messagePlaceholderPlaceholder') : t('messagePlaceholderPlaceholderEn');
	return (
		<Field name={'client.colors'}>
			{({ field }) => {
				const state = { ...DEFAULT_COLORS, ...field.value };
				//console.log('dasd', props.values.client.langData);
				return (
					<div className="chat" style={{ padding: '10px', margin: 'auto', marginTop: '25px' }}>
						<Segment style={{ height: '550px', width: '350px', margin: 'auto', padding: '0', background: '#f7f9fa' }}>
							<div
								className="header"
								style={{
									boxShadow: '0 0px 26px 0 rgba(0,18,46,0.16)',
									height: '45px',
									background: state.sc,
									padding: '5px 5px 0 10px',
									alignItems: 'center',
									display: 'flex',
								}}
							>
								<div
									className="avatar"
									style={{
										width: '30px',
										height: '30px',
										marginRight: '5px',
										borderRadius: '50%',
										overflow: 'hidden',
										background: '#fff',
									}}
								>
									<img
										src={(props.values && props.values.client && props.values.client.image) || '/images/avatar.svg'}
										alt=""
										style={{ width: '30px', height: '30px' }}
									></img>
								</div>
								<div className="title" style={{ marginRight: 'auto' }}>
									<h1
										style={{
											display: 'block',
											fontWeight: 'normal',
											fontSize: '14px',
											margin: '0',
											padding: '0',
											color: state.title_color,
										}}
									>
										{props.values.name || 'Live Chat'}
									</h1>
								</div>
								<div className="close">
									<img src="/images/close.svg" alt=""></img>
								</div>
							</div>
							<div className="messageBox" style={{ height: '55px', marginTop: '20px', textAlign: 'right', marginRight: '30px' }}>
								<div className="messageText">
									<span
										style={{
											padding: '8px 10px',
											background: state.pc,
											color: state.pfc,
											borderRadius: '6px',
										}}
									>
										Hello!
									</span>
								</div>
							</div>
							<div className="messageBox" style={{ height: '55px', marginTop: '20px', textAlign: 'left', marginLeft: '10px' }}>
								<div className="messageText left" style={{ display: 'flex' }}>
									<div
										className="avatar"
										style={{
											width: '30px',
											height: '30px',
											marginRight: '5px',
											borderRadius: '50%',
											overflow: 'hidden',
											background: '#fff',
										}}
									>
										<img
											src={(props.values && props.values.client && props.values.client.image) || '/images/avatar.svg'}
											alt=""
											style={{ width: '30px', height: '30px' }}
										></img>
									</div>
									<span
										style={{
											padding: '8px 10px',
											color: '#000',
											background: '#fff',
											borderRadius: '6px',
											boxShadow: 'rgba(0, 18, 46, 0.16) 0px 0px 26px 0px',
										}}
									>
										Hello!
									</span>
								</div>
							</div>
							<div className="arrayBox">
								<div className="chatarray" style={{ color: state.bc }}>
									Test Button 1
								</div>
								<div className="chatarray" style={{ color: state.bc }}>
									Test Button 2
								</div>
								<div className="chatarray" style={{ color: state.bc }}>
									Test Button 3
								</div>
								<div className="chatarray" style={{ color: state.bc }}>
									Test Button 4
								</div>
							</div>
							<div className="messages" style={{ height: '260px' }}></div>
							<div
								className="input"
								style={{
									background: 'white',
									boxShadow: '0 4px 26px 0 rgba(0,18,46,0.16)',
									position: 'relative',
									width: '100%',
									display: 'flex',
								}}
							>
								<button className="tippy mi-btn mi-menu fs" data-tippy-content="Menü" type="button">
									<div>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={state.bc}>
											<path fill="none" d="M0 0h24v24H0z"></path>
											<path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
										</svg>
									</div>
								</button>
								<div
									style={{
										fontFamily: 'Helvetica Neue,Segoe UI,Helvetica,Arial,sans-serif',
										display: 'block',
										padding: '0.625rem',
										width: '100%',
										background: 'none',
										border: 'none',
										resize: 'none',
										lineHeight: '1.25rem',
										color: 'rgba(0,0,0,0.8)',
										margin: 'auto',
										outline: 'none',
									}}
								>
									{(props.values &&
										props.values.client &&
										props.values.client.langData &&
										props.values.client.langData[language] &&
										props.values.client.langData[language].messagePlaceholder) ||
										messagePlaceholder}
								</div>
								<img
									className="tippy fd fh fc fj mi-btn fs"
									src="/images/emoji.svg"
									alt=""
									style={{
										paddingRight: '10px',
										display: props.values && props.values.client && props.values.client.emoji ? 'flex' : 'none',
									}}
								></img>
								<img
									className="tippy fd fh fc fj mi-btn fs attach-btn"
									src="/images/attach.svg"
									alt=""
									style={{
										paddingRight: '10px',
										transform: 'rotate(45deg)',
										display: props.values && props.values.client && props.values.client.attach ? 'flex' : 'none',
									}}
								></img>
								<img src="/images/send.svg" alt="" style={{ paddingRight: '10px' }}></img>
							</div>
						</Segment>
					</div>
				);
			}}
		</Field>
	);
}
function WidgetPreview() {
	return (
		<Field name={'client.colors'}>
			{({ field }) => {
				const state = { ...DEFAULT_COLORS, ...field.value };
				return (
					<div className="widgetpart" style={{ width: '50%', padding: '10px', margin: 'auto' }}>
						<div className="widget" style={{ width: '80px', height: '80px', background: state.wbg, margin: 'auto', borderRadius: '50%' }}>
							<div style={{ padding: '14px', width: '80px' }}>
								<svg xmlns="http://www.w3.org/2000/svg" fill={state.wfc} viewBox="0 0 24 24">
									<path d="M20 2H4C3 2 2 3 2 4v18l4-4h14c1 0 2-1 2-2V4c0-1-1-2-2-2zM6 9h12v2H6V9zm8 5H6v-2h8v2zm4-6H6V6h12v2z"></path>
								</svg>
							</div>
						</div>
					</div>
				);
			}}
		</Field>
	);
}
function ColorConfigs() {
	const { t } = useTranslation();
	return (
		<Tab.Pane>
			<Table definition>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width={2}>
							<b>{t('mainColors')}</b>
						</Table.HeaderCell>
						<Table.HeaderCell>{t('value')}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					<TableLine single title={t('primary')}>
						<ColorInput name="client.colors.pc" defaultValue={DEFAULT_COLORS.pc} />
					</TableLine>
					<TableLine single title={t('second')}>
						<ColorInput name="client.colors.sc" defaultValue={DEFAULT_COLORS.sc} />
					</TableLine>
					<TableLine single title={t('form')}>
						<ColorInput name="client.colors.form" defaultValue={DEFAULT_COLORS.form} />
					</TableLine>
					<TableLine single title={t('buttons')}>
						<ColorInput name="client.colors.bc" defaultValue={DEFAULT_COLORS.bc} />
					</TableLine>
					<TableLine single title={t('primaryText')}>
						<ColorInput name="client.colors.pfc" defaultValue={DEFAULT_COLORS.pfc} />
					</TableLine>
					<TableLine single title={t('titleFontColor')}>
						<ColorInput name="client.colors.title_color" defaultValue={DEFAULT_COLORS.title_color} />
					</TableLine>
					<Table.Row style={{ height: '100%' }}>
						<Table.Cell colSpan="2"></Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>

			<Table definition>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width={2}>
							<b>{t('widgetColors')}</b>
						</Table.HeaderCell>
						<Table.HeaderCell>{t('value')}</Table.HeaderCell>
						<Table.HeaderCell width={6}>{t('preview')}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					<Table.Row>
						<Table.Cell>{t('widgetBackground')}</Table.Cell>
						<Table.Cell>
							<ColorInput name="client.colors.wbg" defaultValue={DEFAULT_COLORS.wbg} />
						</Table.Cell>
						<Table.Cell rowSpan="3" style={{ borderLeft: '1px solid rgba(34,36,38,.15)' }}>
							<WidgetPreview />
						</Table.Cell>
					</Table.Row>
					<TableLine single title={t('widgetFront')}>
						<ColorInput name="client.colors.wfc" defaultValue={DEFAULT_COLORS.wfc} />
					</TableLine>
					<Table.Row style={{ height: '100%' }}>
						<Table.Cell colSpan="2"></Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
		</Tab.Pane>
	);
}
function WhatsappWidgetPreview() {
	const { t } = useTranslation();
	return (
		<Field name={'client'}>
			{({ field }) => {
				const state = { ...DEFAULT_COLORS, ...field.value };
				return (
					<div id="dahiWrap">
						<div className="dahi-wa-icon">
							<div className="dahi-wa-title">{state.whatsapptitle || t('enterMessage')}</div>
							<a href={'https://wa.me/' + state.whatsapp + '?text=' + state.whatsapptitle} target="_blank" rel="noopener noreferrer">
								<img src="/images/whatsapp-widget.svg" alt="/images/whatsapp-widget.svg" width="52" height="52" style={{ width: '52px' }}></img>
							</a>
						</div>
					</div>
				);
			}}
		</Field>
	);
}
function WhatsappConfigs() {
	const { t } = useTranslation();
	return (
		<Tab.Pane>
			<Table definition>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width={4}>
							<b>WhatsApp Widget</b>
						</Table.HeaderCell>
						<Table.HeaderCell>{t('value')}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					<TableLine title={t('enabled')}>
						<BooleanInput name="client.whatsappwidget" />
					</TableLine>
					<TableLine title={t('webChatHidden')} description={t('webChatHiddenDesc')}>
						<BooleanInput name="client.hidden" />
					</TableLine>
					<TableLine title={t('number')}>
						<TextInput name="client.whatsapp" description={t('yourWhatsappNumber')} />
					</TableLine>
					<TableLine title={t('message')}>
						<TextInput name="client.whatsapptitle" placeholder={t('enterMessage')} />
					</TableLine>
					<TableLine title={t('preview')}>
						<WhatsappWidgetPreview />
					</TableLine>
				</Table.Body>
			</Table>
		</Tab.Pane>
	);
}
function ScriptConfigs() {
	return (
		<Tab.Pane>
			<EmbedCode />
		</Tab.Pane>
	);
}
function Configs(props) {
	const { t } = useTranslation();
	const panes = [
		{
			menuItem: {
				as: NavLink,
				content: t('general'),
				to: '/settings/integrations/website-chat',
				exact: true,
				key: 'webchat',
			},
			render: () => <Route path="/settings/integrations/website-chat" exact component={GeneralConfigs} />,
		},
		{
			menuItem: {
				as: NavLink,
				content: t('register'),
				to: '/settings/integrations/website-chat/register',
				key: 'register',
			},
			render: () => <Route path="/settings/integrations/website-chat/register" exact render={() => <RegisterConfigs {...props}></RegisterConfigs>} />,
		},
		{
			menuItem: {
				as: NavLink,
				content: t('advanced'),
				to: '/settings/integrations/website-chat/advanced',
				key: 'advanced',
			},
			render: () => <Route path="/settings/integrations/website-chat/advanced" component={AdvancedConfigs} />,
		},
		{
			menuItem: {
				as: NavLink,
				content: t('colors'),
				to: '/settings/integrations/website-chat/colors',
				key: 'colors',
			},
			render: () => <Route path="/settings/integrations/website-chat/colors" component={ColorConfigs} />,
		},
		{
			menuItem: {
				as: NavLink,
				content: 'WhatsApp Widget',
				to: '/settings/integrations/website-chat/whatsapp-widget',
				key: 'whatsapp',
			},
			render: () => <Route path="/settings/integrations/website-chat/whatsapp-widget" component={WhatsappConfigs} />,
		},
		{
			menuItem: {
				as: NavLink,
				content: t('languagesOptions'),
				to: '/settings/integrations/website-chat/languages-options',
				key: 'languages-options',
			},
			render: () => <Route path="/settings/integrations/website-chat/languages-options" component={LanguagesConfig} />,
		},
		{
			menuItem: {
				as: NavLink,
				content: 'Script',
				to: '/settings/integrations/website-chat/script',
				key: 'script',
			},
			render: () => <Route path="/settings/integrations/website-chat/script" component={ScriptConfigs} />,
		},
	];
	// Required to get a correct tab opened by default
	const defaultActiveIndex = panes.findIndex((pane) => {
		return !!matchPath(window.location.pathname, {
			path: pane.menuItem.to,
			exact: true,
		});
	});
	return (
		<Page.Wrap>
			<div style={{ display: 'flex' }}>
				<Tab menu={{ secondary: true, pointing: true }} activeIndex={defaultActiveIndex} panes={panes} style={{ width: '70%' }} />
				<Preview {...props}></Preview>
			</div>
		</Page.Wrap>
	);
}

export default function ClientSettings() {
	const { t } = useTranslation();
	const { loading, response } = useFetch('/api/dahi/message/getLiveChat', fetch_config, []);
	const result = response && response.data && response.data.result;
	const initialValues = React.useMemo(() => {
		if (!result) return;
		const { name, client = {} } = result;

		client.attach = client.attach === false ? false : true;
		//const agent = _.pick(_.assignIn(agentOptions, staticOptions), ['emoji']);
		return {
			name,
			client: _.merge(
				{ register: { name: 'required', email: 'required', phone: 'dont' } },
				_.pick(client, [
					'image',
					'sizeX',
					'sizeY',
					'emoji',
					'attach',
					'register',
					'interactive',
					'interactiveTitle',
					'interactiveMessage',
					'interactiveDisableInput',
					'interactiveDisableSave',
					'interactiveOnlyWorkingHours',
					'colors',
					'disable_working_hours',
					'whatsappwidget',
					'hidden',
					'whatsapp',
					'whatsapptitle',
					'disabled',
					'langData',
				])
			),
		};
	}, [result]);
	if (loading)
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/settings`}>
							<Icon name="setting" />
							{t('settings')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />

						<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
							{t('integrations')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
					</Breadcrumb>
				}
				flex
			>
				<MyMenu activeItem="webchat">
					<Page.Loader />
				</MyMenu>
			</Page>
		);
	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values, actions) => {
				if (values.client) values.client.name = values.name;
				let langTr = values.client && values.client.langData && values.client.langData.tr;
				let langEn = values.client && values.client.langData && values.client.langData.en;
				if (langTr) {
					langTr = _.omitBy(langTr, function (it) {
						return it === '';
					});
				}
				if (langEn) {
					langEn = _.omitBy(langEn, function (it) {
						return it === '';
					});
				}
				values.client = _.omit(values.client, ['langData']);
				fetch('/api/dahi/channel/save', {
					method: 'POST',
					body: JSON.stringify({ channel: 'live', data: { set: values, replace: { 'client.langData': { tr: langTr, en: langEn } } } }),
					headers: {
						'Content-Type': 'application/json',
					},
				})
					.then((response) => {
						return response.json();
					})
					.then(() => {
						actions.resetForm(values);
						window.location.reload();
						actions.setSubmitting(false);
					})
					.catch((e) => {
						console.error(e);
						actions.setSubmitting(false);
					});
			}}
		>
			{(props) => {
				return (
					<Page
						header={
							<Breadcrumb size="big">
								<Breadcrumb.Section as={Link} to={`/settings`}>
									<Icon name="setting" />
									{t('settings')}
								</Breadcrumb.Section>
								<Breadcrumb.Divider icon="right chevron" />

								<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
									{t('integrations')}
								</Breadcrumb.Section>
								<Breadcrumb.Divider icon="right chevron" />
								<Breadcrumb.Section>{t('webchatSettings')}</Breadcrumb.Section>
							</Breadcrumb>
						}
						extra={
							<div className="ml-auto d-flex">
								<AccountProgress></AccountProgress>
								<Button
									loading={props.isSubmitting}
									disabled={props.isSubmitting}
									primary
									type="submit"
									style={{ marginLeft: '10px' }}
									onClick={props.submitForm}
								>
									{t('saveChanges')}
								</Button>
							</div>
						}
						flex
					>
						<MyMenu activeItem="webchat">
							<form onSubmit={props.handleSubmit} autoComplete="off">
								<Prompt
									when={props.dirty}
									message={(location) =>
										location.pathname.startsWith('/settings/integrations/website-chat') ? true : t('questionWithoutSaving')
									}
								/>
								<Configs {...props} />
							</form>
						</MyMenu>
					</Page>
				);
			}}
		</Formik>
	);
}
