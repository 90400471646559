import React, { PureComponent } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import { Menu, Icon } from 'semantic-ui-react';
import Page from '../../components/Page';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Contacts from './contacts';
import History from './historyTable';

class WVALID extends PureComponent {
	render() {
		//let { t, product } = this.props;
		return (
			<Page title="WhatsApp Validation" icon="whatsapp">
				<Page.Wrap>
					<Menu className="settings" pointing secondary stackable>
						<Menu.Item as={NavLink} exact to="/wvalidation">
							<Icon name="whatsapp" />
							{'Kontrol'}
						</Menu.Item>
						<Menu.Item as={NavLink} exact to="/wvalidation/history">
							<Icon name="list" />
							{'Geçmiş'}
						</Menu.Item>
					</Menu>

					<Switch>
						<Route path="/wvalidation/history" exact component={History}></Route>
						<Route path="/wvalidation" exact component={Contacts}></Route>
						<Redirect to="/wvalidation" />
					</Switch>
				</Page.Wrap>
			</Page>
		);
	}
}
const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product.live, user: state.access.user.loggedUserObj });
export default connect(stateToProps)(withTranslation()(WVALID));
