import React from 'react';
import { Input, Icon } from 'semantic-ui-react';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import cs from 'classnames';

export class MaterialInput extends React.PureComponent {
	render() {
		let { className, icon, label, color, min, max, ...rest } = this.props;
		return (
			<Input transparent icon={this.props.iconPosition !== 'left' && !!icon} {...rest} className={cs(className, 'yt-input', color)}>
				{icon && <Icon name={icon} />}
				<input min={min} max={max} />
				<span />
			</Input>
		);
	}
}
