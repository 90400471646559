import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './reducers';
import sagas from './sagas';
import createSagaMiddleware from 'redux-saga';
import { getProfile } from './actions/access.actions';
import * as GA from './util/GA';

export const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || ((it) => it);

export default function configureStore(preloadedState) {
	const sagaMiddleware = createSagaMiddleware();
	const store = createStore(
		createRootReducer(history),
		preloadedState,
		composeEnhancers(compose(applyMiddleware(routerMiddleware(history), sagaMiddleware)))
	);
	sagaMiddleware.run(sagas);

	store.dispatch(getProfile());
	return store;
}

history.listen((location) => {
	GA.pageview(location.pathname + location.search);
});
