import React, { Component } from 'react';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Page from '../../components/Page';
import { Menu, Icon, Popup, Button, Radio } from 'semantic-ui-react';
import Picker from '../../components/DateRangePicker';
import moment from 'moment';
import _ from 'lodash';
// eslint-disable-next-line
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import 'moment/min/locales';

import Agent from './agent';
import Selling from './selling';
import Order from './order';
import Closed from './closedtag';
import Time from './time';
import SendingProduct from './sendingproduct';
import Dashboard from './dashboard';
import Template from './template';
import ConversationGroup from './conversationgroup';
import Timeline from './timeline';
import UserList from './userlist';
import StockInfo from './stockinfo';
import EventTable from './event';
import RatesList from './rateslist';
//import Randevu from './randevu';

class Analytic extends Component {
	state = {
		loading: true,
		ecommerce: false,
		sendProduct: false,
		conversationGroup: false,
		pid: /* '1e00a47c670273a623c13c54dba44006' ||  */ /* '02cc63330cd0808f87c54ad9dd94b74a' || */ this.props.product.live.settings.live.token,
		start_date: moment().subtract(7, 'day').startOf('day'),
		agent_role: this.props.access.user.loggedUserObj.role,
		end_date: moment().subtract(1, 'day').endOf('day'),
		start_time: moment().startOf('day'),
		end_time: moment().endOf('day'),
		today: moment(),
		closedExcelData: [],
		agentExcelData: [],
		buttonLoading: false,
		displayName: 'developer',
		rateList: [],
		selectedPlatform: 'all',
	};
	componentDidMount() {
		fetch('/api/live/agent/list')
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ newList: data.agents });
			});
		fetch('/api/dahi/message/getLiveChat', {})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					data: data.result,
					productAgentList:
						data.result &&
						data.result.agents &&
						_.filter(data.result.agents, function (it) {
							return it.type !== 'viewer';
						}),
					loading: false,
					closeTags: data.result && data.result.staticOptions && data.result.staticOptions.closeTags,
				});
			});
	}
	dateChange = ({ selection }) => {
		let start_date = moment(selection.startDate).startOf('day');
		let end_date = moment(selection.endDate);
		if (end_date.format('YYYY-MM-DD') !== this.state.today.format('YYYY-MM-DD')) {
			end_date = end_date.endOf('day');
		} else {
			end_date = moment();
		}
		this.setState({ start_date, end_date });
	};
	timeChangeStart = (value) => {
		let { start_date } = this.state;
		start_date = moment(start_date).set({ hour: moment(value, 'HH:mm').format('HH'), minute: moment('00', 'mm').format('mm') });
		this.setState({ start_date });
	};
	timeChangeEnd = (value) => {
		let { end_date } = this.state;
		end_date = moment(end_date).set({ hour: moment(value, 'HH:mm').format('HH'), minute: moment('59', 'mm').format('mm') });
		this.setState({ end_date });
	};
	toggleName = () => this.setState({ displayName: this.state.displayName === 'developer' ? 'name' : 'developer' });
	async createExcelData(productAgentList, t) {
		let product = this.props.product.live;
		let ecommerce, sendProduct, conversationGroup, showTemplate, generalTable, stockinfo, ratePoint;
		//timeline = true;
		if (product.settings.analyticView) {
			ecommerce = product.settings.analyticView.ecommerce;
			sendProduct = product.settings.analyticView.sendProduct;
			conversationGroup = product.settings.analyticView.conversationGroup;
			showTemplate = product.settings.analyticView.showTemplate;
			generalTable = product.settings.analyticView.generalTable;
			stockinfo = product.settings.analyticView.stockinfo;
			//timeline = product.settings.analyticView.timeline;
			//userlist = product.settings.analyticView.userlist;
			ratePoint = product.settings.analyticView.ratePoint;
		}
		this.setState({ buttonLoading: true });

		await this.getClosedData(productAgentList);
		await this.getAgentData(productAgentList);
		await this.getClosedDataTopic();
		if (ratePoint) {
			await this.getRateList(productAgentList);
		}
		if (sendProduct) {
			await this.getSendingProduct();
		}
		await this.getTimeData(productAgentList);
		await this.getAllTimeData();
		if (generalTable) {
			await this.getGeneralTableData();
			await this.getGeneralGraph();
		}
		if (ecommerce) {
			await this.getSellingData(productAgentList);
		}
		if (ecommerce) {
			await this.getOrderData();
		}
		if (showTemplate) {
			await this.getTemplateData();
		}
		if (conversationGroup) {
			await this.getConversationData();
		}
		if (stockinfo) {
			await this.getStockInfo();
		}

		this.exportExcel(t);
	}
	async getAgentData(productAgentList) {
		let { start_date, end_date, pid } = this.state;
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		let agentExcelData = productAgentList;
		let agentData = await fetch('https://log.yapaytech.com/live/test/getInfo?ssssss', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: {
					type: ['viewed', 'taken', 'contacted', 'closed', 'forwarded'],
					pid: pid,
				},
				select: '',
				group: ['type', 'agent'],
				pairs: { agent: 'select1' },
				options: {
					sum: 0,
				},
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				return response.result;
			});
		_.map(agentData, (it) => {
			var e = _.findIndex(agentExcelData, function (o) {
				return o.id === it.agent;
			});
			/* if (e < 0 && /bot/.test(it.agent)) return agentExcelData.push({ id: it.agent, developer: 'Bot' }); */
			if (e >= 0) return (agentExcelData[e][it.type] = it.count);
		});
		_.map(agentExcelData, (it) => {
			_.map(['viewed', 'taken', 'contacted', 'closed', 'forwarded'], (d) => {
				if (it && !it[d]) {
					it[d] = 0;
				}
			});
			it.name = _.find(this.state.newList, { email: it.developer }) && _.find(this.state.newList, { email: it.developer }).name;
		});
		/* _.map(agentData, (it) => {
			var e = _.findIndex(agentExcelData, function (o) {
				return o.id === it.agent;
			});
			agentExcelData[e][it.type] = it.count;
		}); */

		this.setState({ agentExcelData });
	}

	async getClosedData(productAgentList) {
		let { start_date, end_date, pid, closeTags } = this.state;
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		let closedExcelData = productAgentList;
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		let closedData = await fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: {
					type: ['closed'],
					pid: pid,
				},
				select: '',
				group: ['type', 'agent', 'rate'],
				pairs: { agent: 'select1', topic: 'select2', rate: 'select5' },
				options: {
					sum: 0,
				},
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				return _.filter(response.result, (it) => it.agent !== pid);
			});
		/* 	let closedDataDateAgent = await fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				filter: {
					type: ['closed'],
					pid: pid,
				},
				select: '',
				group: ['type', 'day'],
				pairs: { agent: 'select1', topic: 'select2' },
				options: {
					sum: 0,
				},
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				return _.filter(response.result, (it) => it.agent !== pid);
			});
		//console.log(closedDataDateAgent);
			}); */
		/* let closedDataDateTopic = await fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				filter: {
					type: ['closed'],
					pid: pid,
				},
				select: '',
				group: ['type', 'topic', 'day'],
				pairs: { agent: 'select1', topic: 'select2' },
				options: {
					sum: 0,
				},
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				return _.filter(response.result, (it) => it.agent !== pid);
			});
		let closedDataDateTopicExcel = _.map(closedDataDateTopic, function (it) {
			return _.merge(it, {
				dayNumber: moment(it.day).dayOfYear(),
			});
		});
		closedDataDateTopicExcel = _.groupBy(closedDataDateTopicExcel, 'dayNumber');
		let closedDataDateAgentExcel = _.map(closedDataDateAgent, function (it) {
			return _.merge(it, {
				dayNumber: moment(it.day).dayOfYear(),
			});
		}); */
		let ng = _.map(closedData, function (it) {
			return {
				count: it.count,
				type: it.type,
				agent: it.agent,
				topic: it.topic,
				rate: parseInt(it.rate) || 0,
				total: it.count * (parseInt(it.rate) || 0),
			};
		});
		let groupAgentData = _.groupBy(ng, 'agent');
		_.map(closedExcelData, function (it) {
			return _.assign(it, { count: 0, ratedCount: 0, avgRate: 0, rates_one: 0, rates_two: 0, rates_three: 0, rates_four: 0, rates_five: 0 });
		});
		let newData = [];

		newData = _.map(groupAgentData, function (it) {
			return {
				count: _.sumBy(it, 'count'),
				type: it[0].type,
				agent: it[0].agent,
				topic: (_.find(closeTags, { id: it[0].topic }) && _.find(closeTags, { id: it[0].topic }).title) || it[0].topic,
				ratedCount: _.sumBy(
					_.filter(it, function (o) {
						return o.rate > 0;
					}),
					'count'
				),
				total: _.sumBy(it, 'total'),
				avgRate:
					(
						_.sumBy(
							_.filter(it, function (o) {
								return o.rate > 0;
							}),
							'count'
						) &&
						_.sumBy(it, 'total') /
							_.sumBy(
								_.filter(it, function (o) {
									return o.rate > 0;
								}),
								'count'
							)
					).toFixed(2) || 0,
				rates_one: _.sumBy(
					_.filter(it, (o) => o.rate === 1),
					'count'
				),
				rates_two: _.sumBy(
					_.filter(it, (o) => o.rate === 2),
					'count'
				),
				rates_three: _.sumBy(
					_.filter(it, (o) => o.rate === 3),
					'count'
				),
				rates_four: _.sumBy(
					_.filter(it, (o) => o.rate === 4),
					'count'
				),
				rates_five: _.sumBy(
					_.filter(it, (o) => o.rate === 5),
					'count'
				),
			};
		});
		//console.log('newData', newData);
		_.map(newData, function (it) {
			var e = _.findIndex(closedExcelData, function (o) {
				return o.id === it.agent;
			});
			return _.assign(closedExcelData[e], {
				count: it.count,
				ratedCount: it.ratedCount,
				avgRate: it.avgRate,
				total: it.total,
				rates_one: it.rates_one,
				rates_two: it.rates_two,
				rates_three: it.rates_three,
				rates_four: it.rates_four,
				rates_five: it.rates_five,
			});
		});
		this.setState({ closedExcelData });
	}

	async getRateList(productAgentList) {
		let { start_date, end_date, pid, closeTags } = this.state;
		//console.log('closeTags', closeTags, productAgentList);
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		let closedData = await fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: {
					type: ['closed'],
					pid: pid,
				},
				select: '',
				group: ['type', 'agent', 'rate', 'cid', 'date', 'platform', 'topic'],
				pairs: {
					agent: 'select1',
					topic: 'select2',
					cid: 'select4',
					rate: 'select5',
					platform: 'platform',
				},
				options: {
					sum: 0,
				},
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				return _.filter(response.result, (it) => it.agent !== pid);
			});
		let filterData = _.filter(closedData, (o) => o.rate);
		let rateList = _.map(filterData, function (o) {
			let agent = _.find(productAgentList, { id: o.agent });
			let topic = _.find(closeTags, { id: o.topic });
			return {
				agent: (agent && agent.developer) || o.agent,
				name: (agent && agent.name) || '',
				count: o.count,
				date: moment(o.date).format('DD-MM-YYYY'),
				len: o.len,
				platform: o.platform,
				rate: o.rate,
				topic: (topic && topic.title) || o.topic,
				cid: o.cid,
			};
		});
		this.setState({ rateList });
	}
	async getClosedDataTopic() {
		let { start_date, end_date, pid, closeTags } = this.state;
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		//let closedExcelData = productAgentList;
		let closedData = await fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: {
					type: ['closed'],
					pid: pid,
				},
				select: '',
				group: ['type', 'topic', 'rate'],
				pairs: { agent: 'select1', topic: 'select2', rate: 'select5' },
				options: {
					sum: 0,
				},
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				return _.filter(response.result, (it) => it.topic !== 'auto_close');
			});
		let ng = _.map(closedData, function (it) {
			return {
				count: it.count,
				type: it.type,
				agent: it.agent,
				topic: it.topic,
				rate: parseInt(it.rate) || 0,
				total: it.count * (parseInt(it.rate) || 0),
			};
		});
		let groupAgentData = _.groupBy(ng, 'topic');
		/* _.map(closedExcelData, function (it) {
			return _.assign(it, { count: 0, ratedCount: 0, avgRate: 0 });
		}); */
		let newData = [];

		newData = _.map(groupAgentData, function (it) {
			return {
				count: _.sumBy(it, 'count'),
				type: it[0].type,
				agent: it[0].agent,
				topic: (_.find(closeTags, { id: it[0].topic }) && _.find(closeTags, { id: it[0].topic }).title) || it[0].topic,
				ratedCount: _.sumBy(
					_.filter(it, function (o) {
						return o.rate > 0;
					}),
					'count'
				),
				total: _.sumBy(it, 'total'),
				avgRate:
					(
						_.sumBy(
							_.filter(it, function (o) {
								return o.rate > 0;
							}),
							'count'
						) &&
						_.sumBy(it, 'total') /
							_.sumBy(
								_.filter(it, function (o) {
									return o.rate > 0;
								}),
								'count'
							)
					).toFixed(2) || 0,

				rates_one: _.sumBy(
					_.filter(it, (o) => o.rate === 1),
					'count'
				),
				rates_two: _.sumBy(
					_.filter(it, (o) => o.rate === 2),
					'count'
				),
				rates_three: _.sumBy(
					_.filter(it, (o) => o.rate === 3),
					'count'
				),
				rates_four: _.sumBy(
					_.filter(it, (o) => o.rate === 4),
					'count'
				),
				rates_five: _.sumBy(
					_.filter(it, (o) => o.rate === 5),
					'count'
				),
			};
		});

		this.setState({ closedTopicData: newData });
	}
	async getGeneralTableData() {
		let { start_date, end_date, pid } = this.state;
		let start_date_1 = moment(start_date).format();
		let end_date_1 = moment(end_date).format();
		/* await fetch('https://live.dahi.ai/analytics/dialogtab/uniqueUserCountsByMessage', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					loadinguniqueUsers: false,
					uniqueUsers: data && data.data,
				});
			}); */
		await fetch('https://log.yapaytech.com/live/getEventInfoGeneral?filtertest', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				filter: {
					label: 'activateAgent',
					pid: this.state.pid,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					loadingTotalWaitingConv: false,
					totalWaitingConv: data.result.total,
				});
			});
		await fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date_1,
				end_date: end_date_1,
				include_hour: true,
				filter: {
					type: ['contacted'],
					pid: this.state.pid,
				},
				select: [],
				group: ['type'],
				pairs: { agent: 'type1' },
				options: {
					cids: 'count(distinct select2)',
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					contactedData: (data && data.result && data.result[0] && data.result[0].cids && parseInt(data.result[0].cids)) || 0,
				});
			});
		await fetch('https://log.yapaytech.com/live/test/getInfo?closeddata', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date_1,
				end_date: end_date_1,
				include_hour: true,
				filter: { type: ['closed'], pid: this.state.pid },
				select: [],
				group: ['type', 'agent'],
				pairs: { agent: 'select1' },
				options: {},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let filter = _.filter(data.result, function (it) {
					return it.agent !== '5e6f519560b2b4d07559fcf8';
				});
				let onlyAgentCounts = _.filter(data.result, function (it) {
					return it.agent !== pid;
				});

				this.setState({
					totalClosedData: _.sumBy(filter, 'count') || 0,
					totalAgentClosedData: _.sumBy(onlyAgentCounts, 'count'),
				});
			});
	}
	async getTimeData(productAgentList) {
		let { start_date, end_date } = this.state;
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		let timeExcelData = productAgentList;
		let timeData = await fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: { type: ['agentChatTime', 'chatTimeAfterActivate', 'agentFirstResponseTimeAfterActivate'], pid: this.state.pid },
				select: '',
				group: ['type', 'agent'],
				pairs: { agent: 'select1', topic: 'select2' },
				options: {
					sum: 1,
					avg: 1,
				},
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				return response.result;
			});
		/* let timeDataDate = await fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				filter: { type: ['agentChatTime', 'chatTimeAfterActivate', 'agentFirstResponseTimeAfterActivate'], pid: this.state.pid },
				select: '',
				group: ['type', 'day'],
				pairs: { agent: 'select1', topic: 'select2' },
				options: {
					sum: 1,
					avg: 1,
				},
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				return response.result;
			});
		let timeDataDateExcel = _.map(timeDataDate, function (it) {
			return _.merge(it, {
				dayNumber: moment(it.day).dayOfYear(),
			});
		});
		timeDataDateExcel = _.groupBy(timeDataDateExcel, 'type');
		let agentChatTimeData = timeDataDateExcel.agentChatTime;
		let agentFirstResponseTimeAfterActivateData = timeDataDateExcel.agentFirstResponseTimeAfterActivate;
		let chatTimeAfterActivateData = timeDataDateExcel.chatTimeAfterActivate;
		let timeDataDateExcelL = _.map(agentChatTimeData, function (it, i) {
			_.merge(it, { agentChatTime: it.avg });
		});
		//console.log('data', timeDataDateExcel); */
		_.map(timeData, (it) => {
			var e = _.findIndex(timeExcelData, function (o) {
				return o.id === it.agent;
			});
			/* if (e < 0 && /bot/.test(it.agent)) return timeExcelData.push({ id: it.agent, developer: 'Bot' }); */
			if (e >= 0) return (timeExcelData[e][it.type] = it.avg && parseFloat((it.avg / 1000 / 60).toFixed(2)));
		});
		_.map(timeExcelData, (it) => {
			_.map(['agentChatTime', 'chatTimeAfterActivate', 'agentFirstResponseTimeAfterActivate'], (d) => {
				if (it && !it[d]) {
					it[d] = 0;
				}
			});
		});
		this.setState({ timeExcelData });
	}
	formatTime(value) {
		return (
			value._data.days * 24 +
			value._data.hours +
			':' +
			moment(value._data.minutes, 'm').format('mm') +
			':' +
			moment(value._data.seconds, 's').format('ss')
		);
	}
	async getAllTimeData() {
		let { start_date, end_date } = this.state;
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		let timeAllExcelData = [{ developer: 'All', name: 'All', agentChatTime: 0, chatTimeAfterActivate: 0, agentFirstResponseTimeAfterActivate: 0 }];
		let timeAllExcelDataG = [{ developer: 'All', name: 'All', agentChatTime: 0, chatTimeAfterActivate: 0, agentFirstResponseTimeAfterActivate: 0 }];
		let timeAllExcelDataAll = [{ developer: 'All', name: 'All', agentChatTime: 0, chatTimeAfterActivate: 0, agentFirstResponseTimeAfterActivate: 0 }];
		let timeData = await fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: { type: ['agentChatTime', 'chatTimeAfterActivate', 'agentFirstResponseTimeAfterActivate'], pid: this.state.pid },
				select: '',
				group: ['type'],
				pairs: { agent: 'select1', topic: 'select2' },
				options: {
					sum: 1,
					avg: 1,
				},
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				return response.result;
			});
		_.map(timeData, (it) => {
			let avgMo = moment.duration(it.avg);
			let sumMo = moment.duration(it.sum);

			timeAllExcelData[0][it.type] = it.avg && parseFloat((it.avg / 1000 / 60).toFixed(2));
			timeAllExcelDataG[0][it.type] = avgMo && this.formatTime(avgMo);
			timeAllExcelDataAll[0][it.type] = sumMo && this.formatTime(sumMo);
		});
		this.setState({ timeAllExcelData, timeAllExcelDataAll, timeAllExcelDataG });
	}
	async getSendingProduct() {
		let { start_date, end_date } = this.state;
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		let sendingData = await fetch('https://live.dahi.ai/analytics/sharedProductCount', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				return response.data;
			});
		let agentData = sendingData.byAgent;
		/* let dayData = _.map(sendingData.byDate, function (it) {
			return _.merge(it, { dayNumber: moment(it.date).dayOfYear() });
		}); */
		_.map(agentData, function (it, i) {
			return (agentData[i].rate = (it.c_total && parseFloat((it.total / it.c_total).toFixed(2))) || 0);
		});
		this.setState({ sendingData: agentData });
	}
	async getSellingData(productAgentList) {
		let { start_date, end_date } = this.state;
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		let sellingExcelData = productAgentList;
		let sellingData = await fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				filter: {
					type: ['orderCreated'],
					pid: this.state.pid,
				},
				select: '',
				group: ['type', 'agent'],
				pairs: { agent: 'select5', kod: 'select2' },
				options: {
					sum: 1,
					avg: 1,
				},
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				return response.result;
			});
		/* let sellingDataDate = await fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				filter: {
					type: ['orderCreated'],
					pid: this.state.pid,
					topic: ['01', '12', '14', 'SİPARİŞ TAMAMLANDI'],
				},
				select: '',
				group: ['type', 'day'],
				pairs: { agent: 'select5', kod: 'select2', topic: 'select3' },
				options: {
					sum: 1,
					avg: 1,
				},
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				return response.result;
			});
		let sellingDataDateExcel = _.map(sellingDataDate, function (it) {
			return _.merge(it, {
				order_count: it.len,
				product_count: it.count,
				date: moment(it.day).format('DD-MM-YYYY'),
				dayNumber: moment(it.day).dayOfYear(),
			});
		});
		//console.log('selling', sellingDataDateExcel); */
		let othersdata = {
			avg: 0,
			basketrate: 0,
			count: 0,
			id: 'others',
			key: 'others',
			len: 0,
			sum: 0,
			text: 'others',
			value: 'others',
		};
		_.map(sellingData, function (it) {
			var e = _.findIndex(sellingExcelData, { id: it.agent });
			if (e >= 0) {
				return _.assign(sellingExcelData[e], { sellingCount: parseFloat(it.len) || 0, productCount: it.count, sellingSum: it.sum, sellingAvg: it.avg });
			} else {
				if (!/bot/.test(it.agent)) {
					othersdata = _.assign({
						count: othersdata.count + it.count,
						len: othersdata.len + parseFloat(it.len),
						sum: othersdata.sum + it.sum,
					});
				}
			}
		});
		othersdata = {
			productCount: othersdata.count,
			sellingCount: othersdata.len,
			sellingSum: othersdata.sum,
			sellingAvg: othersdata.len ? parseFloat((othersdata.sum / othersdata.len).toFixed(2)) : 0,
			developer: 'Eski Agentlar',
		};
		let sellingBotData = _.map(
			_.filter(sellingData, function (it) {
				return /bot/.test(it.agent);
			}),
			function (o) {
				return { developer: 'Bot', sellingCount: parseFloat(o.len) || 0, productCount: o.count, sellingSum: o.sum, sellingAvg: o.avg };
			}
		);
		_.map(sellingExcelData, (it) => {
			_.map(['sellingCount', 'productCount', 'sellingSum', 'sellingAvg'], (d) => {
				if (it && !it[d]) {
					it[d] = 0;
				}
			});
		});
		this.setState({ sellingExcelData, othersdata, sellingBotData });
	}
	async getOrderData() {
		let { start_date, end_date } = this.state;
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		let orderData = await fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				filter: {
					type: ['orderCreated', 'orderCanceled', 'orderRefunded'],
					pid: this.state.pid,
				},
				select: '',
				group: ['type', 'agent', 'kod', 'day', 'id'],
				pairs: { agent: 'select5', kod: 'select2', id: 'select7' },
				options: {
					sum: 1,
				},
				agg: {
					id: 'array_agg(distinct select7) filter(where select7 notnull)',
				},
				from: `(select  COALESCE(lr.total, lr2.total) as total, lr.type, lr.pid, lr.select1, lr.select2, lr.select3, lr.select4, lr.select5, lr.select6, lr.select7, lr.count, lr.date, lr.day, lr.hour, lr.platform  from (select *  from live_refined lr where date >= '${moment(
					start_date
				).format('YYYY-MM-DD HH:mm:ss')}.000' and date < '${moment(end_date).format('YYYY-MM-DD HH:mm:ss')}.000' and pid = '${
					this.state.pid
				}') lr left join live_refined lr2 on lr2.select2=lr.select2 and lr2.type='orderCreated' and lr.type <> 'orderCreated') as lr`,
			}),
		})
			.then((response) => response.json())
			.then((response) => {
				return response.result;
			});
		_.map(orderData, (it) => {
			it.dayNumber = moment(it.day).dayOfYear();
			it.date = moment(it.day).format('DD-MM-YYYY');
		});
		orderData = _.orderBy(orderData, ['dayNumber'], ['desc']);
		this.setState({
			orderExcelData: _.filter(orderData, function (it) {
				return it.type === 'orderCreated' && (it.kod || it.id);
			}),
			orderCanceledExcelData: _.filter(orderData, function (it) {
				return it.type === 'orderCanceled' && (it.kod || it.id);
			}),
			orderRefundedExcelData: _.filter(orderData, function (it) {
				return it.type === 'orderRefunded' && (it.kod || it.id);
			}),
			orderOtherData: _.filter(orderData, function (it) {
				return it.type !== 'orderCreated';
			}),
		});
	}
	async getTemplateData() {
		let { start_date, end_date } = this.state;
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		await fetch('https://log.yapaytech.com/live/getEventInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				filter: {
					label: 'template',
					pid: this.state.pid,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ templateTotalData: data.result.total, loading: false });
			});
	}
	async getConversationData() {
		let { start_date, end_date } = this.state;
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		await fetch('https://live.dahi.ai/analytics/conversationByGroup', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ conversationGroupData: data.data, loading: false });
			});
	}

	async getStockInfo() {
		let { start_date, end_date } = this.state;
		await fetch('https://log.yapaytech.com/live/getEvents', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date,
				end_date,
				filter: {
					label: 'out-of-stock',
					pid: this.state.pid,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let newdata = [];
				_.map(_.orderBy(data.result, 'created_at', 'desc'), (it) =>
					newdata.push({
						product_code: it.extra.product_code,
						product_name: it.extra.product_name,
						size: it.extra.product_size,
						phone: it.extra.user_phone,
						date: moment(it.created_at).format('DD-MM-YYYY HH:mm'),
					})
				);
				this.setState({ stockresult: newdata });
			});
	}
	async getGeneralGraph() {
		let { start_date, end_date, pid } = this.state;
		await fetch('https://log.yapaytech.com/live/getEventInfoGeneral?filtertest', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				filter: {
					label: 'activateAgent',
					pid: pid,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					loadingTotalWaitingConv: false,
					totalWaitingConv: data.result.total,
					totalWaitingcConv: data.result.c_total,
					totalWaitingConvHourly: data.result.result,
				});
			});
		let start_date_1 = moment(start_date).format();
		let end_date_1 = moment(end_date).format();
		await fetch('https://log.yapaytech.com/live/test/getInfo?dayHho', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date_1,
				end_date: end_date_1,
				include_hour: true,
				filter: { type: ['closed', 'contacted'], pid },
				select: [],
				group: ['type', 'agent', 'day', 'hour'],
				pairs: { agent: 'select1' },
				options: {},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				_.each(data.result, function (it) {
					return (it.dayNumber = moment(it.day).dayOfYear());
				});
				let filter = _.filter(data.result, function (it) {
					return it.agent !== '5e6f519560b2b4d07559fcf8';
				});
				let onlyAgentCounts = _.groupBy(
					_.filter(data.result, function (it) {
						return it.agent !== pid && it.type === 'closed';
					}),
					'dayNumber'
				);
				this.setState({
					loadingtotalClosedDataHourly: false,
					totalClosedDataHourly: _.sumBy(filter, 'count') || 0,
					totalAgentClosedDataHourly: onlyAgentCounts,
				});
			});
		await fetch('https://log.yapaytech.com/live/test/getInfo?dayHho', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date_1,
				end_date: end_date_1,
				include_hour: true,
				filter: { type: ['contacted'], pid },
				select: [],
				group: ['type', 'agent', 'day', 'hour'],
				pairs: { agent: 'select1' },
				options: {
					cids: 'count(distinct select2)',
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				_.each(data.result, function (it) {
					return (it.dayNumber = moment(it.day).dayOfYear());
				});
				_.each(data.result, function (it) {
					return (it.count = parseInt(it.cids));
				});
				let contactedDataHourly = _.groupBy(
					_.filter(data.result, function (it) {
						return it.type === 'contacted';
					}),
					'dayNumber'
				);
				this.setState({
					contactedDataHourly,
				});
			});
		await fetch('https://log.yapaytech.com/live/getEventInfoGeneralByHour', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				filter: {
					label: 'activateAgent',
					pid: pid,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					totalWaitingConvDayHourly: data.result.result,
				});
			});
		await fetch('https://log.yapaytech.com/live/test/getInfo?dayHho', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date_1,
				end_date: end_date_1,
				include_hour: true,
				filter: { type: ['contacted'], pid },
				select: [],
				group: ['type', 'agent', 'hour'],
				pairs: { agent: 'select1' },
				options: {
					cids: 'count(distinct select2)',
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				_.each(data.result, function (it) {
					return (it.count = parseInt(it.cids));
				});
				let contactedDataDayHourly = _.groupBy(
					_.filter(data.result, function (it) {
						return it.type === 'contacted';
					}),
					'hour'
				);
				this.setState({
					contactedDataDayHourly,
				});
			});
		await fetch('https://log.yapaytech.com/live/test/getInfo?dayHho', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date_1,
				end_date: end_date_1,
				include_hour: true,
				filter: { type: ['closed'], pid },
				select: [],
				group: ['type', 'agent', 'hour'],
				pairs: { agent: 'select1' },
				options: {},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let filter = _.filter(data.result, function (it) {
					return it.agent !== '5e6f519560b2b4d07559fcf8';
				});
				let onlyAgentCounts = _.groupBy(
					_.filter(data.result, function (it) {
						return it.agent !== pid && it.type === 'closed';
					}),
					'hour'
				);
				this.setState({
					loadingtotalClosedDataDayHourly: false,
					totalClosedDataDayHourly: _.sumBy(filter, 'count') || 0,
					totalAgentClosedDataDayHourly: onlyAgentCounts,
				});
			});
	}
	exportExcel = (t) => {
		let value = this.state.productAgentList;
		//console.log('cc', value);
		let product = this.props.product.live;
		let pid = this.state.pid;
		let {
			start_date,
			end_date,
			contactedDataHourly,
			totalAgentClosedDataHourly,
			totalWaitingConvHourly,
			totalWaitingConvDayHourly,
			contactedDataDayHourly,
			totalAgentClosedDataDayHourly,
			rateList,
		} = this.state;
		let ecommerce, sendProduct, conversationGroup, showTemplate, ratePoint, generalTable, stockinfo;
		//timeline = true;
		if (product.settings.analyticView) {
			ecommerce = product.settings.analyticView.ecommerce;
			sendProduct = product.settings.analyticView.sendProduct;
			conversationGroup = product.settings.analyticView.conversationGroup;
			showTemplate = product.settings.analyticView.showTemplate;
			//timeline = product.settings.analyticView.timeline;
			//userlist = product.settings.analyticView.userlist;
			ratePoint = product.settings.analyticView.ratePoint;
			generalTable = product.settings.analyticView.generalTable;
			stockinfo = product.settings.analyticView.stockinfo;
		}
		let closedTopic = this.state.closedTopicData;
		let closedXAgent = ratePoint
			? [
					{ title: t('developer'), key: 'developer' },
					{ title: t('name'), key: 'name' },
					{ title: t('closed'), key: 'closed' },
					{ title: t('rateCount'), key: 'ratedCount' },
					{ title: t('averageRate'), key: 'avgRate' },
					{ title: '1', key: 'rates_one' },
					{ title: '2', key: 'rates_two' },
					{ title: '3', key: 'rates_three' },
					{ title: '4', key: 'rates_four' },
					{ title: '5', key: 'rates_five' },
			  ]
			: [
					{ title: t('developer'), key: 'developer' },
					{ title: t('closed'), key: 'closed' },
			  ];
		let closedXTopic = ratePoint
			? [
					{ title: t('topic'), key: 'topic' },
					{ title: t('count'), key: 'count' },
					{ title: t('rateCount'), key: 'ratedCount' },
					{ title: t('averageRate'), key: 'avgRate' },
					{ title: '1', key: 'rates_one' },
					{ title: '2', key: 'rates_two' },
					{ title: '3', key: 'rates_three' },
					{ title: '4', key: 'rates_four' },
					{ title: '5', key: 'rates_five' },
			  ]
			: [
					{ title: t('topic'), key: 'topic' },
					{ title: t('count'), key: 'count' },
			  ];
		let sendingData = this.state.sendingData;
		let sellingBotData = this.state.sellingBotData;
		let timeAllExcelData = this.state.timeAllExcelData;
		let orderExcelData = this.state.orderExcelData;
		let orderCanceledExcelData = this.state.orderCanceledExcelData;
		let orderRefundedExcelData = this.state.orderRefundedExcelData;
		let orderOtherData = this.state.orderOtherData;
		let othersdata = this.state.othersdata;
		let templateTotalData = this.state.templateTotalData;
		let conversationGroupData = this.state.conversationGroupData;
		let stockresult = this.state.stockresult;
		let orderExcelDataFiltered = [];
		let generalTableData = [],
			generalGraphDataHourly = [],
			generaldataDayHourly = [];
		if (ecommerce) {
			_.map(orderExcelData, (it) => {
				it.developer = (_.find(value, { id: it.agent }) && _.find(value, { id: it.agent }).developer) || 'Bot';
				it.name = (_.find(value, { id: it.agent }) && _.find(value, { id: it.agent }).name) || 'Bot';
				it.status = (_.find(orderOtherData, { kod: it.kod }) && t(_.find(orderOtherData, { kod: it.kod }).type)) || t('created');
				it.id = (it.id && it.id[0]) || '';
			});
			_.map(orderCanceledExcelData, (it) => {
				it.developer = (_.find(value, { id: it.agent }) && _.find(value, { id: it.agent }).developer) || 'Bot';
				it.name = (_.find(value, { id: it.agent }) && _.find(value, { id: it.agent }).name) || 'Bot';
				it.status = t(it.type);
				it.id = (it.id && it.id[0]) || '';
			});
			_.map(orderRefundedExcelData, (it) => {
				it.developer = (_.find(value, { id: it.agent }) && _.find(value, { id: it.agent }).developer) || 'Bot';
				it.name = (_.find(value, { id: it.agent }) && _.find(value, { id: it.agent }).name) || 'Bot';
				it.status = t(it.type);
				it.id = (it.id && it.id[0]) || '';
			});
			orderExcelDataFiltered = _.map(_.groupBy(orderExcelData, 'kod'), function (it) {
				return it[0];
			});
		}
		let filtervalue = _.filter(value, function (o) {
			return o.developer !== 'sephora@yapaytech.com';
		});
		let sellingValue = _.concat(filtervalue, sellingBotData);
		if (othersdata && othersdata.productCount) {
			sellingValue = _.concat(sellingValue, othersdata);
		}
		//console.log('filtervalue', filtervalue);
		let sellingConcatValue = _.concat(sellingValue, {
			developer: 'All',
			name: 'All',
			count: _.sumBy(filtervalue, 'count'),
			ratedCount: _.sumBy(filtervalue, 'ratedCount'),
			viewed: _.sumBy(filtervalue, 'viewed'),
			taken: _.sumBy(filtervalue, 'taken'),
			contacted: _.sumBy(filtervalue, 'contacted'),
			forwarded: _.sumBy(filtervalue, 'forwarded'),
			closed: _.sumBy(filtervalue, 'closed'),
			avgRate: _.sumBy(filtervalue, 'ratedCount') ? (_.sumBy(filtervalue, 'total') / _.sumBy(filtervalue, 'ratedCount')).toFixed(2) : 0,
			sellingCount: _.sumBy(sellingValue, 'sellingCount'),
			productCount: _.sumBy(sellingValue, 'productCount'),
			sellingSum: _.sumBy(sellingValue, 'sellingSum'),
			sellingAvg: _.sumBy(sellingValue, 'sellingCount') && _.sumBy(sellingValue, 'sellingSum') / _.sumBy(sellingValue, 'sellingCount'),
			agentChatTime: timeAllExcelData[0].agentChatTime,
			agentFirstResponseTimeAfterActivate: timeAllExcelData[0].agentFirstResponseTimeAfterActivate,
			chatTimeAfterActivate: timeAllExcelData[0].chatTimeAfterActivate,
			rates_one: _.sumBy(filtervalue, 'rates_one'),
			rates_two: _.sumBy(filtervalue, 'rates_two'),
			rates_three: _.sumBy(filtervalue, 'rates_three'),
			rates_four: _.sumBy(filtervalue, 'rates_four'),
			rates_five: _.sumBy(filtervalue, 'rates_five'),
		});
		sellingConcatValue = _.filter(sellingConcatValue, function (it) {
			return it && it.developer;
		});
		let concatValue = _.concat(value, {
			developer: 'All',
			name: 'All',
			count: _.sumBy(filtervalue, 'count'),
			ratedCount: _.sumBy(filtervalue, 'ratedCount'),
			viewed: _.sumBy(filtervalue, 'viewed'),
			taken: _.sumBy(filtervalue, 'taken'),
			contacted: _.sumBy(filtervalue, 'contacted'),
			forwarded: _.sumBy(filtervalue, 'forwarded'),
			closed: _.sumBy(filtervalue, 'closed'),
			avgRate: _.sumBy(filtervalue, 'ratedCount') ? (_.sumBy(filtervalue, 'total') / _.sumBy(filtervalue, 'ratedCount')).toFixed(2) : 0,
			sellingCount: _.sumBy(filtervalue, 'sellingCount'),
			productCount: _.sumBy(sellingValue, 'productCount'),
			sellingSum: _.sumBy(filtervalue, 'sellingSum'),
			sellingAvg: _.sumBy(filtervalue, 'sellingCount') && _.sumBy(filtervalue, 'sellingSum') / _.sumBy(filtervalue, 'sellingCount'),
			agentChatTime: timeAllExcelData[0].agentChatTime,
			agentFirstResponseTimeAfterActivate: timeAllExcelData[0].agentFirstResponseTimeAfterActivate,
			chatTimeAfterActivate: timeAllExcelData[0].chatTimeAfterActivate,
			rates_one: _.sumBy(filtervalue, 'rates_one'),
			rates_two: _.sumBy(filtervalue, 'rates_two'),
			rates_three: _.sumBy(filtervalue, 'rates_three'),
			rates_four: _.sumBy(filtervalue, 'rates_four'),
			rates_five: _.sumBy(filtervalue, 'rates_five'),
		});
		closedTopic.push({
			topic: 'All',
			count: _.sumBy(filtervalue, 'closed'),
			ratedCount: _.sumBy(filtervalue, 'ratedCount'),
			avgRate: _.sumBy(filtervalue, 'ratedCount') ? (_.sumBy(filtervalue, 'total') / _.sumBy(filtervalue, 'ratedCount')).toFixed(2) : 0,
			rates_one: _.sumBy(filtervalue, 'rates_one'),
			rates_two: _.sumBy(filtervalue, 'rates_two'),
			rates_three: _.sumBy(filtervalue, 'rates_three'),
			rates_four: _.sumBy(filtervalue, 'rates_four'),
			rates_five: _.sumBy(filtervalue, 'rates_five'),
		});
		if (sendProduct) {
			sendingData = _.filter(sendingData, function (it) {
				return it.email !== 'sephora@yapaytech.com';
			});
			sendingData.push({
				email: 'All',
				total: _.sumBy(sendingData, 'total'),
				c_total: _.sumBy(sendingData, 'c_total'),
				u_total: _.sumBy(sendingData, 'u_total'),
				rate: (_.sumBy(sendingData, 'c_total') && parseFloat((_.sumBy(sendingData, 'total') / _.sumBy(sendingData, 'c_total')).toFixed(2))) || 0,
			});
		}
		if (generalTable) {
			generalTableData = [
				{
					type: 'Toplam Bekleyen',
					count: this.state.totalWaitingConv,
				},
				{
					type: 'Toplam Etkileşime Geçilen',
					count: this.state.contactedData,
				},
				{
					type: 'Toplam Kayıp Konuşma',
					count: Math.max(this.state.totalWaitingConv - this.state.contactedData, 0),
				},
				{
					type: 'Toplam Kapatma',
					count: this.state.totalAgentClosedData,
				},
				{
					type: 'Bekleme Süresi',
					count: this.state.timeAllExcelDataG[0].agentFirstResponseTimeAfterActivate,
				},
				{
					type: 'Toplam Görüşme Süresi',
					count: this.state.timeAllExcelDataAll[0].chatTimeAfterActivate,
				},
				{
					type: 'Ortalama Görüşme Süresi',
					count: this.state.timeAllExcelDataG[0].chatTimeAfterActivate,
				},
			];
			generalGraphDataHourly = chartData(contactedDataHourly, totalAgentClosedDataHourly, totalWaitingConvHourly, start_date, end_date, t);
			generaldataDayHourly = chartDataDayHourly(
				totalWaitingConvDayHourly,
				contactedDataDayHourly,
				totalAgentClosedDataDayHourly,
				start_date,
				end_date,
				t
			);
		}
		if (sendProduct) {
			_.each(sellingConcatValue, function (it) {
				let d = _.find(sendingData, function (o) {
					return it.developer === o.email;
				});
				if (d) {
					it.send_total = d.total;
					it.send_c_total = d.c_total;
					it.send_u_total = d.u_total;
					it.send_rate = d.rate;
				} else {
					it.send_total = 0;
					it.send_c_total = 0;
					it.send_u_total = 0;
					it.send_rate = 0;
				}
			});
		}

		let filterConcatValue = _.filter(concatValue, function (o) {
			return o.developer !== 'sephora@yapaytech.com';
		});
		let allX = [
			{ title: t('developer'), key: 'developer' },
			{ title: t('name'), key: 'name' },
			{ title: t('viewed'), key: 'viewed' },
			{ title: t('taken'), key: 'taken' },
			{ title: t('contacted'), key: 'contacted' },
			{ title: t('closed'), key: 'closed' },
			{ title: t('forwarded'), key: 'forwarded' },
			{ title: t('firstResponseActivate'), key: 'agentFirstResponseTimeAfterActivate' },
			{ title: t('agentChatTime'), key: 'agentChatTime' },
			{ title: t('chatTimeActivate'), key: 'chatTimeAfterActivate' },
		];
		//console.log('concatValue', concatValue);
		let sheets = [
			{
				name: 'agent',
				x: [
					{ title: t('developer'), key: 'developer' },
					{ title: t('name'), key: 'name' },
					{ title: t('viewed'), key: 'viewed' },
					{ title: t('taken'), key: 'taken' },
					{ title: t('contacted'), key: 'contacted' },
					{ title: t('closed'), key: 'closed' },
					{ title: t('forwarded'), key: 'forwarded' },
				],
				datasets: _.filter(concatValue, function (o) {
					return o.developer !== 'sephora@yapaytech.com';
				}),
			},
			{
				name: 'closed_agent',
				x: closedXAgent,
				datasets: _.filter(concatValue, function (o) {
					return o.developer !== 'sephora@yapaytech.com';
				}),
			},
			{
				name: 'closed_topic',
				x: closedXTopic,
				datasets: closedTopic,
			},
			{
				name: 'time',
				x: [
					{ title: t('developer'), key: 'developer' },
					{ title: t('name'), key: 'name' },
					{ title: t('firstResponseActivate'), key: 'agentFirstResponseTimeAfterActivate' },
					{ title: t('agentChatTime'), key: 'agentChatTime' },
					{ title: t('chatTimeActivate'), key: 'chatTimeAfterActivate' },
				],
				datasets: filterConcatValue,
			},
		];
		if (ratePoint) {
			sheets.push({
				name: 'rate_list',
				x: [
					{ title: t('email'), key: 'agent' },
					{ title: t('name'), key: 'name' },
					{ title: t('rate'), key: 'rate' },
					{ title: t('topic'), key: 'topic' },
					{ title: t('date'), key: 'date' },
					{ title: t('platform'), key: 'platform' },
					{ title: t('conversation'), key: 'cid' },
				],
				datasets: rateList,
			});
		}
		if (sendProduct) {
			sheets.push({
				name: 'sending_product',
				x: [
					{ title: t('email'), key: 'email' },
					{ title: t('name'), key: 'name' },
					{ title: t('total'), key: 'total' },
					{ title: t('productConversation'), key: 'c_total' },
					{ title: t('u_total'), key: 'uniqueProduct' },
					{ title: t('rate'), key: 'rate' },
				],
				datasets: _.filter(sendingData, function (o) {
					return o.email !== 'sephora@yapaytech.com';
				}),
			});
			allX = _.concat(
				allX,
				{ title: t('total'), key: 'send_total' },
				{ title: t('productConversation'), key: 'send_c_total' },
				{ title: t('u_total'), key: 'send_u_total' },
				{ title: t('rate'), key: 'send_rate' }
			);
		}
		if (ecommerce) {
			sheets.push({
				name: 'selling',
				x: [
					{ title: t('developer'), key: 'developer' },
					{ title: t('name'), key: 'name' },
					{ title: t('orderCount'), key: 'sellingCount' },
					{ title: t('productCount'), key: 'productCount' },
					{ title: t('sum'), key: 'sellingSum' },
					{ title: t('average'), key: 'sellingAvg' },
				],
				datasets: sellingConcatValue,
			});
			allX = _.concat(
				allX,
				{ title: t('orderCount'), key: 'sellingCount' },
				{ title: t('productCount'), key: 'productCount' },
				{ title: t('sum'), key: 'sellingSum' },
				{ title: t('average'), key: 'sellingAvg' }
			);
		}
		/* if (ecommerce) {
			sheets.push({
				name: 'selling_date',
				x: ['date', 'order_count', 'product_count', 'sum', 'avg'],
				datasets: sellingDataDateExcel,
			});
		} */
		if (ecommerce) {
			sheets.push({
				name: 'order_all',
				x: [
					{ title: t('kod'), key: 'kod' },
					{ title: t('id'), key: 'id' },
					{ title: t('count'), key: 'count' },
					{ title: t('sum'), key: 'sum' },
					{ title: t('date'), key: 'date' },
					{ title: t('name'), key: 'name' },
					{ title: t('developer'), key: 'developer' },
					{ title: t('status'), key: 'status' },
				],
				datasets: orderExcelDataFiltered,
			});
		}
		if (ecommerce && orderCanceledExcelData && orderCanceledExcelData.length) {
			sheets.push({
				name: 'order_canceled',
				x: [
					{ title: t('kod'), key: 'kod' },
					{ title: t('count'), key: 'count' },
					{ title: t('sum'), key: 'sum' },
					{ title: t('date'), key: 'date' },
					{ title: t('name'), key: 'name' },
					{ title: t('developer'), key: 'developer' },
					{ title: t('status'), key: 'status' },
				],
				datasets: orderCanceledExcelData,
			});
		}
		if (ecommerce && orderRefundedExcelData && orderRefundedExcelData.length) {
			sheets.push({
				name: 'order_refunded',
				x: [
					{ title: t('kod'), key: 'kod' },
					{ title: t('count'), key: 'count' },
					{ title: t('sum'), key: 'sum' },
					{ title: t('date'), key: 'date' },
					{ title: t('name'), key: 'name' },
					{ title: t('developer'), key: 'developer' },
					{ title: t('status'), key: 'status' },
				],
				datasets: orderRefundedExcelData,
			});
		}
		if (showTemplate) {
			sheets.push({
				name: 'template',
				x: [
					{ title: t('total'), key: 'total' },
					{ title: t('count'), key: 'count' },
				],
				datasets: [{ total: 'sending', count: templateTotalData }],
			});
		}
		if (generalTable) {
			sheets.push({
				name: 'generalTable',
				x: [
					{ title: t('type'), key: 'type' },
					{ title: t('count'), key: 'count' },
				],
				datasets: generalTableData,
			});
			sheets.push({
				name: 'generalHourlyGraph',
				x: [
					{ title: t('label'), key: t('label') },
					{ title: t('totalWaitingAllC'), key: t('totalWaitingAllC') },
					{ title: t('totalContacted'), key: t('totalContacted') },
					{ title: t('dontConnecttedDescription'), key: t('dontConnecttedDescription') },
					{ title: t('totalClosed'), key: t('totalClosed') },
				],
				datasets: generalGraphDataHourly.excelData,
			});
			sheets.push({
				name: 'generalGraph',
				x: [
					{ title: t('label'), key: t('label') },
					{ title: t('totalWaitingAllC'), key: t('totalWaitingAllC') },
					{ title: t('totalContacted'), key: t('totalContacted') },
					{ title: t('dontConnecttedDescription'), key: t('dontConnecttedDescription') },
					{ title: t('totalClosed'), key: t('totalClosed') },
				],
				datasets: generaldataDayHourly.excelData,
			});
		}
		if (conversationGroup) {
			sheets.push({
				name: 'conversationGroup',
				x: [
					{ title: '_id', key: '_id' },
					{ title: t('total'), key: 'total' },
				],
				datasets: conversationGroupData,
			});
		}
		if (stockinfo) {
			sheets.push({
				name: 'stockWarning',
				x: [
					{ title: t('product_code'), key: 'product_code' },
					{ title: t('product_name'), key: 'product_name' },
					{ title: t('size'), key: 'size' },
					{ title: t('phone'), key: 'phone' },
					{ title: t('date'), key: 'date' },
				],
				datasets: stockresult,
			});
		}
		sheets.push({ name: 'all', x: allX, datasets: sellingConcatValue });
		fetch('https://log.yapaytech.com/analytic/download', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				filename: moment(start_date).format('DD-MM-YYYY_HH:mm') + '_' + moment(end_date).format('DD-MM-YYYY_HH:mm') + '_live_chat_analytic_' + pid,
				sheets,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let dataurl = data.url;

				var a = document.createElement('a');
				a.href = dataurl;
				a.setAttribute('download', dataurl);
				a.click();
			})
			.then(this.setState({ buttonLoading: false }));
		//console.log('fil', filtervalue);
		filtervalue = [];
	};
	render() {
		const { t, i18n } = this.props;
		//moment.locale(selectedLang);
		if (this.state.loading)
			return (
				<Page title={t('analytic')} icon="chart bar">
					<Page.Loader></Page.Loader>
				</Page>
			);
		let ecommerce, sendProduct, conversationGroup, showTemplate, timeline, userlist, stockinfo, timeSelect, ratePoint;
		timeline = true;
		if (this.props.product.live.settings.analyticView) {
			ecommerce = this.props.product.live.settings.analyticView.ecommerce;
			sendProduct = this.props.product.live.settings.analyticView.sendProduct;
			conversationGroup = this.props.product.live.settings.analyticView.conversationGroup;
			showTemplate = this.props.product.live.settings.analyticView.showTemplate;
			//timeline = this.props.product.live.settings.analyticView.timeline;
			userlist = this.props.product.live.settings.analyticView.userlist;
			stockinfo = this.props.product.live.settings.analyticView.stockinfo;
			timeSelect = this.props.product.live.settings.analyticView.timeSelect;
			ratePoint = this.props.product.live.settings.analyticView.ratePoint;
		}
		let { start_date, end_date, buttonLoading, start_time, end_time } = this.state;

		let ownerName =
			this.props && this.props.access && this.props.access.user && this.props.access.user.loggedUserObj && this.props.access.user.loggedUserObj.name;
		/* let ownerMail =
			this.props && this.props.access && this.props.access.user && this.props.access.user.loggedUserObj && this.props.access.user.loggedUserObj.email; */
		let newList = this.state.newList;
		let productAgentList = this.state.productAgentList;
		_.map(productAgentList, function (it, i) {
			let name = _.find(newList, { email: it.developer }) && _.find(newList, { email: it.developer }).name;
			if (i === 0) {
				return (it.name = ownerName || it.developer);
			}
			return (it.name = name ? name : it.developer);
		});
		let selectedLang = (i18n && i18n.languages && i18n.languages[0]) || 'en';
		let path = window.location.pathname;
		let isTimeSelectPage = /agent|closedtag|time|sendingproduct|order|selling/.test(path);
	//	let isPlatform = /agent|closedtag|time|order|selling/.test(path);
		let platformList = [
			{
				text: t('allPlatforms'),
				value: 'all',
			},
			{
				text: 'WhatsApp',
				value: 'whatsapp',
			},
			{
				text: 'Facebook',
				value: 'facebook',
			},
			{
				text: 'Instagram',
				value: 'instagram',
			},
			{
				text: 'Web',
				value: 'web',
			},
		];
		return (
			<Page title={t('analytic')} icon="chart bar" data={this.props}>
				<Page.Wrap>
					<Menu className="settings" pointing secondary stackable>
						{this.state.agent_role !== 'agent' ? (
							<>
								<Menu.Item as={NavLink} exact to="/oldanalytic/dashboard">
									<Icon name="table" />
									{t('dashboard')}
								</Menu.Item>
							</>
						) : (
							''
						)}

						<Menu.Item as={NavLink} exact to="/oldanalytic/agent">
							<Icon name="user" />
							{t('agent')}
						</Menu.Item>
						<Menu.Item as={NavLink} exact to="/oldanalytic/closedtag">
							<Icon name="window close" />
							{t('closeTag')}
						</Menu.Item>
						{ratePoint && (
							<Menu.Item as={NavLink} exact to="/oldanalytic/rateslist">
								<Icon name="star" />
								{t('rateslist')}
							</Menu.Item>
						)}
						<Menu.Item as={NavLink} exact to="/oldanalytic/time">
							<Icon name="time" />
							{t('times')}
						</Menu.Item>

						{timeline && this.state.agent_role !== 'agent' ? (
							<>
								<Menu.Item as={NavLink} exact to="/oldanalytic/timeline">
									<Icon name="bars" />
									{t('timeline')}
								</Menu.Item>
							</>
						) : (
							''
						)}
						{userlist && this.state.agent_role !== 'agent' ? (
							<>
								<Menu.Item as={NavLink} exact to="/oldanalytic/userlist">
									<Icon name="users" />
									{t('usersInfo')}
								</Menu.Item>
							</>
						) : (
							''
						)}
						{conversationGroup ? (
							<>
								<Menu.Item as={NavLink} exact to="/oldanalytic/conversationgroup">
									<Icon name="conversation" />
									{t('conversationGroup')}
								</Menu.Item>
							</>
						) : (
							''
						)}
						{sendProduct ? (
							<>
								<Menu.Item as={NavLink} exact to="/oldanalytic/sendingproduct">
									<Icon name="send" />
									{t('sendingProduct')}
								</Menu.Item>
							</>
						) : (
							''
						)}
						{ecommerce ? (
							<>
								<Menu.Item as={NavLink} exact to="/oldanalytic/selling">
									<Icon name="shopping cart" />
									{t('selling')}
								</Menu.Item>
								<Menu.Item as={NavLink} exact to="/oldanalytic/order">
									<Icon name="ordered list" />
									{t('order')}
								</Menu.Item>
							</>
						) : (
							''
						)}
						{stockinfo ? (
							<>
								<Menu.Item as={NavLink} exact to="/oldanalytic/stockinfo">
									<Icon name="ordered list" />
									{t('StockInfo')}
								</Menu.Item>
								<Menu.Item as={NavLink} exact to="/oldanalytic/eventtable">
									<Icon name="ordered list" />
									{t('event')}
								</Menu.Item>
							</>
						) : (
							''
						)}
						{showTemplate ? (
							<>
								<Menu.Item as={NavLink} exact to="/oldanalytic/template">
									<Icon name="whatsapp" />
									{t('Template')}
								</Menu.Item>
							</>
						) : (
							''
						)}
						{/* <Menu.Item as={NavLink} exact to="/oldanalytic/randevu">
								<Icon name="conversation" />
								Appointment
							</Menu.Item> */}
					</Menu>
					<div style={{ display: 'flex' }}>
						<div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: '20px' }}>
							<Radio toggle label={t('displayName')} onChange={this.toggleName}></Radio>
						</div>
						{/* 	{isPlatform && (
							<div>
								<Dropdown
									selection
									options={platformList}
									value={selectedPlatform}
									name="selectedPlatform"
									onChange={(e, { name, value }) => this.setState({ selectedPlatform: value })}
								></Dropdown>
							</div>
						)} */}
						<Popup
							trigger={
								<Button
									primary
									style={{ marginRight: '10px' }}
									content={`${moment(start_date).locale(selectedLang).format('MMM DD, YYYY')} - ${moment(end_date)
										.locale(selectedLang)
										.format('MMM DD, YYYY')}`}
									floated="right"
								/>
							}
							content={
								<Picker
									dateChange={this.dateChange}
									startDate={start_date.toDate()}
									endDate={end_date.toDate()}
									lang={selectedLang}
									timeChangeStart={this.timeChangeStart}
									timeChangeEnd={this.timeChangeEnd}
									startTime={start_time}
									endTime={end_time}
									timeSelect={timeSelect}
									isTimeSelectPage={isTimeSelectPage}
								/>
							}
							on="click"
							position="bottom right"
						/>
						{this.state.agent_role !== 'agent' ? (
							<Button
								style={{ margin: '0', minWidth: '35px', minHeight: '38px', padding: '5px' }}
								color="green"
								icon
								//loading={buttonLoading}
								onClick={() => this.createExcelData(productAgentList, t)}
							>
								{/* <Icon name="file excel"></Icon> */}
								{!buttonLoading ? <img src="/icons/excel-white.png" alt="" width="25px" /> : <Icon width="25px" loading name="spinner" />}
							</Button>
						) : (
							''
						)}
					</div>
				</Page.Wrap>
				<Switch>
					{this.state.agent_role !== 'agent' ? (
						<Route
							path="/oldanalytic/dashboard"
							render={() => (
								<Dashboard
									selectedPlatform={this.state.selectedPlatform}
									platformList={platformList}
									start_date={this.state.start_date}
									end_date={this.state.end_date}
									displayName={this.state.displayName}
								/>
							)}
						/>
					) : (
						''
					)}

					<Route
						path="/oldanalytic/time"
						render={() => (
							<Time
								selectedPlatform={this.state.selectedPlatform}
								platformList={platformList}
								start_date={this.state.start_date}
								end_date={this.state.end_date}
								displayName={this.state.displayName}
							/>
						)}
					/>
					<Route
						path="/oldanalytic/closedtag"
						render={() => (
							<Closed
								selectedPlatform={this.state.selectedPlatform}
								platformList={platformList}
								start_date={this.state.start_date}
								end_date={this.state.end_date}
								displayName={this.state.displayName}
							/>
						)}
					/>
					<Route
						path="/oldanalytic/rateslist"
						render={() => (
							<RatesList
								selectedPlatform={this.state.selectedPlatform}
								platformList={platformList}
								start_date={this.state.start_date}
								end_date={this.state.end_date}
								displayName={this.state.displayName}
							/>
						)}
					/>
					<Route
						path="/oldanalytic/agent"
						render={() => (
							<Agent
								selectedPlatform={this.state.selectedPlatform}
								platformList={platformList}
								start_date={this.state.start_date}
								end_date={this.state.end_date}
								displayName={this.state.displayName}
							/>
						)}
					/>
					{sendProduct ? (
						<Route
							path="/oldanalytic/sendingproduct"
							render={() => (
								<SendingProduct
									selectedPlatform={this.state.selectedPlatform}
									platformList={platformList}
									start_date={this.state.start_date}
									end_date={this.state.end_date}
									displayName={this.state.displayName}
								/>
							)}
						/>
					) : (
						''
					)}
					{ecommerce ? (
						<Route
							path="/oldanalytic/order"
							render={() => (
								<Order
									selectedPlatform={this.state.selectedPlatform}
									platformList={platformList}
									start_date={this.state.start_date}
									end_date={this.state.end_date}
									displayName={this.state.displayName}
								/>
							)}
						/>
					) : (
						''
					)}
					{ecommerce ? (
						<Route
							path="/oldanalytic/selling"
							render={() => (
								<Selling
									selectedPlatform={this.state.selectedPlatform}
									platformList={platformList}
									start_date={this.state.start_date}
									end_date={this.state.end_date}
									displayName={this.state.displayName}
								/>
							)}
						/>
					) : (
						''
					)}
					{stockinfo ? (
						<Route
							path="/oldanalytic/stockinfo"
							render={() => (
								<StockInfo
									selectedPlatform={this.state.selectedPlatform}
									platformList={platformList}
									start_date={this.state.start_date}
									end_date={this.state.end_date}
									displayName={this.state.displayName}
								/>
							)}
						/>
					) : (
						''
					)}
					{true || showTemplate ? (
						<Route
							path="/oldanalytic/template"
							render={() => (
								<Template
									selectedPlatform={this.state.selectedPlatform}
									platformList={platformList}
									start_date={this.state.start_date}
									end_date={this.state.end_date}
									displayName={this.state.displayName}
								/>
							)}
						/>
					) : (
						''
					)}
					{stockinfo ? (
						<Route
							path="/oldanalytic/eventtable"
							render={() => (
								<EventTable
									selectedPlatform={this.state.selectedPlatform}
									platformList={platformList}
									start_date={this.state.start_date}
									end_date={this.state.end_date}
									displayName={this.state.displayName}
								/>
							)}
						/>
					) : (
						''
					)}

					{true || conversationGroup ? (
						<Route
							path="/oldanalytic/conversationgroup"
							render={() => (
								<ConversationGroup
									selectedPlatform={this.state.selectedPlatform}
									platformList={platformList}
									start_date={this.state.start_date}
									end_date={this.state.end_date}
									displayName={this.state.displayName}
								/>
							)}
						/>
					) : (
						''
					)}
					{userlist && this.state.agent_role !== 'agent' ? (
						<Route
							path="/oldanalytic/userlist"
							render={() => (
								<UserList
									selectedPlatform={this.state.selectedPlatform}
									platformList={platformList}
									start_date={this.state.start_date}
									end_date={this.state.end_date}
									displayName={this.state.displayName}
								/>
							)}
						/>
					) : null}

					{timeline && this.state.agent_role !== 'agent' ? (
						<Route
							path="/oldanalytic/timeline"
							render={() => (
								<Timeline
									selectedPlatform={this.state.selectedPlatform}
									platformList={platformList}
									start_date={this.state.start_date}
									end_date={this.state.end_date}
									displayName={this.state.displayName}
								/>
							)}
						/>
					) : null}
					{/* <Route
						to="/oldanalytic/randevu"
						render={() => <Randevu selectedPlatform={this.state.selectedPlatform}
						platformList={platformList}start_date={this.state.start_date} end_date={this.state.end_date} displayName={this.state.displayName} />}
					/> */}
					{this.state.agent_role !== 'agent' ? (
						<Redirect
							to="/oldanalytic/dashboard"
							render={() => (
								<Dashboard
									selectedPlatform={this.state.selectedPlatform}
									platformList={platformList}
									start_date={this.state.start_date}
									end_date={this.state.end_date}
									displayName={this.state.displayName}
								/>
							)}
						/>
					) : (
						<Redirect
							to="/oldanalytic/agent"
							render={() => (
								<Agent
									selectedPlatform={this.state.selectedPlatform}
									platformList={platformList}
									start_date={this.state.start_date}
									end_date={this.state.end_date}
									displayName={this.state.displayName}
								/>
							)}
						/>
					)}
				</Switch>
			</Page>
		);
	}
}
// date array
var getDateArray = function (start, end) {
	var arr = [],
		dt = new Date(start);

	while (dt <= end) {
		arr.push(new Date(dt));
		dt.setDate(dt.getDate() + 1);
	}

	return arr;
};
var getHourArray = function (start, end) {
	var arr = [],
		dt = 0;

	while (dt < 24) {
		arr.push(dt);
		dt++;
	}
	return arr;
};
function chartData(contactedDataHourly, totalAgentClosedDataHourly, totalWaitingConvHourly, start_date, end_date, t) {
	let startDate = new Date(start_date);
	let endDate = new Date(end_date);
	let labels = [];
	var dateArr = getDateArray(startDate, endDate);
	var hourArr = getHourArray();
	_.map(dateArr, (it) => {
		return _.map(hourArr, (o) => {
			labels.push(moment(it).format('DD/MM') + ' - ' + moment(o, 'H').format('HH'));
		});
	});
	let contactedDataLineData = [],
		closedDataLineData = [],
		totalWaitingConvLineData = [],
		contactedDataHourlyObj,
		closedDataHourlyObj,
		totalWaitingConvHourlyObj;
	_.map(dateArr, (item, i) => {
		return _.map(hourArr, (it) => {
			totalWaitingConvHourlyObj = _.find(totalWaitingConvHourly, function (o) {
				return moment(o.date).dayOfYear() === moment(item).dayOfYear() && moment(o.date).hour() === it;
			});
			/* if (it >= 0) {
				contactedDataHourlyObj = _.filter(contactedDataHourly[moment(item).format('YYYY-MM-DD') + 'T00:00:00.000Z'], {
					hour: it - 1,
				});
				closedDataHourlyObj = _.filter(totalAgentClosedDataHourly[moment(item).format('YYYY-MM-DD') + 'T00:00:00.000Z'], {
					hour: it - 1,
				});
			} else {
				contactedDataHourlyObj = _.filter(contactedDataHourly[moment(item).format('YYYY-MM-DD') + 'T00:00:00.000Z'], {
					hour: it + 23,
				});
				closedDataHourlyObj = _.filter(totalAgentClosedDataHourly[moment(item).format('YYYY-MM-DD') + 'T00:00:00.000Z'], {
					hour: it + 23,
				});
			} */
			contactedDataHourlyObj =
				contactedDataHourly &&
				contactedDataHourly[moment(item).dayOfYear()] &&
				_.filter(contactedDataHourly[moment(item).dayOfYear()], {
					hour: it,
				});
			closedDataHourlyObj =
				totalAgentClosedDataHourly &&
				totalAgentClosedDataHourly[moment(item).dayOfYear()] &&
				_.filter(totalAgentClosedDataHourly[moment(item).dayOfYear()], {
					hour: it,
				});
			/* 	//console.log('contactedDataHourlyObj', contactedDataHourlyObj); */
			contactedDataLineData.push((contactedDataHourlyObj && _.sumBy(contactedDataHourlyObj, 'count')) || 0);
			closedDataLineData.push((closedDataHourlyObj && _.sumBy(closedDataHourlyObj, 'count')) || 0);
			totalWaitingConvLineData.push((totalWaitingConvHourlyObj && totalWaitingConvHourlyObj.cid_count) || 0);
		});
	});
	let missingDataLineData = _.map(totalWaitingConvLineData, (it, i) => Math.max(it - contactedDataLineData[i], 0));

	let totalWaitingConvLine = {
			label: t('totalWaitingAllC'),
			data: totalWaitingConvLineData,
			borderColor: 'orange',
			backgroundColor: 'orange',
			fill: false,
		},
		contactedDataLine = {
			label: t('totalContacted'),
			data: contactedDataLineData,
			borderColor: 'blue',
			backgroundColor: 'blue',
			fill: false,
		},
		missingDataLine = {
			label: t('dontConnecttedDescription'),
			data: missingDataLineData,
			borderColor: 'red',
			backgroundColor: 'red',
			fill: false,
		},
		closedDataLine = {
			label: t('totalClosed'),
			data: closedDataLineData,
			borderColor: 'green',
			backgroundColor: 'green',
			fill: false,
		};
	//let datasets = [uniqueUserCountsLine, totalWaitingConvLine, contactedDataLine, closedDataLine];
	let datasets = [totalWaitingConvLine, contactedDataLine, missingDataLine, closedDataLine];
	let excelData = _.map(labels, (it, i) => ({
		[t('label')]: it,
		[t('totalWaitingAllC')]: totalWaitingConvLineData[i],
		[t('totalContacted')]: contactedDataLineData[i],
		[t('dontConnecttedDescription')]: missingDataLineData[i],
		[t('totalClosed')]: closedDataLineData[i],
	}));
	return {
		labels,
		datasets,
		excelData,
	};
}
function chartDataDayHourly(totalWaitingConvDayHourly, contactedDataDayHourly, totalAgentClosedDataDayHourly, start_date, end_date, t) {
	let labels = [];
	//var dateArr = getDateArray(startDate, endDate);
	var hourArr = getHourArray();
	labels = hourArr;
	let totalWaitingConvDayHourlyData = [],
		contactedDataDayHourlyLineData = [],
		totalAgentClosedDataDayHourlyLineData = [],
		twObj,
		cObj,
		tcObj;

	_.map(hourArr, (it, i) => {
		twObj = _.find(totalWaitingConvDayHourly, { hour: (i + 21) % 24 });
		cObj = _.sumBy(contactedDataDayHourly[i - 1], 'count');
		tcObj = _.sumBy(totalAgentClosedDataDayHourly[i - 1], 'count');
		totalWaitingConvDayHourlyData.push((twObj && twObj.count) || 0);
		contactedDataDayHourlyLineData.push(cObj || 0);
		totalAgentClosedDataDayHourlyLineData.push(tcObj || 0);
	});

	let missingDataLineData = _.map(totalWaitingConvDayHourlyData, (it, i) => Math.max(it - contactedDataDayHourlyLineData[i], 0));
	/* //console.log(totalWaitingConvLineData, contactedDataLineData, missingDataLineData); */
	let totalWaitingConvLine = {
			label: t('totalWaitingAllC'),
			data: totalWaitingConvDayHourlyData,
			borderColor: 'orange',
			backgroundColor: 'orange',
			fill: false,
		},
		contactedDataLine = {
			label: t('totalContacted'),
			data: contactedDataDayHourlyLineData,
			borderColor: 'blue',
			backgroundColor: 'blue',
			fill: false,
		},
		missingDataLine = {
			label: t('dontConnecttedDescription'),
			data: missingDataLineData,
			borderColor: 'red',
			backgroundColor: 'red',
			fill: false,
		},
		closedDataLine = {
			label: t('totalClosed'),
			data: totalAgentClosedDataDayHourlyLineData,
			borderColor: 'green',
			backgroundColor: 'green',
			fill: false,
		};
	//let datasets = [uniqueUserCountsLine, totalWaitingConvLine, contactedDataLine, closedDataLine];
	let datasets = [totalWaitingConvLine, contactedDataLine, missingDataLine, closedDataLine];
	let excelData = _.map(labels, (it, i) => ({
		[t('label')]: it,
		[t('totalWaitingAllC')]: totalWaitingConvDayHourlyData[i],
		[t('totalContacted')]: contactedDataDayHourlyLineData[i],
		[t('dontConnecttedDescription')]: missingDataLineData[i],
		[t('totalClosed')]: totalAgentClosedDataDayHourlyLineData[i],
	}));
	return {
		labels,
		datasets,
		excelData,
	};
}
const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product });
export default connect(stateToProps)(withTranslation()(Analytic));