import React, { Component } from 'react';
import Page from '../../components/Page';
import { post } from '../../util/api';
import { Table, Dimmer, Loader, Breadcrumb, Segment, Header, Form, Button, Message, Checkbox } from 'semantic-ui-react';
import _ from 'lodash';
import MyMenu from './menu';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const Plan_Package = [
	{
		text: 'Null',
		name: null,
		price: 0,
		value: null,
	},
	{
		text: 'Start',
		name: 'start_monthly',
		price: 24,
		value: '595965',
	},
	{
		text: 'Grow',
		name: 'grow_monthly',
		price: 36,
		value: '595967',
	},
	{
		text: 'Scale',
		name: 'scale_monthly',
		price: 55,
		value: '595969',
	},
];

class ResellerTable extends Component {
	state = {
		loading: true,
		btnloading: false,
		column: null,
		direction: null,
		hidezero: true,
	};
	componentDidMount() {
		this.getData();
	}
	async getData() {
		await post('/reseller/product/dashboard').then((data) => this.setState({ sdata: data.data.result }));
		await post('/reseller/product/getProducts').then((data) => this.setState({ data: data.data.result, loading: false }));
	}
	refresh() {
		setTimeout(() => {
			window.location.reload(false);
		}, 1000);
	}
	addProduct = (values) => {
		this.setState({ btnloading: true });
		let { pid, agentQuantity, phoneQuantity, plan_id, real_subscription_package } = this.state;
		if (!real_subscription_package) {
			agentQuantity = 0;
			phoneQuantity = 0;
		}
		post('/reseller/product/setProductSubs ', {
			enterprise: pid,
			new_limit: parseInt(agentQuantity),
			new_phone_limit: parseInt(phoneQuantity),
			subscription_plan_id: plan_id,
			real_subscription_package,
		}).then((data) => {
			if (data.data.success) {
				this.setState({ btnloading: false });
				this.refresh();
			} else {
				this.setState({ btnloading: false, errMessage: data.data.error });
			}
		});
	};
	handleChange = (e, { name, value }) => this.setState({ [name]: value });
	selectProduct(value) {
		this.setState({
			pid: value.pid,
			agentQuantity: value.agentQuantity || 0,
			phoneQuantity: value.phoneQuantity || 0,
			password: value.password,
			plan_id: 'custom',
			real_subscription_package: value.plan_id,
			email: value.email,
		});
	}
	handleSort = (clickedColumn, data) => () => {
		const { column, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				data: _.sortBy(data, [clickedColumn]),
				direction: 'ascending',
			});

			return;
		}

		this.setState({
			data: data.reverse(),
			direction: direction === 'ascending' ? 'descending' : 'ascending',
		});
	};
	render() {
		let { loading, data, column, direction, sdata, hidezero } = this.state;
		let { product, t } = this.props;

		if (loading) {
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/reseller`}>
								{t('Partnership')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
					flex
				>
					<MyMenu activeItem="products">
						<Dimmer active inverted>
							<Loader inverted></Loader>
						</Dimmer>
					</MyMenu>
				</Page>
			);
		}
		let agentLimit = product && product.settings && product.settings.agent_limit;
		let phoneLimit = product && product.settings && product.settings.phone_limit;
		let totalAgents = _.sumBy(
			_.filter(sdata, function (it) {
				return it.package !== 'trial-reseller';
			}),
			'agent_limit'
		);
		let trialAgents = _.sumBy(
			_.filter(sdata, function (it) {
				return it.package === 'trial-reseller';
			}),
			'agent_limit'
		);
		let totalPhones = _.sumBy(
			_.filter(sdata, function (it) {
				return it.package !== 'trial-reseller';
			}),
			'phone_limit'
		);
		let trialPhones = _.sumBy(
			_.filter(sdata, function (it) {
				return it.package === 'trial-reseller';
			}),
			'phone_limit'
		);
		let filtered_list = data;
		if (hidezero) {
			filtered_list = _.filter(data, (it) => {
				let agent_limit = (it.settings && it.settings.agent_limit) || 0;
				let phone_limit = (it.settings && it.settings.phone_limit) || 0;
				let total = agent_limit + phone_limit;
				if (total > 0) return it;
			});
		}
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/reseller`}>
							{t('Partnership')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />
						<Breadcrumb.Section as={Link} to={`/reseller/products`}>
							{t('products')}
						</Breadcrumb.Section>
					</Breadcrumb>
				}
				flex
			>
				<MyMenu activeItem="products">
					<Page.Wrap>
						<div style={{ display: 'flex' }}>
							<div style={{ width: '100%', marginRight: '20px' }}>
								<div style={{ display: 'flex' }}>
									<div className="myLabel">
										{t('AgentLimit')}: {agentLimit}
									</div>
									<div className="myLabel">
										{t('UsedAgentNumbers')}: {totalAgents}
									</div>
									<div className="myLabel">
										{t('TrialAgentNumbers')}: {trialAgents}
									</div>
									<div className="myLabel">
										{t('PhoneLimit')}: {phoneLimit}
									</div>
									<div className="myLabel">
										{t('UsedPhoneNumbers')}: {totalPhones}
									</div>
									<div className="myLabel">
										{t('TrialPhoneNumbers')}: {trialPhones}
									</div>
								</div>
								<div style={{ marginTop: '10px' }}>
									<Checkbox
										toggle
										checked={hidezero}
										name="hidezero"
										label={t('hidezero')}
										onChange={() => this.setState({ hidezero: !hidezero })}
									/>
								</div>
								{this.state.errMessage ? <Message negative>{this.state.errMessage}</Message> : null}
								<Table sortable celled>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>#</Table.HeaderCell>
											<Table.HeaderCell sorted={column === 'email' ? direction : null} onClick={this.handleSort('email', data)}>
												Email
											</Table.HeaderCell>
											<Table.HeaderCell
												sorted={column === 'settings.agent_limit' ? direction : null}
												onClick={this.handleSort('settings.agent_limit', data)}
											>
												Agent Limit
											</Table.HeaderCell>
											<Table.HeaderCell
												sorted={column === 'settings.phone_limit' ? direction : null}
												onClick={this.handleSort('settings.phone_limit', data)}
											>
												{t('PhoneLimit')}
											</Table.HeaderCell>
											<Table.HeaderCell
												sorted={column === 'settings.real_subscription_package' ? direction : null}
												onClick={this.handleSort('settings.real_subscription_package', data)}
											>
												{t('Package')}
											</Table.HeaderCell>
											<Table.HeaderCell></Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{_.map(filtered_list, (val, index) => (
											<Table.Row
												key={index}
												onClick={() =>
													this.selectProduct({
														email: val.email,
														password: val.password,
														pid: val.id,
														agentQuantity: val.settings.agent_limit,
														phoneQuantity: val.settings.phone_limit,
														plan_id: val.settings.real_subscription_package,
													})
												}
												style={{ cursor: 'pointer' }}
											>
												<Table.Cell>{index + 1}</Table.Cell>
												<Table.Cell>{(val && val.email) || 0}</Table.Cell>
												<Table.Cell>{(val && val.settings && val.settings.agent_limit) || 0}</Table.Cell>
												<Table.Cell>{(val && val.settings && val.settings.phone_limit) || 0}</Table.Cell>
												<Table.Cell>
													{val &&
													val.settings &&
													val.settings.agent_limit === 0 &&
													val &&
													val.settings &&
													val.settings.phone_limit === 0
														? ''
														: val && val.settings && val.settings.subscription_package === 'trial-reseller'
														? 'Trial'
														: val &&
														  val.settings &&
														  val.settings.real_subscription_package &&
														  _.find(Plan_Package, { value: val.settings.real_subscription_package }) &&
														  _.find(Plan_Package, { value: val.settings.real_subscription_package }).text}
												</Table.Cell>
												<Table.Cell textAlign="right">
													<Button
														color="blue"
														onClick={() =>
															this.selectProduct({
																email: val.email,
																pid: val.id,
																agentQuantity: val.settings.agent_limit,
																phoneQuantity: val.settings.phone_limit,
																plan_id: val.settings.real_subscription_package,
															})
														}
													>
														{t('Edit')}
													</Button>
												</Table.Cell>
											</Table.Row>
										))}
									</Table.Body>
								</Table>
							</div>
						</div>
					</Page.Wrap>
				</MyMenu>
				<Segment className="addproduct" style={{ marginTop: '0', width: '25%', borderTop: 'none', borderRadius: '0' }}>
					<Header>{t('Edit')}</Header>
					<Form autoComplete="off" onSubmit={this.addProduct}>
						<Form.Input label="Email" readOnly value={this.state.email} placeholder="Select Product" required></Form.Input>
						<Form.Input label="Password" disabled={true} required value={this.state.password}></Form.Input>
						<Form.Input label="Pid" readOnly value={this.state.pid} placeholder="Select Product" required></Form.Input>
						<Form.Dropdown
							name="real_subscription_package"
							label={t('Package')}
							value={this.state.real_subscription_package}
							options={Plan_Package}
							disabled={!this.state.pid}
							selection
							required
							onChange={this.handleChange}
						></Form.Dropdown>
						<Form.Input
							name="agentQuantity"
							type="number"
							label={t('AgentQuantity')}
							disabled={!this.state.pid}
							required
							value={this.state.agentQuantity}
							placeholder={t('Selectquantity')}
							onChange={this.handleChange}
						></Form.Input>
						<Form.Input
							name="phoneQuantity"
							type="number"
							label={t('PhoneQuantity')}
							disabled={!this.state.pid}
							required
							value={this.state.phoneQuantity}
							placeholder={t('Selectquantity')}
							onChange={this.handleChange}
						></Form.Input>
						<div className="d-flex">
							<Form.Button
								positive
								content={t('Save')}
								type="submit"
								style={{ width: '100px' }}
								disabled={!this.state.pid}
								loading={this.state.btnloading}
							/>
						</div>
					</Form>
				</Segment>
			</Page>
		);
	}
}

const stateToProps = (state) => ({
	access: state.access,
	location: state.router.location,
	product: state.product.reseller,
	user: state.access.user.loggedUserObj,
});
export default connect(stateToProps)(withTranslation()(ResellerTable));
