import React, { Component } from 'react';
import Page from '../../components/Page';
import { Icon, Segment, Form, Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import { log } from '../../util/api';

class Contacts extends Component {
	phoneToDelete = null;
	state = {
		loading: true,
		saveButton: 'add',
		ex_file: '',
	};
	componentDidMount() {
		this.getList();
	}
	getList() {
		const { product } = this.props;
		let pid = product.id;
		fetch(`https://micro.yapaytech.com/api/dump/read?pid=${pid}&key=validationCheckList`)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let result = data.result;
				let excelData = result && result.data;
				this.setState({ list: excelData, loading: false });
			});
	}

	fileUpload(file) {
		const { product } = this.props;
		if (!file) return;
		this.setState({ loadingBtn: true });
		let pid = product.id;
		const url = 'https://micro.yapaytech.com/api/dump/write/csv';
		const excel_url = 'https://micro.yapaytech.com/api/dump/write/excel';
		let selected_url = /csv$/.test(file.name) ? url : excel_url;
		const formData = new FormData();
		formData.append('file', file);
		formData.append('pid', pid);
		formData.append('key', 'validationCheckList');
		let file_url = '';
		fetch(selected_url, {
			method: 'post',
			body: formData,
		})
			.then((response) => {
				return response.json();
			})
			.then(async (data) => {
				file_url = data && data.files && data.files[0] && data.files[0].url;
				this.setState({ ex_file: file_url, loadingBtn: false, message: true });
				this.getList();
			});
	}
	handleFileInput = (e) => {
		let file = e.target.files[0];
		this.fileUpload(file);
	};
	validNumbers() {
		let { list } = this.state;
		this.setState({ validBtn: true });
		let contacts = _.map(
			_.filter(list, (o, i) => i < 5000),
			(it) => it.full_mobile_number
		);
		fetch('https://micro.yapaytech.com/api/collectactionopenapi/wvalidation', {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ data: { blocking: 'wait', contacts } }),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let result = data.result;
				_.map(result, (it, i) => (it.user_id = (list[i] && list[i].user_id) || ''));
				this.postLog(result);
				this.setState({ validBtn: false });
			});
	}
	async postLog(data) {
		let { product, user } = this.props;
		let pid = product.id;
		let uid = user.id;
		await log('/live/groupMessageLog/add', { pid, uid, log: { date: moment().format('DD-MM-YYYY HH:mm'), data, type: 'valid' } });
	}
	render() {
		const { t } = this.props;
		let { loadingBtn, message, list } = this.state;
		if (this.state.loading) {
			return <Page.Loader />;
		} else {
			return (
				<Segment>
					<div style={{ padding: '10px', width: '100%' }}>
						<h3>
							<Icon name="whatsapp" />
							{t('contacts')}
						</h3>
						<Form>
							<Form.Group style={{ display: 'flex', justifyContent: 'center' }}>
								<Form.Button color="green" loading={this.state.validBtn} onClick={() => this.validNumbers()}>
									Kontrol Et
								</Form.Button>
								<div>
									<Form.Field style={{ display: 'flex', marginBottom: '10px' }}>
										<div className="image-upload" style={{ margin: 'auto' }}>
											<label htmlFor="file-input" className={`ui blue icon left labeled button ${loadingBtn ? 'loading' : null}`}>
												{t('upload')}
												<Icon name="upload" style={{ cursor: 'pointer' }}></Icon>
											</label>
										</div>

										<input
											id="file-input"
											type="file"
											accept=".xlsx, .xls, .csv"
											style={{ display: 'none' }}
											onChange={(e) => {
												this.handleFileInput(e);
											}}
										/>
									</Form.Field>
									{message ? <p style={{ textAlign: 'center' }}>{t('messageExcelSuccess')}</p> : null}
								</div>
							</Form.Group>
						</Form>
					</div>
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>{'full_mobile_number'}</Table.HeaderCell>
								<Table.HeaderCell>{'user_id'}</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{_.map(list, (it, i) => (
								<Table.Row key={i}>
									<Table.Cell>{it.full_mobile_number}</Table.Cell>
									<Table.Cell>{it.user_id}</Table.Cell>
								</Table.Row>
							))}
						</Table.Body>
					</Table>
				</Segment>
			);
		}
	}
}

const stateToProps = (state) => ({
	access: state.access,
	location: state.router.location,
	product: state.product.live,
	user: state.access.user.loggedUserObj,
});

export default connect(stateToProps)(withTranslation()(Contacts));
