import React, { Component } from 'react';
import { post } from '../../util/api';
import { Segment, Table, Tab, Button, Header, Form, Dropdown } from 'semantic-ui-react';
import _ from 'lodash';

export default class MainTable extends Component {
	TableLineCustom = (data) =>
		_.map(data, (val, index) => (
			<Table.Row key={index}>
				<Table.Cell>
					<Button
						color="blue"
						disabled={val.is_reseller || val.is_enterprise}
						onClick={() =>
							this.selectProduct({
								email: val.owner_email,
								pid: val.pid,
								agentQuantity: val.agent_limit,
								phoneQuantity: val.phone_limit,
								instagramQuantity: val.instagram_limit,
								telegramQuantity: val.telegram_limit,
								insta: val.insta,
								plan_id: val.package,
							})
						}
					>
						Edit
					</Button>
				</Table.Cell>
				<Table.Cell>{val && val.owner_id}</Table.Cell>
				<Table.Cell>{val && val.owner_email}</Table.Cell>
				<Table.Cell>{val && val.package}</Table.Cell>
				<Table.Cell>{val && val.agent_limit}</Table.Cell>
				<Table.Cell>{val && val.phone_limit}</Table.Cell>
				<Table.Cell>{val && val.instagram_limit}</Table.Cell>
				<Table.Cell>{val && val.telegram_limit}</Table.Cell>
				<Table.Cell>{val && val.agents && val.agents.length}</Table.Cell>
				<Table.Cell>{val && val.phones && val.phones.length}</Table.Cell>
				<Table.Cell>{val && val.instagrams && val.instagrams.length}</Table.Cell>
				<Table.Cell>{val && val.telegrams && val.telegrams.length}</Table.Cell>
				<Table.Cell>{val && val.is_reseller ? 'true' : 'false'}</Table.Cell>
				<Table.Cell>{val && val.is_enterprise ? 'true' : 'false'}</Table.Cell>
				<Table.Cell>{val && val.insta ? 'true' : 'false'}</Table.Cell>
			</Table.Row>
		));
	selectProduct(value) {
		this.setState({
			pid: value.pid,
			agentQuantity: value.agentQuantity || 0,
			phoneQuantity: value.phoneQuantity || 0,
			instagramQuantity: value.instagramQuantity || 0,
			telegramQuantity: value.telegramQuantity || 0,
			subscriptionId: value.plan_id,
			plan_id: value.plan_id,
			insta: value.insta,
			email: value.email,
		});
	}
	addProduct = () => {
		this.setState({ btnloading: true });
		let { pid, agentQuantity, phoneQuantity, instagramQuantity, telegramQuantity, subscriptionId, trialDay, insta } = this.state;
		post('/live/admin/setPackage', {
			pid: pid,
			new_limit: parseInt(agentQuantity),
			new_phone_limit: parseInt(phoneQuantity),
			new_instagram_limit: parseInt(instagramQuantity),
			new_telegram_limit: parseInt(telegramQuantity),
			subscription_plan_id: subscriptionId,
			insta,
			trial: subscriptionId === 'custom' ? false : true,
			trial_day: trialDay,
		}).then((data) => {
			if (data.data.success) {
				this.setState({ btnloading: false });
				this.refresh();
			} else {
				this.setState({ btnloading: false, errMessage: data.data.error });
			}
		});
	};
	resetSub = () => {
		this.setState({ btnResloading: true });
		let { pid } = this.state;
		post('/live/admin/setPackage', {
			pid: pid,
			reset: true,
		}).then((data) => {
			if (data.data.success) {
				this.setState({ btnResloading: false });
				this.refresh();
			} else {
				this.setState({ btnResloading: false, errMessage: data.data.error });
			}
		});
	};
	handleChange = (e, { name, value }) => this.setState({ [name]: value });
	toggle = (e, { name, value }) => this.setState((prevState) => ({ [name]: !prevState[name] }));
	state = {
		loading: true,
		activePage: 1,
		limit: 50,
		email: '',
		buttonloading: true,
		subscriptionId: 'custom',
		trialDay: 14,
	};
	componentDidMount() {
		this.postData();
	}
	async postData() {
		await post('/live/admin/dashboard').then((data) => this.setState({ data: _.orderBy(data.data.result, 'owner_id', 'desc'), loading: false }));
	}
	handleSort = (clickedColumn, data) => () => {
		const { column, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				data: _.sortBy(data, [clickedColumn]),
				direction: 'ascending',
			});

			return;
		}

		this.setState({
			data: data.reverse(),
			direction: direction === 'ascending' ? 'descending' : 'ascending',
		});
	};
	refresh() {
		setTimeout(() => {
			window.location.reload(false);
		}, 1000);
	}
	render() {
		const { column, direction, loading, data } = this.state;
		let packageOptions = [
			{
				text: 'Custom',
				value: 'custom',
				key: 'custom',
			},
			{
				text: 'Trial',
				value: 'trial-start',
				key: 'trial-start',
			},
		];
		if (loading) return <Segment attached="bottom">loading...</Segment>;
		let dg = _.map(data, function (it) {
			it = {
				agent_limit: it.agent_limit || 0,
				phone_limit: it.phone_limit || 0,
				instagram_limit: it.instagram_limit || 0,
				telegram_limit: it.telegram_limit || 0,
				owner_id: it.owner_id,
				owner_name: it.owner_name,
				is_reseller: it.is_reseller,
				is_enterprise: it.is_enterprise,
				is_eticaret: it.is_eticaret,
				owner_email: it.owner_email,
				pid: it.pid,
				token: it.token,
				agents: it.agents,
				phones: it.phones,
				instagrams: it.instagrams,
				telegrams: it.telegrams,
				package: it.package,
				verified: it.verified,
				total_agent: it.total_agent,
				wapi_pid: it.wapi_pid,
				insta: it.insta,
				wapi_token: it.wapi_token,
				analyticview: it.analyticview,
				userlist: (it.analyticview && it.analyticview.userlist) || false,
				ecommerce: (it.analyticview && it.analyticview.ecommerce) || false,
				ratePoint: (it.analyticview && it.analyticview.ratePoint) || false,
				sendProduct: (it.analyticview && it.analyticview.sendProduct) || false,
				showTemplate: (it.analyticview && it.analyticview.showTemplate) || false,
				conversationGroup: (it.analyticview && it.analyticview.conversationGroup) || false,
				orderRefunded_count: 0,
				orderRefunded_len: 0,
				orderCanceled_count: 0,
				orderCanceled_len: 0,
				test_account: it.test_account || false,
			};
			return it;
		});
		return (
			<div style={{ display: 'flex', maxHeight: '95%' }}>
				<Tab.Pane attached={false} style={{ width: '80%', overflow: 'auto', padding: 0 }}>
					<Table celled sortable>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell width={1}></Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'owner_id' ? direction : null} onClick={this.handleSort('owner_id', dg)}>
									Id
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'owner_email' ? direction : null} onClick={this.handleSort('owner_email', dg)}>
									Email
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'package' ? direction : null} onClick={this.handleSort('package', dg)} width={1}>
									Package
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'agent_limit' ? direction : null} onClick={this.handleSort('agent_limit', dg)}>
									Agent Limit
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'phone_limit' ? direction : null} onClick={this.handleSort('phone_limit', dg)}>
									Phone Limit
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'instagram_limit' ? direction : null} onClick={this.handleSort('instagram_limit', dg)}>
									Insta Limit
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'telegram_limit' ? direction : null} onClick={this.handleSort('telegram_limit', dg)}>
									Telegram Limit
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'agents' ? direction : null} onClick={this.handleSort('agents', dg)}>
									Agents
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'phones' ? direction : null} onClick={this.handleSort('phones', dg)}>
									Phones
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'instagrams' ? direction : null} onClick={this.handleSort('instagrams', dg)}>
									Instagrams
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'telegrams' ? direction : null} onClick={this.handleSort('telegrams', dg)}>
									Telegrams
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'is_reseller' ? direction : null} onClick={this.handleSort('is_reseller', dg)}>
									Reseller
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'is_enterprise' ? direction : null} onClick={this.handleSort('is_enterprise', dg)}>
									Enterprise
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'insta' ? direction : null} onClick={this.handleSort('insta', dg)}>
									Ins Messenger
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>{this.TableLineCustom(dg)}</Table.Body>
					</Table>
				</Tab.Pane>
				<Segment className="left-menu" style={{ marginTop: '0', width: '20%' }}>
					<Header>Edit</Header>
					<Form autoComplete="off" onSubmit={this.addProduct}>
						<Form.Input label="Email" readOnly value={this.state.email} placeholder="Select Product" required></Form.Input>
						<Form.Input label="Pid" readOnly value={this.state.pid} placeholder="Select Product" required></Form.Input>
						<Form.Input
							name="agentQuantity"
							type="number"
							label="Agent Quantity"
							disabled={!this.state.pid}
							required
							value={this.state.agentQuantity}
							placeholder="Select quantity"
							onChange={this.handleChange}
						></Form.Input>
						<Form.Input
							name="phoneQuantity"
							type="number"
							label="Phone Quantity"
							disabled={!this.state.pid}
							required
							value={this.state.phoneQuantity}
							placeholder="Select quantity"
							onChange={this.handleChange}
						></Form.Input>
						<Form.Input
							name="instagramQuantity"
							type="number"
							label="Instagram Quantity"
							disabled={!this.state.pid}
							required
							value={this.state.instagramQuantity}
							placeholder="Select quantity"
							onChange={this.handleChange}
						></Form.Input>
						<Form.Input
							name="telegramQuantity"
							type="number"
							label="Telegram Quantity"
							disabled={!this.state.pid}
							required
							value={this.state.telegramQuantity}
							placeholder="Select quantity"
							onChange={this.handleChange}
						></Form.Input>
						{/* <Form.Checkbox
							name="insta"
							label="Instagram Messenger"
							disabled={!this.state.pid}
							required
							checked={this.state.insta}
							placeholder="Select quantity"
							onChange={this.toggle}
						></Form.Checkbox> */}
						<label
							disabled={!this.state.pid}
							style={{
								display: 'block',
								margin: '0 0 0.28571429rem 0',
								color: !this.state.pid ? '#DFDFDF' : 'black',
								fontSize: '0.92857143em',
								fontWeight: '700',
								textTransform: 'none',
							}}
						>
							Subscription Type:
						</label>
						<Dropdown
							disabled={!this.state.pid}
							name="subscriptionId"
							options={packageOptions}
							value={this.state.subscriptionId}
							selection
							fluid
							onChange={this.handleChange}
							style={{ marginBottom: '1em' }}
						></Dropdown>
						{this.state.subscriptionId === 'trial-start' ? (
							<Form.Input
								name="trialDay"
								type="number"
								label="Trial Day"
								disabled={!this.state.pid}
								required
								value={this.state.trialDay}
								placeholder="Select quantity"
								onChange={this.handleChange}
							></Form.Input>
						) : (
							''
						)}
						<div className="d-flex">
							<Form.Button
								positive
								content="Save"
								type="submit"
								style={{ width: '100px' }}
								disabled={!this.state.pid}
								loading={this.state.btnloading}
							/>
						</div>
						<div className="d-flex" style={{ marginTop: '20px' }}>
							<Form.Button
								primary
								content="Reset"
								type="button"
								style={{ width: '100px' }}
								disabled={!this.state.pid}
								onClick={this.resetSub}
								loading={this.state.btnResloading}
							/>
						</div>
					</Form>
				</Segment>
			</div>
		);
	}
}
