import React from 'react';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import { Menu, Icon } from 'semantic-ui-react';
import Page from '../../components/Page';
import { useTranslation } from 'react-i18next';

import OrderTable from './table';
import ResultTable from './result_table';

export default () => {
	let { t } = useTranslation();
	return (
		<Page title="Sipariş Liste">
			<Page.Wrap>
				<Menu className="settings" pointing secondary>
					<Menu.Item as={NavLink} to="/orderbot" exact>
						<Icon name="table" />
						{t('İstekler')}
					</Menu.Item>
					<Menu.Item as={NavLink} to="/orderbot/result_table" exact>
						<Icon name="table" />
						{t('Gönderilenler')}
					</Menu.Item>
				</Menu>
				<Switch>
					<Route path="/orderbot/result_table" component={ResultTable} />
					<Route path="/orderbot" component={OrderTable} />
					<Redirect to="/orderbot" />
				</Switch>
			</Page.Wrap>
		</Page>
	);
};
