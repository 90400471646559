import React, { Component } from 'react';
import { Tab } from 'semantic-ui-react';
import CustomLink from '../../components/CustomLink';

export default class MainTable extends Component {
	render() {
		return (
			<Tab.Pane attached={false}>
				<CustomLink></CustomLink>
			</Tab.Pane>
		);
	}
}
