import React, { Component } from 'react';
import { Form, Segment, Icon, Header, Container, Grid, Button } from 'semantic-ui-react';
import { post } from '../../util/api';
import { connect } from 'react-redux';
import { updateProfile } from '../../actions/access.actions';
import _ from 'lodash';
import DraggableDiv from '../../components/DraggableDiv';
import { withTranslation } from 'react-i18next';

class UserPage extends Component {
	state = {
		list: (this.props.user.extra && this.props.user.extra.wcart_category) || [],
	};
	handleArrayChange = (e, { name, value }) => {
		let list = this.state.list;
		list[name].name = value;
		this.setState({ list });
	};
	handleArrayToggle = (e, { name, value }) => {
		let list = this.state.list;
		list[name].status = !list[name].status;
		this.setState({ list });
	};
	handleRemove = (index) => {
		let list = this.state.list;
		_.pullAt(list, [index]);
		this.setState({ list });
	};
	postData() {
		let { list } = this.state;
		this.setState({ saveLoad: true });
		let extra = _.merge(this.props.user.extra, {
			wcart_category: list,
		});
		post('/auth/updateInfo', {
			extra,
		}).then(() => {
			this.setState({ saveLoad: false });
		});
	}
	async swap(a, b) {
		let { list } = this.state;
		//list[a] = list.splice(b, 1, list[a])[0];
		list.splice(a, 0, list.splice(b, 1)[0]);
		await this.setState({
			list,
		});
	}

	render() {
		let { list, saveLoad } = this.state;
		const { t, product } = this.props;
		let pid = product.id;
		let max_id =
			(list && list.length && _.orderBy(list, (it) => parseInt(it.id.replace(/.*category#/, '')), ['desc']))[0].id.replace(/.*category#/, '') || 0;
		max_id = parseInt(max_id);
		return (
			<Container>
				<Segment>
					<Header>{t('categories')}</Header>
					{_.map(list, (it, i) => (
						<DraggableDiv key={i} i={i} action={this.swap.bind(this)}>
							<Grid columns="equal">
								<Grid.Column width={1}>
									<div style={{ display: 'flex', alignItems: 'center', height: '100%', cursor: 'grab' }}>
										<Icon name="arrows alternate vertical" size="large"></Icon>
									</div>
								</Grid.Column>
								<Grid.Column width={8}>
									<Form>
										<Form.Input label={t('categoryName')} name={i} value={it.name} onChange={this.handleArrayChange}></Form.Input>
									</Form>
								</Grid.Column>
								<Grid.Column style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
									<Form>
										<Form.Field
											required
											style={{
												display: 'block',
												margin: '0 0 1.28571429rem 0',
												color: 'rgba(0,0,0,.87)',
												fontSize: '.92857143em',
												fontWeight: '700',
												textTransform: 'none',
											}}
										>
											{t('status') + ': '}
										</Form.Field>
										<Form.Checkbox toggle checked={it.status} name={'' + i} onChange={this.handleArrayToggle}></Form.Checkbox>
									</Form>
								</Grid.Column>
								<Grid.Column style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
									<Button icon basic color="red" onClick={() => this.handleRemove(i)}>
										<Icon name="trash"></Icon>
									</Button>
								</Grid.Column>
							</Grid>
						</DraggableDiv>
					))}
					<div style={{ marginTop: '30px', display: 'flex' }}>
						<Button
							basic
							color="green"
							onClick={() => {
								list.push({ name: '', status: false, id: pid + '_category#' + (max_id + 1) });
								this.setState({ list });
							}}
						>
							{t('add')}
						</Button>
						<Button color="green" onClick={() => this.postData()} loading={saveLoad}>
							{t('save')}
						</Button>
					</div>
				</Segment>
			</Container>
		);
	}
}

export default connect(
	(state) => ({ user: state.access.user.loggedUserObj, product: state.product.live }),
	(dispatch) => ({ updateUser: (user) => dispatch(updateProfile(user)) })
)(withTranslation()(UserPage));
