import React, { Component } from 'react';
import { Form, Segment, Icon } from 'semantic-ui-react';
import { post } from '../../util/api';
import { connect } from 'react-redux';
import { updateProfile } from '../../actions/access.actions';
import { MiniContainer } from '../../components/FormikInput';
import Countries from '../settings/countries.json';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

class UserPage extends Component {
	state = {
		userInfo: { icon: 'save', color: 'blue', saved: 'Save', sending: false },
		userPassword: { icon: 'save', color: 'blue', saved: 'Save', sending: false },
		newpassword: '',
		companyName: (this.props.user.extra && this.props.user.extra.company && this.props.user.extra.company.name) || '',
		vatNumber: (this.props.user.extra && this.props.user.extra.company && this.props.user.extra.company.vatNumber) || '',
		companyCountry: (this.props.user.extra && this.props.user.extra.company && this.props.user.extra.company.country) || '',
		companyCity: (this.props.user.extra && this.props.user.extra.company && this.props.user.extra.company.city) || '',
		companyState: (this.props.user.extra && this.props.user.extra.company && this.props.user.extra.company.state) || '',
		companyPostCode: (this.props.user.extra && this.props.user.extra.company && this.props.user.extra.company.postcode) || '',
		companyStreet: (this.props.user.extra && this.props.user.extra.company && this.props.user.extra.company.street) || '',
	};

	handleChange = (e, { name, value }) => this.setState({ [name]: value });

	handleSubmit = (values) => {
		if (this.state.userInfo.sending) return;
		this.setState({ userInfo: { sending: true } });
		this.props.updateUser(values);
		this.setState({
			userInfo: { sending: false, errormessage: '', saved: 'Saved', color: 'teal', icon: 'check' },
		});
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.setState({ userInfo: { icon: 'save', color: 'blue', saved: 'Save' } });
		}, 2000);
		this.postData({
			companyName: this.state.companyName,
			vatNumber: this.state.vatNumber,
			country: this.state.companyCountry,
			city: this.state.companyCity,
			state: this.state.companyState,
			street: this.state.companyStreet,
			postcode: this.state.companyPostCode,
		});
	};
	handleSubmitPassword = (values) => {
		if (this.state.userPassword.sending) return;
		this.setState({ userPassword: { sending: true } });
		this.props.updateUser(values);
		this.postPassword(values);
		if (this.state.passwordError) {
			this.setState({
				userPassword: { sending: false, errormessage: '', saved: 'Unsaved', color: 'red', icon: 'x' },
			});
		} else {
			this.setState({
				userPassword: { sending: false, errormessage: '', saved: 'Saved', color: 'teal', icon: 'check' },
			});
		}

		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.setState({ userPassword: { icon: 'save', color: 'blue', saved: 'Save' } });
		}, 2000);
	};
	postData(values) {
		let extra = _.merge(this.props.user.extra, {
			company: {
				name: values.companyName,
				vatNumber: values.vatNumber,
				country: values.country,
				state: values.state,
				city: values.city,
				street: values.street,
				postcode: values.postcode,
			},
		});
		post('/auth/updateInfo', {
			company: values.companyName,
			extra,
		});
	}
	postPassword(values) {
		post('/auth/updatePassword', {
			newpassword: values.newpassword,
		}).then((data) => {
			this.setState({ passwordErrorMessage: data.data.message, passwordError: data.data.success });
		});
	}
	postVerifyMail(values) {
		post('/mailer/verify', { email: values.email }).then((data) => {
			if (data.data.success) {
				this.setState({ verifyMessage: 'New verify link was sent your email.' });
			} else {
				this.setState({ verifyMessage: 'Error...' });
			}
		});
	}
	render() {
		let { vatNumber, companyCity, companyCountry, companyName, companyPostCode, companyState, companyStreet } = this.state;
		const { t } = this.props;
		return (
			<Segment>
				<MiniContainer>
					<h3>
						<Icon name="building" />
						{t('companyInformation')}
					</h3>

					<Segment>
						<Form onSubmit={this.handleSubmit}>
							<Form.Input
								label={t('vatNumber')}
								placeholder={t('companyPagePlaceholder', { query: t('vatNumber') })}
								required
								name="vatNumber"
								value={vatNumber}
								onChange={this.handleChange}
							></Form.Input>
							<Form.Input
								label={t('companyName')}
								required
								name="companyName"
								placeholder={t('companyPagePlaceholder', { query: t('companyName') })}
								value={companyName}
								onChange={this.handleChange}
							></Form.Input>
							<Form.Input
								label={t('street')}
								required
								name="companyStreet"
								value={companyStreet}
								placeholder={t('companyPagePlaceholder', { query: t('street') })}
								onChange={this.handleChange}
							></Form.Input>
							<Form.Input
								label={t('city')}
								required
								value={companyCity}
								name="companyCity"
								placeholder={t('companyPagePlaceholder', { query: t('city') })}
								onChange={this.handleChange}
							></Form.Input>
							<Form.Input
								label={t('state')}
								value={companyState}
								name="companyState"
								placeholder={t('companyPagePlaceholder', { query: t('state') })}
								onChange={this.handleChange}
							></Form.Input>
							<Form.Dropdown
								label={t('country')}
								placeholder={t('countryPlaceHolder')}
								search
								required
								autoComplete="off"
								fluid
								name="companyCountry"
								value={companyCountry}
								options={Countries}
								selection
								onChange={this.handleChange}
							></Form.Dropdown>
							<Form.Input
								label={t('postcode') + ' (**)'}
								placeholder={t('companyPagePlaceholder', { query: t('postcode') })}
								value={companyPostCode}
								required={_.includes(['AU', 'CA', 'DE', 'ES', 'FR', 'GB', 'IN', 'IT', 'NL', 'US'], this.state.country)}
								name="companyPostCode"
								onChange={this.handleChange}
							></Form.Input>
							<Form.Button
								fluid
								icon={this.state.userInfo.icon}
								type="submit"
								color={this.state.userInfo.color}
								content={this.state.userInfo.saved}
								loading={this.state.userInfo.sending}
							/>
						</Form>
					</Segment>
				</MiniContainer>
			</Segment>
		);
	}
}

export default connect(
	(state) => ({ user: state.access.user.loggedUserObj }),
	(dispatch) => ({ updateUser: (user) => dispatch(updateProfile(user)) })
)(withTranslation()(UserPage));
