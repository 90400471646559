import React, { Component } from 'react';
import { toWapi } from '../../util/api';
import { Segment, Table, Button } from 'semantic-ui-react';
import _ from 'lodash';
import Page from '../../components/Page';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
class MainTable extends Component {
	state = { loading: true };
	componentDidMount() {
		this.postData();
	}

	async postData() {
		await fetch('https://micro.yapaytech.com/api/orderbot/list')
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ data: data.state, loading: false });
			});
	}
	handleSort = (clickedColumn, data) => () => {
		const { column, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				data: _.sortBy(data, [clickedColumn]),
				direction: 'ascending',
			});

			return;
		}

		this.setState({
			data: data.reverse(),
			direction: direction === 'ascending' ? 'descending' : 'ascending',
		});
	};
	async sendMessage(o, cid) {
		let { product } = this.props;
		let pid = product.id;
		toWapi(`/api/${pid}/12852/sendMessage`, product.settings.wapi_token, {
			to_number: o.phone,
			message: `Siparişiniz onaylandı.\n ${o.message}`,
			type: 'text',
		})
			.then(async (data) => {
				if (data.status !== 200) {
					return;
				}
				o.status = 'sent';
				await fetch('https://micro.yapaytech.com/api/orderbot/state/order/update', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						cid,
						order: o,
					}),
				})
					.then((response) => {
						return response.json();
					})
					.then((data) => {
						this.postData();
					});
			})
			.catch((err) => {
				console.error(err);
			});
	}
	refresh() {
		setTimeout(() => {
			window.location.reload(false);
		}, 1000);
	}
	render() {
		let { data, loading } = this.state;
		if (loading) return <Page.Loader></Page.Loader>;
		let newdata = _.filter(data, function (it) {
			return _.filter(it.orders, (o) => o.status).length;
		});
		return (
			<Segment>
				<Table celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Cid</Table.HeaderCell>
							<Table.HeaderCell>Telefon</Table.HeaderCell>
							<Table.HeaderCell>Ürün</Table.HeaderCell>
							<Table.HeaderCell>Durum</Table.HeaderCell>
							<Table.HeaderCell>Agent Mesajı</Table.HeaderCell>
							<Table.HeaderCell></Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{_.map(newdata, (it) => (
							<Table.Row>
								<Table.Cell>{it.cid}</Table.Cell>
								<Table.Cell>
									{_.map(
										_.filter(it.orders, (fi) => fi.status),
										(o) => (
											<Table.Row>
												<Table.Cell>{o.phone}</Table.Cell>
											</Table.Row>
										)
									)}
								</Table.Cell>
								<Table.Cell>
									{_.map(
										_.filter(it.orders, (fi) => fi.status),
										(o) => (
											<Table.Row>
												<Table.Cell>
													{
														<div>
															<p>{o.link}</p>
															<p>{o.size}</p>
														</div>
													}
												</Table.Cell>
											</Table.Row>
										)
									)}
								</Table.Cell>
								<Table.Cell>
									{_.map(
										_.filter(it.orders, (fi) => fi.status),
										(o) => (
											<Table.Row>
												<Table.Cell>{o.status}</Table.Cell>
											</Table.Row>
										)
									)}
								</Table.Cell>
								<Table.Cell>
									{_.map(
										_.filter(it.orders, (fi) => fi.status),
										(o) => (
											<Table.Row>
												<Table.Cell>{o.message}</Table.Cell>
											</Table.Row>
										)
									)}
								</Table.Cell>
								<Table.Cell>
									{_.map(
										_.filter(it.orders, (fi) => fi.status),
										(o) => (
											<Table.Row>
												<Table.Cell>
													{
														<Button.Group>
															<Button as={Link} to={'/#' + it.cid} primary>
																Konuşmaya Git
															</Button>
															<Button positive onClick={() => this.sendMessage(o, it.cid)}>
																Müşteriye Yolla
															</Button>
														</Button.Group>
													}
												</Table.Cell>
											</Table.Row>
										)
									)}
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
			</Segment>
		);
	}
}

const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product.live });
export default connect(stateToProps)(MainTable);
