import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Button, Icon, Modal, Header, Card, Message, Table } from 'semantic-ui-react';
import Page from '../../components/Page';
import { get, post } from '../../util/api';
import moment from 'moment';
import { Link } from 'react-router-dom';
import _ from 'lodash';
//import Lightbox from 'react-image-lightbox';
import { withTranslation } from 'react-i18next';
import { load_product } from '../../actions/product.actions';
class MainApps extends Component {
	state = { loading: true };
	componentDidMount() {
		this.getSubs();
	}
	close = () =>
		this.setState({ open: false, request_open: false, open_go_action: false, install_more_open: false, previewModal: false, historyModal: false });
	refresh() {
		setTimeout(() => {
			this.props.dispatch(load_product('live'));
		}, 1000);
	}
	checkoutHandler = async (quantity, custom_price, category, ownApp) => {
		this.setState({ btnloading: true });
		let link = await post('/live/payment/custompaddlelink', {
			plan_id: 745745,
			country: 'BR',
			email: this.props.user.email,
			pid: this.props.product.id,
			category,
		});
		//this.setState({ btnloading: false });
		let body = ownApp;
		body.data.selected_tier = category;
		body.data.payment_status = 'create';
		try {
			let { product = {} } = this.props;
			let email = this.props.user.email;
			//let country = 'BR';
			if (product && product.id) {
				window.Paddle.Checkout.open({
					override: link.data.response.url,
					email,
					country: this.state.companyCountry || 'BR',
					quantity,
					postcode: this.state.companyPostCode,
					passthrough: {
						pid: product.id,
						categoryId: category,
					},
					successCallback: async (data) => {
						post('/live/payment/checkSubscription', {
							checkout_id: data.checkout.id,
							plan_id: data.product.id,
							user_id: data.user.id,
							pid: this.props.product.id,
							quantity: data.product.quantity,
							email: data.user.email,
							extra: data,
						})
							.then(async (resp) => {
								if (resp.data.success) {
									await post(`/ext/apps/save/${body.id}`, body).then((response) => this.setState({ saveLoading: false }));
									await post(`/ext/apps/build`, {}).then((response) => console.log('ss', response));
									this.getSubs();
									this.setState({ messageOnScreen: 'Process is successful!', messageColor: 'green', btnloading: false });
								} else {
									this.setState({ messageOnScreen: 'Process cannot be applied!', messageColor: 'red', btnloading: false });
								}
							})
							.catch((err) => {
								console.error(err);
							});
					},
					closeCallback: () => {
						this.setState({ messageOnScreen: 'Your process has been cancelled, see you again soon!', messageColor: 'red', btnloading: false });
					},
				});
			}
		} catch (err) {
			console.error(err);
		}
	};
	updateAfterAmuntEntered = (value) => async () => {
		//let planId = parseInt(value.plan_id, 10);
		let { payment_status, selected_price, ownApp, selected_tier } = this.state;
		let body = ownApp;
		let selected_id = selected_tier === 'ecommerce' ? 7 : 5;
		if (!body) {
			let data = await post('/ext/apps/save/install', { selected_tier, id: selected_id });
			body = data.data.result[0];
		}
		body.data.selected_tier = selected_tier;
		body.data.payment_status = payment_status;

		if (payment_status === 'deleted') {
			body.data.status = 'disabled';
		} else {
			body.data.status = 'active';
		}
		//await post(`/ext/apps/build`, {}).then((response) => console.log('ss', response));
		post('/live/payment/updatePrice', {
			pid: this.props.product.id,
			subscription_id: value.subscription_id,
			user_id: value.user_id,
			price: value.price,
			categoryId: selected_id,
			payment_status,
			selected_price,
		}).then(async (data) => {
			if (data.data.success) {
				await post(`/ext/apps/save/${body.id}`, body).then((response) => this.setState({ saveLoading: false }));
				await post(`/ext/apps/build`, {}).then((response) => console.log('ss', response));
				this.getSubs();
				this.setState({ messageOnScreen: 'Update process is successful!', messageColor: 'green' });
			} else {
				this.setState({ messageOnScreen: 'Update process cannot be applied!', messageColor: 'red' });
			}
		});
		this.close();
	};
	openChangePlanModalPreview = async (subscription_id, price, tier, payment_status, ownApp) => {
		let { subscriptions } = this.state;
		let activeSub = _.find(subscriptions, (it) => it.data.subscription_plan_id === '745745' || it.data.subscription_plan_id === 'ext_custom');
		let currency_price = parseFloat(activeSub.data.next_payment_amount || activeSub.data.unit_price);
		let total_price = Math.max(currency_price + price, 0);
		this.setState({ selected_price: price, selected_tier: tier });
		await post('/live/payment/checkpreview', {
			subscription_id,
			price: total_price,
		}).then((data) => {
			if (data.data.success) {
				this.setState({
					previewdata: data.data.response,
					previewModal: true,
					selectedSubcriptionId: subscription_id,
					payment_status,
					ownApp,
					total_price,
				});
			} else {
				this.setState({ messageOnScreen: data.data.message.message, messageColor: 'red' });
			}
		});
	};
	getPaymentHistory = (value) => async () => {
		await post('/live/payment/getPaymentHistory', { subscription_id: value.subscription_id, pid: this.state.pid }).then((data) => {
			this.setState({ paymentHistory: _.orderBy(data.data.response, 'id', 'desc'), historyModal: true });
		});
	};
	updatePayment = (value) => async () => {
		let data = await post('/live/payment/getsubsdetails', { pid: this.props.product.id, subscription_id: value.subscription_id });
		let update_url = data.data && data.data.response && data.data.response[0].update_url;
		window.Paddle.Checkout.open({
			override: update_url,
			passthrough: {
				pid: this.props.product.id,
			},
		});
	};
	async getSubs() {
		let [extData, subscriptionsData] = await Promise.all([get('/ext/apps/list'), post('/live/product/getsubscription', { pid: this.props.product.id })]);
		let allApps = _.sortBy(
			_.filter(extData.data, (it) => it.data.payment_status !== 'deleted'),
			(it) => it.data.ext_id
		);
		let allAppsAll = extData.data;
		let subscriptions = subscriptionsData.data.subscriptions;
		this.setState({ allApps, subscriptions, loading: false, allAppsAll });
	}
	render() {
		let { t } = this.props;
		let { subscriptions, loading, allApps, allAppsAll } = this.state;
		if (loading) return <Page.Loader></Page.Loader>;
		let isTrial = _.findIndex(subscriptions, { trial: true }) > -1;
		let isActiveSub = _.find(subscriptions, (it) => it.data.status === 'active' || it.data.status === 'trial');

		let label_ext_all = _.find(allAppsAll, (it) => it.data.ext_id === 5);
		let ecommerce_ext_all = _.find(allAppsAll, (it) => it.data.ext_id === 7);
		let label_ext = _.find(allApps, (it) => it.data.ext_id === 5 && it.data.payment_status !== 'trial');
		let ecommerce_ext = _.find(allApps, (it) => it.data.ext_id === 7 && it.data.payment_status !== 'trial');
		let diffTrial = isActiveSub && moment(isActiveSub.created_at).add(4, 'days').diff(moment(), 'days');
		//console.log('isActiveSub', moment(isActiveSub.created_at).add(4, 'days'), moment());
		return (
			<Page.Wrap>
				<Segment>
					{isTrial && <Message warning>{diffTrial > 0 ? t('youHaveTrial', { day: diffTrial }) : t('trialOverToday')}</Message>}
					<Header textAlign="center">{t('subscriptions')}</Header>
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>App</Table.HeaderCell>
								<Table.HeaderCell>{t('price')}</Table.HeaderCell>
								<Table.HeaderCell>{isTrial ? t('trialEndDate') : t('nextBillDate')}</Table.HeaderCell>
								<Table.HeaderCell width={4}></Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{_.map(allApps, (it) => (
								<Table.Row>
									<Table.Cell>{it.data.ext_id === 5 ? `${t('labelPackage')} ` : `${t('ecommercePackage')}`}</Table.Cell>
									<Table.Cell>{it.price}</Table.Cell>
									<Table.Cell></Table.Cell>
									<Table.Cell>
										<Button color="teal" as={Link} to={it.data.ext_id === 5 ? '/appstore/agents' : '/ecommerce'}>
											{t('preferences')}
										</Button>
									</Table.Cell>
								</Table.Row>
							))}
							{isTrial ? (
								<Table.Row>
									<Table.Cell>{t('total')}</Table.Cell>
									<Table.Cell>{t('TrialPeriod')}</Table.Cell>
									<Table.Cell>{moment(isActiveSub.created_at).add('3', 'day').format('DD-MM-YYYY')}</Table.Cell>
									<Table.Cell></Table.Cell>
								</Table.Row>
							) : isActiveSub ? (
								<Table.Row>
									<Table.Cell>{t('total')}</Table.Cell>
									<Table.Cell>{isActiveSub.data.next_payment_amount}</Table.Cell>
									<Table.Cell>{isActiveSub.data.next_bill_date}</Table.Cell>
									<Table.Cell>
										<div className="payment-buttons" size="mini">
											<Button
												onClick={this.getPaymentHistory({ subscription_id: isActiveSub.data.subscription_id })}
												size="mini"
												color="teal"
											>
												{t('invoice')}
											</Button>
											<Button
												color="grey"
												size="mini"
												onClick={this.updatePayment({ subscription_id: isActiveSub.data.subscription_id, isActiveSub })}
												style={{ marginLeft: 10 }}
											>
												{t('updatePayment')}
											</Button>
										</div>
									</Table.Cell>
								</Table.Row>
							) : (
								<Table.Row>
									<Table.Cell>{t('noSubscription')}</Table.Cell>
									<Table.Cell></Table.Cell>
									<Table.Cell></Table.Cell>
									<Table.Cell></Table.Cell>
								</Table.Row>
							)}
						</Table.Body>
					</Table>
					<Header textAlign="center">{t('packages')}</Header>
					<Card.Group>
						<Card style={{ marginLeft: '90px', marginRight: '90px' }}>
							<Card.Content style={{ textAlign: 'center', height: '70px', padding: '23px' }} header={t('labelPackage')} />
							<Card.Content
								description={
									<div style={{ maxHeight: '200px' }}>
										<div style={{ textAlign: 'center' }}>
											<span style={{ fontSize: '72px', lineHeight: '72px' }}>
												$19<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
											</span>
										</div>

										<div style={{ listStyle: 'none', minHeight: '100px' }}>
											<li>
												<b>Çoklu Agent Sistemi:</b> Konuşma dağıtım sistemiyle aynı anda 4 kişiyle WhatsApp'ınızı yönetin. Böylece çoklu
												kişi kullanımdan doğan karışıklığın önüne geçin.
											</li>
										</div>
									</div>
								}
							/>
							<Card.Content style={{ maxHeight: '80px' }}>
								<div style={{ textAlign: 'right' }}>
									{/* 	{console.log('ss', isTrial, isActiveSub, ecommerce_ext)} */}
									{isTrial ? (
										<Button
											positive
											onClick={() => this.checkoutHandler(1, 19, 'label', label_ext_all)}
											loading={this.state.btnloading}
											fluid
										>
											{t('subscribe')}
										</Button>
									) : isActiveSub ? (
										label_ext ? (
											<Button
												negative
												onClick={() => this.openChangePlanModalPreview(isActiveSub.charge_id, -19, 'label', 'deleted', label_ext_all)}
												loading={this.state.btnloading}
												fluid
											>
												{t('unSubscribe')}
											</Button>
										) : (
											<Button
												primary
												onClick={() => this.openChangePlanModalPreview(isActiveSub.charge_id, 19, 'label', 'create', label_ext_all)}
												loading={this.state.btnloading}
												fluid
											>
												{t('subscribe')}
											</Button>
										)
									) : (
										<Button
											positive
											onClick={() => this.checkoutHandler(1, 19, 'label', label_ext_all)}
											loading={this.state.btnloading}
											fluid
										>
											{t('subscribe')}
										</Button>
									)}
								</div>
							</Card.Content>
						</Card>
						<Card style={{ marginLeft: '90px', marginRight: '90px' }}>
							<Card.Content style={{ textAlign: 'center', height: '70px', padding: '23px' }} header={t('ecommercePackage')} />
							<Card.Content
								description={
									<div style={{ maxHeight: '200px' }}>
										<div style={{ textAlign: 'center' }}>
											<span style={{ fontSize: '72px', lineHeight: '72px' }}>
												$29<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
											</span>
										</div>

										<div style={{ listStyle: 'none', minHeight: '100px' }}>
											<li>
												<b>E-ticaret Özelliği:</b> E-ticaret özelliğiyle birlikte ürün paylaşımı yapıp, hızlı sipariş almaya başlayın.
											</li>
										</div>
									</div>
								}
							/>
							<Card.Content style={{ maxHeight: '80px' }}>
								<div style={{ textAlign: 'right' }}>
									{isTrial ? (
										<Button
											positive
											onClick={() => this.checkoutHandler(1, 29, 'ecommerce', ecommerce_ext_all)}
											loading={this.state.btnloading}
											fluid
										>
											{t('subscribe')}
										</Button>
									) : isActiveSub ? (
										ecommerce_ext ? (
											<Button
												negative
												onClick={() =>
													this.openChangePlanModalPreview(isActiveSub.charge_id, -29, 'ecommerce', 'deleted', ecommerce_ext_all)
												}
												loading={this.state.btnloading}
												fluid
											>
												{t('unSubscribe')}
											</Button>
										) : (
											<Button
												primary
												onClick={() =>
													this.openChangePlanModalPreview(isActiveSub.charge_id, 29, 'ecommerce', 'create', ecommerce_ext_all)
												}
												loading={this.state.btnloading}
												fluid
											>
												{t('subscribe')}
											</Button>
										)
									) : (
										<Button
											positive
											onClick={() => this.checkoutHandler(1, 29, 'ecommerce', ecommerce_ext_all)}
											loading={this.state.btnloading}
											fluid
										>
											{t('subscribe')}
										</Button>
									)}
								</div>
							</Card.Content>
						</Card>
					</Card.Group>
				</Segment>

				{isActiveSub && (
					<Modal open={this.state.previewModal} onClose={this.close} style={{ maxWidth: '700px' }}>
						<Header icon="exchange" content={t('updateSubscription')} />
						<Modal.Content>
							<div style={{ display: 'flex' }}>
								<div style={{ margin: 'auto' }}>
									<h3>{t('immediatePayment')}:</h3>
									<p>
										{t('amount')}: {this.state.previewdata && this.state.previewdata.immediate_payment.amount + ' $'}
									</p>
									<p>
										{t('date')}: {this.state.previewdata && this.state.previewdata.immediate_payment.date}
									</p>
								</div>
								<div style={{ margin: 'auto' }}>
									<h3>{t('nextPayment')}:</h3>
									<p>
										{t('amount')}: {this.state.previewdata && this.state.previewdata.next_payment.amount + ' $'}
									</p>
									<p>
										{t('date')}: {this.state.previewdata && this.state.previewdata.next_payment.date}
									</p>
								</div>
							</div>
						</Modal.Content>
						<Modal.Actions style={{ textAlign: 'left' }}>
							<div style={{ display: 'flex' }}>
								<h3 style={{ marginBottom: 'auto', marginTop: 'auto' }}>Are you sure update your {this.state.selectedPlanid}. </h3>
								<Button
									color="green"
									style={{ marginLeft: 'auto' }}
									onClick={this.updateAfterAmuntEntered({
										subscription_id: isActiveSub.data.subscription_id,
										user_id: isActiveSub.data.user_id,
										price: this.state.total_price,
									})}
								>
									<Icon name="checkmark" /> {t('yes')}
								</Button>
								<Button color="red" onClick={this.close}>
									<Icon name="x" /> {t('close')}
								</Button>
							</div>
						</Modal.Actions>
					</Modal>
				)}
				{/*Payment History*/}
				<Modal open={this.state.historyModal} onClose={this.close} style={{ maxWidth: '500px' }}>
					<Header icon="dollar" content={t('paymentHistory')} />
					<Modal.Content>
						<h4>Next Payment</h4>
						{_.map(_.filter(this.state.paymentHistory, { is_paid: 0 }), function (it, i) {
							return (
								<a
									href={it.is_paid ? it.receipt_url : null}
									target="_blank"
									rel="noopener noreferrer"
									key={it.id}
									style={{
										display: 'flex',
										color: '#646464',
										padding: '14px',
										flex: ' 0 0 auto',
										textDecoration: 'none',
										border: '1px solid rgb(221, 221, 221)',
										fontSize: '600',
									}}
								>
									<div style={{ width: '70%' }}>
										<div style={{ fontWeight: '600' }}>{'OrderCode: ' + it.id}</div>
										<div style={{ fontWeight: '600' }}>
											{'Next Bill Date: ' + moment(it.payout_date, 'YYYY-MM-DD').format('MMM DD, YYYY')}
										</div>
									</div>
									<div>
										<div style={{ fontWeight: '600' }}>{'$' + it.amount}</div>
									</div>
								</a>
							);
						})}
					</Modal.Content>
					<Modal.Content style={{ paddingTop: '0', paddingBottom: '0' }}>
						<h4>{t('pastPayments')}</h4>
					</Modal.Content>
					<Modal.Content scrolling>
						<div style={{ borderTop: '1px solid rgb(221, 221, 221)', borderRadius: '4px', display: 'flex' }}></div>
						{_.map(_.filter(this.state.paymentHistory, { is_paid: 1 }), function (it, i) {
							return (
								<a
									href={it.is_paid ? it.receipt_url : null}
									target="_blank"
									rel="noopener noreferrer"
									key={it.id}
									style={{
										display: 'flex',
										color: '#646464',
										padding: '14px',
										flex: ' 0 0 auto',
										textDecoration: 'none',
										border: '1px solid rgb(221, 221, 221)',
										borderTop: 'none',
										fontSize: '600',
									}}
								>
									<div style={{ width: '70%' }}>
										<div style={{ fontWeight: '600' }}>{'Order Code: ' + it.id}</div>
										<div style={{ fontWeight: '600' }}>{'Date: ' + moment(it.payout_date, 'YYYY-MM-DD').format('MMM DD, YYYY')}</div>
									</div>
									<div>
										<div style={{ fontWeight: '600' }}>{'$' + it.amount}</div>
										<div style={{ textDecoration: 'underline', color: 'black', fontWeight: '400' }}>{t('showReceipt')}</div>
									</div>
								</a>
							);
						})}
					</Modal.Content>
				</Modal>
			</Page.Wrap>
		);
	}
}

export default connect((state) => ({ user: state.access.user.loggedUserObj, product: state.product.live }))(withTranslation()(MainApps));
