import React, { Component } from 'react';
import MyMenu from '../menu';
import { connect } from 'react-redux';
import { Icon, Breadcrumb, Segment, Message, Form, Dropdown } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Page from '../../../components/Page';
import { post } from '../../../util/api';
import AccountProgress from '../../../components/AccountProgress';
import { withTranslation } from 'react-i18next';

const ecommerces = [
	{
		text: 'Shopify',
		value: 'shopify',
	},
	{
		text: 'WooCommerce',
		value: 'woocommerce',
	},
	{
		text: 'BigCommerce',
		value: 'bigcommerce',
	},
	{
		text: 'Magento',
		value: 'magento',
	},
	{
		text: 'OpenCart',
		value: 'opencart',
	},
];
class Shopify extends Component {
	state = {
		name: this.props.user && this.props.user.name,
		email: this.props.user && this.props.user.email,
		phone: this.props.user && this.props.user.phone,
		loading: false,
	};

	handleFormChange = (e, { name, value }) => this.setState({ [name]: value });
	handleSumbit = () => {
		this.setState({ btnloading: true });
		let { name, phone, email, type } = this.state;
		post('/live/survey/add', {
			data: {
				name,
				phone,
				email,
				type,
			},
		}).then((data) => {
			if (data.data.success) {
				this.setState({ btnloading: false, message: 'Thank you for your contribution. We will contact you later about this.' });
			} else {
				this.setState({ btnloading: false, message: 'Error' });
			}
		});
	};
	render() {
		let { name, email, phone, type } = this.state;
		const { t } = this.props;
		if (this.state.loading) {
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
								{t('integrations')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
					flex
				>
					<MyMenu activeItem="shopify">
						<Page.Loader />
					</MyMenu>
				</Page>
			);
		}
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/settings`}>
							<Icon name="setting" />
							{t('settings')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />

						<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
							{t('integrations')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />
						<Breadcrumb.Section>{t('ecommerce')}</Breadcrumb.Section>
					</Breadcrumb>
				}
				extra={
					<div className="ml-auto">
						<AccountProgress></AccountProgress>
					</div>
				}
				flex
			>
				<MyMenu activeItem="request">
					<Page.Wrap>
						<Segment>
							<h4 style={{ textAlign: 'center' }}>
								{t('manyDifferenEcommerce')}
								<br />
								{t('manyDifferenEcommerce2')}
							</h4>
						</Segment>
						<Segment>
							<Message hidden={!this.state.message}>{this.state.message}</Message>
							<Form onSubmit={this.handleSumbit}>
								<Form.Input
									label={t('fullName')}
									name="name"
									required
									value={name}
									placeholder={t('companyPagePlaceholder', { query: t('fullName') })}
									onChange={this.handleFormChange}
								></Form.Input>
								<Form.Input
									label={t('workMail')}
									name="email"
									type="email"
									required
									value={email}
									placeholder={t('companyPagePlaceholder', { query: t('workMail') })}
									onChange={this.handleFormChange}
								></Form.Input>
								<Form.Input
									label={t('phoneNumber')}
									name="phone"
									type="phone"
									required
									value={phone}
									placeholder={t('companyPagePlaceholder', { query: t('phoneNumber') })}
									onChange={this.handleFormChange}
								></Form.Input>
								<Form.Field required>
									<label>{t('type')}</label>
									<Dropdown
										options={ecommerces}
										selection
										placeholder={t('selectEcommerce')}
										name="type"
										onChange={this.handleFormChange}
										value={type}
									></Dropdown>
								</Form.Field>
								<Form.Button type="submit" positive loading={this.state.btnloading}>
									{t('request')}
								</Form.Button>
							</Form>
						</Segment>
					</Page.Wrap>
				</MyMenu>
			</Page>
		);
	}
}

const stateToProps = (state) => ({
	access: state.access,
	location: state.router.location,
	product: state.product.live,
	user: state.access.user.loggedUserObj,
});
export default connect(stateToProps)(withTranslation()(Shopify));
