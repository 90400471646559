import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import 'semantic-ui-css/semantic.min.css';
import './scss/app.scss';
import App from './App';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './configureStore';
import ErrorMessage from './components/ErrorMessage';
import * as Sentry from '@sentry/browser';
import Page from './components/Page';
//import * as serviceWorker from './serviceWorker';

if (process.env.REACT_APP_RESELLER) {
	if (!window.CONFIG) {
		ReactDOM.render(<div className="host-not-found">Host {window.location.host} could not be found in the system!</div>, document.getElementById('root'));
	}
} else window.CONFIG = { icon: '/dialogtab.png', title: 'DialogTab', tos: 'https://dialogtab.com/legal/terms', privacy: 'https://dialogtab.com/legal/privacy' };

//<a onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</a>

const store = configureStore();
class LogicApp extends React.Component {
	state = {
		error: null,
		errorInfo: null,
	};

	componentDidCatch(error, errorInfo) {
		this.setState({ error, errorInfo });
		Sentry.withScope((scope) => {
			scope.setExtras(errorInfo);
			const eventId = Sentry.captureException(error);
			this.setState({ eventId });
		});
	}

	render() {
		const { error } = this.state;
		return (
			<Provider store={store}>
				<ConnectedRouter history={history}>
					{error ? (
						<ErrorMessage
							code="500"
							title="There was some errors while loading the page."
							list={['Please reload the page and try again.', 'If this error is persisting please contact us from info@yapaytech.com']}
						/>
					) : (
						<Suspense fallback={<Page.Loader></Page.Loader>}>
							<App />
						</Suspense>
					)}
				</ConnectedRouter>
			</Provider>
		);
	}
}

if (window.CONFIG) ReactDOM.render(<LogicApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
