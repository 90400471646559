import { put, call, takeLatest } from 'redux-saga/effects';
//import Cookies from 'js-cookie';
import * as ActionTypes from '../constants/actionTypes';
import { get } from '../util/api';
import lGet from 'lodash/get';
//const YTECH_COOKIE_NAME = 'ytech-auth-user';

function* product(action) {
	try {
		const response = yield call(get, `/${action.product_type}/product`);
		if (!response || !response.success) return put({ type: ActionTypes.PRODUCT_FAILED, error: 'Load Failed.' });
		//console.log(response);
		if (response.force_reload) yield put({ type: ActionTypes.PROFILE_REQUESTED });
		yield put({
			type: ActionTypes.PRODUCT_SUCCEEDED,
			product: response.product,
			list: [response.product],
		});
	} catch (error) {
		if (lGet(error.response, 'data')) {
			yield put({ type: ActionTypes.PRODUCT_FAILED, error: error.response.data });
		} else {
			yield put({ type: ActionTypes.PRODUCT_FAILED, error });
		}
	}
}

export function* watchProduct() {
	yield takeLatest(ActionTypes.PRODUCT_REQUESTED, product);
}
