import React, { PureComponent } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Table from './table';
import SubTable from './sub_table';
import Phones from './phone';
class WGROUP extends PureComponent {
	render() {
		return (
			<Switch>
				<Route path="/wgroup/phones_status" exact component={Phones}></Route>
				<Route path="/wgroup/:day" exact component={SubTable}></Route>
				<Route path="/wgroup" exact component={Table}></Route>
				<Redirect to="/wgroup" />
			</Switch>
		);
	}
}

export default WGROUP;
