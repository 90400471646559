import React, { useState } from 'react';
import Page from '../../../components/Page';
import { Icon, Breadcrumb, Table, Tab, Button, Modal, Header, Message } from 'semantic-ui-react';
import { Link, Prompt, Route, matchPath, NavLink } from 'react-router-dom';
import useFetch from 'use-http';
import { Formik } from 'formik';
import _ from 'lodash';
import MyMenu from '../menu';
import { TextInput, TableLine } from '../inputs';
import AccountProgress from '../../../components/AccountProgress';
import { useTranslation } from 'react-i18next';
const fetch_config = { responseType: 'json', cachePolicy: 'no-cache' };

function GeneralConfigs() {
	const { t } = useTranslation();
	return (
		<Tab.Pane>
			<Table definition>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width={2} />
						<Table.HeaderCell>{t('value')}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					<TableLine title="Admin Token">
						<TextInput name="staticOptions.whatsapp_business_admin_token" placeholder={t('whatsappBusinessAdminTokenPlace')} />
					</TableLine>
					<TableLine title="Token">
						<TextInput name="staticOptionswhatsapp_business_token" placeholder={t('whatsappBusinessTokenPlace')} />
					</TableLine>
				</Table.Body>
			</Table>
		</Tab.Pane>
	);
}

function RequestConfigs() {
	const { t } = useTranslation();
	return (
		<Tab.Pane>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<img src="/images/whatsapp-business.svg" alt="/images/whatsapp-business.svg" style={{ width: '240px', margin: '5px', marginRight: '20px' }} />
				<div style={{ width: '300px', margin: '5px' }}>
					<p>{t('whatsappBusinessSubs', { title: window.CONFIG.title })}</p>
					<p>{t('whatsappBusinessFace')}</p>
					{/* 	<p>
						When you get a {window.CONFIG.title} account we will need your approved Facebook Business Page. Than you can apply WhatsApp Business.
					</p> */}
					<Button color="green" fluid target="_blank" as={'a'} href="https://yapaytech.com/products/whatsapp-business-api/registration">
						{t('requestAccess')}
					</Button>
					<br />
					<p>{t('whatsappBusinessTestSandbox')}</p>
					<Button primary as={Link} to="/settings/integrations/whatsapp-sandbox" icon labelPosition>
						<Icon name="mobile alternate"></Icon>
						{t('clickToAccess')}
					</Button>

					<br />
					<br />
					<p>{t('whatsappBusinessTry')}</p>
					<Button primary as={Link} to="/settings/integrations/whatsapp-web-api" icon labelPosition>
						<Icon name="whatsapp"></Icon>
						{t('clickToTry')}
					</Button>
				</div>
			</div>
		</Tab.Pane>
	);
}

function Configs() {
	const { t } = useTranslation();
	const panes = [
		{
			menuItem: {
				as: NavLink,
				content: t('requestAccess'),
				to: '/settings/integrations/whatsapp-business/request-access',
				key: 'request-access',
			},
			render: () => <Route path="/settings/integrations/whatsapp-business/request-access" component={RequestConfigs} />,
		},
		{
			menuItem: {
				as: NavLink,
				content: t('general'),
				to: '/settings/integrations/whatsapp-business/general',
				exact: true,
				key: 'whatsappbusiness',
			},
			render: () => <Route path="/settings/integrations/whatsapp-business/general" exact component={GeneralConfigs} />,
		},
	];
	// Required to get a correct tab opened by default
	const defaultActiveIndex = panes.findIndex((pane) => {
		return !!matchPath(window.location.pathname, {
			path: pane.menuItem.to,
			exact: true,
		});
	});
	return (
		<Page.Wrap>
			<Tab menu={{ secondary: true, pointing: true }} defaultActiveIndex={defaultActiveIndex} panes={panes} />
		</Page.Wrap>
	);
}

export default function ClientSettings() {
	const { t } = useTranslation();
	const { loading, response } = useFetch('/api/dahi/message/getLiveChat', fetch_config, []);
	const result = response && response.data && response.data.result;
	const [isShowing, setIsShowing] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [message, setMessage] = useState('');
	const [messageType, setMessageType] = useState('');
	const [showMessage, setShowMessage] = useState(true);
	function openModal() {
		setIsShowing(true);
	}
	function closeModal() {
		setIsShowing(false);
	}
	function removeMaytapi(values) {
		setDeleting(true);
		fetch('/api/dahi/channel/save', {
			method: 'POST',
			body: JSON.stringify({
				channel: 'whatsapp_business',
				data: {
					whatsapp_business_admin_token: values.staticOptions && values.staticOptions.whatsapp_business_admin_token,
					whatsapp_business_token: values.staticOptions && values.staticOptions.whatsapp_business_token,
					remove: true,
				},
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.success) {
					setShowMessage(false);
					setIsShowing(false);
					setMessageType('positive');
					setMessage('Your Maytapi deleted.');
					setDeleting(false);
					window.location.reload();
				} else {
					setShowMessage(false);
					setIsShowing(false);
					setMessageType('negative');
					setMessage('Error');
					setDeleting(false);
				}
			});
	}
	const initialValues = React.useMemo(() => {
		if (!result) return;
		const { name, staticOptions } = result;
		//const agent = _.pick(_.assignIn(agentOptions, staticOptions), ['emoji']);
		return {
			name,
			staticOptions: _.pick(staticOptions, ['whatsapp_business_admin_token', 'whatsapp_business_token']),
		};
	}, [result]);
	if (loading)
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/settings`}>
							<Icon name="setting" />
							{t('settings')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />

						<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
							{t('integrations')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
					</Breadcrumb>
				}
				flex
			>
				<MyMenu activeItem="whatsapp business">
					<Page.Loader />
				</MyMenu>
			</Page>
		);
	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values, actions) => {
				if (values.client) values.client.name = values.name;
				fetch('/api/dahi/channel/save', {
					method: 'POST',
					body: JSON.stringify({
						channel: 'whatsapp_business',
						data: {
							whatsapp_business_admin_token: values.staticOptions && values.staticOptions.whatsapp_business_admin_token,
							whatsapp_business_token: values.staticOptions && values.staticOptions.whatsapp_business_token,
							remove: false,
						},
					}),
					headers: {
						'Content-Type': 'application/json',
					},
				})
					.then((response) => {
						return response.json();
					})
					.then((json) => {
						if (json.success) {
							setShowMessage(false);
							setMessageType('positive');
							setMessage('Your WhatsApp Business connect.');
						} else {
							setShowMessage(false);
							setMessageType('negative');
							setMessage('Error');
						}
						actions.resetForm(values);
						actions.setSubmitting(false);
					})
					.catch((e) => {
						console.error(e);
						setShowMessage(false);
						setMessageType('negative');
						setMessage('Error');
						actions.setSubmitting(false);
					});
			}}
		>
			{(props) => {
				return (
					<Page
						header={
							<Breadcrumb size="big">
								<Breadcrumb.Section as={Link} to={`/settings`}>
									<Icon name="setting" />
									{t('settings')}
								</Breadcrumb.Section>
								<Breadcrumb.Divider icon="right chevron" />

								<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
									{t('integrations')}
								</Breadcrumb.Section>
								<Breadcrumb.Divider icon="right chevron" />
								<Breadcrumb.Section>{t('whatsappBusinessSettings')}</Breadcrumb.Section>
							</Breadcrumb>
						}
						extra={
							<div className="ml-auto d-flex">
								<AccountProgress></AccountProgress>
								<Button
									disabled={
										!(
											props.values.staticOptions &&
											props.values.staticOptions.whatsapp_business_admin_token &&
											props.values.staticOptions.whatsapp_business_token
										)
									}
									style={{ marginLeft: '10px' }}
									negative
									type="button"
									onClick={() => openModal()}
								>
									{t('remove')}
								</Button>
								<Button className="ml-auto" loading={props.isSubmitting} primary type="submit" onClick={props.submitForm}>
									{t('saveChanges')}
								</Button>
							</div>
						}
						flex
					>
						<MyMenu activeItem="whatsapp business">
							<form onSubmit={props.handleSubmit} autoComplete="off">
								<Prompt
									when={props.dirty}
									message={(location) =>
										location.pathname.startsWith('/settings/integrations/website-chat') ? true : t('questionWithoutSaving')
									}
								/>

								<Configs />
								<div style={{ textAlign: 'center' }}>
									<Message compact hidden={showMessage} positive={messageType === 'positive'} negative={messageType === 'negative'}>
										{message}
									</Message>
								</div>
							</form>
						</MyMenu>
						{/*delete modal*/}
						<Modal open={isShowing} onClose={() => closeModal()} style={{ width: '500px' }}>
							<Header icon="trash" content="Remove Maytapi" />
							<Modal.Content>
								<p>{t('tsoftRemove', { title: 'Whatsapp Bussiness API' })}</p>
							</Modal.Content>
							<Modal.Actions>
								<Button color="red" onClick={() => closeModal()}>
									<Icon name="remove" /> {t('no')}
								</Button>
								<Button color="green" loading={deleting} onClick={() => removeMaytapi(props.values)}>
									<Icon name="checkmark" /> {t('yes')}
								</Button>
							</Modal.Actions>
						</Modal>
					</Page>
				);
			}}
		</Formik>
	);
}
