import React, { Component } from 'react';
import { post } from '../../util/api';
import { Segment, Table, Tab, Button, Header, Form } from 'semantic-ui-react';
import _ from 'lodash';

export default class MainTable extends Component {
	TableLineReseller = (data) =>
		_.map(data, (val, index) => (
			<Table.Row key={index}>
				{' '}
				<Table.Cell>
					<Button
						color="blue"
						onClick={() =>
							this.selectProduct({
								email: val.owner_email,
								pid: val.pid,
								agentQuantity: val.agent_limit,
								phoneQuantity: val.phone_limit,
								instagramQuantity: val.instagram_limit,
							})
						}
					>
						Edit
					</Button>
				</Table.Cell>
				<Table.Cell>{val && val.owner_id}</Table.Cell>
				<Table.Cell>{val && val.owner_email}</Table.Cell>
				<Table.Cell>{val && val.agent_limit}</Table.Cell>
				<Table.Cell>{val && val.phone_limit}</Table.Cell>
				<Table.Cell>{val && val.instagram_limit}</Table.Cell>
			</Table.Row>
		));
	selectProduct(value) {
		this.setState({
			pid: value.pid,
			agentQuantity: value.agentQuantity || 0,
			phoneQuantity: value.phoneQuantity || 0,
			instagramQuantity: value.instagramQuantity || 0,
			plan_id: 'custom',
			email: value.email,
		});
	}
	resellerProduct = () => {
		this.setState({ btnloading: true });
		let { pid, agentQuantity, phoneQuantity, instagramQuantity } = this.state;
		post('/live/admin/setResellerLimit', {
			pid: pid,
			agent_limit: parseInt(agentQuantity),
			phone_limit: parseInt(phoneQuantity),
			instagram_limit: parseInt(instagramQuantity),
		}).then((data) => {
			if (data.data.success) {
				this.setState({ btnloading: false });
				this.refresh();
			} else {
				this.setState({ btnloading: false, errMessage: data.data.error });
			}
		});
	};
	handleChange = (e, { name, value }) => this.setState({ [name]: value });
	state = {
		loading: true,
		activePage: 1,
		limit: 50,
		email: '',
		buttonloading: true,
	};
	componentDidMount() {
		this.postData();
	}
	async postData() {
		await post('/live/admin/dashboard').then((data) => this.setState({ data: _.orderBy(data.data.result, 'owner_id', 'desc'), loading: false }));
	}
	handleSort = (clickedColumn, data) => () => {
		const { column, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				data: _.sortBy(data, [clickedColumn]),
				direction: 'ascending',
			});

			return;
		}

		this.setState({
			data: data.reverse(),
			direction: direction === 'ascending' ? 'descending' : 'ascending',
		});
	};
	refresh() {
		setTimeout(() => {
			window.location.reload(false);
		}, 1000);
	}
	render() {
		const { column, direction, loading, data } = this.state;
		if (loading) return <Segment attached="bottom">loading...</Segment>;
		let resellerdata = _.map(_.filter(data, { is_reseller: true }), function (it) {
			it = {
				agent_limit: it.agent_limit || 0,
				phone_limit: it.phone_limit || 0,
				instagram_limit: it.instagram_limit || 0,
				owner_id: it.owner_id,
				owner_name: it.owner_name,
				is_reseller: it.is_reseller,
				is_enterprise: it.is_enterprise,
				is_eticaret: it.is_eticaret,
				owner_email: it.owner_email,
				pid: it.pid,
				token: it.token,
				agents: it.agents,
				phones: it.phones,
				instagrams: it.instagrams,
				package: it.package,
				verified: it.verified,
				total_agent: it.total_agent,
				wapi_pid: it.wapi_pid,
				wapi_token: it.wapi_token,
				analyticview: it.analyticview,
			};
			return it;
		});
		return (
			<div style={{ display: 'flex', maxHeight: '95%' }}>
				<Tab.Pane attached={false} style={{ width: '75%', overflow: 'auto', padding: 0 }}>
					<Table celled sortable>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell width={1}></Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'owner_id' ? direction : null} onClick={this.handleSort('owner_id', resellerdata)}>
									Id
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'owner_email' ? direction : null} onClick={this.handleSort('owner_email', resellerdata)}>
									Email
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'agent_limit' ? direction : null} onClick={this.handleSort('agent_limit', resellerdata)}>
									Agent Limit
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'phone_limit' ? direction : null} onClick={this.handleSort('phone_limit', resellerdata)}>
									Phone Limit
								</Table.HeaderCell>
								<Table.HeaderCell
									sorted={column === 'instagram_limit' ? direction : null}
									onClick={this.handleSort('instagram_limit', resellerdata)}
								>
									Instagram Limit
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>{this.TableLineReseller(resellerdata)}</Table.Body>
					</Table>
				</Tab.Pane>
				<Segment className="addproduct" style={{ marginTop: '0', width: '25%', maxHeight: '786px' }}>
					<Header>Edit</Header>
					<Form autoComplete="off" onSubmit={this.resellerProduct}>
						<Form.Input label="Email" readOnly value={this.state.email} placeholder="Select Product" required></Form.Input>
						<Form.Input label="Pid" readOnly value={this.state.pid} placeholder="Select Product" required></Form.Input>
						<Form.Input
							name="agentQuantity"
							type="number"
							label="Agent Quantity"
							disabled={!this.state.pid}
							required
							value={this.state.agentQuantity}
							placeholder="Select quantity"
							onChange={this.handleChange}
						></Form.Input>
						<Form.Input
							name="phoneQuantity"
							type="number"
							label="Phone Quantity"
							disabled={!this.state.pid}
							required
							value={this.state.phoneQuantity}
							placeholder="Select quantity"
							onChange={this.handleChange}
						></Form.Input>
						{/* <Form.Input
							name="instagramQuantity"
							type="number"
							label="Instagram Quantity"
							disabled={!this.state.pid}
							required
							value={this.state.instagramQuantity}
							placeholder="Select quantity"
							onChange={this.handleChange}
						></Form.Input> */}
						<div className="d-flex">
							<Form.Button
								positive
								content="Save"
								type="submit"
								style={{ width: '100px' }}
								disabled={!this.state.pid}
								loading={this.state.btnloading}
							/>
						</div>
					</Form>
				</Segment>
			</div>
		);
	}
}
