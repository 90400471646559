import React from 'react';
import { Icon, Loader as RawLoader, Breadcrumb } from 'semantic-ui-react';
import cs from 'classnames';
import _ from 'lodash';
import moment from 'moment';

const Page = (props) => (
	<div className="page-wrap">
		{props.disabled ? null : (
			<div className="layout-header">
				{props.header || (
					<Breadcrumb size="big">
						<Breadcrumb.Section>
							{props.icon && <Icon name={props.icon} />}
							<span>{props.title}</span>
							{props.subtitle && <span className="description">{props.subtitle}</span>}
						</Breadcrumb.Section>
						{props.breads}
					</Breadcrumb>
				)}
				{props &&
				props.data &&
				props.data.product &&
				props.data.product.live &&
				props.data.product.live.settings &&
				props.data.product.live.settings.subscription_package === 'trial-start' ? (
					<div style={{ margin: 'auto', color: 'red' }}>
						<p>
							{'Your trial will over'}{' '}
							{moment(props && props.data && props.data.product && props.data.product.live && props.data.product.live.created_at)
								.add(14, 'days')
								.diff(moment().startOf('day'), 'days') > 0
								? moment(props && props.data && props.data.product && props.data.product.live && props.data.product.live.created_at)
										.add(14, 'days')
										.diff(moment().startOf('day'), 'days') + ' days later.'
								: 'today.'}
						</p>
					</div>
				) : props &&
				  props.data &&
				  props.data.product &&
				  props.data.product.live &&
				  props.data.product.live.settings &&
				  props.data.product.live.settings.subscription_package === null ? (
					<div style={{ margin: 'auto', color: 'red' }}>
						<p>Your trial is over.</p>
					</div>
				) : null}
				{props.extra}
			</div>
		)}

		<div className={cs('layout-body', { 'd-flex': _.has(props, 'flex') })}>{props.children}</div>
	</div>
);

Page.Loader = React.memo(() => (
	<div className="layout-center">
		<RawLoader active inline="centered" />
	</div>
));

Page.Wrap = React.memo((props) => <div className="layout-wrap">{props.children}</div>);

export default Page;
