import React from 'react';
import { Form, Label, Divider, Header, Container, Icon, Checkbox, Dropdown } from 'semantic-ui-react';
import { Field, FieldArray } from 'formik';
import _ from 'lodash';
// eslint-disable-next-line
import styled from 'styled-components/macro';

const Input = styled(Form.Input)`
	margin-bottom: 0 !important;
	input::placeholder {
		color: #333 !important;
	}

	&.error input::placeholder {
		color: #9f3a38 !important;
	}
`;

export const MiniContainer = styled(Container)`
	width: 780px !important;
`;
export const MyDividerCenter = (props) => (
	<Divider horizontal className="my-divider-center">
		<Header as="h4">{props.name}</Header>
	</Divider>
);
export class FormikInput extends React.PureComponent {
	render() {
		const { name, label, className, ...rest } = this.props;
		return (
			<Field
				name={name}
				render={({ field, form }) => {
					let error = form.touched[field.name] && form.errors[field.name];
					return (
						<Form.Field className={className}>
							<label
								css={`
									height: 25px;
									line-height: 25px;
								`}
							>
								{label}
								{error && (
									<Label color="red" size="small" pointing="left">
										{error}
									</Label>
								)}
							</label>
							<Input {...rest} error={!!error} {...field} />
						</Form.Field>
					);
				}}
			/>
		);
	}
}

export class TrimmedFormikInput extends React.PureComponent {
	render() {
		const { name, label, className, ...rest } = this.props;
		return (
			<Field
				name={name}
				label={label}
				render={({ field, form }) => {
					let error = form.touched[field.name] && form.errors[field.name];
					return (
						<Form.Field className={className}>
							<label
								css={`
									height: 25px;
									line-height: 25px;
								`}
							>
								{label}
								{error && (
									<Label color="red" size="small" pointing="left">
										{error}
									</Label>
								)}
							</label>
							<Input {...rest} error={!!error} {...field} onChange={(e, data) => form.setFieldValue(name, data.value.trim())} />
						</Form.Field>
					);
				}}
			/>
		);
	}
}

export const InlineFormikInput = styled(FormikInput)`
	display: flex;
	align-items: center;
	label {
		width: 80px;
		margin-right: 5px;
	}
`;

export class FormikCheckbox extends React.PureComponent {
	render() {
		const { name, ...rest } = this.props;
		return (
			<Field name={name}>
				{({ field, form }) => {
					let error = form.touched[field.name] && form.errors[field.name];
					return (
						<Form.Field>
							<Checkbox {...rest} error={!!error} {...field} onChange={(e, data) => form.setFieldValue(field.name, data.checked)} />

							{error && (
								<Label color="red" size="small" pointing>
									{error}
								</Label>
							)}
						</Form.Field>
					);
				}}
			</Field>
		);
	}
}
export function DropdownInput(props) {
	return (
		<Field name={props.name}>
			{({ field, form }) => (
				<Dropdown
					placeholder={props.placeholder || 'Select'}
					fluid
					selection
					value={field.value}
					onChange={(e, { value }) => {
						form.setFieldValue(props.name, value);
					}}
					options={props.list}
				/>
			)}
		</Field>
	);
}

export const MyDivider = (props) => (
	<Divider
		horizontal
		css={`
			:before {
				width: 3% !important;
			}
			:after {
				width: 100% !important;
			}
			:after,
			:before {
				background-repeat: repeat-x !important;
			}
			.header {
				padding: 0 1rem;
			}
		`}
	>
		<Header as="h4">
			<Icon name={props.icon} />
			{props.name}
		</Header>
	</Divider>
);

class ArrayWrap extends React.Component {
	shouldComponentUpdate(next) {
		let nextList = this.getList(next.helper),
			current = this.getList();
		return !_.isEqual(nextList, current);
	}

	getList(obj = this.props.helper) {
		let name = obj.name.replace('.sub', '');
		return _.get(obj.form.values, name);
	}

	render() {
		let list = this.getList();
		let { _render, helper } = this.props;
		if (helper.name.indexOf('.sub') !== -1) list = list.sub;
		return _render({ list, helper });
	}
}

export class FastFieldArray extends React.PureComponent {
	render() {
		return <FieldArray name={this.props.name} render={(helper) => <ArrayWrap helper={helper} _render={this.props.render} />} />;
	}
}
