export const GA_TRACKING_ID = 'UA-43334566-21';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
	window.gtag &&
		window.gtag('config', GA_TRACKING_ID, {
			page_location: window.location.href,
			page_path: url,
		});
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
	window.gtag &&
		window.gtag('event', action, {
			event_category: category,
			event_label: label,
			value: value,
		});
};
