import React, { Component } from 'react';
import { Form, Segment, Icon, Message, Button, Confirm, Modal } from 'semantic-ui-react';
import { post } from '../../util/api';
import { connect } from 'react-redux';
import { updateProfile } from '../../actions/access.actions';
import { Formik } from 'formik';
import { FormikInput, MiniContainer, DropdownInput } from '../../components/FormikInput';
import * as Yup from 'yup';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Page from '../../components/Page';

function customizer(objValue, srcValue) {
	return _.isUndefined(objValue) || objValue === null ? srcValue : objValue;
}

var defaults = _.partialRight(_.assignWith, customizer);
class UserPage extends Component {
	state = {
		userInfo: { icon: 'save', color: 'blue', saved: 'Save', sending: false },
		userPassword: { icon: 'save', color: 'blue', saved: 'Save', sending: false },
		newpassword: '',
		modal_open: false,
		ex_file: '',
		loading: true,
	};
	componentDidMount() {
		this.getSubs();
	}
	async getSubs() {
		await post('/live/product/getsubscription', { pid: this.props.product.id }).then((data) => {
			let subs = data && data.data && data.data.subscriptions;
			let isActiveSub = _.filter(subs, (it) => it.data && it.data.status && it.data.status === 'active');
			this.setState({ subs, isActiveSub, loading: false });
		});
	}
	handleChange = (e, { name, value }) => this.setState({ [name]: value });
	changePlatform(value) {
		post('/live/product/setPanel', { panel: value }).then((response) => window.location.reload());
	}
	openModal(value) {
		const { product } = this.props;
		let { isActiveSub } = this.state;
		if (isActiveSub && isActiveSub.length) {
			this.setState({ subsModal: true });
			return;
		}
		let platform = product && product.settings && product.settings.panel;
		if (platform !== value) this.setState({ modal_open: true, value });
	}
	handleSubmit = (values) => {
		const { t } = this.props;
		if (this.state.userInfo.sending) return;
		this.setState({ userInfo: { sending: true } });
		this.props.updateUser(values);
		this.setState({
			userInfo: { sending: false, errormessage: '', saved: t('saved'), color: 'teal', icon: 'check' },
		});
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.setState({ userInfo: { icon: 'save', color: 'blue', saved: t('save') } });
		}, 2000);
		this.postData(values);
	};
	handleSubmitPassword = (values) => {
		const { t } = this.props;
		if (this.state.userPassword.sending) return;
		this.setState({ userPassword: { sending: true } });
		this.props.updateUser(values);
		this.postPassword(values);
		if (this.state.passwordError) {
			this.setState({
				userPassword: { sending: false, errormessage: '', saved: t('unSaved'), color: 'red', icon: 'x' },
			});
		} else {
			this.setState({
				userPassword: { sending: false, errormessage: '', saved: t('saved'), color: 'teal', icon: 'check' },
			});
		}

		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.setState({ userPassword: { icon: 'save', color: 'blue', saved: t('save') } });
		}, 2000);
	};
	postData(values) {
		let body = {
			name: values.name,
			phone: values.phone,
			company: values.company,
			email: values.email,
			agent: true,
		};
		if (!values.__isAgent) {
			body.extra = _.merge(values.extra, {
				ecommerce: values.ecommerce,
				domain: values.domain,
				isContacts: values.isContacts,
			});
		}
		post('/auth/updateInfo', body);
	}
	postPassword(values) {
		post('/auth/updatePassword', {
			newpassword: values.newpassword,
		}).then((data) => {
			this.setState({ passwordErrorMessage: data.data.message, passwordError: data.data.success });
		});
	}

	postVerifyMail(values) {
		post('/mailer/verify', { email: values.email }).then((data) => {
			if (data.data.success) {
				this.setState({ verifyMessage: 'New verify link was sent your email.' });
			} else {
				this.setState({ verifyMessage: 'Error...' });
			}
		});
	}

	render() {
		const { t, host, user, product } = this.props;
		let { modal_open, value, subsModal, loading } = this.state;
		let platform = product && product.settings && product.settings.panel;
		const initials = defaults({}, this.props.user, this.props.user.extra, {
			name: '',
			company: '',
			phone: '',
			newpassword: '',
			confirmpassword: '',
			isContacts: false,
		});
		//let { ex_file } = this.state;
		const options = [
			{
				text: t('yes'),
				value: 'yes',
			},
			{
				text: t('no'),
				value: 'no',
			},
		];
		const options_2 = [
			{
				text: t('openTrue'),
				value: true,
			},
			{
				text: t('closeFalse'),
				value: false,
			},
		];
		let roleAgent = user && user.role && user.role === 'agent';
		if (loading) return <Page.Loader></Page.Loader>;
		return (
			<Segment>
				<MiniContainer>
					{!roleAgent && (
						<div style={{ marginBottom: '20px' }}>
							<h3>
								<Icon name="at" />
								{t('platform')}
							</h3>
							<Button.Group>
								<Button color="blue" basic={platform === 'dialogtab' ? false : true} onClick={() => this.openModal('dialogtab')}>
									DialogTab Panel
								</Button>
								<Button color="blue" basic={platform === 'ext' ? false : true} onClick={() => this.openModal('ext')}>
									Chrome Eklenti
								</Button>
							</Button.Group>
						</div>
					)}

					<Confirm
						open={modal_open}
						content={t('areYouSureChangePlatform')}
						onCancel={() => this.setState({ modal_open: false })}
						onConfirm={() => this.changePlatform(value)}
						cancelButton={t('no')}
						confirmButton={t('yes')}
					></Confirm>
					<Modal open={subsModal} closeIcon content={t('youUnsubscribeSubs')} onClose={() => this.setState({ subsModal: false })}></Modal>
					<h3>
						<Icon name="user" />
						{t('personalInformation')}
					</h3>
					{host === 'app.dialogtab.com' && !this.props.user.verified ? (
						<Message error>
							<span>{t('accountNotVerified')}</span>
							<Button size="mini" primary style={{ marginLeft: '10px' }} onClick={() => this.postVerifyMail({ email: this.props.user.email })}>
								{t('sendMail')}
							</Button>
						</Message>
					) : (
						''
					)}
					<Segment>
						<Formik
							initialValues={initials}
							onSubmit={this.handleSubmit}
							validationSchema={Yup.object().shape({
								name: Yup.string().required(t('nameisRequired')),
								//company: Yup.string().required(t('companyisRequired')),
								//phone: Yup.string().matches(/^(\+90|0)?(\(\d{3}\)|\d{3})-?\d{3}-?\d{4}$/, 'Phone number is not valid!'),
							})}
						>
							{(props) => {
								const { values, handleChange, handleBlur, handleSubmit } = props;

								return (
									<Form onSubmit={handleSubmit}>
										<FormikInput
											id="name"
											label={t('name')}
											name="name"
											icon="user"
											iconPosition="left"
											placeholder={t('userPlaceholder', { query: t('name') })}
											type="text"
											value={values.name}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<FormikInput
											id="company"
											label={t('companyName')}
											icon="building"
											name="company"
											placeholder={t('userPlaceholder', { query: t('company') })}
											iconPosition="left"
											type="text"
											value={values.company}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<FormikInput
											id="email"
											label={t('email')}
											name="email"
											icon="at"
											iconPosition="left"
											placeholder={t('userPlaceholder', { query: t('email') })}
											type="text"
											value={values.email}
											onChange={handleChange}
											onBlur={handleBlur}
											readOnly
										/>
										<FormikInput
											id="phone"
											label={t('phone')}
											iconPosition="left"
											name="phone"
											icon="phone"
											placeholder={t('userPlaceholder', { query: t('phone') })}
											type="phone"
											value={values.phone}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										{!values.__isAgent ? (
											<div>
												<FormikInput
													id="domain"
													label="Domain"
													iconPosition="left"
													name="domain"
													icon="at"
													placeholder={t('enterDomain')}
													type="domain"
													value={values.domain}
													onChange={handleChange}
													onBlur={handleBlur}
												></FormikInput>
												<Form.Field
													required
													style={{
														display: 'block',
														margin: '0 0 .28571429rem 0',
														color: 'rgba(0,0,0,.87)',
														fontSize: '.92857143em',
														fontWeight: '700',
														textTransform: 'none',
													}}
												>
													{t('areYouEcommerce')}
												</Form.Field>
												<DropdownInput name="ecommerce" list={options} placeholder={t('select')} />
											</div>
										) : null}
										<Form.Field
											required
											style={{
												display: 'block',
												margin: '0 0 .28571429rem 0',
												color: 'rgba(0,0,0,.87)',
												fontSize: '.92857143em',
												fontWeight: '700',
												textTransform: 'none',
											}}
										>
											{t('contacts')}
										</Form.Field>
										<DropdownInput name="isContacts" list={options_2} placeholder={t('contacts')} />
										<Form.Button
											fluid
											style={{ marginTop: '15px' }}
											icon={this.state.userInfo.icon}
											type="submit"
											color={this.state.userInfo.color}
											content={this.state.userInfo.saved}
											loading={this.state.userInfo.sending}
										/>
									</Form>
								);
							}}
						</Formik>
					</Segment>
					<h3 style={{ marginTop: '5rem' }}>
						<Icon name="lock" />
						{t('password')}
					</h3>
					<Segment>
						<Formik
							initialValues={initials}
							onSubmit={this.handleSubmitPassword}
							validationSchema={Yup.object().shape({
								newpassword: Yup.string()
									.required('Password is required!')
									.matches(
										/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
										'Password must be min 8, at least one uppercase, lowercase and special character.'
									),
								confirmpassword: Yup.string().oneOf([Yup.ref('newpassword'), null], t('passwordValidationMatch')),
							})}
						>
							{(props) => {
								const { handleChange, handleBlur, handleSubmit } = props;
								return (
									<Form onSubmit={handleSubmit}>
										<FormikInput
											label={t('newPassword')}
											icon="key"
											iconPosition="left"
											name="newpassword"
											type="password"
											placeholder={t('enterNewPassword')}
											id="newpassword"
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<FormikInput
											label={t('confirmPassword')}
											icon="key"
											iconPosition="left"
											name="confirmpassword"
											type="password"
											placeholder={t('enterNewPasswordAgain')}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<Form.Button
											fluid
											icon={this.state.userPassword.icon}
											type="submit"
											color={this.state.userPassword.color}
											content={this.state.userPassword.saved}
											loading={this.state.userPassword.sending}
										/>
									</Form>
								);
							}}
						</Formik>
					</Segment>
				</MiniContainer>
			</Segment>
		);
	}
}

export default connect(
	(state) => ({ user: state.access.user.loggedUserObj, product: state.product.live }),
	(dispatch) => ({ updateUser: (user) => dispatch(updateProfile(user)) })
)(withTranslation()(UserPage));
