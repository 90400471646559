import React, { Component } from 'react';
import { Modal, Loader, Dimmer } from 'semantic-ui-react';
import _ from 'lodash';
import { connect } from 'react-redux';
import 'react-intl-tel-input/dist/main.css';
import { post, get } from '../util/api';

class SandBox extends Component {
	state = {
		loading: true,
		buttonLoading: false,
		ecommerce: 'no',
		prev: false,
		logoutopen: false,
		trialSuccess: false,
		domain: (this.props.user && this.props.user.extra && this.props.user.extra.domain) || '',
	};
	componentDidMount() {
		this.active = true;
		let reseller = this.props.user && this.props.user.extra && this.props.user.extra.reseller;
		let product = 'live';
		if (reseller) {
			product = 'reseller';
		}
		get('/live/product/hash').then((data) => {
			if (!this.active) return;
			this.setState({ hash: data.hash, loading: false });
			this.timer = setInterval(() => {
				get(`/${product}/product`).then((response) => {
					if (!this.active) return;
					if (response.product.settings.sandbox) {
						this.props.dispatch({
							type: 'PRODUCT_SUCCEEDED',
							product: response.product,
						});
						clearInterval(this.timer);
					}
				});
			}, 5000);
		});
	}

	componentWillUnmount() {
		this.active = false;
		clearInterval(this.timer);
	}
	handlePrev() {
		this.setState({ prev: true });
	}
	openLogout() {
		this.setState({ logoutopen: true });
	}
	closeLogout() {
		this.setState({ logoutopen: false });
	}
	handleChange = (e, { name, value }) => this.setState({ [name]: value });
	handleSubmit = (values) => {
		this.setState({
			buttonLoading: true,
		});
		let extra = _.merge(this.props.user.extra, {
			domain: this.state.domain,
			ecommerce: this.state.ecommerce,
		});
		post('/auth/updateInfo', {
			id: this.props.user.id,
			extra,
		}).then((data) => {
			if (data.data.success) {
				this.setState({ buttonLoading: false, completed: true, prev: false });
			}
		});
	};
	async createTrial() {
		let host =
			this.props.user.host === null ||
			this.props.user.host === 'app.dialogtab.com' ||
			this.props.user.host === 'localhost' ||
			this.props.user.host === 'test.dialogtab.com';
		let plan = host ? 'start' : 'reseller';
		await post('/live/payment/createFreeTrial', {
			pid: this.props.product.id,
			plan,
		}).then((data) => {
			get('/live/product').then((response) => {
				this.props.dispatch({
					type: 'PRODUCT_SUCCEEDED',
					product: response.product,
				});
			});
		});
	}
	async resellerUp() {
		get('/reseller/product').then((response) => {
			this.props.dispatch({
				type: 'PRODUCT_SUCCEEDED',
				product: response.product,
			});
		});
	}
	render() {
		let { loading, trialSuccess } = this.state;
		if (loading)
			return (
				<Modal open style={{ height: '410px' }}>
					<Modal.Header>Welcome to {window.CONFIG.title}</Modal.Header>
					<Modal.Content>
						<Dimmer active inverted>
							<Loader inverted></Loader>
						</Dimmer>
					</Modal.Content>
				</Modal>
			);
		this.createTrial();
		if (!trialSuccess) {
			return (
				<Modal open style={{ height: '410px' }} onClose={() => this.openLogout()}>
					<Modal.Header>Creating your trial package..</Modal.Header>
					<Modal.Content>
						<Dimmer active inverted>
							<Loader inverted></Loader>
						</Dimmer>
					</Modal.Content>
				</Modal>
			);
		}

		return (
			<Modal open style={{ height: '410px' }}>
				<Modal.Header>Welcome to {window.CONFIG.title}</Modal.Header>
				<Modal.Content>
					<Dimmer active inverted>
						<Loader inverted></Loader>
					</Dimmer>
				</Modal.Content>
			</Modal>
		);
	}
}
export default connect((state) => ({ user: state.access.user.loggedUserObj, product: state.product.live }))(SandBox);
