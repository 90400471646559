import React, { Component } from 'react';
import { Icon, Popup, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { get, toWapi } from '../util/api';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
class AccountProgress extends Component {
	state = {
		totalPhones: 0,
		loading: true,
		phones: [],
	};

	async componentDidMount() {
		let product = this.props.product.live || this.props.product.reseller;
		let pid = product.id;
		let wapiPid = product && product.settings && product.settings.wapi_pid;
		let wapi_token = product && product.settings && product.settings.wapi_token;
		if (this.props.user && this.props.user.extra && !this.props.user.extra.reseller) {
			await get('/dahi/message/getLiveChat').then((data) => this.setState({ livechat: data.result }));
			if (wapi_token) {
				await toWapi(`/api/${wapiPid || pid}/listPhones`, product.settings.wapi_token).then((data) => {
					this.setState({ phones: data.data });
				});
			}
			await get('/live/agent/list').then((data) => this.setState({ agents: data.agents && !!data.agents.length, loading: false }));
		}
	}
	render() {
		const { t } = this.props;
		let { agents, loading, livechat, phones } = this.state;
		let subscription =
			this.props.product && this.props.product.live && this.props.product.live.settings && this.props.product.live.settings.subscription_package;
		let user = this.props.user;
		if (user && user.extra && user.extra.reseller) {
			return <div style={{ marginLeft: 'auto' }}></div>;
		}
		if (loading) {
			return (
				<div style={{ marginLeft: 'auto' }}>
					<Loader active inline />
				</div>
			);
		}
		let goals = [
			{
				name: t('addPhone'),
				completed: phones && phones.length && phones.length > 0,
				url: '/settings/integrations/whatsapp-web-api',
			},
			/* {
				name: t('connectToWhatsappWeb'),
				completed: _.filter(phones, { status: 'active' }).length > 0,
				url: '/settings/integrations/whatsapp-web-api',
			}, */
			{
				name: t('addAgent'),
				completed: agents && agents.length && agents.length > 0,
				url: '/settings/agents',
			},
			{
				name: t('verifyMail'),
				completed: this.props.user.verified,
				url: '/account',
			},
			{
				name: t('changeChatName'),
				completed: livechat && livechat.name && livechat.name !== 'Live Chat',
				url: '/settings/integrations/website-chat',
			},
			{
				name: t('subscriptionPackage'),
				completed: subscription && subscription.length && subscription.length > 0,
				url: '/settings/subscription',
			},

			{
				name: t('completeAccount'),
				completed: !!(this.props.user.name && this.props.user.company && this.props.user.phone),
				url: '/account',
			},
			{
				name: t('addCompanyInformation'),
				completed: this.props.user.extra && !!this.props.user.extra.company,
				url: '/account/company',
			},
		];

		let percent = ((_.filter(goals, { completed: true }).length * 100) / goals.length).toFixed(0);
		return (
			<>
				<div style={{ marginLeft: 'auto' }}>
					<Popup
						trigger={
							<div style={{ width: '50px', margin: 'auto 15px auto auto' }}>
								<h1 style={{ color: '#499DF3' }}>%{percent}</h1>
							</div>
						}
						position="top right"
						flowing
						hoverable
					>
						{_.map(goals, (it, i) => (
							<div key={i}>
								{it.completed ? (
									<p style={{ marginBottom: 0 }}>
										<Icon name={it.completed ? 'check' : 'close'} color={it.completed ? 'green' : 'red'}></Icon>
										{it.name}
										{!it.completed && it.sub ? ' (Enter your ' + _.map(it.sub, (it, i) => (!it ? i : '')).join(' ') + ')' : ''}
									</p>
								) : (
									<Link to={it.url} style={{ marginBottom: 0 }}>
										<Icon name={it.completed ? 'check' : 'close'} color={it.completed ? 'green' : 'red'}></Icon>
										{it.name}
										{!it.completed && it.sub
											? ' (Enter your ' +
											  _.map(it.sub, (it, i) => (!it ? i : ''))
													.join(', ')
													.replace(', ,', '') +
											  ')'
											: ''}
									</Link>
								)}
							</div>
						))}
					</Popup>
				</div>
			</>
		);
	}
}

const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product, user: state.access.user.loggedUserObj });
export default connect(stateToProps)(withTranslation()(AccountProgress));
