import React, { Component } from 'react';
import { Form, Button, Modal, Confirm, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import { connect } from 'react-redux';
import 'react-intl-tel-input/dist/main.css';
import { logout } from '../actions/access.actions';
import { post, get } from '../util/api';
import { withTranslation } from 'react-i18next';
import EcommerceTypes from '../pages/ecommerce_types.json';
class PlatformModal extends Component {
	state = {
		selectedPlatform: '',
		activePage: 1,
		btnLoading: false,
		domain: 'https://',
	};
	async createTrialExt() {
		await post('/live/payment/createFreeTrial', { pid: this.props.product.id, plan: 'label' })
			.then(async (data) => {
				this.setState({ lastModal: true });
				await post('/ext/apps/save/install', { selected_tier: 'label', id: 5, payment_status: 'trial' });
				await post('/ext/apps/save/install', { selected_tier: 'ecommerce', id: 7, payment_status: 'trial' });
			})
			.then(async () => {
				setTimeout(
					await post(`/ext/apps/build`, {}).then((response) => {
						const win = window.open('/appstore/install', '_self');
						win.focus();
					}),
					3000
				);
			});
	}
	async createTrialWeb() {
		await post('/live/payment/createFreeTrial', { pid: this.props.product.id, plan: 'start' }).then((data) => {
			get('/live/product').then((response) => {
				this.props.dispatch({
					type: 'PRODUCT_SUCCEEDED',
					product: response.product,
				});
			});
		});
	}
	async postData(values) {
		let { selectedPlatform, phone, domain, ecommerce, ecommerce_type } = this.state;
		this.setState({ btnLoading: true });
		if (values.extra) {
			values.phone = phone;
			values.extra = _.merge(values.extra, { domain, ecommerce: ecommerce ? 'yes' : 'no' });
		} else {
			values.extra = {};
			values.phone = phone;
			values.extra = { phone, domain, ecommerce: ecommerce ? 'yes' : 'no' };
		}
		if (ecommerce) values.extra.ecommerce_type = ecommerce_type;
		await post('/auth/updateInfo', values);
		await post('/live/product/setPanel', { panel: selectedPlatform }).then(() =>
			selectedPlatform === 'ext' ? this.createTrialExt() : this.createTrialWeb()
		);
	}
	openLogout() {
		this.setState({ logoutopen: true });
	}
	closeLogout() {
		this.setState({ logoutopen: false });
	}
	render() {
		let { t, user } = this.props;
		let platforms = [
			{
				text: 'DialogTab Panel',
				value: 'dialogtab',
			},
			{
				text: 'Chrome/WhatsApp Extension',
				value: 'ext',
			},
		];
		let { selectedPlatform, domain, ecommerce, activePage, ecommerce_type, lastModal } = this.state;
		let selectedPlatformText = selectedPlatform && _.find(platforms, { value: selectedPlatform }).text;
		return (
			<div>
				<Confirm
					open={this.state.logoutopen}
					content={t('doYouLogout')}
					cancelButton={t('no')}
					confirmButton={t('yes')}
					onCancel={() => this.closeLogout()}
					onConfirm={() => this.props.dispatch(logout())}
				></Confirm>
				<Modal open closeIcon onClose={() => this.openLogout()} size="large">
					<Modal.Header>{activePage === 1 ? "DialogTab'ın hangi ürününü kullanmak istiyorsunuz?" : selectedPlatformText}</Modal.Header>
					<Modal.Content>
						{activePage === 1 ? (
							<div>
								<div className="platforms">
									<div
										className="platform-web"
										onClick={() => {
											this.setState({ activePage: 2, selectedPlatform: 'ext' });
										}}
									>
										<img src="/images/extension_promo.png" alt=""></img>
										<div className="title">Chrome/WhatsApp Extension</div>
										<p>WhatsApp Çoklu cihaz desteği ile beraber kullanılabilen Chrome eklentisi</p>
										<ul className="description">
											<li>
												<b>WhatsApp ekranlarını kullanın</b>
											</li>
											<li>
												<b>Çoklu Agent Sistemi:</b> Konuşma dağıtım sistemiyle aynı anda 4 kişiyle WhatsApp ’ınızı yönetin. Böylece
												çoklu kişi kullanımdan doğan karışıklığın önüne geçin.
											</li>
											<li>
												<b>Eticaret Özelliği:</b> İsterseniz eticaret özelliğiyle birlikte ürün paylaşımı yapıp, hızlı sipariş almaya
												başlayın. (Bu özellik ilave aylık 29$)
											</li>
											<li>
												<b>Gizlilik:</b> Mesajlarınız ve verileriniz sizde kalsın. DialogTab hiçbir şekilde bu verileri tutmaz.
											</li>
											<li>Küçük ve Yeni başlayan firmalar için ideal</li>
										</ul>
										<Button className="select-btn">{'WhatsApp Extension ' + t('select')}</Button>
									</div>
									<div class="platform-vl"></div>
									<div
										className="platform-extension"
										onClick={() => {
											this.setState({ activePage: 2, selectedPlatform: 'dialogtab' });
										}}
									>
										<img src="/images/dialogtab_promo.png" alt=""></img>
										<div className="title">DialogTab Panel</div>
										<p>Çoklu Kanalı çoklu agentla yönetmenizi sağlayan DialogTab paneli </p>
										<ul className="description">
											<li>
												<b>Çoklu Kanal Sistemi:</b> Tüm kanalları (WhatsApp, Instagram, Messenger vs) DialogTab ekranları üzerinden tek
												bir noktadan yönetin.
											</li>
											<li>
												<b>Çoklu Agent Sistemi:</b> İstediğiniz kadar kişiyle tüm kanallarınızı yönetin.
											</li>
											<li>
												<b>Eticaret Özelliği:</b> Panel üzerinden ürün paylaşımı ve hızlı sipariş almaya başlayın.
											</li>
											<li>
												<b>Gelişmiş Raporlama:</b> Her şey kontrolünüz altında olsun.
											</li>
											<li>Orta ölçekli ve Enterprise firmalar için ideal </li>
										</ul>
										<Button className="select-btn">{'DialogTab Panel ' + t('select')}</Button>
									</div>
								</div>
								<p style={{ textAlign: 'center', marginTop: '5px', fontSize: '16px' }}>
									Daha sonradan hesap ayarlarından istediğiniz zaman değiştirebilirsiniz.
								</p>
							</div>
						) : (
							<Form onSubmit={() => this.postData(user)}>
								<Button basic icon labelPosition="left" style={{ marginBottom: '10px' }} onClick={() => this.setState({ activePage: 1 })}>
									<Icon name="arrow left"></Icon>
									{t('back')}
								</Button>
								{/* <Form.Input
									label={t('phone')}
									value={phone}
									type="number"
									placeholder={t('companyPagePlaceholder', { query: t('phone') })}
									onChange={(e, { value }) => this.setState({ phone: value })}
								></Form.Input> */}
								<Form.Input
									name="domain"
									label={t('domain')}
									value={domain}
									placeholder={t('companyPagePlaceholder', { query: t('domain') })}
									onChange={(e, { value }) => this.setState({ domain: value })}
									required
								></Form.Input>

								<Form.Field>
									<label>{t('ecommerce')}</label>
									<Form.Checkbox
										label={t('areEcommerce')}
										checked={ecommerce}
										onChange={(e, { value }) => this.setState({ ecommerce: !ecommerce })}
									></Form.Checkbox>
								</Form.Field>
								{ecommerce ? (
									<Form.Field>
										<label>{t('type')}</label>
										<Form.Dropdown
											value={ecommerce_type}
											name="ecommerce_type"
											placeholder={t('selectEcommerce')}
											options={EcommerceTypes}
											selection
											onChange={(e, { value }) => this.setState({ ecommerce_type: value })}
										></Form.Dropdown>
									</Form.Field>
								) : null}
								<p>{t('platformTrialMessage', { day: selectedPlatform === 'ext' ? 3 : 14 })}</p>
								<Form.Button basic positive loading={this.state.btnLoading}>
									{t('startTrial3', { day: selectedPlatform === 'ext' ? 3 : 14 })}
								</Form.Button>
								{lastModal && <p>{t('startYourTrial')}</p>}
							</Form>
						)}
					</Modal.Content>
				</Modal>
			</div>
		);
	}
}
const stateToProps = (state) => ({ user: state.access.user.loggedUserObj, product: state.product.live });

export default connect(stateToProps)(withTranslation()(PlatformModal));
