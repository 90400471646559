import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import access from './access.reducers';
import product from './product.reducers';
export default history =>
	combineReducers({
		router: connectRouter(history),
		access,
		product,
	});
