import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
// eslint-disable-next-line
import styled from 'styled-components/macro';

class MenuExampleTabularOnLeft extends Component {
	state = { activeItem: 'bio' };

	handleItemClick = (e, { name }) => this.setState({ activeItem: name });

	render() {
		const { activeItem, t } = this.props;
		return (
			<div
				css={`
					display: flex;
					flex: 1;
					.left-menu {
						flex: 0 0 auto;
						.ui.vertical.menu {
							border-radius: 0 !important;
							height: 100% !important;
							border-top: 0 !important;
							border-bottom: 0 !important;
						}
					}
					.right-menu {
						flex: 1;
						overflow: auto;
					}
				`}
			>
				<div className="left-menu">
					<Menu vertical style={{ minHeight: '100%!important', borderLeft: 'none' }}>
						<Menu.Item style={{ paddingBottom: '0', paddingTop: '0' }}>
							<Menu.Header style={{ fontSize: '18px', margin: '0', paddingTop: '10px', paddingBottom: '4px' }}>Menu</Menu.Header>
						</Menu.Item>
						<Menu.Menu>
							<Menu.Item name="dashboard" active={activeItem === 'dashboard'} style={{ fontSize: '16px' }} as={Link} to="/reseller">
								{t('Dashboard')}
							</Menu.Item>
							<Menu.Item name="products" active={activeItem === 'products'} style={{ fontSize: '16px' }} as={Link} to="/reseller/products">
								{t('products')}
							</Menu.Item>
							<Menu.Item name="config" active={activeItem === 'config'} style={{ fontSize: '16px' }} as={Link} to="/reseller/configs">
								{t('account')}
							</Menu.Item>
						</Menu.Menu>
					</Menu>
				</div>
				<div className="right-menu">{this.props.children}</div>
			</div>
		);
	}
}
export default withTranslation()(MenuExampleTabularOnLeft);
