import React, { Component } from 'react';
import { Button, Icon, Header, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import 'react-intl-tel-input/dist/main.css';
import { withTranslation } from 'react-i18next';
import Page from '../../../components/Page';
import { Link } from 'react-router-dom';
class PlatformModal extends Component {
	state = {
		selectedPlatform: '',
		activePage: 1,
		btnLoading: false,
	};

	render() {
		let { t } = this.props;

		return (
			<Page.Wrap>
				<Segment>
					<div>
						<Button basic color="grey" labelPosition="left" icon size="small" as={Link} to="/appstore/help">
							<Icon name="arrow left"></Icon>
							{t('back')}
						</Button>
						<Header>{t('ecommerce')} </Header>
					</div>
					<div>
						<p>
							Eticaret sitenizin servislerini kullanabilmemiz için altyapınızın web servis bilgilerini <Link to="/ecommerce">Eticaret</Link>{' '}
							sayfasına girmeniz gerekmektedir.
						</p>
						<p>
							Eticaret sayfasında altyapınızı seçtikten sonra gerekli bilgiler için form açılacaktır, buraya gelecek olan bilgilere nasıl
							ulaşabileceğinize sayfada görebilir yada altyapınızla iletişime geçebilirsiniz.{' '}
						</p>
					</div>
				</Segment>
			</Page.Wrap>
		);
	}
}
const stateToProps = (state) => ({ user: state.access.user.loggedUserObj, product: state.product.live });

export default connect(stateToProps)(withTranslation()(PlatformModal));
