import React, { useState } from 'react';
import Page from '../../../components/Page';
import { Icon, Breadcrumb, Table, Tab, Button, Modal, Header, Message, Image } from 'semantic-ui-react';
import { Link, Prompt, Route, matchPath, NavLink } from 'react-router-dom';
import useFetch from 'use-http';
import { Formik, Field } from 'formik';
import _ from 'lodash';
import { TableLine, BooleanInput, DropdownInput, TextInputField } from '../inputs';
import { useTranslation } from 'react-i18next';
import ecommerce_list from '../../ecommerce_types.json';
const fetch_config = { responseType: 'json', cachePolicy: 'no-cache' };

function SubConfigs2() {
	const { t } = useTranslation();
	return (
		<Field name="active">
			{({ field }) => (
				<Table definition>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell width={2} />
							<Table.HeaderCell>{t('value')}</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						<TableLine title={t('productSearch')} description={t('productSearchDesc')}>
							<BooleanInput name="_search" />
						</TableLine>
						<TableLine title={t('productDetails')} description={t('productDetailsDesc')}>
							<BooleanInput name="_detail" />
						</TableLine>
					</Table.Body>
				</Table>
			)}
		</Field>
	);
}
function SubConfigs() {
	const { t } = useTranslation();

	return (
		<Field name="active">
			{({ field, form }) => {
				let ecommerce_type = form.values.channel;
				let selected_ecommerce = _.find(ecommerce_list, { value: ecommerce_type });
				return selected_ecommerce && selected_ecommerce.value !== 'select' ? (
					<div>
						<Table definition>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell width={2} />
									<Table.HeaderCell>{t('value')}</Table.HeaderCell>
								</Table.Row>
							</Table.Header>

							<Table.Body>
								{_.map(selected_ecommerce.options, (it) => (
									<TableLine title={t(it.text)}>
										<TextInputField name={it.name} placeholder={t(it.placeholder)} />
									</TableLine>
								))}
							</Table.Body>
						</Table>
						<SubConfigs2 />
						<ul className="login-steps">
							{_.map(selected_ecommerce.steps, (o) => (
								<li>
									{o.text}
									{o.image ? <Image src={o.image}></Image> : null}
								</li>
							))}
						</ul>
					</div>
				) : null;
			}}
		</Field>
	);
}

function GeneralConfigs(props) {
	const { t } = useTranslation();
	return (
		<Tab.Pane>
			<Table definition>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width={2} />
						<Table.HeaderCell>{t('value')}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					<TableLine title={t('ecommerce')} description={t('selectYourEcommerce')}>
						<DropdownInput name="channel" list={ecommerce_list} />
					</TableLine>
				</Table.Body>
			</Table>
			<SubConfigs />
		</Tab.Pane>
	);
}

function Configs() {
	const { t } = useTranslation();
	const panes = [
		{
			menuItem: {
				as: NavLink,
				content: t('general'),
				to: '/ecommerce',
				exact: true,
				key: 'ticimax',
			},
			render: () => <Route path="/ecommerce" exact component={GeneralConfigs} />,
		},
	];
	// Required to get a correct tab opened by default
	const defaultActiveIndex = panes.findIndex((pane) => {
		return !!matchPath(window.location.pathname, {
			path: pane.menuItem.to,
			exact: true,
		});
	});
	return (
		<Page.Wrap>
			<Tab menu={{ secondary: true, pointing: true }} defaultActiveIndex={defaultActiveIndex} panes={panes} />
		</Page.Wrap>
	);
}

export default function ClientSettings() {
	const { t } = useTranslation();
	const { loading, response } = useFetch('/api/dahi/channel/get/ecommerce', fetch_config, []);
	const result = response && response.data;
	const [isShowing, setIsShowing] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [message, setMessage] = useState('');
	const [messageType, setMessageType] = useState('');
	const [showMessage, setShowMessage] = useState(true);
	const initialValues = React.useMemo(() => {
		if (!result) return;
		const { config } = (result && result.data) || {};
		//const agent = _.pick(_.assignIn(agentOptions, staticOptions), ['emoji']);
		return _.merge({ host: '', username: '', password: '' }, config);
	}, [result]);
	if (loading)
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/ecommerce`}>
							<Icon name="setting" />
							{t('ecommerce')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />
						<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
					</Breadcrumb>
				}
			>
				<Page.Loader />
			</Page>
		);

	function openModal() {
		setIsShowing(true);
	}
	function closeModal() {
		setIsShowing(false);
	}
	function removeMaytapi() {
		setDeleting(true);
		fetch('/api/dahi/channel/save', {
			method: 'POST',
			body: JSON.stringify({
				channel: 'ticimax',
				data: { remove: true },
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.success) {
					setShowMessage(false);
					setIsShowing(false);
					setMessageType('positive');
					setMessage('E-commerce account deleted.');
					setDeleting(false);
					window.location.reload();
				} else {
					setShowMessage(false);
					setIsShowing(false);
					setMessageType('negative');
					setMessage('Error');
					setDeleting(false);
				}
			});
	}
	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values, actions) => {
				if (values.client) values.client.name = values.name;
				fetch('/api/dahi/channel/save', {
					method: 'POST',
					body: JSON.stringify({
						channel: values.channel,
						data: values,
					}),
					headers: {
						'Content-Type': 'application/json',
					},
				})
					.then((response) => {
						return response.json();
					})
					.then((json) => {
						if (json.success) {
							setShowMessage(false);
							setMessageType('positive');
							setMessage('Ecommerce account connected.');
							values.active = true;
							actions.resetForm(values);
						} else {
							setShowMessage(false);
							setMessageType('negative');
							setMessage('Error');
						}
						actions.setSubmitting(false);
					})
					.catch((e) => {
						console.error(e);
						setShowMessage(false);
						setMessageType('negative');
						setMessage('Error');
						actions.setSubmitting(false);
					});
			}}
		>
			{(props) => {
				return (
					<Page
						header={
							<Breadcrumb size="big">
								<Breadcrumb.Section as={Link} to={`/ecommerce`}>
									<Icon name="setting" />
									{t('ecommerce')}
								</Breadcrumb.Section>
							</Breadcrumb>
						}
						extra={
							<div className="ml-auto d-flex">
								<Button disabled={!props.values.active} style={{ marginLeft: '10px' }} negative type="button" onClick={() => openModal()}>
									{t('remove')}
								</Button>
								<Button className="ml-auto" loading={props.isSubmitting} primary type="submit" onClick={props.submitForm}>
									{t('saveChanges')}
								</Button>
							</div>
						}
					>
						<form onSubmit={props.handleSubmit} autoComplete="off">
							<Prompt
								when={props.dirty}
								message={(location) =>
									location.pathname.startsWith('/settings/integrations/website-chat') ? true : t('questionWithoutSaving')
								}
							/>

							<Configs />
							<div style={{ textAlign: 'center' }}>
								<Message compact hidden={showMessage} positive={messageType === 'positive'} negative={messageType === 'negative'}>
									{message}
								</Message>
							</div>
						</form>
						{/*delete modal*/}
						<Modal open={isShowing} onClose={() => closeModal()} style={{ width: '500px' }}>
							<Header icon="trash" content={t('removeTsoftConnection', { title: 'Ticimax' })} />
							<Modal.Content>
								<p>{t('tsoftRemove', { title: 'Ticimax' })}</p>
							</Modal.Content>
							<Modal.Actions>
								<Button color="red" onClick={() => closeModal()}>
									<Icon name="remove" /> {t('no')}
								</Button>
								<Button color="green" loading={deleting} onClick={() => removeMaytapi(props.values)}>
									<Icon name="checkmark" /> {t('yes')}
								</Button>
							</Modal.Actions>
						</Modal>
					</Page>
				);
			}}
		</Formik>
	);
}
