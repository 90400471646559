import React, { Component } from 'react';
import Page from '../../components/Page';
import { connect } from 'react-redux';
import { get, post } from '../../util/api';
import _ from 'lodash';
import MyMenu from './menu';
import { Link } from 'react-router-dom';
import AccountProgress from '../../components/AccountProgress';
import { withTranslation } from 'react-i18next';
import { Card, Segment, Table, Button, List, Modal, Header, Form, Icon, Breadcrumb } from 'semantic-ui-react';

const s_packages = [
	{
		title: 'Basic',
		price: 49,
		agent_number: 2,
		phone_number: 1,
		tier: 'basic',
		subscription_plan_id: 'shopify_basic',
		extra: ['1 Web API', '2 Agents', 'further agent 19$'],
		extra_agent: 19,
		extra_phone: 39,
	},
	{
		title: 'Pro',
		price: 99,
		agent_number: 2,
		phone_number: 1,
		tier: 'pro',
		extra: ['1 Web API', '2 Agents', 'further agent 39$', 'Ecommerce Options'],
		subscription_plan_id: 'shopify_pro',
		extra_agent: 39,
		extra_phone: 39,
	},
	{
		title: 'Scale',
		price: 199,
		agent_number: 3,
		phone_number: 1,
		tier: 'scale',
		subscription_plan_id: 'shopify_scale',
		extra: ['1 Business API', '3 Agents', 'further agent 39$', 'Ecommerce Options'],
		extra_agent: 39,
		extra_phone: 0,
	},
];

class ShopifySubscription extends Component {
	state = {
		loading: true,
		extra_agent: 0,
		extra_phone: 0,
		openModal: false,
		selectedSub: {},
		createLoading: false,
	};
	async componentDidMount() {
		let data = await post('/live/product/getsubscription', { pid: this.props.product.id });
		this.setState({ subscriptions: data.data.subscriptions, loading: false });
	}
	createSub(tier) {
		this.setState({ createLoading: true });
		let { extra_agent, extra_phone } = this.state;
		post(`/auth/shopify/payment/create/${tier}`, { extra_agent, extra_phone }).then((data) => {
			if (data && data.data && data.data.success) {
				window.location.href = data.data.url;
			}
		});
	}
	deleteSub(tier) {
		this.setState({ deleteLoading: true });
		get(`/auth/shopify/payment/delete/${tier}`).then((data) => {
			if (data && data.success) {
				window.location.reload();
			}
		});
	}
	closeModal = () => {
		this.setState({
			openModal: false,
			extra_agent: 0,
			extra_phone: 0,
			openCancelModal: false,
			openUpdateModal: false,
			modalForm: false,
			createLoading: false,
		});
	};
	handleFormChange = (e, { name, value }) => this.setState({ [name]: value });
	render() {
		let { t } = this.props;
		let {
			subscriptions,
			loading,
			openModal,
			selectedTier,
			extra_phone,
			extra_agent,
			selectedSub,
			openCancelModal,
			deletingSubId,

			deleteLoading,
			openUpdateModal,
			modalForm,
		} = this.state;
		if (loading)
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
					flex
				>
					<MyMenu activeItem="package">
						<Page.Loader />
					</MyMenu>
				</Page>
			);
		let activeSubscriptions = _.filter(subscriptions, function (o) {
			return o.data.status === 'active' && o.shop;
		});
		return (
			<Page
				header={
					<>
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Section as={Link} to={`/settings/subscription`}>
								{t('subscription')}
							</Breadcrumb.Section>
						</Breadcrumb>
						<AccountProgress></AccountProgress>
					</>
				}
				flex
			>
				<MyMenu activeItem="package">
					<Page.Wrap>
						{/*satın al modal*/}
						<Modal open={openModal} onClose={this.closeModal} closeIcon>
							<Header icon="shop" content={'Shopify Subscription'} />
							<Modal.Content>
								<Form>
									<Form.Input label="Extra Agent" type="number" name="extra_agent" value={extra_agent} onChange={this.handleFormChange} />
									{/* {selectedSub.extra_phone > 0 && (
									<Form.Input label="Extra Phone" type="number" name="extra_phone" value={extra_phone} onChange={this.handleFormChange} />
								)} */}
								</Form>
								<Header>
									Total Price is {selectedSub.price + extra_agent * selectedSub.extra_agent + extra_phone * selectedSub.extra_phone}
								</Header>
							</Modal.Content>
							<Modal.Actions>
								<Button color="teal" onClick={() => this.createSub(selectedTier, extra_agent, extra_phone)} fluid style={{ margin: 'auto' }}>
									{t('purchase')}
								</Button>
							</Modal.Actions>
						</Modal>
						{/*CANCEL subs  */}
						<Modal open={openCancelModal} onClose={this.closeModal} style={{ maxWidth: '500px' }} closeIcon>
							<Header icon="trash" content={t('unsubscribe')} />
							<Modal.Content>
								<p>{t('areYouWantsUnsubscribe')}</p>
							</Modal.Content>
							<Modal.Actions>
								<Button color="red" onClick={this.closeModal}>
									<Icon name="remove" /> {t('no')}
								</Button>
								<Button color="green" loading={deleteLoading} onClick={() => this.deleteSub(deletingSubId)}>
									<Icon name="checkmark" /> {t('yes')}
								</Button>
							</Modal.Actions>
						</Modal>
						{/*uptade subs*/}
						<Modal open={openUpdateModal} onClose={this.closeModal} closeIcon>
							<Header icon="shop" content={t('update')} />
							<Modal.Content>
								<Card.Group>
									{_.map(s_packages, (it) => (
										<Card>
											<Card.Content
												style={{ textAlign: 'center', maxHeight: '120px', padding: '23px' }}
												header={it.title + ' ' + t('package')}
											/>
											<Card.Content
												description={
													<div style={{ maxHeight: '200px' }}>
														<div style={{ textAlign: 'center' }}>
															<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																${it.price}
																<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
															</span>
														</div>
													</div>
												}
											/>
											<Card.Content style={{ height: '120px' }}>
												<List>
													{_.map(it.extra, (o) => (
														<List.Item>{o}</List.Item>
													))}
												</List>
											</Card.Content>
											<Card.Content style={{ maxHeight: '80px' }}>
												<Button
													color="teal"
													onClick={() => this.setState({ modalForm: true, selectedTier: it.tier, selectedSub: it })}
													fluid
												>
													{t('select')}
												</Button>
											</Card.Content>
										</Card>
									))}
								</Card.Group>
							</Modal.Content>
							{modalForm ? (
								<Modal.Content>
									<Form>
										<Form.Input label="Extra Agent" type="number" name="extra_agent" value={extra_agent} onChange={this.handleFormChange} />
										{/* {selectedSub.extra_phone > 0 && (
										<Form.Input label="Extra Phone" type="number" name="extra_phone" value={extra_phone} onChange={this.handleFormChange} />
									)} */}
									</Form>
									<Header>
										Total Price is {selectedSub.price + extra_agent * selectedSub.extra_agent + extra_phone * selectedSub.extra_phone}
									</Header>
								</Modal.Content>
							) : (
								''
							)}
							{modalForm ? (
								<Modal.Actions>
									<Button
										color="teal"
										onClick={() => this.createSub(selectedTier, extra_agent, extra_phone)}
										fluid
										style={{ margin: 'auto' }}
									>
										{t('purchase')}
									</Button>
								</Modal.Actions>
							) : (
								''
							)}
						</Modal>
						{!activeSubscriptions.length ? (
							<Segment>
								<Card.Group>
									{_.map(s_packages, (it) => (
										<Card>
											<Card.Content
												style={{ textAlign: 'center', maxHeight: '120px', padding: '23px' }}
												header={it.title + ' ' + t('package')}
											/>
											<Card.Content
												description={
													<div style={{ maxHeight: '200px' }}>
														<div style={{ textAlign: 'center' }}>
															<span style={{ fontSize: '72px', lineHeight: '72px' }}>
																${it.price}
																<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
															</span>
														</div>
													</div>
												}
											/>
											<Card.Content style={{ height: '120px' }}>
												<List>
													{_.map(it.extra, (o) => (
														<List.Item>{o}</List.Item>
													))}
												</List>
											</Card.Content>
											<Card.Content style={{ maxHeight: '80px' }}>
												<Button
													color="teal"
													onClick={() => this.setState({ openModal: true, selectedTier: it.tier, selectedSub: it })}
													fluid
												>
													{t('purchase')}
												</Button>
											</Card.Content>
										</Card>
									))}
								</Card.Group>
							</Segment>
						) : (
							<Segment>
								<Table>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>{t('subscription')}</Table.HeaderCell>
											<Table.HeaderCell>{t('agentNumbers')}</Table.HeaderCell>
											<Table.HeaderCell>{t('phoneNumbers')}</Table.HeaderCell>
											<Table.HeaderCell>{t('enddate')}</Table.HeaderCell>
											<Table.HeaderCell></Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{_.map(activeSubscriptions, (data, i) => {
											return (
												<Table.Row key={i}>
													<Table.Cell>{data.data.name}</Table.Cell>
													<Table.Cell>{data.data.quantity}</Table.Cell>
													<Table.Cell>{data.data.phone_quantity}</Table.Cell>
													<Table.Cell>{data.data.billing_on}</Table.Cell>
													<Table.Cell>
														<Button.Group>
															<Button positive size="mini" onClick={() => this.setState({ openUpdateModal: true })}>
																{t('update')}
															</Button>
															<Button
																negative
																size="mini"
																onClick={() => this.setState({ deletingSubId: data.data.id, openCancelModal: true })}
															>
																{t('cancel')}
															</Button>
														</Button.Group>
													</Table.Cell>
												</Table.Row>
											);
										})}
									</Table.Body>
								</Table>
							</Segment>
						)}
					</Page.Wrap>
				</MyMenu>
			</Page>
		);
	}
}

const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product.live, user: state.access.user.loggedUserObj });
export default connect(stateToProps)(withTranslation()(ShopifySubscription));
