import React, { Component } from 'react';
import { post } from '../../util/api';
import { Segment, Table, Tab } from 'semantic-ui-react';
import _ from 'lodash';

const TableLine = (data) =>
	_.map(data, (val, index) => (
		<Table.Row key={val && val.owner_id}>
			<Table.Cell>{index + 1}</Table.Cell>
			<Table.Cell>{val && val.owner_id}</Table.Cell>
			<Table.Cell>{val && val.owner_name}</Table.Cell>
			<Table.Cell>{val && val.owner_email}</Table.Cell>
			<Table.Cell>{val && val.verified ? 'true' : 'false'}</Table.Cell>
			<Table.Cell>{val && val.package}</Table.Cell>
			<Table.Cell>{val && val.agent_limit}</Table.Cell>
			<Table.Cell>{val && val.phone_limit}</Table.Cell>
			<Table.Cell>{val && val.instagram_limit}</Table.Cell>
			<Table.Cell>
				{_.map(val && val.agents, (it) => (
					<p>{it.agent_email}</p>
				))}
			</Table.Cell>
			<Table.Cell>
				{val && val.phones && val.phones.length ? (
					<Table>
						{_.map(val && val.phones, (it) => (
							<Table.Row>
								<Table.Cell>{it.id}</Table.Cell>
								<Table.Cell style={{ textAlign: 'center' }}>{it.number || '-----'}</Table.Cell>
								<Table.Cell>{it.status}</Table.Cell>
								<Table.Cell style={{ textAlign: 'center' }}>
									<a
										href={'https://wapi.yapaytech.com/api/' + (val.wapi_pid || val.pid) + '/' + it.id + '/screen?token=' + val.wapi_token}
										target="_blank"
										style={{ margin: 'auto' }}
										rel="noopener noreferrer"
									>
										{'screen '}
									</a>
									<a
										href={'https://wapi.yapaytech.com/api/' + (val.wapi_pid || val.pid) + '/' + it.id + '/redeploy?token=' + val.wapi_token}
										target="_blank"
										style={{ margin: 'auto' }}
										rel="noopener noreferrer"
									>
										{'redeploy '}
									</a>
									{/* <a
										href={
											'https://wapi.yapaytech.com/api/' +
											(val.wapi_pid || val.pid) +
											'/' +
											it.id +
											'/factoryreset?token=' +
											val.wapi_token
										}
										target="_blank"
										style={{ margin: 'auto' }}
										rel="noopener noreferrer"
									>
										{'factoryreset '}
									</a> */}
								</Table.Cell>
							</Table.Row>
						))}
					</Table>
				) : null}
			</Table.Cell>
			<Table.Cell>
				{val && val.instagrams && val.instagrams.length ? (
					<Table>
						{_.map(val && val.instagrams, (it) => (
							<Table.Row>
								<Table.Cell>{it.id}</Table.Cell>
								<Table.Cell style={{ textAlign: 'center' }}>{it.number || '-----'}</Table.Cell>
								<Table.Cell>{it.status}</Table.Cell>
								<Table.Cell style={{ textAlign: 'center' }}>
									<a
										href={'https://wapi.yapaytech.com/api/' + (val.wapi_pid || val.pid) + '/' + it.id + '/redeploy?token=' + val.wapi_token}
										target="_blank"
										style={{ margin: 'auto' }}
										rel="noopener noreferrer"
									>
										{'redeploy '}
									</a>
									{/* <a
										href={
											'https://wapi.yapaytech.com/api/' +
											(val.wapi_pid || val.pid) +
											'/' +
											it.id +
											'/factoryreset?token=' +
											val.wapi_token
										}
										target="_blank"
										style={{ margin: 'auto' }}
										rel="noopener noreferrer"
									>
										{'factoryreset '}
									</a> */}
								</Table.Cell>
							</Table.Row>
						))}
					</Table>
				) : null}
			</Table.Cell>

			<Table.Cell>{val && val.is_reseller ? 'true' : 'false'}</Table.Cell>
			<Table.Cell>{val && val.is_enterprise ? 'true' : 'false'}</Table.Cell>
			<Table.Cell>
				{
					<>
						<p>{val && val.is_eticaret ? 'true' : 'false'}</p>
						<p>{val && val.domain}</p>
					</>
				}
			</Table.Cell>
		</Table.Row>
	));

export default class MainTable extends Component {
	state = {
		loading: true,
		activePage: 1,
		limit: 50,
		email: '',
		buttonloading: true,
	};
	componentDidMount() {
		this.postData();
	}
	async postData() {
		await post('/live/admin/dashboard').then((data) => this.setState({ data: _.orderBy(data.data.result, 'owner_id', 'desc'), loading: false }));
	}
	handleSort = (clickedColumn, data) => () => {
		const { column, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				data: _.sortBy(data, [clickedColumn]),
				direction: 'ascending',
			});

			return;
		}

		this.setState({
			data: data.reverse(),
			direction: direction === 'ascending' ? 'descending' : 'ascending',
		});
	};
	render() {
		const { column, direction, loading, data } = this.state;
		if (loading) return <Segment attached="bottom">loading...</Segment>;
		let dg = _.map(data, function (it) {
			it = {
				agent_limit: it.agent_limit || 0,
				phone_limit: it.phone_limit || 0,
				instagram_limit: it.instagram_limit || 0,
				owner_id: it.owner_id,
				owner_name: it.owner_name,
				is_reseller: it.is_reseller,
				is_enterprise: it.is_enterprise,
				is_eticaret: it.is_eticaret,
				owner_email: it.owner_email,
				pid: it.pid,
				token: it.token,
				agents: it.agents,
				phones: it.phones,
				instagrams: it.instagrams,
				package: it.package,
				verified: it.verified,
				total_agent: it.total_agent,
				wapi_pid: it.wapi_pid,
				wapi_token: it.wapi_token,
				analyticview: it.analyticview,
				userlist: (it.analyticview && it.analyticview.userlist) || false,
				ecommerce: (it.analyticview && it.analyticview.ecommerce) || false,
				ratePoint: (it.analyticview && it.analyticview.ratePoint) || false,
				sendProduct: (it.analyticview && it.analyticview.sendProduct) || false,
				showTemplate: (it.analyticview && it.analyticview.showTemplate) || false,
				conversationGroup: (it.analyticview && it.analyticview.conversationGroup) || false,
				orderRefunded_count: 0,
				orderRefunded_len: 0,
				orderCanceled_count: 0,
				orderCanceled_len: 0,
				test_account: it.test_account || false,
			};
			return it;
		});
		return (
			<div style={{ display: 'flex', maxHeight: '95%' }}>
				<Tab.Pane attached={true} style={{ overflow: 'auto', padding: 0 }}>
					<div className="scroll">
						<Table celled sortable>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>#</Table.HeaderCell>
									<Table.HeaderCell sorted={column === 'owner_id' ? direction : null} onClick={this.handleSort('owner_id', dg)}>
										Id
									</Table.HeaderCell>
									<Table.HeaderCell sorted={column === 'owner_name' ? direction : null} onClick={this.handleSort('owner_name', dg)}>
										Name
									</Table.HeaderCell>
									<Table.HeaderCell sorted={column === 'owner_email' ? direction : null} onClick={this.handleSort('owner_email', dg)}>
										Email
									</Table.HeaderCell>
									<Table.HeaderCell width={1} sorted={column === 'verified' ? direction : null} onClick={this.handleSort('verified', dg)}>
										Verified
									</Table.HeaderCell>
									<Table.HeaderCell sorted={column === 'package' ? direction : null} onClick={this.handleSort('package', dg)}>
										Package
									</Table.HeaderCell>
									<Table.HeaderCell sorted={column === 'agent_limit' ? direction : null} onClick={this.handleSort('agent_limit', dg)}>
										Agent Limit
									</Table.HeaderCell>
									<Table.HeaderCell sorted={column === 'phone_limit' ? direction : null} onClick={this.handleSort('phone_limit', dg)}>
										Phone Limit
									</Table.HeaderCell>
									<Table.HeaderCell sorted={column === 'instagram_limit' ? direction : null} onClick={this.handleSort('instagram_limit', dg)}>
										Instagram Limit
									</Table.HeaderCell>
									<Table.HeaderCell sorted={column === 'agents' ? direction : null} onClick={this.handleSort('agents', dg)}>
										Agents
									</Table.HeaderCell>
									<Table.HeaderCell sorted={column === 'phones' ? direction : null} onClick={this.handleSort('phones', dg)}>
										Phones
									</Table.HeaderCell>
									<Table.HeaderCell sorted={column === 'instagrams' ? direction : null} onClick={this.handleSort('instagrams', dg)}>
										Instagrams
									</Table.HeaderCell>
									<Table.HeaderCell sorted={column === 'is_reseller' ? direction : null} onClick={this.handleSort('is_reseller', dg)}>
										Reseller
									</Table.HeaderCell>
									<Table.HeaderCell sorted={column === 'is_enterprise' ? direction : null} onClick={this.handleSort('is_enterprise', dg)}>
										Enterprise
									</Table.HeaderCell>
									<Table.HeaderCell sorted={column === 'is_eticaret' ? direction : null} onClick={this.handleSort('is_eticaret', dg)}>
										ECommerce
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>{TableLine(dg)} </Table.Body>
						</Table>
					</div>
				</Tab.Pane>
			</div>
		);
	}
}
