import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Page from '../../components/Page';

//import AppList from './appList';
//import AppPage from './appPage';
import Yours from './yours';
//import Edit from './appEdit';
import MainApps from './mainApps';
import Agents from './agents';
import Install from './install';
import Help from './help';

export default (props) => {
	let roleAgent =
		props.access &&
		props.access.user &&
		props.access.user.loggedUserObj &&
		props.access.user.loggedUserObj.role &&
		props.access.user.loggedUserObj.role === 'agent';
	return (
		<Page title="Eklenti" icon="whatsapp">
			<Switch>
				{roleAgent ? <Route path="/" exact component={Install} /> : <Route path="/" exact component={MainApps} />}

				<Route path="/appstore" exact component={MainApps} />
				{/* <Route path="/appstore/app/:id" exact component={AppPage} />
				<Route path="/appstore/app/edit/:id" component={Edit} />
				<Route path="/appstore/app/create/app_:id" component={Edit} /> */}
				<Route path="/appstore/myapps" component={Yours} />
				<Route path="/appstore/agents" component={Agents} />
				<Route path="/appstore/install" component={Install} />
				<Route path="/appstore/help" component={Help} />
			</Switch>
		</Page>
	);
};
